import { Component, Input, OnInit } from '@angular/core';
import { grafica } from 'src/app/interfaces/dashboard.interface';

@Component({
  selector: 'app-graficas',
  templateUrl: './graficas.component.html',
  styleUrls: ['./graficas.component.scss'],
})
export class GraficasComponent implements OnInit {
  @Input() graficas: Array<grafica> = [];
  @Input() loading: Boolean;

  constructor() {}

  ngOnInit(): void {}
}
