import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractPagination } from 'src/app/interfaces/Pagination.interface';
import { AppState } from 'src/app/store/app.state';
import { AltasAreasTableActions, TypesInColumnsGeneral } from '../../constants/AltasAreas.constants';
import { categories } from '../../enums/categories';
import { AltasService } from 'src/app/services/altas.service';
import { PageEvent } from '@angular/material/paginator';
import { PowerbiService } from 'src/app/services/powerbi.service';
import { changeEditorStep, selectEditorArea } from '../state/editor.actions';
import { AltaArea, OtherOptionsTableAltas } from 'src/app/interfaces/AltasArea.interface';
import { CommonService } from 'src/app/services/Common.service';
import { AltasAreasService } from 'src/app/services/AltasAreas.service';
import { Router } from '@angular/router';
import { saveClientData } from 'src/app/pages/admin/state/admin.actions';
import { loadSuperuserToken } from 'src/app/auth/state/auth.actions';

@Component({
  selector: 'app-list-areas',
  templateUrl: './list-areas.component.html',
  styleUrls: ['./list-areas.component.scss']
})
export class ListAreasComponent implements OnInit, OnChanges {

  @Input('columns') displayedColumns: string[] = [];
  @Input('pagination') pagination: AbstractPagination<any> | null = null;
  @Input('maxHeight') height: number = 60;
  @Input('loading') loading: boolean = false;
  @Input('orderby') orderby: string | null = null;
  @Input('typesColumns') typesColumns: Object = { };
  @Input('columnsNames') columnsNames: Object = {};
  @Input('limit') limit: number = 10;
  @Input('notFilteredColumns') notFilteredColumns: string[] = [];
  @Input('filter') actualFilter: Object = {}
  @Input('moreOptions') moreOptions: OtherOptionsTableAltas[] = [];
  @Input('selectableRows') selectableRows: boolean = false;
  @Input('hoverStatus') hoverStatus: boolean = false;
  @Input('addButton') addButton: boolean = false;

  @Output('changePage') changePageEmitter:EventEmitter<{
    page: number,
    limit: number,
    orderby: string,
    filter?: { key?: string, value?: any, drop?: boolean },
    dropAll?: boolean
  }> = new EventEmitter();
  @Output('optionSelected') optionSelectedEmitter: EventEmitter<{option: OtherOptionsTableAltas | string, area: AltaArea}> = new EventEmitter();
  @Output('rowSelected') rowSelectedEmitter: EventEmitter<AltaArea> = new EventEmitter();
  @Output('createArea') createAreaEmitter: EventEmitter<boolean> = new EventEmitter();
  @Input('actions') actionsTable: AltasAreasTableActions[] = [];
  altasAreasTableActions = AltasAreasTableActions;


  filterColumns: string[] = [];
  typesInColumnsGeneral = TypesInColumnsGeneral;
  categories = categories;
  pageSizeOptions: number[] = AltasService.pageSizeOptions;
  pageIndex: number = 0;
  columnSelected: string | null = null;
  valueFilter: any = '';
  mouseEnterPopList: { pop: any, mouseEnterPop: boolean }[] = [];
  categoriesEnum = Object.entries(categories)
  .map(([key, value]) => { return { key: key, value: value }})
  .filter(({key, value}) => typeof value === 'number' && value > 1 && value < 4);

  constructor(
    private store: Store<AppState>,
    private altasService: AltasService,
    private altasAreasService: AltasAreasService,
    private powerbiService: PowerbiService,
    private commonService: CommonService,
    private router: Router
    ) { }

  ngOnInit(): void {
    if(!this.moreOptions?.length) {
      this.actionsTable = this.actionsTable?.filter(e => e !== AltasAreasTableActions.OTHER_OPTIONS);
      (this.actionsTable?.length) ?
      !this.displayedColumns.includes(AltasAreasTableActions.LABEL) && this.displayedColumns.push(AltasAreasTableActions.LABEL) :
      this.displayedColumns = this.displayedColumns.filter(e => e !== AltasAreasTableActions.LABEL)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if('pagination' in changes) {
      this.pageSizeOptions = (this.pagination?.objects) ? this.altasService.getPageSizeOptions(this.pagination?.objects) : [];
      this.limit = (this.pageSizeOptions?.length === 1) ? this.pageSizeOptions[0] : this.pagination?.limit;
      // check if pageIndex is correct
      let page = this.powerbiService.getPageFromUrl(this.pagination?.current);
      if(page > 0 && page - 1 !== this.pageIndex) {
        this.pageIndex = page - 1;
      }
    }

    if('displayedColumns' in changes) {
      this.filterColumns = this.displayedColumns.filter(e => !this.notFilteredColumns?.includes(e))
      if((!this.columnSelected || this.filterColumns?.every(e => e !== this.columnSelected)) && this.filterColumns?.length) {
        this.columnSelected = this.filterColumns[0];
      }
    }

    if('notFilteredColumns' in changes) {
      this.filterColumns = this.displayedColumns.filter(e => !this.notFilteredColumns?.includes(e))
    }

    if('moreOptions' in changes) {
      if(!this.moreOptions?.length) {
        this.actionsTable = this.actionsTable?.filter(e => e !== AltasAreasTableActions.OTHER_OPTIONS);
        (this.actionsTable?.length) ?
        !this.displayedColumns.includes(AltasAreasTableActions.LABEL) && this.displayedColumns.push(AltasAreasTableActions.LABEL) :
        this.displayedColumns = this.displayedColumns.filter(e => e !== AltasAreasTableActions.LABEL)
      }
    }

    if('actionsTable' in changes) {
      (this.actionsTable?.length) ?
      !this.displayedColumns.includes(AltasAreasTableActions.LABEL) && this.displayedColumns.push(AltasAreasTableActions.LABEL) :
      this.displayedColumns = this.displayedColumns.filter(e => e !== AltasAreasTableActions.LABEL)
    }

  }

  emitAdd() {
    this.createAreaEmitter.emit(true);
  }

  changeOrder(column: string) {
    let startsWithHypen = column.startsWith('-');
    column = column.replace('-', '');
    this.orderby = (column === this.orderby && !startsWithHypen) ? '-' + column : column;
    this.changePageEmitter.emit({ page: 1, limit: this.limit, orderby: this.orderby });
    this.pageIndex = 0;
  }

  changePage({ length, pageIndex, pageSize }: PageEvent) {
    if(pageSize !== this.limit) {
      // change limit
      this.limit = pageSize;
      this.changePageEmitter.emit({ page: 1, limit: pageSize, orderby: this.orderby });
      this.pageIndex = 0;
      return;
    }
    // change page
    this.pageIndex = pageIndex;
    this.changePageEmitter.emit({ page: pageIndex + 1, limit: pageSize, orderby: this.orderby });
  }

  onMouseenterStatus(event, popover) {
    this.mouseEnterPopList = this.mouseEnterPopList.filter(e => e.pop['_ngbPopoverWindowId'] !== popover['_ngbPopoverWindowId']);
    this.mouseEnterPopList.push({ pop: popover, mouseEnterPop: false });
    popover.open();
  }

  onMouseLeaveStatus(event, popover) {
    setTimeout(() => {
      let p = this.mouseEnterPopList.find(e => e.pop['_ngbPopoverWindowId'] === popover['_ngbPopoverWindowId']);
      if(p) {
        if(!p.mouseEnterPop) {
          popover.close();
          this.mouseEnterPopList = this.mouseEnterPopList.filter(e => e.pop['_ngbPopoverWindowId'] !== popover['_ngbPopoverWindowId']);
        }
      }
    }, 1000)

  }

  onMouseenterPopover(event, popover) {
    let p = this.mouseEnterPopList.find(e => e.pop['_ngbPopoverWindowId'] === popover['_ngbPopoverWindowId']);
    if(p) {
      p.mouseEnterPop = true;
    }
  }

  onMouseLeavePopover(event, popover) {
    let p = this.mouseEnterPopList.find(e => e.pop['_ngbPopoverWindowId'] === popover['_ngbPopoverWindowId']);
    if(p) {
      popover.close();
      this.mouseEnterPopList = this.mouseEnterPopList.filter(e => e.pop['_ngbPopoverWindowId'] !== popover['_ngbPopoverWindowId']);
    }
  }

  changeColumnSelected(column: string) {
    if(this.typesColumns[this.columnSelected] === this.typesInColumnsGeneral.BOOLEAN) {
      this.valueFilter = true;
      return;
    }
    if(this.typesColumns[this.columnSelected] === this.typesInColumnsGeneral.CATEGORY) {
      this.valueFilter = (this.categoriesEnum?.length) ? this.categoriesEnum[0].value : 0;
      return;
    }

    if([this.typesInColumnsGeneral.DATE, this.typesInColumnsGeneral.DATE_CADUCATE].includes(this.typesColumns[this.columnSelected])) {
      let date = new Date(Date.now());
      this.valueFilter = this.commonService.convertMomentDateToString(date);
      return;
    }

    this.valueFilter = '';
  }

  changeFilter(filt?: {key?: string, value?: any, drop?: boolean }, dropAll?: boolean) {
    this.changePageEmitter.emit({
      page: 1,
      limit: this.limit,
      orderby: this.orderby,
      filter: (filt) ? { ...filt } : { key: this.columnSelected, value: this.valueFilter },
      dropAll: dropAll
    });
    this.changeColumnSelected(this.columnSelected);
  }

  changeDate() {
    this.valueFilter = this.commonService.convertMomentDateToString(this.valueFilter);
  }

  flowStatusHover(option: string, area: AltaArea) {
    if(option === 'in_update_flow' && area.in_update_flow) return;
    this.optionSelectedEmitter.emit({ option: option, area: area });
  }

  goToArea(element: AltaArea) {
    let area = this.altasAreasService.transformAltasAreaToAreaObject(element);
    this.store.dispatch(changeEditorStep({ step: 1 }));

    this.store.dispatch(selectEditorArea({ area: area }));
    let url: string = (element.fk_cliente__category === categories.DEMOS) ? '/admin/demos/demo?type=2' :
    (element.fk_cliente__category === categories.CLIENTES) ? '/admin/clientes/cliente?type=3' :
    '';

    if(url !== '') {
      let dialogSwal: any = this.commonService.loadSwalSpinner('Preparando datos...');
      this.powerbiService.getPaginationPowerbi({ filtro: {workspace: element.fk_cliente__workspace}})
      .pipe()
      .subscribe(value => {
        dialogSwal.close();
        let client: any = (value?.datos?.length) ? value.datos[0] : null;
        client?.id && this.store.dispatch(loadSuperuserToken({ id: client.id }));
        this.store.dispatch(saveClientData({ cliente: client }));
        this.router.navigateByUrl(url);
      })
    }
  }

  selectOptionActions(option: OtherOptionsTableAltas, area: AltaArea) {
    this.optionSelectedEmitter.emit({ option: option, area: area });
  }

  selectRow(area: AltaArea) {
    this.rowSelectedEmitter.emit(area);
  }
}
