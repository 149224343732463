import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Producto } from 'src/app/interfaces/producto';
import { AppState } from 'src/app/store/app.state';
import { loadEditorProductos, loadProductsConf } from '../state/editor.actions';
import { getAreaProducts } from '../state/editor.selector';

@Component({
  selector: 'app-editor-products',
  templateUrl: './editor-products.component.html',
  styleUrls: ['./editor-products.component.scss']
})
export class EditorProductsComponent implements OnInit {

  @Input() client;
  @Input() area;

  @Output() editarDato:EventEmitter<any> = new EventEmitter();
  @Output() productLoaderEvent:EventEmitter<{ productLoader: boolean }> = new EventEmitter();
  
  plan_selected: string = 'sin plan';

  products_configuration: any[] = []
  crop: string | null = null
  area_products: Producto[];

  indeterminates: any = {};
  checked: any = {};

  productLoader: boolean = true;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private store: Store<AppState>,private actions: Actions) { }

  ngOnInit(): void {
    // Get products configurations
    this.store.dispatch(loadEditorProductos({client: this.client, area: this.area}));
  }

  ngAfterViewInit(){
    // Listen to product configurations
    this.actions.pipe(
      ofType(loadProductsConf),
      takeUntil(this.ngUnsubscribe))
    .subscribe( (value: any) => {
      this.productLoader = false;
      this.productLoaderEvent.emit({productLoader: this.productLoader});
      if(!this.products_configuration && value.configurations){
        this.products_configuration = value.configurations;
        const groups = Object.keys(this.products_configuration)
        setTimeout(() => {
          groups.forEach(element => {
            this.checkGroupSelection(element);
          });
        }, 500);
      }
      this.products_configuration = value.configurations;
    })

    // Listen to area products
    this.store.select(getAreaProducts)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(value => {
      this.area_products = value;
    })
  }

  ngOnChanges(changes: SimpleChange){
    if(changes['area'] && changes['area'].currentValue && changes['area'].currentValue.cultivo){
      this.formatCropProducts(changes['area'].currentValue!.cultivo)
    }
  }

  changeSelection(group: string, product: any){
    // Find configuration
    let configurations = JSON.parse(JSON.stringify(this.products_configuration));
    let found = this.products_configuration[group].findIndex((conf: any) => conf.id == product.id);
    if(found>=0){
      // Change configuration group
      configurations[group][found].check = !configurations[group][found].check;

      // Save configurations
      this.products_configuration = configurations;

      // Check group checkbox
      this.checkGroupSelection(group);
    }
  }

  changeGroupSelection(group: string, event: any){
    // Get event value
    let value: boolean = event.target.checked;

    // Get group configurations
    let configurations = JSON.parse(JSON.stringify(this.products_configuration));

    // Change group configurations value
    configurations[group].forEach((conf: any) => {
      conf.check = value;
    });

    this.products_configuration = configurations;

    // Check group checkbox
    this.checkGroupSelection(group);
  }

  checkGroupSelection(group: string){
    // Get uniques values
    let uniques = [...new Set(this.products_configuration[group].map(item => item.check))];

    // Get HTML element
    var el: any = document.getElementById(group+'_checkbox');

    // Modify group checkbox style
    if(el){
      if(uniques.length == 1){
        this.indeterminates[group] = false;
        if(uniques[0] == true){
          this.checked[group] = true;
        }else if(uniques[0] == false){
          this.checked[group] = false;
        }
      }else{
        this.checked[group] = false;
        this.indeterminates[group] = true;
      }
    }

    this.editarDato.emit(this.products_configuration)
  }

  formatCropProducts(crop: string){
    this.crop = crop
    if(this.products_configuration){
      var configurations = JSON.parse(JSON.stringify(this.products_configuration))
      Object.keys(configurations).forEach((element: string) => {
        configurations[element].forEach((conf: any) => {
          if(!conf.cultivos.includes(this.crop)){
            conf.check=false
          }
        });
      });
      this.products_configuration = configurations
      this.editarDato.emit(this.products_configuration)
    }
  }

  changePlan(event){
    let numbers = []
    if(event=='basic'){
      numbers = [2,6]
    }else if(event=='premium'){
      numbers = [6,10]
    }
    if(this.products_configuration){
      var configurations = JSON.parse(JSON.stringify(this.products_configuration))
      Object.keys(configurations).forEach((element: string) => {
        configurations[element].forEach((conf: any, index: number) => {
          if(index>=numbers[0] && index <= numbers[1]){
            conf.check = true
          }else{
            conf.check = false
          }
        });
      });
      this.products_configuration = configurations
      this.editarDato.emit(this.products_configuration)
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
