import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { changeLoginWaiting, noData } from 'src/app/auth/state/auth.actions';
import {
  createAreaSuccess,
  editClientSuccess,
} from 'src/app/commons/editor/state/editor.actions';
import { Pagination } from 'src/app/interfaces/Pagination.interface';
import { AltasService } from 'src/app/services/altas.service';
import { clearRequest } from 'src/app/services/clearRequest.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import { setLoading } from '../../state/admin.actions';
import { getClientData } from '../../state/admin.selector';
import {
  changeKeyValueAltas,
  createMongoArea,
  createMongoClient,
  getMongoArea,
  getMongoClient,
  getMongoSuccess,
  getNotProcessingAreas,
  getNotProcessingAreasSuccess,
  getNotProcessingClients,
  getNotProcessingClientsSuccess,
  getProductProcess,
  getProductProcessSuccess,
  loadAltasFilters,
  loadDataAltas,
  loadStatistics,
  loadStatisticsSuccess,
  postNotProcessingClientsAndAreas,
  updateAltasClients,
  updateAltasClientsSuccess,
} from './altas.actions';
import { getAltasActiveFilters } from './altas.selector';

@Injectable()
export class AltasEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private altasService: AltasService,
    private clearRequestService: clearRequest
  ) {}

  /** STATISTICS */
  loadStatistics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadStatistics),
      withLatestFrom(this.store.select(getAltasActiveFilters)),
      switchMap((action) => {
        let { filters, date } = action[0];
        let activeFilters = action[1];

        let petitions = this.altasService.buildPetitions(
          filters,
          activeFilters,
          date
        );

        if (petitions) {
          return forkJoin(petitions).pipe(
            map((data: any) => {
              filters = filters.concat([{ attribute: 'date', value: date }]);
              this.store.dispatch(loadAltasFilters({ filters: filters }));
              return loadStatisticsSuccess({ data: data });
            })
          );
        } else {
          return of(noData());
        }
      })
    );
  });

  /** PROCESSES */
  loadDataAltas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDataAltas),
      withLatestFrom(this.store.select(getAltasActiveFilters)),
      tap((e) =>
        this.store.dispatch(
          changeKeyValueAltas({ key: 'loadingData', value: true })
        )
      ),
      switchMap(([action, filters]) => {
        let { page, limit, orderby, filter, load, date } = action.attributes;
        let filter_aux = JSON.parse(JSON.stringify(filter));
        filter = this.altasService.formatFilters(filter, date);
        // replace this for call to api
        return this.altasService
          .getUrlLoadData({ page, limit, orderby, filter, load })
          .pipe(
            takeUntil(
              this.clearRequestService.onCancelPendingRequestsAltasStatistics()
            ),
            map((data: Pagination) => {
              this.store.dispatch(
                changeKeyValueAltas({ key: 'activeFilters', value: filter_aux })
              );
              return changeKeyValueAltas({
                key: 'pagination',
                value: JSON.parse(JSON.stringify(data)),
              });
            }),
            catchError((error) => {
              return of(
                changeKeyValueAltas({
                  key: 'pagination',
                  value: JSON.parse(JSON.stringify(null)),
                })
              );
            })
          );
      }),
      map((e) => {
        this.store.dispatch(
          changeKeyValueAltas({ key: 'loadingData', value: false })
        );
        return e;
      })
    );
  });

  /** MONGO */
  createMongoClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createMongoClient),
      exhaustMap((action) => {
        let body: any = {
          id: action.data.id,
          workspace: action.data.workspace,
          type_client: action.data.category == 3 ? 'client' : 'demo',
          server_ip: null,
        };
        // replace this for call to api
        return this.altasService.createMongoClient(body).pipe(
          map((data: any) => {
            return editClientSuccess({ isNew: true });
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Cliente no registrado',
            });
            return of(changeLoginWaiting({ status: false }));
          })
        );
      })
    );
  });

  createMongoArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createMongoArea),
      exhaustMap((action) => {
        let body: any = {
          id: action.data.id,
          name: action.data.nombre,
          client_id: action.data.fk_cliente,
        };
        // replace this for call to api
        return this.altasService.createMongoArea(body).pipe(
          map((data: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Área creada correctamente',
              showConfirmButton: false,
              timer: 1500,
            });
            return createAreaSuccess({ data: action.data });
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Ha habido un problema creando el área.',
              text: 'Área no creada',
              showConfirmButton: false,
              timer: 1500,
            });
            return of(setLoading({ loading: false }));
          })
        );
      })
    );
  });

  getMongoClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMongoClient),
      withLatestFrom(this.store.select(getClientData)),
      exhaustMap((action) => {
        return this.altasService.getMongoClient(action[0].client).pipe(
          map((data: any) => {
            return getMongoSuccess({ attribute: 'mongoClient', value: data });
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'warning',
              title: 'El cliente no está creado en la BBDD de Mongo.',
              showConfirmButton: true,
              confirmButtonText: 'Crear',
              allowOutsideClick: false,
            }).then((result) => {
              this.store.dispatch(createMongoClient({ data: action[1] }));
            });
            return of(noData());
          })
        );
      })
    );
  });

  getMongoArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMongoArea),
      withLatestFrom(this.store.select(getClientData)),
      exhaustMap((action) => {
        let area = JSON.parse(JSON.stringify(action[0].area));
        area.fk_cliente = action[1].id;
        return this.altasService.getMongoArea(action[0].area.id).pipe(
          map((data: any) => {
            return getMongoSuccess({ attribute: 'mongoArea', value: data });
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'warning',
              title: 'El area no está creada en la BBDD de Mongo.',
              showConfirmButton: true,
              confirmButtonText: 'Crear',
              allowOutsideClick: false,
            }).then((result) => {
              this.store.dispatch(createMongoArea({ data: area }));
            });
            return of(noData());
          })
        );
      })
    );
  });

  getNotProcessingClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNotProcessingClients),
      switchMap((action) => {
        return this.altasService.getNotProcessing('clients').pipe(
          map((data: any[]) => {
            return getNotProcessingClientsSuccess({ data: data });
          }),
          catchError((error) => {
            return of(noData());
          })
        );
      })
    );
  });

  getNotProcessingAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNotProcessingAreas),
      switchMap((action) => {
        return this.altasService.getNotProcessing('areas').pipe(
          map((data: any[]) => {
            return getNotProcessingAreasSuccess({ data: data });
          }),
          catchError((error) => {
            return of(noData());
          })
        );
      })
    );
  });

  postNotProcessingAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postNotProcessingClientsAndAreas),
      switchMap((action) => {
        let bodyAreas = this.altasService.formatAreasBody(
          action.areas,
          action.clientsSelected
        );

        let petitions = [
          this.altasService.postNotProcessing(
            'areas',
            JSON.stringify(bodyAreas)
          ),
        ];

        return forkJoin(petitions).pipe(
          map((data: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Clientes y areas cread@s',
            });
            this.store.dispatch(getNotProcessingAreas());
            return noData();
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al crear l@s clientes/areas',
              text: error,
            });
            return of(noData());
          })
        );
      })
    );
  });

  updateAltasClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAltasClients),
      mergeMap(({ id, body }) => {
        return this.altasService.updateProcess(id, body).pipe(
          map((result: any) => {
            return updateAltasClientsSuccess({
              result: true,
              message: 'Proceso actualizado',
              newAlta: result,
            });
          }),
          catchError((error) => {
            return of(
              updateAltasClientsSuccess({
                result: false,
                message: 'Error al actualizar el proceso',
                description: error['errors'] || error || '',
              })
            );
          })
        );
      })
    );
  });

  getProductProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProductProcess),
      mergeMap(({ id }) => {
        return this.altasService.getProductsProcess(id).pipe(
          map((result: any) => {
            return getProductProcessSuccess({
              result: true,
              products:
                'products' in result ? result['products'] : result || [],
            });
          }),
          catchError((error) => {
            let result = this.altasService.resultGetProducts(error);

            return of(
              getProductProcessSuccess({
                result: result,
                message: result
                  ? 'Error al obtener los productos del proceso'
                  : undefined,
                products: [],
              })
            );
          })
        );
      })
    );
  });
}
