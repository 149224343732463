import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import {
  MatCalendar,
  MatCalendarHeader,
  MatDatepickerIntl,
} from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarAltasService } from 'src/app/services/CalendarAltas.service';

@Component({
  selector: 'app-custom-altas-calendar-header',
  templateUrl: './custom-altas-calendar-header.component.html',
  styleUrls: ['./custom-altas-calendar-header.component.scss'],
})
export class CustomAltasCalendarHeaderComponent
  extends MatCalendarHeader<any>
  implements OnInit
{
  private destroyed = new Subject<void>();
  loading: boolean = false;
  last: Date | null = null;
  first: Date | null = null;
  actualViewDate: { $gte: Date; $lt: Date };
  disabledLeftArrow: boolean = false;
  disabledRightArrow: boolean = false;

  constructor(
    _intl: MatDatepickerIntl,
    calendar: MatCalendar<any>,
    private dateAdapter: DateAdapter<Date>,
    private calendarService: CalendarAltasService,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef
  ) {
    super(_intl, calendar, dateAdapter, dateFormats, cdr);
  }

  ngOnInit() {
    this.calendarService.loadingCalendarSubject
      .pipe(takeUntil(this.destroyed))
      .subscribe((element) => {
        this.loading = element;
        !element && this.calendar.updateTodaysDate();
      });

    this.calendarService.actualDateViewSubject
      .pipe(takeUntil(this.destroyed))
      .subscribe((element) => {
        this.calendar.activeDate = element;
        this.disabledArrows(this.calendar.activeDate);
      });

    this.calendarService.limitsSubject
      .pipe(takeUntil(this.destroyed))
      .subscribe((element) => {
        let { last, first } = element;
        this.last = last;
        this.first = first;
        this.disabledArrows(this.calendar.activeDate);
      });

    this.actualViewDate = this.calendar.activeDate;
  }

  disabledArrows(actualDate: Date) {
    this.disabledRightArrow =
      this.last !== null &&
      this.last.getMonth() === actualDate.getMonth() &&
      this.last.getFullYear() === actualDate.getFullYear();
    this.disabledLeftArrow =
      this.first !== null &&
      this.first.getMonth() === actualDate.getMonth() &&
      this.first.getFullYear() === actualDate.getFullYear();
  }

  customPreviousNextClicked(type: number) {
    switch (this.calendar.currentView) {
      case 'month': {
        let activeDate = this.calendar.activeDate;
        let year = activeDate.getFullYear();
        let month = activeDate.getMonth() + type;

        let dateStart = new Date(year, month, 1);
        let dateEnd = new Date(year, month + 1, 0);

        this.actualViewDate = { $gte: dateStart, $lt: dateEnd };
        this.calendar.monthSelected.emit(this.actualViewDate);
        break;
      }
      case 'multi-year':
        this.previousNextMultiYearClicked('year', type);
        break;
      case 'year':
        this.calendar.yearSelected.emit(this.actualViewDate);
        break;
      default:
        break;
    }
  }

  /**
   * Previous multi year clicked
   * @param mode
   */
  previousNextMultiYearClicked(mode: 'year', type: number) {
    // rangos de la nueva vista multi-year
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(
      this.calendar.activeDate,
      type * 24
    );
    this.disabledArrows(this.calendar.activeDate);
  }

  yearViewClicked() {
    this.calendar.currentView = 'multi-year';
    this.disabledArrows(this.calendar.activeDate);
  }

  get periodLabel() {
    this.calendarService.changeViewSubject.next(this.calendar.currentView);
    return this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
