<div style="width: 75vw; height: auto">
  <!-- CREATE/EDIT SERVER -->
  <div *ngIf="data && data.tipo != 'area'">
    <!-- TITLE -->
    <div style="margin-bottom: 2vw">
      <span style="font-size: x-large">{{
        (data.servidor ? "Editar " : "Crear ") + "Servidor"
      }}</span>
    </div>
    <!-- FORM -->
    <div *ngIf="!loading">
      <form [formGroup]="serverForm" (keydown.enter)="$event.preventDefault()">
        <div class="divForm">
          <mat-form-field class="col-lg-3 matFormFild">
            <!-- IP -->
            <mat-label>Ip</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="ip"
              type="text"
              placeholder="Ip*"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['ip'].invalid &&
                serverForm.controls['ip'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Ip no válida</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-3 matFormFild">
            <!-- NAME -->
            <mat-label>Name</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="name"
              type="text"
              placeholder="Name*"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['name'].invalid &&
                serverForm.controls['name'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Name no válido</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-3 matFormFild">
            <!-- PORT_RDP -->
            <mat-label>Port_rdp</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="port_rdp"
              type="number"
              placeholder="Port_rdp*"
              OnlyNumber="true"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['port_rdp'].invalid &&
                serverForm.controls['port_rdp'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Port_rdp no válido</span
              >
            </mat-error>
          </mat-form-field>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-bottom: 2vw;
          "
        >
          <mat-form-field class="col-lg-3 matFormFild">
            <!-- PORT_SSH -->
            <mat-label>Port_ssh</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="port_ssh"
              type="number"
              placeholder="Port_ssh*"
              OnlyNumber="true"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['port_ssh'].invalid &&
                serverForm.controls['port_ssh'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Port_ssh no válido</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-3 matFormFildMargin">
            <!-- PORT_FTP -->
            <mat-label>Port_ftp</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="port_ftp"
              type="number"
              placeholder="Port_ftp*"
              OnlyNumber="true"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['port_ftp'].invalid &&
                serverForm.controls['port_ftp'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Port_ftp no válido</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-3 matFormFildMargin">
            <!-- PATH_SENTINEL_2 -->
            <mat-label>Path_sentinel_2</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="path_sentinel_2"
              type="text"
              placeholder="Path_sentinel_2*"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['path_sentinel_2'].invalid &&
                serverForm.controls['path_sentinel_2'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Path_sentinel_2 no válido</span
              >
            </mat-error>
          </mat-form-field>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-bottom: 2vw;
          "
        >
          <mat-form-field class="col-lg-4 matFormFildMargin">
            <mat-label>Path_planet</mat-label>
            <input
              matInput
              class="inputs form-control"
              formControlName="path_planet"
              type="text"
              placeholder="Path_planet*"
            />
            <mat-error
              *ngIf="
                serverForm &&
                serverForm.controls['path_planet'].invalid &&
                serverForm.controls['path_planet'].touched
              "
            >
              <span style="color: crimson; margin-left: 0.5em"
                >Path_planet no válido</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-4 matFormFildStatus">
            <!-- STATUS -->
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" name="status">
              <mat-option *ngFor="let opt of statusOptions" [value]="opt">{{
                opt | statusServers
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <!-- BUTTON CREATE/EDIT -->
    <div *ngIf="!loading" style="display: flex; justify-content: flex-end">
      <button
        [disabled]="serverForm.invalid"
        class="btn btn-success btn-lg text-white"
        (click)="sendServer()"
      >
        {{ data.servidor ? "Editar " : "Crear " }}
      </button>
    </div>
    <!-- SPINNER -->
    <div *ngIf="loading" class="spinner">
      <mat-spinner diameter="150"></mat-spinner>
    </div>
  </div>

  <!-- SEND AREA -->
  <div *ngIf="data && data.tipo == 'area'">
    <!-- TITLE -->
    <div style="margin-bottom: 2vw">
      <span style="font-size: x-large">Enviar área a otro servidor</span>
    </div>
    <!-- SEARCH -->
    <div style="margin-bottom: 2vw">
      <span style="margin-right: 1vw">Buscar:</span>
      <input
        (keyup)="applyFilter($event)"
        placeholder="Búsqueda automática"
        #input
      />
    </div>
    <!-- TABLE ALL SERVERS -->
    <div *ngIf="!loading" class="divTable">
      <table
        style="width: 100%"
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
      >
        <!-- Checkbox Column -->
        <ng-container sticky matColumnDef="select">
          <th style="padding-left: 20px" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="check($event, row)"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Other Columns -->
        <ng-container
          *ngFor="let column of displayedColumns | slice : 1"
          matColumnDef="{{ column }}"
        >
          <th mat-header-cell *matHeaderCellDef>
            <div class="centered">
              <span>{{ column | titlecase }}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="centered">
              <span>{{
                column == "status"
                  ? (element[column] | statusServers)
                  : element[column]
              }}</span>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="example-element-row"
          (click)="check($event, row)"
        ></tr>
      </table>
    </div>
    <!-- BUTTON SEND AREA -->
    <div *ngIf="!loading" style="display: flex; justify-content: flex-end">
      <button
        [disabled]="this.selection.isEmpty()"
        class="btn btn-success btn-lg text-white"
        (click)="sendArea()"
      >
        Enviar área
      </button>
    </div>
    <!-- SPINNER -->
    <div *ngIf="loading" class="spinner">
      <mat-spinner diameter="150"></mat-spinner>
    </div>
  </div>
</div>
