<!-- Titulo y 'x' para cerrar el dialogo -->
<div style="display: flex">
  <div class="containerTitulo">
    <h1 mat-dialog-title class="titulo">ASIGNAR CURVAS</h1>
  </div>
  <div style="width: 44%">
    <i
      class="fa fa-times"
      style="display: flex; float: right; color: #afaeae; cursor: pointer"
      (click)="closeTool()"
    ></i>
  </div>
</div>
<!-- Bloque de controles para asignar las curvas -->
<div class="matDialogHeight">
  <div
    style="display: flex; justify-content: center; height: 26vh"
    mat-dialog-content
  >
    <!-- Seleccion de área y filtros para el área a asignar -->
    <div class="asignarCurvas">
      <h5 class="tituloAsignar">Asignar Curvas a:</h5>
      <div class="selectorAsignar">
        <span class="spanSelector">Selección de área</span>
        <ng-select
          [disabled]="loadingCurvas"
          [ngModel]="areaAsignar | showNameArea"
          (ngModelChange)="areaAsignar = $event; pedirCurvas(1)"
          [ngModelOptions]="{ standalone: true }"
          class="inputs ngSelector ngSelectSize"
          placeholder="Selecciona una área"
          appendTo="body"
        >
          <ng-option [value]="null" disabled>Selecciona un área</ng-option>
          <ng-option *ngFor="let a of areas" [value]="a">
            {{ a | showNameArea }}
          </ng-option>
        </ng-select>
      </div>
      <div class="selectorAsignar">
        <div>
          <span class="spanSelector">Selección de producto</span>
        </div>
        <div style="display: flex; align-items: center">
          <ng-select
            [disabled]="loadingCurvas"
            [ngModel]="productoFiltro | showNameArea"
            (ngModelChange)="filtrosAsignar(1, $event)"
            class="inputs ngSelector ngSelectSize"
            placeholder="Selecciona un producto"
            [ngModelOptions]="{ standalone: true }"
            appendTo="body"
            [ngStyle]="{ 'margin-right': productoFiltro ? '-2vh' : '0' }"
          >
            <ng-option [value]="null" disabled
              >Selecciona un producto</ng-option
            >
            <ng-option
              *ngFor="let p of productosAreaFiltrados"
              [value]="p"
              [class.hidden]="p.nombre == ndvi"
            >
              {{ p | showNameArea }}
            </ng-option>
          </ng-select>
          <i
            *ngIf="productoFiltro"
            class="fa fa-times"
            style="
              margin-left: 1vh;
              color: #afaeae;
              cursor: pointer;
              transform: translateX(-300%);
            "
            (click)="limpiarFiltro(1)"
          ></i>
        </div>
      </div>
      <div class="selectorAsignar2">
        <div>
          <span class="spanSelector">Selección de variedad</span>
        </div>
        <div style="display: flex; align-items: center">
          <ng-select
            [disabled]="loadingCurvas"
            [ngModel]="variedadFiltro"
            (ngModelChange)="filtrosAsignar(2, $event)"
            class="inputs ngSelector ngSelectSize"
            placeholder="Selecciona una variedad"
            [ngModelOptions]="{ standalone: true }"
            appendTo="body"
            [ngStyle]="{ 'margin-right': variedadFiltro ? '-2vh' : '0' }"
          >
            <ng-option [value]="null" disabled
              >Selecciona una variedad</ng-option
            >
            <ng-option *ngFor="let v of variedadesArea" [value]="v">
              {{ v }}
            </ng-option>
          </ng-select>
          <i
            *ngIf="variedadFiltro"
            class="fa fa-times"
            style="
              margin-left: 1vh;
              color: #afaeae;
              cursor: pointer;
              transform: translateX(-300%);
            "
            (click)="limpiarFiltro(2)"
          ></i>
        </div>
      </div>
    </div>
    <!-- Selección de área de la que obtener las curvas y botón de asignar -->
    <div class="seleccionarCurvas">
      <h5 class="tituloSeleccionar">Seleccionar Curvas de:</h5>
      <div class="selectorSeleccionar">
        <span class="spanSelector">Selección de área</span>
        <ng-select
          [disabled]="loadingCurvas"
          style="z-index: 200"
          [ngModel]="areaSeleccionar | showNameArea : 'areasAll'"
          (ngModelChange)="areaSeleccionar = $event; pedirCurvas(2)"
          [ngModelOptions]="{ standalone: true }"
          class="inputs ngSelector ngSelectSize"
          placeholder="Selecciona una área"
          appendTo="body"
        >
          <ng-option style="z-index: 200" [value]="null" disabled
            >Selecciona un área</ng-option
          >
          <ng-option
            style="z-index: 200"
            *ngFor="let al of areasAll"
            [value]="al"
          >
            {{ al | showNameArea : "areasAll" }}
          </ng-option>
        </ng-select>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 15%;
        "
      >
        <span
          >Curvas: {{ curvasSeleccionar ? curvasSeleccionar.objects : 0 }}</span
        >
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 15%;
        "
      >
        <mat-icon>info</mat-icon>
        <span style="margin-left: 1vh; font-size: 1.3vh" class="pequeñaInfo"
          >Las curvas repetidas o que correspondan a un producto que no posee el
          área al que se asignan, no se asignarán.</span
        >
      </div>
      <div
        *ngIf="spinnerSeleccionar == true"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1vh;
        "
      >
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div *ngIf="spinnerSeleccionar == false" class="asignarCurvasBoton">
        <button
          *ngIf="productosAreaFiltrados && productosAreaFiltrados.length != 0"
          (click)="asignar()"
          mat-raised-button
          class="btn btn-info botonPequeño"
          [disabled]="
            !areaSeleccionar ||
            !curvasSeleccionar ||
            curvasSeleccionar.objects == 0 ||
            loadingCurvas == true
          "
        >
          Asignar curvas
        </button>
        <span
          *ngIf="productosAreaFiltrados && productosAreaFiltrados.length == 0"
          class="pequeñoSpan"
          style="color: red"
          >El área al que se quiere asignar las curvas no tiene los productos
          agua, ndvi, clorofila, lai.</span
        >
      </div>
    </div>
  </div>
</div>
<!-- Titulo de la tabla y botón para eliminar las curvas seleccionadas -->
<div
  style="
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1vh;
  "
>
  <h5>Curvas de {{ areaAsignar.titulo }}</h5>
  <button
    *ngIf="curvasBorrar && curvasBorrar.length > 0"
    [disabled]="loadingCurvas"
    type="button"
    class="btn btn-danger text-white botonPequeño"
    (click)="borrar()"
  >
    Eliminar
  </button>
</div>
<!-- Componente tabla con los inputs para Asignar Curvas -->
<div
  style="padding-bottom: 0 !important; margin-bottom: 0 !important"
  class="card py-3"
>
  <app-table
    [title]="'curvas'"
    [buttons]="buttonsCurva"
    [data]="curvas"
    [page]="actualPage"
    [allChecked]="allChecked"
    [orderedBy]="orderedBy"
    (selectCurvaEmitter)="selectCurva($event)"
    (changeOrderedBy)="changeOrder($event)"
    (changePage)="changePage($event)"
    (changeLimit)="changeLimit($event)"
  >
  </app-table>
</div>
