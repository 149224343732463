import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PowerbiState } from './powerbi.state';

export const POWERBI_STATE_NAME = 'powerbi';

const getPowerbiState = createFeatureSelector<PowerbiState>(POWERBI_STATE_NAME);

export const getPowerbiData = createSelector(getPowerbiState, (state) => {
  return state?.data;
});

export const getPowerbiFilter = createSelector(getPowerbiState, (state) => {
  return state?.filter;
});

export const getPowerbiLoading = createSelector(getPowerbiState, (state) => {
  return state?.loading;
});

export const getPowerbiLoadingStatus = createSelector(
  getPowerbiState,
  (state) => {
    return state?.loadingStatus;
  }
);

export const getPendingTasksPowerbi = createSelector(
  getPowerbiState,
  (state) => {
    return state?.pendingTasksPowerbi;
  }
);
