import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  InputDialogProducts,
  OrderByMode,
  ProductProcess,
} from 'src/app/interfaces/altas/Altas.interface';
import { AppState } from 'src/app/store/app.state';
import {
  getProductProcess,
  getProductProcessSuccess,
} from '../state/altas.actions';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AltasService } from 'src/app/services/altas.service';

const NONE_DISPLAY = 'none';
const NOT_NONE_DISPLAY = 'block';

@Component({
  selector: 'app-products-dialog',
  templateUrl: './products-dialog.component.html',
  styleUrls: ['./products-dialog.component.scss'],
})
export class ProductsDialogComponent implements OnInit, OnDestroy {
  productsFinished: ProductProcess[] = [];
  productsNotFinished: ProductProcess[] = [];
  productsInView: ProductProcess[] = [];
  loading: boolean = false;
  text: string = 'Cargando productos...';
  radioButtonInput: boolean = false;
  @ViewChild('productsList', { static: false }) productListDiv?;

  private displayElementHTML?: HTMLDivElement;

  private idErrorMessages = 'error-message';
  private idAllErrorMessages = 'all-' + this.idErrorMessages;
  private ngUnsubscribe = new Subject();
  OrderByMode = OrderByMode;
  orderBy: { attribute: string; mode: OrderByMode } | null = null;

  constructor(
    public dialogRef: MatDialogRef<ProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDialogProducts,
    private actions: Actions,
    private store: Store<AppState>,
    private altasService: AltasService
  ) {}

  ngOnInit() {
    this.filterProductsByFinished(this.data?.products);
    this.actions
      .pipe(ofType(getProductProcessSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe(({ result, message, products }) => {
        if (!result) {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener los productos',
          });
        }
        this.filterProductsByFinished(products);
        this.loading = false;
        this.text = 'Actualizando...';
      });
    this.reloadProducts();
  }

  private filterProductsByFinished(products: ProductProcess[]) {
    this.productsFinished = [];
    this.productsNotFinished = [];
    products?.forEach((e) => {
      e.finished
        ? this.productsFinished.push(e)
        : this.productsNotFinished.push(e);
    });
    products?.length && this.changeViewList(this.radioButtonInput);
  }

  reloadProducts() {
    this.loading = true;
    this.store.dispatch(getProductProcess({ id: this.data.id_process }));
  }

  trackByName(index: number, element: ProductProcess) {
    return element?.name;
  }

  changeViewList(value: boolean = false) {
    this.orderBy = null;
    this.productsInView = value
      ? [...this.productsFinished]
      : [...this.productsNotFinished];
  }

  selectedOrderBy(attribute: string) {
    let newMode = OrderByMode.ASC;
    if (this.orderBy?.attribute === attribute) {
      if (this.orderBy.mode === OrderByMode.DESC) {
        this.changeViewList(this.radioButtonInput);
        return;
      }
      newMode = OrderByMode.DESC;
    }
    this.orderBy = { attribute: attribute, mode: newMode };
    this.sortProductsInView(attribute);
  }

  private sortProductsInView(attribute: string) {
    this.productsInView.sort((key1, key2) => {
      if (
        [typeof key1[attribute] === 'string', typeof key2[attribute]].includes(
          'string'
        )
      ) {
        return this.orderBy.mode === OrderByMode.DESC
          ? key2[attribute].localeCompare(key1[attribute])
          : key1[attribute].localeCompare(key2[attribute]);
      }
      if (key1[attribute] === null) return 1;
      if (key2[attribute] === null) return -1;
      return this.orderBy.mode === OrderByMode.DESC
        ? key2[attribute] - key1[attribute]
        : key1[attribute] - key2[attribute];
    });
  }

  /**
   * Reset displayElementHTML to a display: none
   * @param childElement
   * @returns
   */
  private resetDisplayElement(childElement?: HTMLDivElement): void {
    if (!(this.displayElementHTML && this.displayElementHTML !== childElement))
      return;
    this.displayElementHTML.style.display = NONE_DISPLAY;
  }

  /**
   * Opens or closes error message in a item list
   * @param rootElement div element
   * @returns
   */
  changeErrorVisibility(rootElement: HTMLDivElement): void {
    let childElement: HTMLDivElement = this.altasService.searchChildNodeById(
      rootElement,
      this.idErrorMessages
    );

    this.resetDisplayElement(childElement);
    this.displayElementHTML = childElement;

    if (!childElement) return;

    childElement.style.display =
      childElement.style.display === NONE_DISPLAY
        ? NOT_NONE_DISPLAY
        : NONE_DISPLAY;
    childElement.style.display !== NONE_DISPLAY &&
      rootElement.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Change preview error message for the total message
   * @param rootElement
   */
  seeAllError(rootElement: HTMLDivElement) {
    for (let element of rootElement.childNodes) {
      if (element.nodeName === '#comment') continue;
      let childElement = element as HTMLDivElement;
      let changeDisplay =
        childElement.id === this.idAllErrorMessages ? 'block' : 'none';
      childElement.style.display = changeDisplay;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
