import { createReducer, on } from '@ngrx/store';
import {
  addBorrarCurvas,
  areasAllSuccess,
  changeEditorLoader,
  changeEditorStep,
  clearEditorState,
  createAreaSuccess,
  curvasOptimasSuccess,
  loadAreaProductsSuccess,
  loadClientAreasSucces,
  loadEditorProductosSucces,
  loadingCurves,
  selectEditorArea,
  setCurveFilter,
  uniquesLimitedSuccess,
  uniquesSuccess,
  vaciarCurvas,
} from './editor.actions';
import { initialStateEditor } from './editor.state';

const _EditorReducer = createReducer(
  initialStateEditor,
  on(loadEditorProductosSucces, (state, action) => {
    //copia profunda de los productos
    let newProducts = state.productos ? Object.assign([], state.productos) : [];
    // no existe el área en el array de productos, ya sea por id (editar) o por titulo (nuevos)
    if (newProducts.every((el) => el.area.id !== action.area.id)) {
      // no existen los productos del área
      newProducts = newProducts.concat({
        area: action.area,
        productos: action.productos,
      });
    }
    return {
      ...state,
      productos: newProducts.length > 0 ? newProducts : null,
    };
  }),
  on(loadClientAreasSucces, (state, action) => {
    var areas = action.data.filter((obj) => {
      return obj.nombre != 'global';
    });
    return {
      ...state,
      areas: areas,
    };
  }),
  on(createAreaSuccess, (state, action) => {
    var newAreas = state.areas
      ? Object.assign([], state.areas.concat(action.data))
      : [action.data];
    return {
      ...state,
      areas: newAreas,
    };
  }),
  on(clearEditorState, (state, action) => {
    return initialStateEditor;
  }),
  on(uniquesLimitedSuccess, (state, action) => {
    let uniquesLimitedCopy = Object.assign([], action.data);

    return {
      ...state,
      responseUniquesLimited: uniquesLimitedCopy,
    };
  }),
  on(uniquesSuccess, (state, action) => {
    let uniquesCopy = Object.assign([], action.data);

    return {
      ...state,
      responseUniques: uniquesCopy,
    };
  }),
  on(areasAllSuccess, (state, action) => {
    let areasCopy = Object.assign([], action.areas);

    return {
      ...state,
      areasSeleccionar: areasCopy,
    };
  }),
  on(curvasOptimasSuccess, (state, action) => {
    if (action.tipo == 1) {
      let curvasCopy = Object.assign([], action.curvas);
      return {
        ...state,
        curvas: curvasCopy,
      };
    } else if (action.tipo == 2) {
      let curvasCopy2 = Object.assign([], action.curvas);
      return {
        ...state,
        curvasSeleccionar: curvasCopy2,
      };
    }
  }),
  on(addBorrarCurvas, (state, action) => {
    let borrarCopy: any[] = state.curvasBorrar
      ? Object.assign([], state.curvasBorrar)
      : [];
    let curvasReceived: any[] = action.curvas
      ? Object.assign([], action.curvas)
      : [];

    if (curvasReceived.length > 0 && action.add) {
      // Añadir curvas
      curvasReceived.forEach((element) => {
        if (!borrarCopy.find((curva) => curva.id == element.id)) {
          borrarCopy.push(element);
        }
      });
    }

    if (curvasReceived.length > 0 && !action.add) {
      // Eliminar curvas
      curvasReceived.forEach((element) => {
        let index = borrarCopy.findIndex((curva) => curva.id == element.id);
        if (index >= 0) {
          borrarCopy.splice(index, 1);
        }
      });
    }

    return {
      ...state,
      curvasBorrar: borrarCopy,
    };
  }),
  on(vaciarCurvas, (state, action) => {
    return {
      ...state,
      curvasSeleccionar: null,
      curvas: null,
    };
  }),
  on(setCurveFilter, (state, action) => {
    let filtroCopy = action.filter;
    return {
      ...state,
      filter: filtroCopy,
    };
  }),
  on(loadingCurves, (state, action) => {
    let active = action.active;
    return {
      ...state,
      loadingCurve: active,
    };
  }),
  /** NEW */
  on(changeEditorStep, (state, action) => {
    return {
      ...state,
      step: action.step,
    };
  }),
  on(selectEditorArea, (state, action) => {
    return {
      ...state,
      area_selected: action.area,
    };
  }),
  on(loadAreaProductsSuccess, (state, action) => {
    return {
      ...state,
      area_products: action.products,
    };
  }),
  on(changeEditorLoader, (state, action) => {
    return {
      ...state,
      loader: {
        id: action.id,
        message: action.message,
        value: action.value,
      },
    };
  })
);

export function EditorReducer(state, action) {
  return _EditorReducer(state, action);
}
