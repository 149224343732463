import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { getStatusSidebar } from 'src/app/pages/admin/altas/state/altas.selector';
import { setFilter } from 'src/app/pages/admin/state/admin.actions';
import { AltasService } from 'src/app/services/altas.service';
import { AppState } from 'src/app/store/app.state';
import { sidebarItem } from '../../interfaces/sidebar.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  url: string = '';
  actualUrl: string;
  menu: sidebarItem[] = [
    {
      titulo: 'GESTIÓN CLIENTES',
      icono: 'fas fa-user-friends',
      isCollapsed: false,
      subMenu: [
        {
          titulo: 'Leads',
          icono: 'fas fa-child',
          url: '/admin/leads',
          active: false,
        },
        {
          titulo: 'Demos',
          icono: 'fas fa-play-circle',
          url: '/admin/demos',
          active: false,
        },
        {
          titulo: 'Clientes',
          icono: 'fa fa-users',
          url: '/admin/clientes',
          active: false,
        },
        {
          titulo: 'Bajas',
          icono: 'fas fa-sign-in-alt',
          url: '/admin/bajas',
          active: false,
        },
      ],
    },

    {
      titulo: 'DESCARGAS',
      icono: 'fas fa-download',
      isCollapsed: false,
      subMenu: [
        {
          titulo: 'Tiles',
          icono: ' far fa-arrow-alt-circle-up',
          url: '/admin/tiles',
          active: false,
        },
      ],
    },

    {
      titulo: 'PROCESOS',
      icono: 'fas fa-wrench',
      isCollapsed: false,
      subMenu: [
        {
          titulo: 'Altas',
          icono: 'fas fa-sync-alt',
          url: '/admin/altas',
          active: false,
        },
        {
          titulo: 'Servidores',
          icono: 'fas fa-server',
          url: '/admin/servers',
          active: false,
        },
        {
          titulo: 'PowerBI',
          icono: 'fas fa-chart-bar',
          url: '/admin/powerbi',
          active: false,
        },
      ],
    },

    {
      titulo: 'OTROS',
      icono: 'fas fa-laptop',
      isCollapsed: true,
      subMenu: [
        {
          titulo: 'Productos',
          icono: 'fas fa-chess-board ',
          url: '/admin/products',
          active: false,
        },
        {
          titulo: 'Novedades',
          icono: 'fa fa-rocket',
          url: '/admin/novedades',
          active: false,
        },
        {
          titulo: 'Estadísticas',
          icono: 'fa fa-tachometer-alt',
          url: '/admin/dashboard',
          active: false,
        },
      ],
    },
  ];
  statusAltas: number = 0;

  private ngUnsubscribe: Subject<any> = new Subject();

  public isCollapsed: boolean = false;
  isClosed: boolean = false;

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private altasService: AltasService
  ) {}

  ngOnInit(): void {
    this.router.events.pipe().subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.actualUrl = this.url;
        // url a la que va a ir después aún con el redirect
        this.url = value.urlAfterRedirects;
      }
    });
    this.url = this.router.url;
    this.actualUrl = this.url;
    this.checkStatusAltas();
    this.checkSubMenuTitle();

    this.store.select(getStatusSidebar).subscribe((value) => {
      this.isClosed = value;
    });
  }

  navigate(url) {
    // Delete filter if tab change
    if (!this.url.includes(this.actualUrl))
      this.store.dispatch(setFilter({ filter: undefined }));
    this.actualUrl = url;
    this.router.navigateByUrl(url);
    this.checkSubMenuTitle();
  }

  /** We check the status of the processes from the last month */
  async checkStatusAltas() {
    const { currentDate, previousMonthDate } =
      this.getCurrentAndPreviousMonthDates();

    try {
      const { error: errors, executing: progress }: any =
        await this.altasService.loadDatesFromYear(null, [-1, 3], {
          date: {
            $gte: this.convertDateString(`${previousMonthDate}`),
            $lt: this.convertDateString(`${currentDate}`),
          },
        });
      this.statusAltas = errors ? 2 : progress ? 1 : 0;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * We get the current date and the date from a month ago
   * @returns Object with two dates
   */
  getCurrentAndPreviousMonthDates(): {
    currentDate: Date;
    previousMonthDate: Date;
  } {
    const currentDate = new Date();
    const previousMonthDate = new Date();
    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
    return { currentDate, previousMonthDate };
  }

  /**
   * Format the default date to the format required for altasService.loadDatesFromYear()
   * @param dateString
   * @returns String with date and hour
   */
  convertDateString(dateString: string): string {
    const date = new Date(dateString);
    const isoString = date.toISOString();
    const yearMonthDay = isoString.substr(0, 10);
    const time = 'T00:00:00';
    return `${yearMonthDay + time}`;
  }

  /** Function that checks which is the active route to select the correct element in the sidebar */
  checkSubMenuTitle() {
    this.menu.forEach((item) => {
      item.subMenu.forEach((subMenuItem) => {
        subMenuItem.active = false;
      });
    });
    this.menu.forEach((item) => {
      item.subMenu.forEach((subMenuItem) => {
        if (this.actualUrl.includes(subMenuItem.url)) {
          subMenuItem.active = true;
          if (item.titulo == 'OTROS') {
            item.isCollapsed = false;
          }
        }
      });
    });
  }

  /** DESTROY */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
