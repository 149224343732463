export enum botonesTabla {
  editar = 'edit',
  usuario = 'user',
  convertir = 'convertir',
  superuser = 'superuser',
  terminado = 'terminado',
  procHistoricos = 'procesar historicos',
  procCurvas = 'procesar curvas',
  seleccionar = 'seleccionar',
  eliminar = 'eliminar',
  crear = 'crear',
  curvas = 'curvas',
}
