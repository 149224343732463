<div class="progress">
  <div
    (click)="accion()"
    class="progress-bar bg-success"
    role="progressbar"
    style="width: {{ ancho }}%"
    aria-valuenow="25"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
