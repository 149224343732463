import { dataStadisticts } from 'src/app/commons/enums/TypeStadisticsAltas.enum';
import { Pagination } from 'src/app/interfaces/Pagination.interface';

export class statistic_element {
  id: string;
  hide: boolean;
  title: string;
  data: any;
}

export interface AltasState {
  data: statistic_element[];
  activeFilters: any[];
  pagination: Pagination;
  not_processing_areas: any[];
  loadingData: boolean;
  status_Sidebar: boolean;
}

export const initialStateAltas: AltasState = {
  data: Object.values(dataStadisticts),
  activeFilters: null,
  pagination: null,
  not_processing_areas: null,
  loadingData: false,
  status_Sidebar: false,
};
