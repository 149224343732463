export interface TilesHistoric {
  id: number;
  tile: string;
  fk_tile: number;
  filename: string;
  date_download: string;
  date_processed: string;
  generation_date: string;
  cloud_percentage: number;
  status: number;
  areas: number;
  fk_tile_product: number;
  bbox: any;
}

export interface DatesByStatus {
  error: string[];
  stopped: string[];
  queue: string[];
  executing: string[];
  completed: string[];
}

export const columnsNameTilesHistoric = {
  id: 'ID',
  tile: 'Tile',
  date_download: 'Download date',
  date_processed: 'Processed date',
  generation_date: 'Generated date',
  cloud_percentage: 'Clouds',
  status: 'Status',
  filename: 'Filename',
  areas: 'Areas',
  actions: 'Actions',
};

export const displayedColumnsTilesHistoric = [
  'tile',
  'filename',
  'status',
  'cloud_percentage',
  'date_download',
  'date_processed',
  'generation_date',
  'areas',
  'actions',
];

export const orderByTilesHistoric = 'status';

export const filterColumnsTilesHistoric = ['tile'];

export const filterColumnsFormatTilesHistoric = {
  tile: 'fk_tile_product__fk_tile__name__icontains',
  date_download: 'date_download__range',
  date_processed: 'date_processed__range',
  generation_date: 'generation_date__range',
  cloud_percentage: 'cloud_percentage',
  status: 'status',
  fk_tile_product__fk_tile__name__icontains: 'tile',
  date_download__range: 'date_download',
  date_processed__range: 'date_processed',
  generation_date__range: 'generation_date',
  cloud_percentaje: 'cloud_percentage',
};

export const orderColumnsFormatTilesHistoric = {
  tile: 'fk_tile_product__fk_tile__name',
  date_download: 'date_download',
  date_processed: 'date_processed',
  generation_date: 'generation_date',
  cloud_percentage: 'cloud_percentage',
  status: 'status',
  filename: 'filename',
};
