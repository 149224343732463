import { Injectable } from "@angular/core";
import { CommonService } from "./Common.service";
import { HttpClient } from "@angular/common/http";
import { AbstractPagination } from "../interfaces/Pagination.interface";
import { AltaArea } from "../interfaces/AltasArea.interface";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { AppState } from "../store/app.state";
import { Store } from "@ngrx/store";
import { loadAltasAreasData, setKeyValueAltasArea } from "../commons/dialog/altas-areas-dialog/state/altas-area.actions";
import { labelsStateAltasArea } from "../commons/dialog/altas-areas-dialog/state/altas-area.state";
import { TypesInColumnsGeneral, typesColumnsAltasAreas } from "../commons/constants/AltasAreas.constants";
import { MatDialog } from "@angular/material/dialog";
import { AsignarCurvasComponent } from "../commons/table/asignar-curvas/asignar-curvas.component";
import { Area } from "../interfaces/area";
import { GenerarCurvasComponent } from "../commons/table/generar-curvas/generar-curvas.component";
import { ReprocesarFsComponent } from "../commons/table/reprocesar-fs/reprocesar-fs.component";
import { NewTileComponent } from "../pages/admin/tiles/new-alta/new-tile.component";
import { Actions, ofType } from "@ngrx/effects";
import { loadProccesses, setProccesses } from "../pages/admin/state/admin.actions";
import { takeUntil } from "rxjs/operators";
import { loadClientAreas, loadClientAreasSucces } from "../commons/editor/state/editor.actions";
import Swal from "sweetalert2";
import { AltasService } from "./altas.service";
import { categories } from "../commons/enums/categories";

@Injectable({
  providedIn: 'root'
})
export class AltasAreasService {
  readonly valuesFiltro = [
    'nombre',
    'fk_cliente__workspace',
    'fk_cliente__category',
    'fin_actualizacion',
    'activo'
  ];
  readonly valuesExtraFilt = [
    'status',
    'some_field',
    'some_historical_data',
    'some_curve',
    'in_update_flow',
    'ha_activas_tot',
    'ha_activas_planet',
  ];

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private store: Store<AppState>,
    private actions: Actions,
    public dialog: MatDialog,
    private altasService: AltasService
  ) { }

  getData(
    page: number = 1,
    limit: number = 5,
    orderby: string = 'status',
    body: Object = {}
  ): Observable<AbstractPagination<AltaArea>> {
    let params = this.commonService.getPaginationUrlParams(page, limit, orderby);
    return this.http.post<AbstractPagination<AltaArea>>(`${environment.databaseURL}/rest/areas/statusaltaspage${params}`, body)
  }

  getDataByUrl(url: string, body: Object = {}): Observable<AbstractPagination<AltaArea>> {
    return this.http.post<AbstractPagination<AltaArea>>(`${environment.databaseURL}${url}`, body)
  }

  /**
   * Method that analyzes key and returns if is a filtro values or extra_filt value.
   * Returns null if key doesn't belong to either of the two lists.
   * @param key value to analyze
   * @returns
   */
  filtPosition(key: string): 'filtro' | 'extra_filt' | null {
    if(this.valuesFiltro.includes(key)) return 'filtro';
    if(this.valuesExtraFilt.includes(key)) return 'extra_filt';
    return null;
  }

  transformKey(key: string, value: any): string {
    if([TypesInColumnsGeneral.DATE, TypesInColumnsGeneral.DATE_CADUCATE].includes(typesColumnsAltasAreas[key])) return key + '__gte'
    if(['nombre', 'fk_cliente__workspace'].includes(key)) return key + '__icontains';
    if(['ha_activas_tot', 'ha_activas_planet'].includes(key)) return key + '__lte';
    if(key === 'fk_cliente__category' || value instanceof Array ) return key + '__in';
    return key;
  }

  changeFilter(filt: Object, changes: { key: string, value: any, drop?: boolean }, position: 'filtro' | 'extra_filt'): Object {
    let auxFilt: Object = (filt) ? filt[position] || {} : {};
    let transformedKey = this.transformKey(changes.key, changes.value);
    if(changes.drop) {
      delete auxFilt[transformedKey]
    } else {
      auxFilt[transformedKey] = (transformedKey.includes('__in')) ? (changes.value instanceof Array) ? changes.value : [changes.value] : changes.value;
    }
    return {
      ...(filt || {}),
      [position]: { ...auxFilt }
    }
  }

  getShowFilter(filt: Object) {
    if(!filt) {
      return {};
    }
    let showFilter = { ...(filt['filtro'] || {}), ...(filt['extra_filt'] || {}) }
    return Object.entries(showFilter).reduce((acc, [key, value]) => {
      let str = key.replace(/__icontains|__in|__gte|__lte/ig, '');
      return { ...acc, [str]: value}
    }, {});
  }

  changePage({ page, limit, orderby, filter, dropAll }, actualFilter, workspace?: string): string {
    if(!filter) {
      this.store.dispatch(loadAltasAreasData({ page: page, limit: limit, orderby: orderby, workspace: workspace }))
      return orderby;
    }

    if(dropAll) {
      this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.ACTUAL_FILTER, value: null }));
      return orderby;
    }

    let position = this.filtPosition(filter?.key);
    if(!position) {
      this.store.dispatch(loadAltasAreasData({ page: page, limit: limit, orderby: orderby, workspace: workspace }))
      return orderby;
    }
    let filt = this.changeFilter(actualFilter, filter, position);

    this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.ACTUAL_FILTER, value: filt }));
    return orderby;
  }

  transformAltasAreaToAreaObject(area: AltaArea): Area {
    let newArea: Area = {
      titulo: area?.titulo,
      cultivo: area?.cultivo,
      fin_actualizacion: this.commonService.convertMomentDateToString(area?.fin_actualizacion),
      terminado: area?.terminado,
      id: area?.id,
      nombre: area?.nombre,
      ha_activas_planet: area?.ha_activas_planet,
      ha_activas_tot: area?.ha_activas_tot,
    }

    // Add some field value to use it in the next steps
    newArea['some_field']= area.some_field
    
    return newArea;
  }

  openAsignarCurvas(element: any) {
    this.dialog.open(AsignarCurvasComponent, {
      width: '150vh',
      height: '90vh',
      data: {
        element: element
      }
    });
  }

  openGenerarCurvas(area: Area, idCliente: number) {
    let subject: Subject<boolean> = new Subject();
    this.actions.pipe(
      ofType(loadClientAreasSucces),
      takeUntil(subject)
    ).subscribe(value => {
      this.dialog.open(GenerarCurvasComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '92.5%',
        width: '90%',
        panelClass: 'dialog-generate-curves',
        data: {
          areaSelected: area,
          areas: value?.data || []
        }
      })
      subject.next(true);
      subject.complete();
    })

    this.store.dispatch(loadClientAreas({ clientId: idCliente }));
    return subject;
  }

  openReprocesarFs(area: Area) {
    this.dialog.open(ReprocesarFsComponent, {
      data: { area: area }
    })
  }

  openDescargarHistoricos(workspace: string, area: Area) {
    let subject: Subject<boolean> = new Subject();
    this.actions.pipe(
      ofType(setProccesses),
      takeUntil(subject)
    ).subscribe(value => {
      this.dialog.open(NewTileComponent, {
        data: {
          priority: value.data.length || 1,
          workspace: workspace,
          area_id: area?.id
        },
        disableClose: true
      })
      subject.next(true);
      subject.complete();
    });

    this.store.dispatch(loadProccesses({ processed: false }));
    return subject;
  }

  getCategoryStringMongo(category: categories): string | null {
    switch(category) {
      case 2: return 'demo';
      case 3: return 'client';
      default: return null;
    }
  }

  async createMongoArea(workspace: string, area: Area, category: string, id?: number): Promise<boolean> {
    return await Swal.fire({
      icon: 'question',
      title: 'Crear área',
      text: `¿Desea crear el área ${area?.nombre || area?.titulo} para el cliente ${workspace} en api-process-client?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Crear',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let body = [
          {
            id: id,
            type_client: category,
            workspace: workspace,
            areas: [
              {
                id: area.id,
                name: area.nombre,
                crop_type: area.cultivo
              }
            ]
          }
        ];
        return this.altasService.postNotProcessing('areas', body).toPromise()
        .then(value => true)
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: 'Error al crear el área'
          })
          return false;
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Área creada'
        })
      }
      return result.value;
    })
  }
}
