import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.state';
import { ServersDialog } from './servers-dialog/servers-dialog.component';
import { getServers, loadingTable } from './state/servers.actions';
import { getLoadingTable, getServersSelector } from './state/servers.selector';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ServersComponent implements OnInit {
  loading: boolean = false;
  disabledPagination: boolean = false;
  datos: any = [];
  columnsToDisplay: Array<string> = [
    'id',
    'ip',
    'name',
    'port_rdp',
    'port_ssh',
    'port_ftp',
    'path_sentinel_2',
    'path_planet',
    'status',
    'Acciones',
  ];
  columnsToDisplayAreas: Array<string> = [
    'id',
    'name',
    'crop_type',
    'clientId',
    'clientType_client',
    'clientWorkspace',
    'Acciones',
  ];
  serversDialog: MatDialogRef<ServersDialog>;
  dataSource;
  objects: number;
  limit: number;
  totalPages: number;
  page: number = 1;
  title: string = 'servers';
  actualFilter: string = '';
  current: string;
  next: string;
  previous: string;
  inputSearch: string = '';
  order_by: string = 'id';

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private store: Store<AppState>, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.store.dispatch(loadingTable({ active: true }));
    this.store.dispatch(getServers({}));

    this.store
      .select(getServersSelector)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) this.addServersTable(value);
      });

    this.store
      .select(getLoadingTable)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value != null) {
          this.loading = value;
        }
      });
  }

  createServer() {
    this.openDialog('create');
  }

  editServer(event, server) {
    event.stopPropagation();
    this.openDialog('edit', server);
  }

  deleteServer(event) {
    event.stopPropagation();
  }

  sendArea(area) {
    this.openDialog('area', area);
  }

  /**
   * Add servers to data table and initialize variables
   * @param servers
   */
  addServersTable(servers) {
    this.datos = [];
    this.datos = servers.datos;
    this.dataSource = new MatTableDataSource(this.datos);
    this.objects = servers.objects;
    this.limit = servers.limit;
    this.totalPages = servers.total_pages;
    this.current = servers.current;
    this.next = servers.next;
    this.previous = servers.previous;
  }

  /**
   * Open dialog to create/edit server or send server area to other server
   * @param type dialog type create/edit/area
   * @param server server or area if is necessary
   */
  openDialog(type: string, server?: any) {
    this.serversDialog = this.dialog.open(ServersDialog, {
      disableClose: false,
      data: {
        tipo: type,
        servidor: server,
        parametros: {
          page: this.page,
          limit: this.limit,
          order_by: this.order_by,
          filt: this.actualFilter,
        },
      },
    });
  }

  /**
   * Función que prepara la llamada para cambiar la página
   * @param pag pagina a la que se navega
   */
  cambiarPagina(pag: number) {
    this.store.dispatch(loadingTable({ active: true }));
    this.page = pag;
    let url = this.current.split('/');
    let change = url.splice(4);
    change = change[0].split('&');
    change = change[0].split('=');
    let actualPag = parseInt(change[1]);
    change[0] = pag + '';
    pag === actualPag + 1 || pag === actualPag - 1
      ? this.store.dispatch(
          getServers({
            url:
              pag === actualPag + 1
                ? this.next
                : pag === actualPag - 1
                ? this.previous
                : null,
          })
        )
      : this.store.dispatch(
          getServers({
            page: pag,
            limit: this.limit,
            order_by: this.order_by,
            filt: this.actualFilter,
          })
        );
  }

  /**
   * Change objects limit to show in the table
   * @param event new limit
   */
  cambiarLimit(event) {
    this.store.dispatch(loadingTable({ active: true }));
    let url = this.current.split('/');
    let change = url.splice(4);
    change = change[0].split('&');
    change = change[0].split('=');
    let actualPag = parseInt(change[1]);
    // obtiene primer elemento que se visualiza ahora en la tabla
    const firstObject = (actualPag - 1) * this.limit + 1;
    // calcula la página donde se visalizaría el primer elemento actual
    let newPage = Math.floor((firstObject - 1) / event) + 1;
    this.page = newPage;
    this.limit = event;
    this.store.dispatch(
      getServers({
        page: this.page,
        limit: this.limit,
        order_by: this.order_by,
        filt: this.actualFilter,
      })
    );
  }

  /** Search */
  search() {
    this.store.dispatch(loadingTable({ active: true }));
    this.actualFilter = this.inputSearch;
    this.store.dispatch(
      getServers({
        page: 1,
        limit: this.limit,
        order_by: this.order_by,
        filt: this.actualFilter,
      })
    );
  }

  /** Clear search */
  clearSearch() {
    this.store.dispatch(loadingTable({ active: true }));
    this.actualFilter = '';
    this.inputSearch = '';
    this.store.dispatch(
      getServers({ page: 1, limit: this.limit, order_by: this.order_by })
    );
  }

  /**
   * Change column to order the table
   * @param column
   */
  changeOrder(column) {
    this.store.dispatch(loadingTable({ active: true }));
    let send;
    if (column == this.order_by) {
      this.order_by = '-' + column;
    } else {
      this.order_by = column;
    }
    this.order_by.includes('id')
      ? (send = this.order_by.replace('id', '_id'))
      : (send = this.order_by);
    this.store.dispatch(
      getServers({
        page: this.page,
        limit: this.limit,
        order_by: send,
        filt: this.actualFilter,
      })
    );
  }

  /** DESTROY */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
