import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { logout } from 'src/app/auth/state/auth.actions';
import {
  getNotProcessingAreas,
  openedSidebar,
} from 'src/app/pages/admin/altas/state/altas.actions';
import { getNotProcessingData } from 'src/app/pages/admin/altas/state/altas.selector';
import {
  getClientsUniquesLimited,
  getClientsUniquesLimitedSuccess,
  loadData,
  setFilter,
} from 'src/app/pages/admin/state/admin.actions';
import { AdminService } from 'src/app/services/admin.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import { AltasAreasDialogComponent } from '../dialog/altas-areas-dialog/altas-areas-dialog.component';
import { loadAltasAreasObjects } from '../dialog/altas-areas-dialog/state/altas-area.actions';
import {
  getLoadingAltasArea,
  getTotalObjectsAltasArea,
} from '../dialog/altas-areas-dialog/state/altas-area.selector';
import { categories } from '../enums/categories';
import { WarningDialog } from './warning-dialog/warning-dialog.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [TitleCasePipe],
})
export class NavbarComponent implements OnInit {
  imagenUrl: string = '';

  not_processing_clients: any;
  not_processing_areas: Observable<any[]>;

  warningDialog: MatDialogRef<WarningDialog>;
  searchValue: string = '';

  sidebarOpened: boolean = false;

  clients: { id: number; workspace: string; category: categories }[] = [];
  categories = categories;
  loadingUsers: boolean = false;
  visibleInputClients: boolean = true;
  totalAltasAreas: number = -1;
  loadingAltasArea: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private actions: Actions,
    private adminService: AdminService,
    public titleCasePipe: TitleCasePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.notProcessing();
    this.clickListener();

    /**
     * Subscribe to change of url event
     */
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        let url = value.url;
        this.visibleInputClients =
          this.adminService.isEditRoute(url || '') || false;
        this.store.dispatch(loadAltasAreasObjects());
      }
    });

    this.store
      .select(getLoadingAltasArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.loadingAltasArea = value;
      });

    this.store
      .select(getTotalObjectsAltasArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.totalAltasAreas = value;
      });
    this.store.dispatch(loadAltasAreasObjects());
  }

  /** Areas that are not in mongo are requested and stored in a variable */
  notProcessing() {
    this.store.dispatch(getNotProcessingAreas());
    this.not_processing_areas = this.store.select(
      getNotProcessingData('areas')
    );
  }

  /** Warning-dialog.component is opened and the data of the areas with their clients are passed to it */
  openWarning(dataClients: any, dataAreas: any) {
    this.warningDialog = this.dialog.open(WarningDialog, {
      disableClose: false,
      data: { clients: dataClients, areas: dataAreas },
    });
  }

  logout() {
    this.store.dispatch(logout());
  }

  openAltasAreasDialog() {
    this.dialog.open(AltasAreasDialogComponent, {
      minWidth: '50%',
      maxWidth: '80%',
      maxHeight: '90%',
    });
  }

  searchClients(search: string): void {
    if (search === '') {
      this.adminService.subjectClients.next();
      this.loadingUsers = false;
      this.clients = [];
      return;
    }
    this.loadingUsers = true;
    this.store.dispatch(getClientsUniquesLimited({ search: search }));
    this.actions
      .pipe(
        ofType(getClientsUniquesLimitedSuccess),
        takeUntil(this.adminService.subjectClients)
      )
      .subscribe(({ clients, error }) => {
        this.loadingUsers = false;
        this.clients = clients;
        if (error) {
          console.error(error);
        }
      });
  }

  goToClient(client: { id: number; workspace: string; category: categories }) {
    let title = this.titleCasePipe.transform(
      categories[client.category]?.toLowerCase() || ''
    );
    let fk_clienteLabel =
      client?.category === categories.LEADS ? 'fk_cliente__' : '';
    let filt: Object = {};
    filt[`${fk_clienteLabel}workspace`] = client.workspace;
    const url = this.adminService.getCategoryUrl(client?.category);
    Swal.fire({
      icon: 'info',
      title: `Cambiando a tabla ${title}...`,
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    }).then((value) => {
      this.searchValue = '';
      this.searchClients('');
    });

    const filter = { filtro: filt, title: title };
    if (window.location.href.includes(url)) {
      this.store.dispatch(
        loadData({ category: client.category, body: filter })
      );
    } else {
      this.store.dispatch(
        setFilter({ filter: { filtro: filt, title: title } })
      );
      this.router.navigateByUrl(url);
    }
  }

  /**
   * Función que escucha el click de toda la página para deseleccionar e select
   * en caso de que se haya seleccionado
   */
  clickListener() {
    const body = document.getElementById('main-wrapper');
    body.addEventListener('click', (event) => {
      this.searchValue = '';
      this.searchClients('');
    });
  }

  /** Changes the state of the variable that controls whether the sidebar is open or closed */
  openSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
    this.store.dispatch(openedSidebar({ opened: this.sidebarOpened }));
  }
}
