import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

const ROW_HEIGHT_LARGE = '14vw';
const ROW_HEIGHT_MEDIUM = '7vw';
const ROW_HEIGHT_SMALL = '4.5vw';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss'],
})
export class IndicadoresComponent implements OnInit, OnChanges {
  @Input() indicadores = [];
  @Input() loading;
  cols = 1;
  rowHeight = ROW_HEIGHT_LARGE;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.indicadores) {
      this.getCols();
    }
  }

  getCols() {
    if (this.indicadores) {
      const data = [
        { maxIndicators: 6, cols: 3, rowHeight: ROW_HEIGHT_SMALL },
        { maxIndicators: 4, cols: 3, rowHeight: ROW_HEIGHT_MEDIUM },
        { maxIndicators: 2, cols: 2, rowHeight: ROW_HEIGHT_MEDIUM },
        { maxIndicators: 1, rowHeight: ROW_HEIGHT_MEDIUM },
        { maxIndicators: 0, rowHeight: ROW_HEIGHT_LARGE },
      ];

      const matchingData = data.find(
        (d) => this.indicadores.length > d.maxIndicators
      );
      if (matchingData) {
        this.cols = matchingData.cols;
        this.rowHeight = matchingData.rowHeight;
      }
    }
  }
}
