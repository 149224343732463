import { Options } from '@angular-slider/ngx-slider';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  loadClientAreas,
  loadClientAreasSucces,
} from 'src/app/commons/editor/state/editor.actions';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  createAlta,
  createAltaSuccess,
  loadClientsDemosAndClients,
  loadProccesses,
  setLoading,
} from '../../state/admin.actions';
import { getClientsDemosAndClients } from '../../state/admin.selector';

@Component({
  selector: 'app-new-tile',
  templateUrl: './new-tile.component.html',
  styleUrls: ['./new-tile.component.scss'],
})
export class NewTileComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngUnsubscribe: Subject<any> = new Subject();

  optionsSlider: Options;
  value: number = 0;
  formBuilder: FormBuilder = new FormBuilder();
  formAlta: FormGroup;
  clients: Object[] = [];
  clientSelected: number;
  loadDataClient: boolean = false;
  loadDataArea: boolean = false;
  loadingAreas: boolean = false;
  areas = {};
  showRange = false;

  constructor(
    public dialogRef: MatDialogRef<NewTileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdrf: ChangeDetectorRef,
    private actions: Actions,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    // priority range
    this.optionsSlider = {
      floor: 1,
      ceil: this.data.priority + 1,
      showTicks: true,
      animate: true,
    };
    this.value = this.data.priority + 1;

    // crear form
    this.formAlta = this.formBuilder.group({
      date_end: [
        moment(Date.now()).toISOString(true).split('T')[0],
        [Validators.required],
      ],
      date_start: [
        moment(Date.now()).toISOString(true).split('T')[0],
        [Validators.required],
      ],
      historic: [true, [Validators.required]],
      generate_curves: [false, [Validators.required]],
      generate_points: [true, [Validators.required]],
      priority: [this.data.priority + 1, [Validators.required]],
      fk_area_id: [null, [Validators.required]],
      upload_image: [false],
    });
    // obtener clientes
    this.store
      .select(getClientsDemosAndClients)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) {
          // ordenar clientes alfabéticamente
          this.clients = [...value].sort((a, b) => {
            if (a === null) return 1;
            if (b === null) return -1;
            if (a['nombre'] === null) return 1;
            if (!b['nombre']) return -1;
            return a['nombre'].localeCompare(b['nombre']);
          });
          if (this.data?.workspace && !this.loadDataClient) {
            let client = this.clients?.find(
              (e) => e['workspace'] === this.data?.workspace
            );
            if (client) {
              this.loadAreasClient(client['id']);
            }
            this.loadDataClient = true;
          }
        } else {
          this.store.dispatch(loadClientsDemosAndClients());
        }
      });

    this.actions
      .pipe(ofType(loadClientAreasSucces), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.loadingAreas = false;
        // guardar las áreas del cliente seleccionad
        this.areas[this.clientSelected] = value.data;
        this.formAlta.controls['fk_area_id'].setValue(
          !this.loadDataArea && this.data?.area_id
            ? this.data?.area_id
            : this.areas[this.clientSelected] &&
              this.areas[this.clientSelected].length
            ? this.areas[this.clientSelected][0].id
            : null
        );
        if (this.formAlta.controls['fk_area_id'].value === this.data?.area_id) {
          this.loadDataArea = true;
        }
        this.cdrf.detectChanges();
      });

    this.actions
      .pipe(ofType(createAltaSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        const { result, message } = value;
        if (result) {
          // resultado satisfacctorio de la operación
          Swal.fire({
            icon: 'success',
            title: 'Alta creada correctamente',
            timer: 2000,
          });
          // cargar de nuevo los procesos
          this.store.dispatch(setLoading({ loading: true }));
          this.store.dispatch(loadProccesses({}));
          this.closeDialog();
        } else {
          //
          Swal.fire({
            icon: 'error',
            title: 'No se pudo crear el alta',
            text: message || '',
            timer: 2000,
          });
        }
      });

    // arreglo para que el rango se muestre correctamente
    setTimeout(() => {
      this.showRange = true;
    }, 200);
  }

  ngAfterViewInit(): void {
    this.cdrf.detectChanges();
  }

  /**
   * Función que analiza si ya está cargadas las áreas del cliente seleccionado, y si no es así,
   * las carga
   * @param id id del cliente
   */
  loadAreasClient(id: number) {
    // cargar areas del cliente
    this.clientSelected = id;
    // comprobar si ya existen las areas, sino se piden
    if (this.areas[this.clientSelected] === undefined) {
      this.formAlta.controls['fk_area_id'].setValue(null);
      this.loadingAreas = true;
      this.store.dispatch(loadClientAreas({ clientId: id }));
    }
  }

  /**
   * Función que cambia el valor a uno normalizado
   * @param value date
   * @param control label del control en el que se ha cambiado el valor
   */
  changeDateValue(value, control: string) {
    this.formAlta.controls[control].setValue(
      moment(value).toISOString(true).split('T')[0]
    );
  }

  /**
   * Función que crea un alta
   */
  crearAlta() {
    let newAlta: Object = Object.keys(this.formAlta.controls).reduce(
      (act, label) => {
        return label.includes('date')
          ? { ...act, [label]: new Date(this.formAlta.controls[label].value) }
          : { ...act, [label]: this.formAlta.controls[label].value };
      },
      {}
    );

    this.store.dispatch(createAlta({ newAlta: newAlta }));
  }

  /**
   * CLOSE DIALOG
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * CLOSE TOOL
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
