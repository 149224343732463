<div id="content">
  <!-- TITLE AND POPOVER -->
  <div class="content-title">
    <div style="width: 20%"></div>
    <div style="width: 60%">
      <span style="margin-bottom: 0.25em; font-size: x-large"
        >NOT PROCESSING CLIENTS</span
      >
    </div>
    <div
      style="
        width: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
      "
    >
      <div class="btn btn-danger-flicker" style="height: 90%">
        <span
          [popover]="popover"
          popoverPlacement="bottom"
          [popoverOnHover]="false"
          style="color: white"
          >REQUISITOS</span
        >
      </div>
    </div>
    <popover-content
      #popover
      title="Requisitos de las areas"
      [closeOnClickOutside]="true"
    >
      <ng-container>
        <li><strong>Client category:</strong> 2(demo), 3(client)</li>
        <li><strong>Client ha_contrat_sent:</strong> > 0</li>
        <li><strong>Client fin_actualizacion: </strong> > today</li>
        <li><strong>Area fin_actualizacion: </strong> > today</li>
        <li><strong>Area activo:</strong> true</li>
        <li><strong>Area terminado:</strong> null</li>
        <li><strong>Area superficie:</strong> > 0</li>
        <li><strong>Area bbox: </strong> not null</li>
        <li><strong>Area bounding_box: </strong> not null</li>
        <li><strong>Area epsg_code:</strong> not null</li>
        <li><strong>Area epsg_proj: </strong> not null</li>
      </ng-container>
    </popover-content>
  </div>
  <!-- FILTER -->
  <div style="margin-bottom: 1vw">
    <span style="margin-right: 1vw">Buscar:</span>
    <input
      (keyup)="applyFilter($event)"
      placeholder="Búsqueda automática"
      #input
    />
  </div>
  <!-- MAIN CONTAINER -->
  <div
    style="overflow-y: scroll; height: 64vh"
    [ngClass]="{ loadingClass: loading == true }"
  >
    <!-- CLIENTS AND AREAS TABLE -->
    <table
      *ngIf="loading == false"
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef sticky="true">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="
              selection.isSelected(row) == true ||
              (selection.isSelected(row) == false && expandedElement == row)
                ? $event.stopPropagation()
                : ''
            "
            (change)="
              $event ? selection.toggle(row) : null;
              checkearAreas(row, selection.isSelected(row))
            "
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay | slice : 1"
      >
        <th mat-header-cell *matHeaderCellDef>
          {{ column | titleColumns | titlecase }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element[column] == null ? "null" : element[column] }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="example-element-description">
              <div>
                <table
                  mat-table
                  [dataSource]="element.areas"
                  class="mat-elevation-z8"
                >
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox
                        *ngIf="row.add == true; else rowAddFalse"
                        #product
                        type="checkbox"
                        [(checked)]="row.checked"
                        [disabled]="row.add == false"
                        (change)="onCheckboxChangeAreas(row)"
                        [color]="'primary'"
                        (click)="$event.stopPropagation()"
                      >
                      </mat-checkbox>
                      <ng-template #rowAddFalse>
                        <div>
                          <mat-icon style="scale: 0.8; margin-left: -0.15em"
                            >block</mat-icon
                          >
                        </div>
                      </ng-template>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="{{ column }}"
                    *ngFor="let column of columnsToDisplayAreas | slice : 1"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      {{ column | titleColumns | titlecase }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div>
                        {{ element[column] == null ? "null" : element[column] }}
                      </div>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="columnsToDisplayAreas"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: columnsToDisplayAreas"
                    (click)="selectionAreas.toggle(row)"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
    <!-- LOADING SPINNER -->
    <mat-progress-spinner
      *ngIf="loading == true"
      style="margin: 0 10px"
      color="accent"
      mode="indeterminate"
    >
    </mat-progress-spinner>
  </div>
  <!-- PROCESS BUTTON -->
  <div style="display: flex; justify-content: center; margin-top: 2vh">
    <button
      [disabled]="loading == true"
      type="button"
      class="btn btn-success btn-lg text-white"
      (click)="procesar()"
      style="display: flex"
    >
      PROCESAR
    </button>
  </div>
</div>
