import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { buttonsEachTabla } from 'src/app/commons/constants/columnsTable';
import { categories } from 'src/app/commons/enums/categories';
import {
  TypeofColumns,
  TypesInColumns,
} from 'src/app/commons/enums/typeofColumns.enum';
import { TableTemplate } from 'src/app/commons/table/table-template';
import { IsDatePipe } from 'src/app/pipes/is-date.pipe';
import { AdminService } from 'src/app/services/admin.service';
import { AppState } from 'src/app/store/app.state';
import { getTitle } from 'src/app/store/share/share.selector';
import {
  getDataByUrl,
  loadDataProductosConf,
  setFilter,
  setLoading,
} from '../state/admin.actions';
import { getData, getFilter } from '../state/admin.selector';
import { FormProductsConfigurationComponent } from './form-products_configuration/form-products_configuration.component';
import { loadProviders } from './state/productsConfiguration.actions';

@Component({
  selector: 'app-products-configuration',
  templateUrl: './products_configuration.component.html',
  styleUrls: ['./products_configuration.component.scss'],
})
export class ProductsConfigurationComponent implements OnInit {
  category = categories.PRODUCTS;
  title;
  actualFilter;
  actualPage;
  datos;
  data;
  disabledFilter;
  buttonsTable;
  tipoInput;
  isDate: IsDatePipe = new IsDatePipe();

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(setLoading({ loading: true }));
    //this.store.dispatch(setFilter({ filter: undefined }));
    this.store
      .select(getTitle)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.title = value;
      });
    this.store
      .select(getFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (this.actualFilter && this.actualFilter['title'] !== this.title)
          this.store.dispatch(setFilter({ filter: undefined }));
        this.actualFilter = value;
      });
    this.store.dispatch(
      loadDataProductosConf({
        body:
          this.actualFilter && this.actualFilter['title'] === this.title
            ? this.actualFilter
            : undefined,
      })
    );
    this.store
      .select(getData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value && value.datos) {
          // actualizar número de página
          let corte = value.current.split('/').length != 9 ? 3 : 6;
          let change = value.current.split('/').slice(corte);
          // son los datos de la categoría actual
          this.actualPage = parseInt(change[0]);

          this.datos = this.adminService.transformDatos(
            value.datos,
            this.title.toLocaleLowerCase(),
            false,
            this.category
          );
          this.data = { ...value };
          if (this.data.objects < 10) this.data.limit = this.data.objects;
        } else {
          this.data = value;
          this.datos = undefined;
        }
        this.disabledFilter = !value || !value.datos || value.datos.length < 2;
      });
    // botones de la tabla
    this.buttonsTable = buttonsEachTabla[this.title.toLowerCase()];

    this.store.dispatch(loadProviders());
  }

  /**
   * Escucha evento de nuevo o editar dato
   * @param element dato a editar (opcional)
   */
  openEditProduct(element?: Object) {
    this.dialog.open(FormProductsConfigurationComponent, {
      data: element,
      disableClose: true,
    });
  }

  /**
   * Función que busca en los datos el tipo de dato que es la clave
   * @param key campo a buscar
   */
  obtenerTipo(key: string) {
    if (this.datos && this.datos.length) {
      // buscamos el tipo de dato en los datos de la tabla
      let dato = this.datos.find((element) => element[key] !== null);
      if (dato) {
        // obtememos el tipo de dato
        if (this.isDate.transform(dato[key])) this.tipoInput = 'date';
        else if (typeof dato[key] === 'number') this.tipoInput = 'number';
        else if (typeof dato[key] === 'boolean') this.tipoInput = 'radio';
        else this.tipoInput = 'text';
      } else {
        this.tipoInput = 'text';
      }
    } else {
      this.tipoInput = 'text';
    }
  }

  /**
   * Función que define el filtro que se acaba de editar
   * @param event
   */
  filtrar(event?: any[]) {
    if (!event) {
      this.store.dispatch(setLoading({ loading: true }));
      this.store.dispatch(loadDataProductosConf({ body: this.actualFilter }));
      return;
    }
    let [key, value, newFilter] = event,
      filter: Object;
    //Deivi filtrar fechas desde hoy hasta la fecha seleccionada
    var filterOperation = '__icontains';
    if (Number(TypeofColumns[key]) === TypesInColumns.DATE) {
      filterOperation = '__range';
      value = [new Date().toISOString().split('T')[0], value];
      value.sort();
    }

    if (!newFilter) {
      // no se ha pasado el filtro, añadimos el nuevo parámetro
      let keyString: string = key || '',
        pre = '';
      if (!key) key = '';
      // existe filtro, copiamos su contenido y añadimos el nuevo
      filter = this.actualFilter
        ? {
            filtro: {
              ...this.actualFilter['filtro'],
              [pre + keyString + filterOperation]: value,
            },
          }
        : // no existe, inicializamos
          { filtro: { [pre + keyString + filterOperation]: value } };
    } else filter = newFilter;

    filter['title'] = this.title;
    if (!Object.keys(filter['filtro']).length) filter = undefined;
    this.store.dispatch(setLoading({ loading: true }));
    this.store.dispatch(loadDataProductosConf({ body: filter }));
  }

  /**
   * Función que prepara la llamada para cambiar la página
   * @param pag pagina a la que se navega
   */
  changePage(pag: number) {
    this.store.dispatch(setLoading({ loading: true }));
    let corte = 6;
    let url = this.data.current.split('/');
    // llamada usuarios o categoria
    if (url.length !== 9) corte = 3;
    // ['1', '10', 'user']
    let change = url.splice(corte);
    let actualPag = parseInt(change[0]);

    let body = this.actualFilter
      ? {
          ...this.actualFilter['filtro'],
          filtro: { ...this.actualFilter['filtro'] },
        }
      : undefined;
    change[0] = pag + '';
    this.store.dispatch(
      getDataByUrl({
        url:
          pag === actualPag + 1
            ? this.data.next
            : pag === actualPag - 1
            ? this.data.previous
            : url.concat(change).join('/'),
        body: body,
      })
    );
  }

  /**
   * Cambia el límite de la página
   * @param limit límite a cambiar
   */
  changeLimit(limit: number) {
    this.store.dispatch(setLoading({ loading: true }));
    let corte = 6;
    let url = this.data.current.split('/');
    if (url.length === 6) corte = 3;
    let change = url.splice(corte);
    let actualPag = parseInt(change[0]);
    // obtiene primer elemento que se visualiza ahora en la tabla
    const firstObject = (actualPag - 1) * this.data.limit + 1;
    // calcula la página donde se visalizaría el primer elemento actual
    let newPage = Math.floor((firstObject - 1) / limit) + 1;
    change[0] = newPage + '';
    change[1] = limit + '';

    this.actualPage = newPage;
    // añadir filtro si hubiere
    let body = this.actualFilter
      ? { filtro: { ...this.actualFilter['filtro'] } }
      : undefined;
    this.store.dispatch(
      getDataByUrl({
        url: url.concat(change).join('/'),
        body: body,
      })
    );
  }

  /**
   * Función que prepara la peición para ordenar por columna de forma ascendente o descendente
   * @param column columna referenciada para realizar la ordenación
   */
  changeOrder(column: string) {
    let corte = 6,
      pre = '',
      guion = '';
    if (column.includes('-')) guion = '-';
    let url = this.data.current.split('/');
    if (url.length === 6) corte = 3;
    let change = url.splice(corte);
    change[0] = '1';
    change[2] = guion + pre + column.replace('-', '');
    let body = this.actualFilter
      ? { filtro: { ...this.actualFilter['filtro'] } }
      : undefined;
    this.store.dispatch(setLoading({ loading: true }));
    this.store.dispatch(
      getDataByUrl({
        url: url.concat(change).join('/'),
        body: body,
      })
    );
  }
}
