export interface OptionsProcessFilter {
  areas?: number[];
  //algoritmo?: string,
  tipo?: string;
  producto?: string[];
  len_curve?: number;
  min_days?: number;
}

export enum CurvesSelection {
  BBDD = 'BBDD',
  TEMPORALES = 'Temporales',
}
