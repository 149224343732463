<h3 mat-dialog-title>{{ 'Altas sin finalizar' | uppercase }}</h3>
<div mat-dialog-content>
  <app-list-areas [typesColumns]="typesInColumns" [pagination]="paginator"
  [columns]="columns" [columnsNames]="names" [orderby]="orderby" [maxHeight]="50"
  [loading]="loadingTable"
  [notFilteredColumns]="['status']"
  [filter]="showFilter"
  [actions]="tableActions"
  (changePage)="changePage($event)"
  ></app-list-areas>
</div>
