import { Component, OnDestroy, OnInit } from '@angular/core';
import { TypesInColumns } from '../../enums/typeofColumns.enum';
import { AltasAreasTableActions, TypesInColumnsGeneral, columnsAltasAreas, namesColumnsAltasAreas, typesColumnsAltasAreas } from '../../constants/AltasAreas.constants';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { getFilterAltasArea, getLoadingTableAltasArea, getPaginationAltasAreas } from './state/altas-area.selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractPagination } from 'src/app/interfaces/Pagination.interface';
import { AltaArea } from 'src/app/interfaces/AltasArea.interface';
import { loadAltasAreasData, resetAltasArea, setKeyValueAltasArea } from './state/altas-area.actions';
import { labelsStateAltasArea } from './state/altas-area.state';
import { AltasAreasService } from 'src/app/services/AltasAreas.service';

@Component({
  selector: 'app-altas-areas-dialog',
  templateUrl: './altas-areas-dialog.component.html',
  styleUrls: ['./altas-areas-dialog.component.scss']
})
export class AltasAreasDialogComponent implements OnInit, OnDestroy {
  paginator: AbstractPagination<AltaArea> | null = null;
  loadingTable: boolean = false;
  typesInColumns = typesColumnsAltasAreas;
  columns = columnsAltasAreas;
  names = namesColumnsAltasAreas;
  orderby: string = 'status';
  actualFilter: { filtro: Object, extra_filt: Object } | null = null;
  showFilter: Object = {};
  tableActions = [AltasAreasTableActions.GO_TO_AREA]

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private altasAreasService: AltasAreasService
  ) { }

  ngOnInit(): void {
    this.resetPaginationAndFilter();
    this.store.select(getPaginationAltasAreas)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value => {
      this.paginator = JSON.parse(JSON.stringify(value));
    });

    this.store.select(getLoadingTableAltasArea)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value => {
      this.loadingTable = value;
    });

    this.store.select(getFilterAltasArea)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value => {
      this.actualFilter = JSON.parse(JSON.stringify(value));
      this.showFilter = this.altasAreasService.getShowFilter(this.actualFilter);
      this.store.dispatch(loadAltasAreasData({ orderby: this.orderby }));
    });
  }

  private resetPaginationAndFilter() {
    this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.ACTUAL_FILTER, value: null }));
    this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.PAGINATION, value: null }));
  }

  /**
   * Load new data because page, limit or orderby have changed
   * @param param0
   */
  changePage({ page, limit, orderby, filter, dropAll }) {
    this.orderby = this.altasAreasService.changePage(
      {
        page: page,
        limit: limit,
        orderby: orderby,
        filter: filter,
        dropAll: dropAll
      },
      this.actualFilter
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.resetPaginationAndFilter();
  }
}
