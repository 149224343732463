export enum TypesInColumns {
  NUMBER = 1,
  DATE = 2,
  BOOLEAN = 3,
  STRING = 4,
  OTHER = 10,
}

/**
 * TYPES OF VALUES COLUMNS
 * NO EXISTS: text
 * 1. NUMBER
 * 2. DATE
 * 3. BOOLEAN
 */
export enum TypeofColumns {
  ha_empresa = 1,
  ha_activas_sent = 1,
  ha_contrat = 1,
  ha_contrat_sent = 1,
  superficie = 1,
  fin_actualizacion = 2,
  fin_plataforma = 2,
  freinicio = 2,
  fecha_registro = 2,
  last_login = 2,
  date_start = 2,
  date_end = 2,
  alta_freq = 3,
  pagado = 3,
  verificado = 3,
  contactado = 3,
  terminado = 3,
  reinicio = 3,
  zafra_cont = 3,
  historic = 3,
  generate_curves = 3,
  generate_points = 3,
  processed_pctg = 10,
  contador_dashboard = 1,
  contador_login = 1,
  contador_powerbi = 1,
  contador_tareas = 1,
  category = 1,
  correo = 4,
  empresa = 4,
  workspace = 4,
  language = 4,
  metodo_pago = 4,
  nombre = 4,
  apellidos = 4,
  cultivo = 4,
  email = 4,
  pais = 4,
  phone = 4,
  downloaded_pctg = 10,
  processed_tiles_pctg = 10,
  upload_image = 3,
}
