<div class="row">
  <div class="col" style="display: flex; justify-content: center">
    <br />
    <div style="height: 15vw; width: 40vw">
      <canvas
        baseChart
        [data]="salesData"
        [type]="'doughnut'"
        [options]="chartOptions"
      ></canvas>
    </div>
  </div>
</div>
