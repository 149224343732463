export enum TypesInColumnsGeneral {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  PROGRESS = 'PROGRESS',
  CATEGORY = 'category',
  DEFAULT = 'text',
  DATE = 'date',
  DATE_CADUCATE = 'date_caducate',
}

export const columnsAltasAreas = [
  'nombre',
  'fk_cliente__workspace',
  'fk_cliente__category',
  'status',
  'some_field',
  'some_historical_data',
  'some_curve',
  'in_update_flow'
];

export const columnsAltasAreasEditor = [
  'nombre',
  'ha_activas_tot',
  'ha_activas_planet',
  'fin_actualizacion',
  'activo',
  'status',
];

export const namesColumnsAltasAreas = {
  'nombre': 'área',
  'fk_cliente__workspace': 'cliente',
  'fk_cliente__category': 'tipo',
  'ha_activas_tot': 'ha activas totales',
  'ha_activas_planet': 'ha activas planet',
  'status': 'estado',
  'fin_actualizacion': 'fin actualización',
  'some_field': 'parcelas',
  'some_historical_data': 'históricos',
  'some_curve': 'curvas óptimas',
  'in_update_flow': 'flujo actualización'
}

export const typesColumnsAltasAreas = {
  'fk_cliente__category': TypesInColumnsGeneral.CATEGORY,
  'status': TypesInColumnsGeneral.PROGRESS,
  'some_field': TypesInColumnsGeneral.BOOLEAN,
  'some_historical_data': TypesInColumnsGeneral.BOOLEAN,
  'some_curve': TypesInColumnsGeneral.BOOLEAN,
  'in_update_flow': TypesInColumnsGeneral.BOOLEAN,
  'activo': TypesInColumnsGeneral.BOOLEAN,
  'fin_actualizacion': TypesInColumnsGeneral.DATE_CADUCATE
}

export enum AltasAreasTableActions {
  LABEL = 'acciones',
  GO_TO_AREA = 'go_to_area',
  OTHER_OPTIONS = 'other_options'
}

export enum AltasAreasOptions {
  GENERAR_CURVAS = 'Generar curvas',
  ASIGNAR_CURVAS = 'Asignar curvas',
  DESCARGAR_HISTORICOS = 'Descargar históricos',
  REPROCESAR = 'Reprocesar'
}
