import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PopoverModule } from 'ngx-smart-popover';
import { AltasEffects } from 'src/app/pages/admin/altas/state/altas.effects';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CommonsModule } from '../commons.module';
import { AltasAreaEffects } from '../dialog/altas-areas-dialog/state/altas-area.effects';
import { AltasAreaReducer } from '../dialog/altas-areas-dialog/state/altas-area.reducer';
import { ALTAS_AREA_STATE_NAME } from '../dialog/altas-areas-dialog/state/altas-area.selector';
import { WarningDialog } from './warning-dialog/warning-dialog.component';

@NgModule({
  declarations: [WarningDialog],
  imports: [
    CommonModule,
    CommonsModule,
    EffectsModule.forFeature([AltasEffects, AltasAreaEffects]),
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    PipesModule,
    PopoverModule,
    NgbModule,
    StoreModule.forFeature(ALTAS_AREA_STATE_NAME, AltasAreaReducer),
  ],
})
export class NavbarModule {}
