import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/Common.service';

@Pipe({
  name: 'dateCaducated'
})
export class DateCaducatedPipe implements PipeTransform {
  constructor(private commonService: CommonService) {}
  transform(date: string | Date | null, dateCaducate?: string | Date): { isCaducated: boolean, date: Date | string | null, daysToCaducate?: number, color?: string } {
    if(!date) {
      return {
        isCaducated: false,
        date: null,
        daysToCaducate: undefined,
        color: undefined

      }
    }

    if(typeof date === 'string') {
      date = new Date(date);
      date.setHours(0, 0, 0, 0);
    }

    if(!dateCaducate) {
      dateCaducate = this.commonService.getTodayDate();
    }

    if(typeof dateCaducate === 'string') {
      dateCaducate = new Date(dateCaducate);
      dateCaducate.setHours(0, 0, 0, 0);
    }
    let isCaducated = date < dateCaducate;
    let daysToCaducate = (date.getTime() - dateCaducate.getTime()) / 1000 / 60 / 60 / 24;
    return {
      isCaducated: isCaducated,
      date: this.commonService.convertMomentDateToString(date),
      daysToCaducate: (daysToCaducate < 14 || isCaducated) ? daysToCaducate : undefined,
      color: (isCaducated) ? 'red' : (daysToCaducate < 14) ? 'orange' : undefined
    }
  }
}
