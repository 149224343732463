import { createAction, props } from '@ngrx/store';

export const CREATE_SERVER = '[servers] create server';
export const createServer = createAction(
  CREATE_SERVER,
  props<{ body: any; parametersPagination: Object }>()
);

export const GET_SERVERS = '[servers] get servers';
export const getServers = createAction(
  GET_SERVERS,
  props<{
    page?: number;
    limit?: number;
    order_by?: string;
    filt?: string;
    url?: string;
  }>()
);

export const GET_SERVERS_SUCCESS = '[servers] get servers succes';
export const getServersSuccess = createAction(
  GET_SERVERS_SUCCESS,
  props<{ result: any }>()
);

export const UPDATE_SERVER = '[servers] update server';
export const updateServer = createAction(
  UPDATE_SERVER,
  props<{ id: number; body: any; parametersPagination: Object }>()
);

export const DELETE_SERVER = '[servers] delete server';
export const deleteServer = createAction(
  DELETE_SERVER,
  props<{ id: number }>()
);

export const LOADING_TABLE = '[servers] loading table';
export const loadingTable = createAction(
  LOADING_TABLE,
  props<{ active: boolean }>()
);

export const GET_ALL_SERVERS = '[servers] get all servers';
export const getAllServers = createAction(
  GET_ALL_SERVERS,
  props<{ filt?: string }>()
);

export const GET_ALL_SERVERS_SUCCESS = '[servers] get all servers succes';
export const getAllServersSuccess = createAction(
  GET_ALL_SERVERS_SUCCESS,
  props<{ result: any }>()
);

export const SEND_AREA = '[servers] send area';
export const sendArea = createAction(
  SEND_AREA,
  props<{ idArea: any; idServer: string; parametersPagination: Object }>()
);
