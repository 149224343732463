<div id="container-powerbi">
  <app-filter-table
    [title]="title"
    [actualFilter]="actualFilter"
    (filtrarTabla)="filtrar($event)"
    [disabled]="loading || (data && data.objects < 2)"
    [tipoInput]="tipoInput"
    [loading]="loading"
  ></app-filter-table>
  <div class="tasks-pending-container">
    <div style="padding: 0.5em 0">
      Tareas pendientes: {{ pendingTasksPowerbi.length }}
      <i
        [popover]="myPopover"
        popoverPlacement="right"
        *ngIf="pendingTasksPowerbi.length"
        [popoverOnHover]="false"
        class="fas fa-info-circle ml-3"
        style="cursor: pointer"
      ></i>
      <popover-content
        #myPopover
        title="Tareas pendientes"
        [closeOnClickOutside]="true"
      >
        <ng-container *ngFor="let task of pendingTasksPowerbi; let i = index">
          <div class="pending-task">
            {{ task.workspace }}
            <i
              title="Cancelar proceso"
              class="fas fa-trash"
              style="color: red; cursor: pointer"
              (click)="revokePendingTask(task)"
            ></i>
          </div>
          <mat-divider
            *ngIf="i !== pendingTasksPowerbi.length - 1"
          ></mat-divider>
        </ng-container>
      </popover-content>
    </div>
    <button #buttonInfo class="btn btn-danger btn-danger-flicker" show-info>
      <span>IMPORTANTE LEER</span>
    </button>
  </div>
  <app-indeterminate-progress-bar
    *ngIf="loadingStatus | async as a"
  ></app-indeterminate-progress-bar>
  <div
    [ngClass]="{
      'card col-md-12': true,
      'min-table': data?.datos && data?.datos.length
    }"
  >
    <app-table
      [data]="data"
      [title]="title"
      [datos]="data?.datos"
      [page]="actualPage"
      [loading]="loading"
      [orderedBy]="orderby"
      [disabledPagination]="loading"
      (changePage)="changePage($event)"
      (changeLimit)="changeLimit($event)"
      (changeOrderedBy)="changeOrder($event)"
      (clickColumnButtonEvent)="launchPowerbi($event)"
    >
    </app-table>
  </div>
</div>
