<div id="container-main-list">
  <div id="filter-list-areas-container">
    <ng-select [(ngModel)]="columnSelected" [disabled]="loading" [clearable]="false" (ngModelChange)="changeColumnSelected(columnSelected)">
      <ng-option *ngFor="let column of filterColumns" [value]="column">
        {{ (columnsNames[column] || column) | titlecase }}
      </ng-option>
    </ng-select>
    <ng-container *ngIf="typesColumns[columnSelected] === typesInColumnsGeneral.BOOLEAN; else categoryInput">
      <fieldset class="boolean-input" (keyup.enter)="changeFilter()">
        <label>
          <i class="fas fa fa-check mx-2"></i>
          <input type="radio" name="filter-radio" [value]="true" class="mx-2" [(ngModel)]="valueFilter" [disabled]="loading || !pagination?.datos">
        </label>
        <label>
          <i class="fas fa fa-times mx-2"></i>
          <input type="radio" name="filter-radio" class="mx-2" [value]="false" [(ngModel)]="valueFilter" [disabled]="loading || !pagination?.datos">
        </label>
      </fieldset>
    </ng-container>
    <ng-template #categoryInput>
      <ng-container *ngIf="typesColumns[columnSelected] === typesInColumnsGeneral.CATEGORY; else dateInput">
        <ng-select [(ngModel)]="valueFilter" [disabled]="loading" [clearable]="false">
          <ng-option *ngFor="let column of categoriesEnum" [value]="column.value" [disabled]="loading || !pagination?.datos">
            {{ column.key | titlecase }}
          </ng-option>
        </ng-select>
      </ng-container>
    </ng-template>
    <ng-template #dateInput>
      <ng-container *ngIf="[typesInColumnsGeneral.DATE, typesInColumnsGeneral.DATE_CADUCATE].includes(typesColumns[columnSelected]); else defaultInput">
        <div class="d-flex align-items-center">
          <input placeholder="YYYY-MM-DD" class="inputs form-control" [(ngModel)]="valueFilter" readonly
          style="cursor: pointer;"
          matInput [matDatepicker]="picker4" (focus)="picker4.open()" (click)="picker4.open()">
          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
          <mat-datepicker #picker4 (closed)="changeDate()"></mat-datepicker>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #defaultInput>
      <input [disabled]="loading || !pagination?.datos" [(ngModel)]="valueFilter" type="text"
      (keyup.enter)="changeFilter()"
      [placeholder]="(columnsNames[columnSelected] || columnSelected || 'filtro') | titlecase">
    </ng-template>
    <button class="btn btn-dark" [disabled]="loading || valueFilter === ''" (click)="changeFilter()">
      <mat-icon>search</mat-icon>
      <span>Filtrar</span>
    </button>
    <div class="button-add" *ngIf="addButton">
      <button class="btn btn-sm btn-primary" (click)="emitAdd()">
        <span>AÑADIR ÁREA</span>
        <mat-icon [inline]="true">add_circle</mat-icon>
      </button>
      <mat-icon style="color: #0d6efd; cursor: pointer;" (click)="emitAdd()">add_circle</mat-icon>
    </div>
  </div>
  <ng-container *ngIf="(actualFilter | keyvalue) as keyValueFilter">
    <div *ngIf="keyValueFilter?.length" class="container-show-filt">
      <div *ngFor="let value of keyValueFilter" class="filt-box">
        <ng-container *ngIf="typesColumns[value.key] === typesInColumnsGeneral.BOOLEAN; else categoryFilter">
          <span>{{ (columnsNames[value.key] || value.key) | titlecase }}: </span>
          <i class="fas fa mx-2 icons-dark" [ngClass]="{'fa-check': value.value, 'fa-times': !value.value}"></i>
        </ng-container>
        <ng-template #categoryFilter>
          <ng-container *ngIf="typesColumns[value.key] === typesInColumnsGeneral.CATEGORY; else defaultFilter">
          <span>{{ (columnsNames[value.key] || value.key) | titlecase }}: {{ (categories[value.value] || value.value) | titlecase }}</span>
          </ng-container>
        </ng-template>
        <ng-template #defaultFilter>
          <span>{{ (columnsNames[value.key] || value.key) | titlecase }}: {{ value.value }}</span>
        </ng-template>
        <i class="fas fa fa-times" [ngClass]="{'disabled-filter': loading}" (click)="!loading && changeFilter({key: value.key, value: value.value, drop: true})"></i>
      </div>
      <div *ngIf="keyValueFilter?.length > 1" class="ml-2">
        <button class="btn btn-sm btn-success" [disabled]="loading" (click)="!loading && changeFilter({}, true)">Borrar filtros</button>
      </div>
    </div>
  </ng-container>
  <div id="areas-list-container">
    <div class="table-container" [ngStyle]="{'max-height': height + 'vh', 'overflow': (loading) ? 'hidden' : 'auto'}"
    *ngIf="pagination?.datos?.length; else noData">
      <mat-table [dataSource]="pagination?.datos || []" class="mat-elevation-z8" >
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <ng-container *ngIf="column === altasAreasTableActions.LABEL; else headerOrdered">
            <mat-header-cell *matHeaderCellDef>
              {{ column | titlecase }}
            </mat-header-cell>
          </ng-container>
          <ng-template #headerOrdered>
            <mat-header-cell *matHeaderCellDef [ngClass]="{'order-columns': orderby !== null}" (click)="orderby && changeOrder(column)">
              <span [ngStyle]="{ color: orderby?.endsWith(column) ? 'black' : 'grey' }">{{ (columnsNames[column] || column) | titlecase }}</span>
              <ng-container *ngIf="orderby">
                <mat-icon *ngIf="orderby?.startsWith('-') && orderby?.endsWith(column); else arrowDown" [ngStyle]="{ color: orderby?.endsWith(column) ? 'black' : 'grey' }" style="position: absolute; right: 0;">keyboard_arrow_up</mat-icon>
                <ng-template #arrowDown>
                  <mat-icon style="position: absolute; right: 0;" [ngStyle]="{ color: orderby?.endsWith(column) ? 'black' : 'grey' }">keyboard_arrow_down</mat-icon>
                </ng-template>
              </ng-container>
            </mat-header-cell>
          </ng-template>
          <ng-container *matCellDef="let element">
            <mat-cell *ngIf="column === altasAreasTableActions.LABEL; else defaultCells" class="cell-actions">
              <ng-container *ngIf="actionsTable.includes(altasAreasTableActions.GO_TO_AREA)">
                <mat-icon title="Navegar al área" (click)="goToArea(element)">send</mat-icon>
              </ng-container>
              <ng-container *ngIf="actionsTable.includes(altasAreasTableActions.OTHER_OPTIONS)">
                <mat-icon [ngbPopover]="popContent" placement="left"
                 title="Más">more_vert</mat-icon>
                 <ng-template #popContent>
                  <ng-container *ngFor="let option of moreOptions; let i = index; last as isLast">
                    <div class="pop-row" (click)="selectOptionActions(option, element)">
                      <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
                      <span>{{ option?.title || '-' }}</span>
                    </div>
                    <mat-divider *ngIf="!isLast"></mat-divider>
                  </ng-container>
                </ng-template>
              </ng-container>
            </mat-cell>
            <ng-template #defaultCells>
              <mat-cell [ngSwitch]="typesColumns[column]" [ngStyle]="{'cursor': (selectableRows) ? 'pointer' : 'auto'}"
              (click)="selectableRows && selectRow(element)"
              [ngClass]="{'width-progress cell-popover-no-title': typesColumns[column] === typesInColumnsGeneral.PROGRESS}">
                <ng-template [ngSwitchCase]="typesInColumnsGeneral.BOOLEAN">
                    <i [ngClass]="{'fa-check': element[column], 'fa-times': !element[column], 'fa fas': true}"></i>
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumnsGeneral.DATE">
                  {{ (element[column] | date:'yyyy-MM-dd') || '-' }}
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumnsGeneral.DATE_CADUCATE">
                  <ng-container *ngIf="((element[column] | date:'yyyy-MM-dd') | dateCaducated) as caducated; else emptyCell">
                    <div class="d-flex align-items-center w-100 justify-content-center" (click)="$event.stopPropagation()"
                    [ngbPopover]="(caducated.isCaducated || caducated.daysToCaducate !== undefined) ? popCaducatedContent : null"
                      placement="bottom" triggers="mouseenter:mouseleave">
                      <span [ngStyle]="{'color': caducated?.color || 'black'}">{{ caducated.date || '-' }}</span>
                      <mat-icon
                      [inline]="true" class="ml-2"
                      *ngIf="caducated.isCaducated || caducated.daysToCaducate !== undefined"
                      [ngStyle]="{'color': caducated?.color || 'black'}">error</mat-icon>
                      <ng-template #popCaducatedContent>
                          <div *ngIf="caducated.isCaducated">Caducó hace {{ caducated.daysToCaducate * -1 | number:'1.0-0' }} {{ (caducated.daysToCaducate * -1) === 1 ? 'día' : 'días' }}</div>
                          <div *ngIf="!caducated.isCaducated">Caducará en {{ caducated.daysToCaducate | number:'1.0-0' }} {{ caducated.daysToCaducate === 1 ? 'día' : 'días' }}</div>
                      </ng-template>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumnsGeneral.CATEGORY">
                  {{ ((element[column] !== undefined && element[column] !== null) ? (categories[element[column]] | slice:0:-1 | lowercase) : '-') }}
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumnsGeneral.PROGRESS">
                  <div class="progress" #p="ngbPopover" style="width: 100%;" [ngbPopover]="(hoverStatus) ? popoverStatus : null" placement="bottom"
                  [autoClose]="true"
                  (mouseenter)="onMouseenterStatus($event, p)"
                  (mouseleave)="onMouseLeaveStatus($event, p)"
                  triggers="manual"
                  container="body" popoverClass="cell-popover-no-title">
                    <div [ngClass]="{'progress-bar progress-bar-striped progress-bar-animated': true,
                    'bg-cyan': element[column] > 40 && element[column] <= 80,
                    'bg-warning': element[column] <= 40,
                    'bg-success': element[column] > 80}"
                    [ngStyle]="{'width': (element[column] || 0) + '%'}">
                  </div>
                </div>
                {{ (element[column] || 0) + '%' }}
                <ng-template #popoverStatus>
                  <div (mouseenter)="onMouseenterPopover($event, p)" (mouseleave)="onMouseLeavePopover($event, p)">
                    <div *ngFor="let option of ['some_field', 'some_historical_data', 'some_curve', 'in_update_flow']"
                    [ngClass]="{'popover-status-row': true, 'popover-status-row-disabled': option === 'in_update_flow' && element[option]}"
                    (click)="flowStatusHover(option, element)">
                      <i [ngClass]="{'fa-check': element[option], 'fa-times': !element[option], 'fa fas': true}"></i>
                      <span class="ml-2">{{ (columnsNames[option] || option) | titlecase }}</span>
                    </div>
                  </div>
                </ng-template>
                </ng-template>
                <ng-template ngSwitchDefault>
                      {{ element[column] || '-'}}
                </ng-template>
              </mat-cell>
            </ng-template>
          </ng-container>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row [ngClass]="{'hover-shadow': selectableRows }" *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator *ngIf="pagination?.datos?.length"
        [length]="pagination?.objects"
        [pageSize]="limit"
        [(pageIndex)]="pageIndex"
        (page)="changePage($event)"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
    <div *ngIf="loading" [ngClass]="{'loading-info-absolute': pagination?.datos?.length, 'loading-info': true}">
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
      <span [ngClass]="{'color-span': pagination?.datos?.length, 'ml-2': true}">Cargando información...</span>
    </div>
  </div>

</div>
<ng-template #noData>
  <div *ngIf="!loading" class="loading-info">
    <span>No hay datos</span>
  </div>
</ng-template>

<ng-template #emptyCell>
  <span>-</span>
</ng-template>
