import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { setKeyValueAdmin } from 'src/app/pages/admin/state/admin.actions';
import { labelsStateAdmin } from 'src/app/pages/admin/state/admin.state';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/api/auth.service';
import { AppState } from '../../store/app.state';
import {
  changeLoginWaiting,
  loadSuperuserToken,
  loadSuperuserTokenSuccess,
  loadUser,
  loadUserSuccess,
  loginStart,
  loginSuccess,
  logout,
  logoutSuccess,
  noData,
  refresh,
} from './auth.actions';
import { initialStateAuth } from './auth.state';

@Injectable()
export class AuthEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  /** LOGIN */
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginStart),
      exhaustMap((action) => {
        const { user, password, saveTokenSuperUser } = action;
        return this.authService.login(user, password).pipe(
          map((data) => {
            // Format and save token
            const token = this.authService.format(data);
            // solo queremos el token del usuario, no se está iniciando sesión
            if (saveTokenSuperUser)
              return loadSuperuserTokenSuccess({ token: token });
            // se está iniciando sesión
            this.authService.setTokenDataInLocalStorage(token);
            this.store.dispatch(loginSuccess({ token }));

            // Load user information
            return loadUser();
          }),
          catchError((error) => {
            let status = typeof error === 'object' ? error['status'] : null;
            if (typeof error === 'object' && 'error' in error) {
              error = error.error;
            }
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text:
                status === 0
                  ? 'Error en la conexión con el servidor'
                  : typeof error === 'string' && error?.length > 200
                  ? error.slice(0, 200) + '...'
                  : error || 'Error inesperado',
            });
            return of(changeLoginWaiting({ status: false }));
          })
        );
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      mergeMap((action) => {
        // Eliminamos el localStorage
        this.authService.removeLocalStorage();

        // Navegamos al login
        this.router.navigateByUrl('/login');

        return of(logoutSuccess({ token: initialStateAuth.token }));
      })
    );
  });

  /** REFRESH */
  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refresh),
      exhaustMap((action) => {
        const token = this.authService.getTokenFromLocalStorage();
        if (token != null) {
          this.store.dispatch(loginSuccess({ token }));
          return of(loadUser());
        } else {
          this.router.navigate(['/login']);
          return of(noData());
        }
      })
    );
  });

  /** USER */
  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUser),
      exhaustMap((action) => {
        return this.authService.getUser().pipe(
          map((data) => {
            // Ponemos el loginWaiting a false
            this.store.dispatch(changeLoginWaiting({ status: false }));
            var ruta =
              window.location.pathname == '/login'
                ? '/admin/leads'
                : window.location.pathname;
            ruta =
              window.location.href.split(window.location.pathname)[1].length > 0
                ? ruta + window.location.href.split(window.location.pathname)[1]
                : ruta;
            if (data['superuser']) {
              // Si es superuser, go to admin/leads
              this.router.navigateByUrl(ruta);
              return loadUserSuccess({ user: data });
            } else {
              // Si no es superuser, mostramos error y borramos el localStorage
              this.authService.removeLocalStorage();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Usted no puede acceder.',
              });
              return noData();
            }
          }),
          catchError((err) => {
            // Si obtenemos un error, volvemos a login
            this.router.navigateByUrl('/login');
            return of(noData());
          })
        );
      })
    );
  });

  loadSuperuserToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSuperuserToken),
      tap((e) =>
        this.store.dispatch(
          setKeyValueAdmin({
            key: labelsStateAdmin.LOADING_SUPERUSERS,
            value: true,
          })
        )
      ),
      exhaustMap((action) => {
        return this.authService.getSuperuserToken(action.id).pipe(
          map((data) => {
            // Format and save token
            const token_data = this.authService.format(data);

            // Load user information
            this.store.dispatch(
              setKeyValueAdmin({
                key: labelsStateAdmin.MESSAGE_SUPER,
                value: null,
              })
            );

            return loadSuperuserTokenSuccess({ token: token_data });
          }),
          catchError((err) => {
            this.store.dispatch(
              setKeyValueAdmin({
                key: labelsStateAdmin.MESSAGE_SUPER,
                value:
                  'Error al obtener token del superuser en estos momentos. No se podrán editar los datos del cliente. Intentar más tarde.',
              })
            );
            return of(loadSuperuserTokenSuccess({ token: null }));
          })
        );
      }),
      map((e) => {
        this.store.dispatch(
          setKeyValueAdmin({
            key: labelsStateAdmin.LOADING_SUPERUSERS,
            value: false,
          })
        );
        return e;
      })
    );
  });
}
