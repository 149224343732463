<div class="globalDiv">
  <mat-grid-list
    *ngIf="!loading"
    [cols]="cols"
    [rowHeight]="rowHeight"
    gutterSize="20px"
  >
    <mat-grid-tile
      *ngFor="let indicador of indicadores"
      [colspan]="1"
      [rowspan]="1"
    >
      <div class="contentTile">
        <span>{{ indicador.titulo | titlecase }}</span>
        <span>{{ indicador.numero | number }}</span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <div
    *ngIf="loading"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
