<div class="altas-container">
  <!-- statistics -->
  <div class="statistics-container">
    <mat-grid-list
      *ngIf="grid_tiles; else nodata"
      [cols]="columns"
      rowHeight="7em"
      (window:resize)="onResize($event)"
    >
      <mat-grid-tile *ngFor="let statistic of grid_tiles">
        <div class="title bg-dark text-white">
          <span>{{ statistic.title }}</span>
        </div>
        <div class="data-container" *ngIf="statistic.data">
          <div
            id="{{ statistic.id }}_{{ data.key }}"
            *ngFor="let data of statistic.data | keyvalue"
            [ngClass]="{
              'data-clickable':
                (statistic.id !== 'time' || data.key === 'date') &&
                !(loadingData | async),
              'data-filtered':
                data.key | altasFilterStyle : data.value : filters,
              'data-element': true
            }"
            (click)="onStatisticClick(statistic.id, data.key)"
          >
            <div>
              <span>{{ data.key | uppercase }}</span>
            </div>
            <div
              *ngIf="
                !(loadingData | async) || data.value !== null;
                else spinnerClock
              "
              [ngStyle]="{
                display:
                  data.key == 'date' && endDate !== null ? 'contents' : 'flex'
              }"
            >
              <div
                *ngIf="data.key === 'date'; else defaultSpanTime"
                class="date-container"
                [ngStyle]="{
                  'font-size':
                    endDate !== null &&
                    (startDate | date : 'dd/MM/yyyy') !=
                      (endDate | date : 'dd/MM/yyyy')
                      ? '0.7vw'
                      : 'inherit'
                }"
              >
                <span>{{
                  startDate !== null ? (startDate | date : "dd/MM/yyyy") : "-"
                }}</span>
                <ng-container
                  *ngIf="
                    endDate !== null &&
                    (startDate | date : 'dd/MM/yyyy') !=
                      (endDate | date : 'dd/MM/yyyy')
                  "
                >
                  <span>{{ (endDate | date : "dd/MM/yyyy") || "" }}</span>
                </ng-container>
              </div>
              <ng-template #defaultSpanTime>
                <span>{{ data.value !== null ? data.value : "-" }}</span>
              </ng-template>
            </div>
            <mat-form-field
              class="example-form-field"
              appearance="fill"
              *ngIf="data.key === 'date'"
            >
              <mat-date-range-input
                [rangePicker]="datePicker"
                [comparisonStart]="startDate"
                [comparisonEnd]="endDate"
              >
                <input matInput readonly matStartDate [(ngModel)]="startDate" />
                <input matInput readonly matEndDate [(ngModel)]="endDate" />
              </mat-date-range-input>

              <mat-date-range-picker
                #datePicker
                [disabled]="loadingNewDates"
                (opened)="openDatepicker()"
                (closed)="onDateChange()"
                [calendarHeaderComponent]="headerCalendar"
                [dateClass]="dateClass()"
                (yearSelected)="loadDates($event)"
                (monthSelected)="loadDates($event)"
              >
              </mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <ng-template class="nodata-container" #nodata>
      <span>{{
        (loadingData | async) ? "Cargando información" : "No hay información"
      }}</span>
    </ng-template>
  </div>

  <!-- filter -->
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    "
    [ngStyle]="{ 'flex-direction': columns === 1 ? 'column' : 'row' }"
  >
    <div class="filter-altas">
      <div class="inputs-filter-altas">
        <ng-select [(ngModel)]="selectedFilter" [clearable]="false">
          <ng-option *ngFor="let key of filterColumns" [value]="key">
            {{ columnsName[key] }}
          </ng-option>
        </ng-select>
        <input
          type="text"
          [(ngModel)]="inputFilter"
          (keydown.enter)="
            filtrar([selectedFilter, inputFilter]); inputFilter = ''
          "
        />
      </div>
      <div class="input-filter-button">
        <button
          [disabled]="!inputFilter?.length"
          (click)="filtrar([selectedFilter, inputFilter]); inputFilter = ''"
        >
          Filtrar
        </button>
      </div>
      <div class="container-all-filters-alta" *ngIf="filters?.length">
        <div
          *ngFor="let filt of filters | filterAltas : showAll"
          class="container-filter-alta"
        >
          <ng-container>
            <span>
              <span class="ellipsis">{{ columnsName[filt.attribute] }}</span
              ><span> {{ filt.value }}</span>
            </span>
            <mat-icon
              class="clear-filter-altas"
              (click)="filtrar([filt.attribute, filt.value])"
              >clear</mat-icon
            >
          </ng-container>
        </div>
      </div>
      <div id="button-delete-filters-container">
        <button
          [disabled]="!filters?.length"
          class="button-delete-filters"
          (click)="deleteAllFilters()"
        >
          <span>Eliminar filtros</span>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- table -->
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="(loadingData | async) || loadingReprocess"
  ></mat-progress-bar>
  <div class="table-container table-responsive" fxFlex fxLayout="row wrap">
    <div *ngIf="loadingData | async" class="loading-info">
      <span>Cargando información</span>
    </div>
    <div cdkScrollable fxFlex="100" class="mat-elevation-z8 table-scroll card">
      <mat-table
        [dataSource]="pagination?.datos"
        *ngIf="pagination?.datos?.length; else noResults"
        [cdkDropList]="true"
        (cdkDropListDropped)="dropTable($event)"
        fxFlex
      >
        <ng-container *ngFor="let key of displayedColumns" [matColumnDef]="key">
          <mat-header-cell
            *matHeaderCellDef
            style="position: relative"
            class="sticky"
            (click)="changeOrder(key)"
          >
            <span
              [ngStyle]="{ color: orderby.endsWith(key) ? 'black' : 'grey' }"
              >{{ columnsName[key] }}</span
            >
            <mat-icon
              *ngIf="
                orderby.startsWith('-') && orderby.endsWith(key);
                else arrowDown
              "
              style="color: black; position: absolute; right: 0"
              >keyboard_arrow_up</mat-icon
            >
            <ng-template #arrowDown>
              <mat-icon
                style="position: absolute; right: 0"
                [ngStyle]="{ color: orderby.endsWith(key) ? 'black' : 'grey' }"
                >keyboard_arrow_down</mat-icon
              >
            </ng-template>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; index as i">
            <ng-container *ngIf="key === 'status'; else percentageView">
              <ng-container *ngIf="element[key] | statusAltas as status">
                <span [ngStyle]="{ color: status.color }">{{
                  status.value
                }}</span>
              </ng-container>
            </ng-container>
            <ng-template #percentageView>
              <ng-container *ngIf="key === 'progress'; else dateView">
                <div class="progress" style="width: 100%">
                  <div
                    [ngClass]="{
                      'progress-bar progress-bar-striped progress-bar-animated': true,
                      'bg-cyan': element[key] > 40 && element[key] <= 80,
                      'bg-warning': element[key] <= 40,
                      'bg-success': element[key] > 80
                    }"
                    [ngStyle]="{ width: (element[key] || 0) + '%' }"
                  ></div>
                </div>
                {{ element[key] + "%" }}
              </ng-container>
            </ng-template>
            <ng-template #dateView>
              <ng-container *ngIf="key === 'date'; else defaultView">
                <span>{{ element[key] | date : "YYYY-MM-dd, HH:mm" }}</span>
              </ng-container>
            </ng-template>
            <ng-template #defaultView>
              <span> {{ element | altasCellObjectFormat : key }} </span>
            </ng-template>
          </mat-cell>
        </ng-container>
        <mat-header-row
          class="sticky-row"
          *matHeaderRowDef="displayedColumns; sticky"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="hover-row"
        ></mat-row
        ><!--cdkDrag-->
        <!-- <div *cdkDragPlaceholder></div> -->
      </mat-table>
      <mat-paginator
        *ngIf="pagination?.datos?.length"
        [length]="pagination?.objects"
        [pageIndex]="pageIndex"
        [pageSize]="limit"
        (page)="changePage($event)"
        [pageSizeOptions]="pageSizeOptions"
      ></mat-paginator>
      <ng-template #noResults>
        <div id="error-message">
          {{ (loadingData | async) ? "" : "No hay datos" }}
          <ng-container *ngIf="loadingData | async">
            <br /><br /><br />
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #spinnerClock>
  <div class="clock-container"></div>
</ng-template>
