<div class="loading-modals" *ngIf="loadingOptionsDialogs">
  <div class="pl pl-origami"></div>
</div>
<div id="container-editor">
  <mat-horizontal-stepper
    #stepper
    [selectedIndex]="step"
    style="height: 100%"
    (selectionChange)="selectionChangeStep($event)"
  >
    <!-- stepper icons -->
    <ng-template matStepperIcon="account_circle">
      <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="public">
      <mat-icon>public</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="info">
      <mat-icon>info</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload_file">
      <mat-icon>upload_file</mat-icon>
    </ng-template>

    <!-- step 1: client info -->
    <mat-step [completed]="step > 0" state="account_circle">
      <ng-template matStepLabel>Información del cliente</ng-template>
      <app-editor-form
        *ngIf="step == 0"
        [client]="client"
        (formChange)="getFormChange($event)"
      ></app-editor-form>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <button mat-button (click)="Back()">Salir</button>
          <button
            mat-button
            (click)="Forward()"
            [disabled]="
              (client &&
                ((!superuserToken && !editForm?.valid) ||
                  loadingSuperuserToken)) ||
              (!client && !client_data) ||
              !validForm
            "
          >
            Siguiente
          </button>
        </div>
        <div *ngIf="!validForm">
          <span class="errorForm">* Cliente no válido</span>
        </div>
        <ng-container *ngIf="client">
          <div
            class="alert alert-danger info-superusers"
            id="buttonDanger"
            *ngIf="
              !loadingSuperuserToken && superuserToken === null;
              else correctSuperusers
            "
            disappear
          >
            <i class="fas fa-times" style="color: red"></i
            ><span>Superuser no cargado</span>
          </div>
          <ng-template #correctSuperusers>
            <div
              class="alert alert-success info-superusers"
              id="buttonSuccess"
              *ngIf="
                !loadingSuperuserToken && superuserToken !== null;
                else loadingSuperuser
              "
              disappear
            >
              <i class="fas fa-check" style="color: green"></i
              ><span>Superuser cargado</span>
            </div>
          </ng-template>
          <ng-template #loadingSuperuser>
            <div
              class="alert alert-warning info-superusers"
              *ngIf="loadingSuperuserToken"
            >
              <i class="fas fa-spinner fa-spin"></i
              ><span
                >Cargando superuser{{
                  changeStepSuperuser ? ". Cambiando a áreas..." : "..."
                }}
              </span>
            </div>
          </ng-template>
        </ng-container>
        <button
          *ngIf="client?.category == 2"
          mat-button
          style="background-color: #293133; color: white"
          (click)="demoToClient()"
        >
          Convertir a cliente
        </button>
        <button
          *ngIf="client?.category == 3"
          mat-button
          style="background-color: #293133; color: white"
          (click)="expireClient()"
        >
          Dar de baja
        </button>
      </div>
    </mat-step>

    <!-- step 2: list areas -->
    <mat-step [completed]="step > 1" state="public">
      <ng-template matStepLabel>Áreas</ng-template>
      <app-list-areas
        *ngIf="
          paginatorAltasAreas?.datos?.length ||
            actualFilterAltas ||
            loadingTableAltas;
          else noAreasClient
        "
        [typesColumns]="typesInColumns"
        [pagination]="paginatorAltasAreas"
        [columns]="columnsAltas"
        [columnsNames]="namesAltas"
        [orderby]="orderbyTable"
        [maxHeight]="55"
        [loading]="loadingTableAltas"
        [notFilteredColumns]="['status']"
        [filter]="showFilterAltas"
        [actions]="actionsTable"
        [moreOptions]="moreOptionsTable"
        [selectableRows]="true"
        [hoverStatus]="true"
        [addButton]="true"
        (rowSelected)="selectArea($event)"
        (optionSelected)="selectedOption($event)"
        (changePage)="changePage($event)"
        (createArea)="Forward()"
      ></app-list-areas>
      <ng-template #noAreasClient>
        <div class="not-areas-container">
          <span>Todavía no existen áreas para este cliente</span>
          <div class="button-add-editor">
            <button class="btn btn-sm btn-primary" (click)="Forward()">
              <span>AÑADIR ÁREA</span>
              <mat-icon [inline]="true">add_circle</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>
      <div>
        <button mat-button (click)="Back()">Atrás</button>
      </div>
    </mat-step>

    <!-- step 3: area info-->
    <mat-step [completed]="step > 1" state="info">
      <ng-template matStepLabel>Información del área</ng-template>
      <div class="divArea" *ngIf="step == 2">
        <app-editor-form
          [area]="area"
          (formChange)="getFormChange($event)"
        ></app-editor-form>
        <app-editor-products
          [client]="client"
          [area]="area_data"
          (editarDato)="productsFormChange($event)"
          (productLoaderEvent)="productsLoader = $event.productLoader"
        ></app-editor-products>
      </div>
      <div class="areaFooter">
        <button mat-button [disabled]="productsLoader" (click)="Back()">
          Atrás
        </button>
        <button
          mat-button
          [disabled]="!validForm || productsLoader"
          (click)="Forward()"
        >
          Siguiente
        </button>
        <div *ngIf="!validForm">
          <span class="errorForm">* Área no válida</span>
        </div>
      </div>
    </mat-step>

    <!-- step 4: upload files -->
    <mat-step [completed]="step > 1" state="upload_file">
      <ng-template matStepLabel>Subir datos</ng-template>
      <app-upload-data
        [area]="area"
        [client]="client"
        (finishedArea)="finishedArea($event)"
      ></app-upload-data>
      <div>
        <button mat-button (click)="Back()">
          Volver a la edición del área
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
