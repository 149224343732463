import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';

import { EditorComponent } from '../commons/editor/editor.component';
import { AdminLayoutComponent } from '../layouts/admin-layout/admin-layout.component';
import { AltasComponent } from './admin/altas/altas.component';
import { BajasComponent } from './admin/bajas/bajas.component';
import { ClientesComponent } from './admin/clientes/clientes.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { DemosComponent } from './admin/demos/demos.component';
import { LeadsComponent } from './admin/leads/leads.component';
import { NovedadesComponent } from './admin/novedades/novedades.component';
import { PowerbiComponent } from './admin/powerbi/powerbi.component';
import { ProductsConfigurationComponent } from './admin/products_configuration/products_configuration.component';
import { ServersComponent } from './admin/servers/servers.component';
import { TilesComponent } from './admin/tiles/tiles.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: { rol: 'ROL_ADMIN' },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Estadísticas',
          // breadcrums: []
        },
      },
      {
        path: 'powerbi',
        component: PowerbiComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/powerbi/powerbi.module').then(
            (m) => m.PowerbiModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'PowerBI',
          // breadcrums: []
        },
      },
      {
        path: 'leads',
        component: LeadsComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/leads/leads.module').then(
            (m) => m.LeadsModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Leads',
          // breadcrums: []
        },
      },
      {
        path: 'leads/lead',
        component: EditorComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('../commons/editor/editor.module').then((m) => m.EditorModule),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Editor de Lead',
          breadcrums: [{ titulo: 'Tabla Leads', url: '/admin/leads' }],
        },
      },
      {
        path: 'demos',
        component: DemosComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/demos/demos.module').then(
            (m) => m.DemosModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Demos',
          // breadcrums: []
        },
      },
      {
        path: 'demos/demo',
        component: EditorComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('../commons/editor/editor.module').then((m) => m.EditorModule),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Editor de Demo',
          breadcrums: [{ titulo: 'Tabla Demos', url: '/admin/demos' }],
        },
      },
      {
        path: 'clientes',
        component: ClientesComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/clientes/clientes.module').then(
            (m) => m.ClientesModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Clientes',
          // breadcrums: []
        },
      },
      {
        path: 'clientes/cliente',
        component: EditorComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('../commons/editor/editor.module').then((m) => m.EditorModule),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Editor de Cliente',
          breadcrums: [{ titulo: 'Tabla Clientes', url: '/admin/clientes' }],
        },
      },
      {
        path: 'bajas',
        component: BajasComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/bajas/bajas.module').then(
            (m) => m.BajasModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Bajas',
          // breadcrums: []
        },
      },
      {
        path: 'novedades',
        component: NovedadesComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/novedades/novedades.module').then(
            (m) => m.NovedadesModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Novedades',
          // breadcrums: []
        },
      },
      {
        path: 'products',
        component: ProductsConfigurationComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import(
            'src/app/pages/admin/products_configuration/products_configuration.module'
          ).then((m) => m.ProductsConfigurationModule),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Products',
          // breadcrums: []
        },
      },
      {
        path: 'tiles',
        component: TilesComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/tiles/tiles.module').then(
            (m) => m.TilesModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Tiles',
          // breadcrums: []
        },
      },
      {
        path: 'altas',
        component: AltasComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/altas/altas.module').then(
            (m) => m.AltasModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Altas',
          // breadcrums: []
        },
      },
      {
        path: 'servers',
        component: ServersComponent,
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/servers/servers.module').then(
            (m) => m.ServersModule
          ),
        data: {
          rol: 'ROL_ADMIN',
          titulo: 'Servidores',
          // breadcrums: []
        },
      },
      { path: '**', redirectTo: 'dashboard' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
