import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { columnsTable } from '../../constants/columnsTable';

@Component({
  selector: 'app-download-clients-dialog',
  templateUrl: './download-clients-dialog.component.html',
  styleUrls: ['./download-clients-dialog.component.scss']
})
export class DownloadClientsDialogComponent implements OnInit {
  columns: string[] = [];
  valueRadio: boolean = false;
  namesColumns: string[] = [];
  selectedColumns: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<DownloadClientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { columns: string[], title: string },
  ) { }

  ngOnInit(): void {
    this.columns = this.data?.columns || [];
    this.namesColumns = this.columns.map(label => {
      let a = label.split('__');
      return a[a.length - 1];
    })
    this.selectedColumns = [ ...this.columns ];
  }

  changeSelectedColumns() {
    this.selectedColumns = !this.valueRadio ? [ ...this.columns ] : [];
  }

  addSelectedColumn(column: string) {
    if(this.selectedColumns.includes(column)) {
      this.selectedColumns = this.selectedColumns.filter(e => e != column)
    } else {
      this.selectedColumns.push(column)
    }
  }

  sendColumns() {
    let columns = this.columns?.filter(e => this.selectedColumns.includes(e));
    this.dialogRef.close({ columns: columns });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
