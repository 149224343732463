import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { PopoverModule } from 'ngx-smart-popover';
import { MainPipe } from '../pipes/main-pipe.module';
import { PipesModule } from '../pipes/pipes.module';
import { CommonService } from '../services/Common.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { DonutBarComponent } from './donut-bar/donut-bar.component';
import { EditorComponent } from './editor/editor.component';
import { FilterTableComponent } from './filter-table/filter-table.component';
import { IndeterminateProgressBarComponent } from './indeterminate-progress-bar/indeterminate-progress-bar.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { SelectusersComponent } from './selectusers/selectusers.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableComponent } from './table/table.component';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AltasEffects } from '../pages/admin/altas/state/altas.effects';
import { AltasAreasDialogComponent } from './dialog/altas-areas-dialog/altas-areas-dialog.component';
import { AltasAreaEffects } from './dialog/altas-areas-dialog/state/altas-area.effects';
import { AltasAreaReducer } from './dialog/altas-areas-dialog/state/altas-area.reducer';
import { ALTAS_AREA_STATE_NAME } from './dialog/altas-areas-dialog/state/altas-area.selector';
import { DownloadClientsDialogComponent } from './dialog/download-clients-dialog/download-clients-dialog.component';
import { EditorFormComponent } from './editor/editor-form/editor-form.component';
import { EditorProductsComponent } from './editor/editor-products/editor-products.component';
import { ListAreasComponent } from './editor/list-areas/list-areas.component';
import { UploadDataComponent } from './editor/upload-data/upload-data.component';
import { UploadTableComponent } from './editor/upload-data/upload-table/upload-table.component';
import { AsignarCurvasComponent } from './table/asignar-curvas/asignar-curvas.component';
import { CurvasComponent } from './table/generar-curvas/curvas/curvas.component';
import { FilterCurvesComponent } from './table/generar-curvas/filter-curves/filter-curves.component';
import { GenerarCurvasComponent } from './table/generar-curvas/generar-curvas.component';
import { GenerarCurvasEffects } from './table/generar-curvas/state/generar-curvas.effects';
import { GenerarCurvasReducer } from './table/generar-curvas/state/generar-curvas.reducer';
import { GENERARCURVAS_STATE_NAME } from './table/generar-curvas/state/generar-curvas.selector';
import { ReprocesarFsComponent } from './table/reprocesar-fs/reprocesar-fs.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    NavbarComponent,
    SidebarComponent,
    PaginationComponent,
    ProgressbarComponent,
    SelectusersComponent,
    DonutBarComponent,
    LineChartComponent,
    EditorComponent,
    TableComponent,
    IndeterminateProgressBarComponent,
    FilterTableComponent,
    AsignarCurvasComponent,
    ReprocesarFsComponent,
    GenerarCurvasComponent,
    CurvasComponent,
    FilterCurvesComponent,
    DownloadClientsDialogComponent,
    AltasAreasDialogComponent,
    EditorFormComponent,
    EditorProductsComponent,
    ListAreasComponent,
    UploadDataComponent,
    UploadTableComponent,
  ],
  exports: [
    BreadcrumbComponent,
    NavbarComponent,
    SidebarComponent,
    PaginationComponent,
    ProgressbarComponent,
    MatIconModule,
    SelectusersComponent,
    DonutBarComponent,
    LineChartComponent,
    EditorComponent,
    MatProgressSpinnerModule,
    TableComponent,
    MatIconModule,
    IndeterminateProgressBarComponent,
    FilterTableComponent,
    FilterCurvesComponent,
    CommonModule,
    MatDividerModule,
    NgChartsModule,
    MatTabsModule,
    NgSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MainPipe,
    MatCheckboxModule,
    PipesModule,
    RouterModule,
    MatDialogModule,
    PopoverModule,
    MatMenuModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatBadgeModule,
    ListAreasComponent,
    DownloadClientsDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    NgChartsModule,
    MatTabsModule,
    NgSelectModule,
    MatRadioModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MainPipe,
    MatCheckboxModule,
    PipesModule,
    RouterModule,
    MatDialogModule,
    MatMenuModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatBadgeModule,
    NgbModule,
    MatStepperModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressBarModule,
    PopoverModule,
    EffectsModule.forFeature([
      GenerarCurvasEffects,
      AltasEffects,
      AltasAreaEffects,
    ]),
    StoreModule.forFeature(GENERARCURVAS_STATE_NAME, GenerarCurvasReducer),
    StoreModule.forFeature(ALTAS_AREA_STATE_NAME, AltasAreaReducer),
  ],
  providers: [CommonService],
})
export class CommonsModule {}
