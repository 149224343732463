<div class="animate__animated animate__fadeInRight animate__faster content">
  <div style="overflow-y: hidden">
    <div class="filter-container">
      <app-filter-table
        [title]="title"
        (obtenerTipo)="obtenerTipo($event)"
        (filtrarTabla)="filtrar($event)"
        [actualFilter]="actualFilter"
        [disabled]="disabledFilter || (data && data.objects < 2)"
        [tipoInput]="tipoInput"
      ></app-filter-table>
      <div
        [ngClass]="{
          'button-with-filter': actualFilter,
          'button-download-container': true
        }"
        *ngIf="!showUsers"
      >
        <button
          class="button-download"
          [disabled]="!data || !datos?.length || (loading | async)"
          (click)="downloadCSVTable()"
        >
          <mat-icon
            *ngIf="!loadingDownload; else spinnerDownloads"
            [inline]="true"
            >download</mat-icon
          >
          <span>Descargar</span>
        </button>
      </div>
    </div>
    <div
      [ngClass]="{ 'card col-md-12': true, 'min-table': data && datos?.length }"
    >
      <app-table
        [data]="data"
        [title]="title"
        [showUsers]="showUsers"
        [datos]="datos"
        [buttons]="buttonsTable"
        [page]="actualPage"
        (editarDato)="editarDato($event)"
        (changePage)="changePage($event)"
        (sendUser)="showUsersClient($event)"
        (changeLimit)="changeLimit($event)"
        (changeOrderedBy)="changeOrder($event)"
        (openNew)="newDato()"
        [danger]="[dangerTitle, dangerFunction]"
        (showSuperuserEvent)="showSuperUser($event)"
      >
      </app-table>
    </div>
  </div>
</div>
<ng-template #spinnerDownloads>
  <mat-spinner class="spinner-downloads"></mat-spinner>
</ng-template>
