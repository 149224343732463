import { createAction, props } from '@ngrx/store';
import { Area } from 'src/app/interfaces/area';
import { cliente } from 'src/app/interfaces/cliente.interface';
import { Producto } from 'src/app/interfaces/producto';

/** CLIENTE */
export const EDIT_CLIENT_SUCCESS = '[ editor page] edit client success';
export const EXPIRE_CLIENT = '[editor page] expire client';
export const REGISTER_CLIENT_TO_STRIPE =
  '[editor page] register client to stripe';
export const CREATE_CLIENT = '[editor page] create client';
export const NEW_EDIT_CLIENT = '[editor page] new edit client';
export const CREATE_OR_EDIT_CLIENT_SUCCESS =
  '[editor page] create or edit client success';

export const editClientSuccess = createAction(
  EDIT_CLIENT_SUCCESS,
  props<{ data?: any; category?: number; result?: boolean; isNew?: boolean }>()
);
export const expireClient = createAction(
  EXPIRE_CLIENT,
  props<{ client: any }>()
);
export const registerClientToStripe = createAction(
  REGISTER_CLIENT_TO_STRIPE,
  props<{ id: number }>()
);
export const createClient = createAction(CREATE_CLIENT, props<{ data: any }>());
export const newEditClient = createAction(
  NEW_EDIT_CLIENT,
  props<{ id: number; data: any }>()
);
export const createOrEditClientSuccess = createAction(
  CREATE_OR_EDIT_CLIENT_SUCCESS,
  props<{ result: boolean; message?: string }>()
);

/** AREAS */
export const AREAS_ALL = '[ editor page] areas all';
export const AREAS_ALL_SUCCESS = '[ editor page] areas all success';
export const LOAD_CLIENT_AREAS = '[ editor page] load client areas ';
export const LOAD_CLIENT_AREAS_SUCCESS =
  '[ editor page] load client areas succes ';
export const CREATE_AREA = '[ editor page] create areas ';
export const CREATE_AREA_SUCCESS = '[ editor page] create areas success ';
export const EDIT_AREA_SUCCESS = '[ editor page] edit area success';
export const EDIT_AREA = '[ editor page] edit area';
export const FINISH_AREA = '[ editor page] finish area ';
export const FINISH_AREA_SUCCESS = '[ editor page] finish area success';
export const LOAD_PRODUCTS_CONF = '[editor page] load products conf';
export const LOAD_AREA_PRODUCTS = '[ editor page] load area products';
export const LOAD_AREA_PRODUCTS_SUCCESS =
  '[ editor page] load area products success';

export const areasAll = createAction(AREAS_ALL, props<{ filtros: any[] }>());
export const areasAllSuccess = createAction(
  AREAS_ALL_SUCCESS,
  props<{ areas: any }>()
);
export const loadClientAreas = createAction(
  LOAD_CLIENT_AREAS,
  props<{ clientId: number }>()
);
export const loadClientAreasSucces = createAction(
  LOAD_CLIENT_AREAS_SUCCESS,
  props<{ data: any }>()
);
export const createArea = createAction(
  CREATE_AREA,
  props<{ client: any; area: Area; productsForm: any }>()
);
export const createAreaSuccess = createAction(
  CREATE_AREA_SUCCESS,
  props<{ data: Area }>()
);
export const editArea = createAction(
  EDIT_AREA,
  props<{ area: Area; data: Object; cliente: any; productsForm: any }>()
);
export const editAreaSuccess = createAction(
  EDIT_AREA_SUCCESS,
  props<{ result: boolean }>()
);
export const finishArea = createAction(
  FINISH_AREA,
  props<{ areaId: number; clienteId: number }>()
);
export const finishAreaSuccess = createAction(
  FINISH_AREA_SUCCESS,
  props<{ data: any }>()
);
export const loadProductsConf = createAction(
  LOAD_PRODUCTS_CONF,
  props<{ configurations: any[] }>()
);
export const loadAreaProducts = createAction(
  LOAD_AREA_PRODUCTS,
  props<{ areaId: number; configurations: any }>()
);
export const loadAreaProductsSuccess = createAction(
  LOAD_AREA_PRODUCTS_SUCCESS,
  props<{ products: Producto[] }>()
);

/** PRODUCTOS */
export const LOAD_EDITOR_PRODUCTOS = '[ editor page] load editor productos';
export const LOAD_EDITOR_PRODUCTOS_SUCCES =
  '[ editor page] load editor productos success';
export const CHANGE_EDITOR_PRODUCT = '[ editor page] change editor product';
export const CREATE_AREA_PRODUCTS = '[ editor page] create area products';
export const CREATE_AREA_PRODUCTS_SUCCESS =
  '[ editor page] create area products success';

export const loadEditorProductos = createAction(
  LOAD_EDITOR_PRODUCTOS,
  props<{ client: cliente; area: Area }>()
);
export const loadEditorProductosSucces = createAction(
  LOAD_EDITOR_PRODUCTOS_SUCCES,
  props<{ area: Area; productos: Producto[] }>()
);
export const createAreaProducts = createAction(
  CREATE_AREA_PRODUCTS,
  props<{ peticiones: any[] }>()
);
export const createAreaProductsSuccess = createAction(
  CREATE_AREA_PRODUCTS_SUCCESS,
  props<{ result?: boolean }>()
);

/** POLIGONOS */
export const EDIT_POLYGONS = '[ editor page] edit polygons';
export const EDIT_POLYGONS_SUCCESS = '[ editor page] edit polygons success';
export const UPLOAD_POLYGONS = '[ editor page] upload polygons';
export const UPLOAD_POLYGONS_SUCCESS = '[ editor page] upload polygons success';

export const editPolygons = createAction(
  EDIT_POLYGONS,
  props<{ areaId: number; update: any; upload: any }>()
);
export const editPolygonsSuccess = createAction(
  EDIT_POLYGONS_SUCCESS,
  props<{ result: any }>()
);
export const uploadPolygons = createAction(
  UPLOAD_POLYGONS,
  props<{ areaId: number; upload: any }>()
);
export const uploadPolygonsSuccess = createAction(
  UPLOAD_POLYGONS_SUCCESS,
  props<{ result: any }>()
);

/** FILES */
export const PROCESS_FILETOGEOJSON = '[ editor page] process fileToGeojson';
export const PROCESS_COMPARESHAPE = '[ editor page] process compareShape';
export const PROCESS_HISTORICSHAPE = '[ editor page] process historicShape';

export const processFileToGeojson = createAction(
  PROCESS_FILETOGEOJSON,
  props<{ data: any }>()
);
export const processCompareShape = createAction(
  PROCESS_COMPARESHAPE,
  props<{ areaId: number; data: any }>()
);
export const processHistoricShape = createAction(
  PROCESS_HISTORICSHAPE,
  props<{ areaId: number; data: any }>()
);

/** UNIQUES */
export const UNIQUES_LIMITED = '[ editor page] uniques limited ';
export const UNIQUES_LIMITED_SUCCESS = '[ editor page] uniques limited success';
export const UNIQUES = '[ editor page] unique ';
export const UNIQUES_SUCCESS = '[ editor page] uniques success';

export const uniquesLimited = createAction(
  UNIQUES_LIMITED,
  props<{ areaId: number; datos: any }>()
);
export const uniquesLimitedSuccess = createAction(
  UNIQUES_LIMITED_SUCCESS,
  props<{ data: any }>()
);
export const uniques = createAction(
  UNIQUES,
  props<{ areaId: number; datos: any }>()
);
export const uniquesSuccess = createAction(
  UNIQUES_SUCCESS,
  props<{ data: any }>()
);

/** CURVAS */

export const CURVAS_OPTIMAS = '[editor page] curvas optimas';
export const CURVAS_OPTIMAS_SUCCESS = '[editor page] curvas optimas succes';
export const ASIGNAR_CURVAS = '[editor page] asignar curvas';
export const ASIGNAR_CURVAS_SUCCESS = '[editor page] asignar curvas succes';
export const BORRAR_CURVAS = '[editor page] borrar curvas';
export const BORRAR_CURVAS_SUCCESS = '[editor page] borrar curvas succes';
export const ADD_BORRAR_CURVAS = '[editor page] add borrar curvas';
export const VACIAR_CURVAS = '[editor page] vaciar curvas';
export const SET_CURVE_FILTER = '[editor page] set curve filter';
export const LOADING_CURVES = '[editor page] loading curves';

export const curvasOptimas = createAction(
  CURVAS_OPTIMAS,
  props<{ url: string; tipo: number; body?: any; area: Area }>()
);
export const curvasOptimasSuccess = createAction(
  CURVAS_OPTIMAS_SUCCESS,
  props<{ curvas: any; tipo: number }>()
);
export const asignarCurvas = createAction(
  ASIGNAR_CURVAS,
  props<{ area: Area; productos: any[]; curvasSeleccionar: any[] }>()
);
export const asignarCurvasSuccess = createAction(
  ASIGNAR_CURVAS_SUCCESS,
  props<{ data: any }>()
);
export const borrarCurvas = createAction(
  BORRAR_CURVAS,
  props<{ area: Area; productos: any[]; borrar: any[]; curvas; variedad }>()
);
export const borrarCurvasSuccess = createAction(
  BORRAR_CURVAS_SUCCESS,
  props<{ data: any }>()
);
export const addBorrarCurvas = createAction(
  ADD_BORRAR_CURVAS,
  props<{ curvas: any[]; add: boolean }>()
);
export const vaciarCurvas = createAction(VACIAR_CURVAS);
export const setCurveFilter = createAction(
  SET_CURVE_FILTER,
  props<{ filter: any }>()
);
export const loadingCurves = createAction(
  LOADING_CURVES,
  props<{ active: boolean }>()
);

/** EDITOR */
export const CHANGE_EDITOR_STEP = '[editor page] change editor step';
export const SELECT_EDITOR_AREA = '[editor page] select editor area';
export const PARCELAS_PAGE = '[editor page] parcelas page';
export const PARCELAS_PAGE_SUCCESS = '[editor page] parcelas page success';
export const CHANGE_EDITOR_LOADER = '[editor page] change editor loader';

export const changeEditorStep = createAction(
  CHANGE_EDITOR_STEP,
  props<{ step: number }>()
);
export const selectEditorArea = createAction(
  SELECT_EDITOR_AREA,
  props<{ area: Area | null }>()
);
export const parcelasPage = createAction(
  PARCELAS_PAGE,
  props<{ area: Area }>()
);
export const parcelasPageSuccess = createAction(
  PARCELAS_PAGE_SUCCESS,
  props<{ parcelas: { par_activas: number; par_activas_planet: number } }>()
);
export const changeEditorLoader = createAction(
  CHANGE_EDITOR_LOADER,
  props<{ id: string; message: string; value: boolean }>()
);

/** FILES */
export const PROCCESS_FILES = '[editor page] proccess files';
export const PROCCESS_FILES_SUCCESS = '[editor page] proccess files success';

export const proccessFiles = createAction(
  PROCCESS_FILES,
  props<{ files: Array<File>; area: Area }>()
);
export const proccessFilesSuccess = createAction(
  PROCCESS_FILES_SUCCESS,
  props<{ result: any; historic?: boolean }>()
);

/** CLEAR */
export const CLEAR_EDITOR_STATE = '[ editor page] clear editor state';

export const clearEditorState = createAction(CLEAR_EDITOR_STATE);
