import { Tile } from "src/app/interfaces/tiles.interface"
import { AbstractPagination, Pagination } from "src/app/interfaces/Pagination.interface"
import { AltaArea } from "src/app/interfaces/AltasArea.interface"

export interface AltasAreaState {
  pagination: AbstractPagination<AltaArea> | null,
  total_altas: number,
  loading: boolean,
  loadingTable: boolean,
  actualFilter: { filtro: Object, extra_filt: Object } | null
}

export const initialAltasAreaState: AltasAreaState = {
  pagination: null,
  total_altas: -1,
  loading: false,
  loadingTable: false,
  actualFilter: null
}

export enum labelsStateAltasArea {
  PAGINATION = 'pagination',
  TOTAL_ALTAS = 'total_altas',
  LOADING = 'loading',
  LOADING_TABLE = 'loadingTable',
  ACTUAL_FILTER = 'actualFilter'
}
