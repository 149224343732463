<div class="altas-header">
  <!-- legend -->
  <div class="legend">
    <div class="field-legend">
      <div class="square" style="background-color: dodgerblue"></div>
      Executing
    </div>
    <div class="field-legend">
      <div class="square" style="background-color: red"></div>
      Error
    </div>
  </div>
  <div class="header-menu">
    <!-- date selected + year view -->
    <button
      mat-raised-button
      class="date-button"
      (click)="yearViewClicked()"
      [disabled]="
        calendar.currentView == 'multi-year' ||
        calendar.currentView == 'year' ||
        loading
      "
    >
      <span class="example-header-label">{{ periodLabel }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <div class="arrow-buttons">
      <!-- previous month -->
      <button
        mat-icon-button
        style="outline: none"
        [disabled]="
          calendar.currentView === 'year' || loading || disabledLeftArrow
        "
        (click)="customPreviousNextClicked(-1)"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <!-- next month -->
      <button
        mat-icon-button
        style="outline: none"
        [disabled]="
          calendar.currentView === 'year' || loading || disabledRightArrow
        "
        (click)="customPreviousNextClicked(1)"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>
