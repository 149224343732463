import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/Common.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  crearNovedad,
  crearNovedadSuccess,
  editarNovedad,
  editarNovedadSuccess,
  getDetailNovedad,
  getDetailNovedadSuccess,
  loadClientsDemosAndClients,
  loadDataNovedades,
  setLoading,
} from '../../state/admin.actions';
import { getClientsDemosAndClients } from '../../state/admin.selector';

@Component({
  selector: 'app-form-novedades',
  templateUrl: './form-novedades.component.html',
  styleUrls: ['./form-novedades.component.scss'],
})
export class FormNovedadesComponent implements OnInit, OnDestroy {
  title: string = '';
  firstTouch: boolean = true;
  formNovedad: FormGroup;
  formBuilder: FormBuilder = new FormBuilder();
  imageToSave: any;
  indicadores: any;
  clients: Object[];
  cultivesUniques: any;
  cultivesSelected: any;
  searchText: string = '';
  // tamaño máximo de la imagen a ingresar
  MAX_SIZE_IMAGE = 1 /** mb */ * 1024 * 1024;
  loadingImage: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<FormNovedadesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<Date>,
    private actions: Actions,
    private store: Store<AppState>,
    private domSanitizer: DomSanitizer,
    private imageCompressor: NgxImageCompressService,
    private commonService: CommonService
  ) {
    this.dateAdapter.setLocale('es');
  }

  ngOnInit(): void {
    this.store
      .select(getClientsDemosAndClients)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) {
          // ordenar clientes alfabéticamente
          this.clients = [...value].sort((a, b) => {
            if (a === null) return 1;
            if (b === null) return -1;
            if (a['nombre'] === null) return 1;
            if (!b['nombre']) return -1;
            return a['nombre'].localeCompare(b['nombre']);
          });
          // obtener los cultivos únicos de los clientes disponibles
          this.cultivesUniques = this.clients.reduce(
            (act: string[], element) => {
              return act.concat(
                element['cultivos'].filter(
                  (cultive) => !act.includes(cultive) && cultive !== null
                )
              );
            },
            []
          );
          // ordenarlos alfabéticamente
          this.cultivesUniques = [...this.cultivesUniques].sort((a, b) => {
            if (a === null) return 1;
            if (b === null) return -1;
            return a.localeCompare(b);
          });
        } else {
          this.store.dispatch(loadClientsDemosAndClients());
        }
      });
    this.actions
      .pipe(ofType(crearNovedadSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        const { result } = value;
        if (result) {
          Swal.fire({
            icon: 'success',
            title: '¡Novedad creada!',
            timer: 4000,
          }).then((fin) => {
            this.store.dispatch(loadDataNovedades({}));
            this.dialogRef.close();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Novedad no creada!',
            text: 'Hubieron errores al crear la novedad',
            timer: 4000,
          });
          this.store.dispatch(setLoading({ loading: false }));
        }
      });

    this.actions
      .pipe(ofType(editarNovedadSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        const { result } = value;
        if (result) {
          Swal.fire({
            icon: 'success',
            title: '¡Novedad editada!',
            timer: 4000,
          }).then((fin) => {
            this.store.dispatch(loadDataNovedades({}));
            this.dialogRef.close();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Novedad no editada!',
            text: 'Hubieron errores al editar la novedad',
            timer: 4000,
          });
          this.store.dispatch(setLoading({ loading: false }));
        }
      });

    this.data
      ? (this.title = 'Editar novedad')
      : (this.title = 'Crear novedad');

    this.iniciarFormulario();
    this.data && this.loadAllInfo();
  }

  private loadAllInfo() {
    this.loadingImage = true;
    let id = this.data['id'];
    let subject = new Subject();
    this.actions
      .pipe(ofType(getDetailNovedadSuccess), takeUntil(subject))
      .subscribe(({ value }) => {
        if (value) {
          this.imageToSave = value['imagen'];
          this.formNovedad
            .get('imagen')
            .setValidators(value['imagen'] ? [] : [Validators.required]);
          this.formNovedad.get('imagen').updateValueAndValidity();
        }
        this.loadingImage = false;
        subject.next();
      });
    this.store.dispatch(getDetailNovedad({ id: id }));
  }

  /**
   * Cambiar validador de imagen, ya que se necesita o un vídeo o una imagen
   */
  async changeValidatorImagen() {
    const { imagen, link_video } = this.formNovedad.controls;

    if (
      (imagen.value && imagen.value !== '') ||
      (link_video.value && link_video.value !== '') ||
      this.imageToSave
    ) {
      // se han rellenado uno de los cambios
      imagen.setValidators([]);
    } else {
      // no hay datos, se añade validador
      imagen.setValidators([Validators.required]);
    }
    imagen.updateValueAndValidity();
  }

  iniciarFormulario() {
    this.formNovedad = this.formBuilder.group({
      titulo: [this.data ? this.data['titulo'] : '', [Validators.required]],
      fecha_inicio: [
        this.data ? this.data['fecha_inicio'] : '',
        [Validators.required],
      ],
      fecha_vencimiento: [
        this.data ? this.data['fecha_vencimiento'] : '',
        [Validators.required],
      ],
      link: [this.data ? this.data['link'] : ''],
      link_video: [this.data ? this.data['link_video'] : ''],
      tipo: [this.data ? this.data['tipo'] : 1, [Validators.required]],
      rol_user: [this.data ? this.data['rol_user'] : null],
      descripcion: [this.data ? this.data['descripcion'] : '', []],
      imagen: [
        this.data && this.data['imagen'] ? `` : '',
        this.data && this.data['imagen'] ? [] : [Validators.required],
      ],
      clientes: [
        this.data && this.data['clientes'] ? this.data['clientes'] : [],
        [Validators.required],
      ],
    });

    // guardar imagen
    if (this.data && this.data['imagen'])
      this.imageToSave = this.data['imagen'];

    // cambiar valores al formato correcto
    this.dateChanged('fecha_inicio');
    this.dateChanged('fecha_vencimiento');
  }

  /**
   * Función que normaliza el valor de date de un control del form a un valor legible en bd
   * @param name nombre del control de tipo date
   */
  dateChanged(name: string) {
    this.formNovedad.get(name).valueChanges.subscribe((value: Date) => {
      if (
        value &&
        !/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(
          value.toString()
        )
      ) {
        const day =
          value.getDate() < 10 ? '0' + value.getDate() : value.getDate();
        const month =
          value.getMonth() + 1 < 10
            ? '0' + (value.getMonth() + 1)
            : value.getMonth() + 1;
        const year = value.getFullYear();
        this.formNovedad.get(name).setValue(`${year}-${month}-${day}`);
      }
    });
  }

  /**
   * Función submit que envía la información según el tipo de envío
   */
  async prepararInfo() {
    if (this.formNovedad.invalid && this.firstTouch) {
      // no es válido el form
      this.formNovedad.markAllAsTouched();
      this.firstTouch = false;
      this.formNovedad.updateValueAndValidity();
      return false;
    }

    this.enviarInfo();
  }

  /**
   * Función que formatea los datos y los envía
   */
  enviarInfo() {
    const novedad = {
      titulo: this.formNovedad.get('titulo').value,
      fecha_inicio: this.formNovedad.get('fecha_inicio').value,
      fecha_vencimiento: this.formNovedad.get('fecha_vencimiento').value,
      link: this.formNovedad.get('link').value,
      link_video: this.formNovedad.get('link_video').value,
      tipo: this.formNovedad.get('tipo').value,
      rol_user: this.formNovedad.get('rol_user').value,
      descripcion:
        this.formNovedad.get('descripcion').value === ''
          ? null
          : this.formNovedad.get('descripcion').value,
      imagen: this.imageToSave ? this.imageToSave.split(`,`)[1] : null,
      clientes: this.formNovedad.get('clientes').value,
    };

    // lanzamos nuevo o editar
    this.store.dispatch(setLoading({ loading: true }));
    this.data
      ? this.store.dispatch(
          editarNovedad({ body: novedad, id: this.data['id'] })
        )
      : this.store.dispatch(crearNovedad({ novedad: novedad }));
  }

  /**
   * Función que selecciona los clientes que contienen alguno de los cultivos seleccionados
   * @param cultives cultivos actualmente seleccionados
   */
  changeFilterCultives(cultives) {
    if (cultives.length > 0) {
      this.formNovedad.controls.clientes.setValue(
        this.clients
          .filter((element) =>
            element['cultivos'].some((cultive) => cultives.includes(cultive))
          )
          .map((element) => element['id'])
      );
    } else {
      this.formNovedad.controls.clientes.setValue([]);
    }
  }

  /**
   * Función que escucha el click de el option seleccionar todos y los selecciona o deselecciona según el comportamiento
   */
  selectAll() {
    if (
      this.formNovedad.controls.clientes.value.length - 1 ===
      this.clients.length
    ) {
      this.formNovedad.controls.clientes.setValue([]);
    } else {
      this.formNovedad.controls.clientes.setValue(
        this.clients.map((element) => element['id'])
      );
    }
    // se modifican los valores, el filtro de cultivos se resetea
    this.cultivesSelected = [];
  }

  private errorWhileCompressImage(): void {
    Swal.fire({
      icon: 'error',
      title: 'Error mientras se cargaba la imagen',
    });
    return;
  }

  /**
   * Función que escucha al input de la imagen
   * @param event
   */
  async readImageInfo(event) {
    var file;
    event.srcElement.files && event.srcElement.files.length > 0
      ? (file = event.srcElement.files[0])
      : (file = null);

    if (file) {
      if (!file.type.includes('image')) {
        // no es formato de imagen
        Swal.fire({
          icon: 'error',
          title: `Formato ${file.type} no soportado`,
          text: 'Debe ser una imagen',
        });
        this.formNovedad.get('imagen').setValue('');
      } else {
        // cambiar archivo file a base64
        this.commonService
          .converImageFileToBase64(file)
          .pipe(take(1))
          .subscribe(async (value) => {
            let image = value;
            if (!file.type.includes('gif') && file.size > this.MAX_SIZE_IMAGE) {
              image = await this.imageCompressor.compressFile(value, 1, 40, 75);
            }
            this.imageToSave = image;
          });
      }
    } else {
      // no hay imagen
      this.imageToSave = null;
      this.formNovedad.get('imagen').setValue('');
    }
    // actualizar el form
    this.formNovedad.get('imagen').updateValueAndValidity();
  }

  /**
   * Abre imagen seleccionada en el input
   * @param event
   */
  openImage(event) {
    event.stopPropagation();
    Swal.fire({
      html: `<div>
      <img height="200" style="box-shadow: 0 0 10px rgb(245, 245, 245); border-radius: 4px;"
      src="${
        this.imageToSave.includes('data:image')
          ? this.imageToSave
          : 'data:image/*;base64,' + this.imageToSave
      }">
      </div>`,
    });
  }

  /**
   * Cierra diálogo
   */
  closeTool() {
    this.dialogRef.close();
    this.ngOnDestroy();
  }

  /**
   * Cierra componente
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
