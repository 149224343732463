import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServersState } from './servers.state';

export const SERVERS_STATE_NAME = 'servers';

const getServersState = createFeatureSelector<ServersState>(SERVERS_STATE_NAME);

export const getServersSelector = createSelector(getServersState, (state) => {
  return state?.servers;
});

export const getLoadingTable = createSelector(getServersState, (state) => {
  return state.loadingTable;
});

export const getAllServersSelector = createSelector(
  getServersState,
  (state) => {
    return state?.allServers;
  }
);
