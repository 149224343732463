import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { indicador, tab } from '../../../interfaces/dashboard.interface';
import {
  getDemosAndClients,
  loadDashboardClientes,
  loadDashboardSuperficie,
  resetDashboardState,
} from './state/dashboard.actions';
import {
  getDashboardClientes,
  getDashboardSuperficies,
} from './state/dashboard.selector';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  indicadores: Array<indicador> = [];
  public graficas: any;
  tabs: Array<tab> = [];
  loadingIndicadores: boolean = true;
  loadingGraficas: boolean = true;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(loadDashboardSuperficie());
    this.store.select(getDashboardSuperficies).subscribe((value) => {
      if (value) {
        this.graficas = value;
        this.tabs[1].stats[0].data = [this.graficas[0]];
        this.loadingGraficas = false;
      }
    });

    this.store.dispatch(loadDashboardClientes());
    this.store.select(getDashboardClientes).subscribe((value) => {
      if (value) {
        this.indicadores = value;
        const { clientesPorTipo, clientesPorCultivo } = this.separarPorTipo(
          this.indicadores
        );
        this.tabs[0].stats[0].data = clientesPorTipo;
        this.tabs[1].stats[1].data = clientesPorCultivo;
        this.loadingIndicadores = false;
      }
    });

    this.inicializeTabs();
  }

  /** Complete information for the tabs */
  inicializeTabs() {
    this.tabs = [
      // Clients tab
      {
        title: 'clientes',
        stats: [
          {
            title: 'clientes por tipo',
            type: 'text',
            cols: 1,
            rows: 1,
            data: null,
          },
          {
            title: 'clientes sin alta frecuencia',
            type: 'table',
            cols: 1,
            rows: 2,
            data: null,
            columns: ['workspace', 'ha_contrat_sent', 'ha_activas_sent'],
          },
          {
            title: 'clientes con alta frecuencia',
            type: 'table',
            cols: 1,
            rows: 2,
            data: null,
            columns: ['workspace', 'ha_contrat', 'ha_activas'],
          },
        ],
      },
      // Cultivations tab
      {
        title: 'cultivos',
        stats: [
          {
            title: 'área de cada cultivo',
            type: 'graph',
            cols: 1,
            rows: 1,
            data: null,
          },
          {
            title: 'clientes por cultivo',
            type: 'text',
            cols: 1,
            rows: 1,
            data: null,
          },
        ],
      },
    ];

    // Get initial information for tables,this information is recieve in dashboard-table component
    this.store.dispatch(
      getDemosAndClients({
        ids: [
          this.tabs[0].stats[1].title.replace(/\s/g, '_'),
          this.tabs[0].stats[2].title.replace(/\s/g, '_'),
        ],
      })
    );
  }

  /**
   * Filter indicators with the type
   * @param array indicators
   * @returns clientesPorTipo, clientesPorCultivo
   */
  separarPorTipo(
    array: Array<{ titulo: string; numero: number; type: string }>
  ) {
    const clientesPorTipo = array.filter(
      (objeto) => objeto.type === 'clientes_por_tipo'
    );
    const clientesPorCultivo = array.filter(
      (objeto) => objeto.type === 'clientes_por_cultivo'
    );

    return { clientesPorTipo, clientesPorCultivo };
  }

  ngOnDestroy() {
    this.store.dispatch(resetDashboardState());
  }
}
