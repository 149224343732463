import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  TypeStadisticsAltas,
  dataStadisticts,
} from '../commons/enums/TypeStadisticsAltas.enum';
import { Pagination } from '../interfaces/Pagination.interface';
import {
  FilterAlta,
  PaginationFilterInput,
  ProductProcess,
} from '../interfaces/altas/Altas.interface';
import { statistic_element } from '../pages/admin/altas/state/altas.state';
import { CommonService } from './Common.service';
import { clearRequest } from './clearRequest.service';

@Injectable({
  providedIn: 'root',
})
export class AltasService {
  static pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private clearRequestService: clearRequest
  ) {}

  getActualPage(pagination: any): number | null {
    if (!pagination) return 1;
    let url_object = new URL(
      environment.api_process_client_url + pagination.current
    );
    let params = url_object.searchParams;
    let page = params.get('page');
    return parseInt(page);
  }

  /**
   * Returns page size options according of actual size
   * @param objects length of actual data
   * @returns page size options
   */
  getPageSizeOptions(objects: number): number[] {
    if (AltasService.pageSizeOptions.every((e) => e <= objects))
      return AltasService.pageSizeOptions;
    let lastElement = AltasService.pageSizeOptions.find(
      (value, index, array) => index === array.length - 1
    );
    return lastElement < objects
      ? [...AltasService.pageSizeOptions, objects]
      : [...AltasService.pageSizeOptions.filter((e) => e < objects), objects];
  }

  /** FORMATTING */
  buildPetitions(
    filters: any[],
    activeFilters: any[],
    date?: { $gte: string; $lt: string }
  ): any[] {
    if (!activeFilters) activeFilters = [];
    let petitions = [];
    let body: any = this.formatFilters(filters, date);
    let oldDate = activeFilters.find((el) => el.attribute == 'date')
      ? activeFilters.find((el) => el.attribute == 'date').value
      : date;

    if (
      !filters.some((el: any) => el.attribute == 'client') ||
      !activeFilters.some((el: any) => el.attribute == 'client') ||
      date != oldDate
    ) {
      petitions.push(this.getStadistics(TypeStadisticsAltas.CLIENTS, body));
    }

    if (
      !filters.some((el: any) => el.attribute == 'status') ||
      date != oldDate
    ) {
      petitions.push(this.getStadistics(TypeStadisticsAltas.STATUS, body));
    }

    if (
      !filters.some((el: any) => el.attribute == 'type') ||
      !activeFilters.some((el: any) => el.attribute == 'type') ||
      date != oldDate
    ) {
      petitions.push(this.getStadistics(TypeStadisticsAltas.TYPE, body));
    }

    /*if(!filters.find((el: any)=> el.attribute == 'date') && !activeFilters.find((el: any)=> el.attribute == 'date')){*/
    petitions.push(this.getStadistics(TypeStadisticsAltas.TIME, body));
    //}

    return petitions;
  }

  /**
   * Return to null values that will be requestered again
   * @param filters
   * @param activeFilters
   * @param date
   * @param grid_tiles
   * @returns
   */
  loadingGridTiles(
    filters: any[],
    activeFilters: any[],
    date?: { $gte: string; $lt: string },
    grid_tiles: statistic_element[] = []
  ): any[] {
    if (!activeFilters) activeFilters = [];
    return grid_tiles.map((element) => {
      return !filters.some((el: any) => el.attribute == element.id) ||
        (element.id !== 'status' &&
          !activeFilters.some((el: any) => el.attribute == element.id))
        ? {
            ...element,
            data: Object.keys(element.data).reduce((acc, e) => {
              return e !== 'date'
                ? { ...acc, [e]: null }
                : { ...acc, [e]: element.data[e] };
            }, {}),
          }
        : element;
    });
  }

  private getDataStadistics(label: TypeStadisticsAltas, data: any) {
    return {
      ...dataStadisticts[label],
      data: data,
    };
  }

  /**
   * Function to build the body sent to get the statistics correctly
   * @param filters filters active
   * @returns body
   */
  formatFilters(filters: any, date?: { $gte: string; $lt: string }) {
    let body = filters.reduce((acc, element) => {
      return {
        ...acc,
        [this.formatStatisticsAttributes(element.attribute)]:
          this.formatStatisticsValues(element.attribute, element.value),
      };
    }, {});

    if (!date) return body;
    return {
      ...body,
      date: date,
    };
  }

  /**
   * Function to format some of the body values correctly
   * @param value value to format
   * @returns value formatted
   */
  formatStatisticsValues(attribute: string, value: string) {
    if (['area.client.workspace', 'area.name'].includes(attribute))
      return { $regex: value, $options: 'i' };
    switch (value) {
      case 'clients':
        return 'client';
      case 'demos':
        return 'demo';
      case 'completed':
        return 1;
      case 'executing':
        return 3;
      case 'stopped':
        return 0;
      case 'error':
        return -1;
      case 'queue':
        return 2;
      default:
        return value;
    }
  }

  /**
   * Function to format some of the body values correctly
   * @param value value to format
   * @returns value formatted
   */
  formatStatisticsAttributes(attribute: string) {
    if (attribute == 'client') return 'area.client.type_client';
    return attribute;
  }

  /** FILTERS */
  /**
   * Function that resets the filter by hierarchy
   * @param filters actual filters
   * @param group filter change
   * @returns new filters
   */
  deleteByHierarchy(filters: any[], group: string) {
    filters = JSON.parse(JSON.stringify(filters));
    let findIndex = filters.findIndex((el: any) => el.attribute == group);

    if (findIndex !== -1 && filters.length - 1 != findIndex) {
      filters.splice(findIndex, 10);
    }
    return filters;
  }

  /**
   * Control to add, modify or detele attributes filter
   * @param filter
   * @param key
   * @param value
   * @returns
   */
  addFilter(filter: FilterAlta[], key: string, value: any): FilterAlta[] {
    filter = JSON.parse(JSON.stringify(filter));
    return filter.some(
      (element) => element.attribute === key && element.value === value
    )
      ? // drop filter
        [...filter.filter((element) => element.attribute !== key)]
      : // add new filter
        [
          ...filter.filter((element) => element.attribute !== key),
          { attribute: key, value: value },
        ];
  }

  /** ENDPOINTS */

  getUrlLoadData(attributes: PaginationFilterInput): Observable<Pagination> {
    let { page, limit, orderby, filter, load } = attributes;
    let url = '/process/list-process';
    /** load = true --> first request */
    if (!load)
      url = this.commonService.serializeParamsQuery(url, {
        page: page,
        limit: limit,
        order_by: orderby,
        filter: JSON.stringify(filter),
      });
    return this.http.get<Pagination>(
      `${environment.api_process_client_url}${url}`
    );
  }

  getStadistics(label: TypeStadisticsAltas, body: any) {
    return this.http
      .get<JSON>(
        `${
          environment.api_process_client_url
        }${this.commonService.serializeParamsQuery(
          `/process-stadistics/${label}`,
          { filter: JSON.stringify(body) }
        )}`,
        this.httpOptions
      )
      .pipe(
        takeUntil(
          this.clearRequestService.onCancelPendingRequestsAltasStatistics()
        ),
        map((x: any) => {
          return this.getDataStadistics(label, x);
        })
      );
  }

  /** mongo */
  createMongoClient(body: any) {
    return this.http.post<any>(
      environment.api_process_client_url + '/client-create',
      body,
      this.httpOptions
    );
  }

  createMongoArea(body: any) {
    return this.http.post<any>(
      environment.api_process_client_url + '/area',
      body,
      this.httpOptions
    );
  }

  getMongoClient(client: number) {
    return this.http.get<JSON>(
      `${environment.api_process_client_url}/client/${client}`,
      this.httpOptions
    );
  }

  getMongoArea(area: number) {
    return this.http.get<JSON>(
      `${environment.api_process_client_url}/area/${area}`,
      this.httpOptions
    );
  }

  getNotProcessing(type: string) {
    return this.http.get<any>(
      `${environment.databaseURL}/rest/not_processing/${type}`
    );
  }

  postNotProcessing(type: string, body: any) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/not_processing/${type}`,
      body,
      this.httpOptions
    );
  }

  /**
   * Load dates with status in the selected year
   * @param year
   * @param status
   * @param filt
   * @returns
   */
  loadDatesFromYear(
    year?: number,
    status: number[] = [],
    filt: Object = {}
  ): Promise<Object> {
    const params = {
      year: year || null,
      status: JSON.stringify(status),
      filter_obj: filt ? JSON.stringify(filt) : null,
    };
    const url = this.commonService.serializeParamsQuery(
      `/process/days-per-status`,
      params
    );
    return this.http
      .get<Object>(`${environment.api_process_client_url}${url}`)
      .toPromise();
  }

  /**
   * Format clients with their areas to send to mongoDB
   * @param clients all clients
   * @param clientsSelected clients that have been selected in the warning-dialog.component table
   * @returns clients formatted with their areas
   */
  formatAreasBody(clients: Array<any>, clientsSelected: Array<any>) {
    let formated = [];

    clients.map((value) => {
      let formatedAreas = [];
      value.areas.map((value_area) => {
        if (value_area.checked == true) {
          let formatedArea = {
            id: value_area.id,
            name: value_area.nombre,
            crop_type: value_area.cultivo,
          };
          formatedAreas.push(formatedArea);
        }
      });
      if (formatedAreas.length > 0) {
        let formatedClient = {
          id: value.id,
          workspace: value.workspace,
          areas: formatedAreas,
          type_client: value.type_client,
        };
        formated.push(formatedClient);
      } else {
        clientsSelected.map((client) => {
          if (client.id == value.id) {
            formated.push({
              id: value.id,
              workspace: value.workspace,
              areas: [],
              type_client: value.type_client,
            });
          }
        });
      }
    });

    return formated;
  }

  /**
   * Update method for processes
   * @param id process id
   * @param body elements to update in the selected process
   * @returns updated process
   */
  updateProcess(id: string | number, body: Object): Observable<Object> {
    return this.http.put<Object>(
      `${environment.api_process_client_url}/process/${id}`,
      body
    );
  }

  /**
   * Get process' products
   * @param id process id
   * @returns a list of all process' products
   */
  getProductsProcess(id: string | number): Observable<ProductProcess[]> {
    return this.http.get<ProductProcess[]>(
      `${environment.api_process_client_url}/process/${id}/products`
    );
  }

  /**
   * Check if the error result is equal to the specification of not found of the process-client api
   * @param error error to check
   * @returns the boolean result
   */
  resultGetProducts(error: any): boolean {
    let labelErrors = 'errors';
    let errorMessage = "doesn't have any products";
    return (
      labelErrors in error &&
      error[labelErrors].constructor.name === 'Array' &&
      error[labelErrors]?.length &&
      error[labelErrors][0].includes(errorMessage)
    );
  }

  /**
   * Search in a div element child nodes a element that matches by id
   * @param rootElement
   * @param id
   * @returns
   */
  searchChildNodeById(
    rootElement: HTMLDivElement,
    id: string
  ): HTMLDivElement | undefined {
    let childElement: HTMLDivElement;
    for (let element of rootElement.childNodes) {
      if (element['id'] === id) {
        childElement = element as HTMLDivElement;
        break;
      }
    }
    return childElement;
  }
}
