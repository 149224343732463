<header class="topbar" data-navbarbg="skin5">
  <nav class="navbar top-navbar navbar-expand-md navbar-dark">
    <!-- LOGO -->
    <div class="navbar-header">
      <a class="nav-toggler waves-effect waves-light d-block d-md-none"
        ><i class="fa fa-bars font-24" style="color: white"></i
      ></a>
      <a
        class="navbar-brand"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <b style="padding-left: 0 !important" class="hide-icon ps-2">
          <img height="60" src="../../../assets/images/nax.png" />
        </b>
        <span class="logo-text">
          <div class="nax">nax</div>
        </span>
      </a>
      <a
        class="topbartoggler d-block d-md-none waves-effect waves-light"
        href="javascript:void(0)"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fa fa-ellipsis-h"></i
      ></a>
    </div>
    <!-- TOOLS -->
    <div
      class="navbar-collapse collapse"
      id="navbarSupportedContent"
      data-navbarbg="skin5"
    >
      <ul class="navbar-nav float-left">
        <li class="nav-item d-none d-md-block">
          <a
            (click)="openSidebar()"
            class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)"
            data-sidebartype="mini-sidebar"
            ><i class="fa fa-bars font-24"></i
          ></a>
        </li>
      </ul>

      <ul
        class="navbar-nav"
        style="width: 100%; display: flex; justify-content: center"
        (click)="$event.stopPropagation()"
        *ngIf="visibleInputClients"
      >
        <div style="background-color: white" class="input-navbar-container">
          <input
            class="input-navbar"
            #inputSearch
            [(ngModel)]="searchValue"
            (input)="searchClients($event.target.value)"
            placeholder="Buscar..."
          />
          <mat-icon
            *ngIf="inputSearch.value !== ''"
            [inline]="true"
            (click)="
              inputSearch.value = ''; searchClients(''); inputSearch.focus()
            "
            class="input-clear"
            >clear</mat-icon
          >
          <mat-icon
            [inline]="true"
            color="black"
            *ngIf="!loadingUsers; else spinnerUsers"
            >search</mat-icon
          >
          <div class="clients-container" *ngIf="!loadingUsers">
            <ng-container *ngFor="let client of clients; last as isLast">
              <div
                class="clients-row"
                title="Buscar"
                (click)="goToClient(client)"
              >
                <span>{{
                  client?.value === client?.workspace
                    ? client?.workspace
                    : client?.value + " (" + client?.workspace + ")"
                }}</span>
                <i
                  [ngClass]="{
                    'fa-child': client?.category === categories.LEADS,
                    'fa-play-circle': client?.category === categories.DEMOS,
                    'fa-users': client?.category === categories.CLIENTES,
                    'fa-sign-in-alt': client?.category === categories.BAJAS,
                    'fa fas': true
                  }"
                ></i>
              </div>
              <mat-divider
                style="border-top-color: rgba(0, 0, 0, 0.3)"
                *ngIf="!isLast"
              ></mat-divider>
            </ng-container>
          </div>
        </div>
      </ul>

      <ul
        class="navbar-nav float-right ml-auto"
        style="display: flex; flex-direction: row; align-items: end"
      >
        <li
          class="nav-item dropdown"
          style="display: flex; margin-right: 1.5vw"
          title="Altas área"
          *ngIf="
            (loadingAltasArea || totalAltasAreas > 0) && visibleInputClients
          "
          (click)="openAltasAreasDialog()"
        >
          <mat-icon
            [ngClass]="{
              'icon-altas': loadingAltasArea,
              'icon-altas-material': loadingAltasArea && totalAltasAreas < 0
            }"
            [matBadge]="loadingAltasArea ? 'pending' : totalAltasAreas"
            matBadgeColor="warn"
            style="color: white; cursor: pointer"
            >publish</mat-icon
          >
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="{ areas: not_processing_areas | async } as not_processing"
          (click)="openWarning([], not_processing.areas)"
          style="display: flex; margin-right: 1.5vw"
        >
          <mat-icon
            *ngIf="not_processing.areas"
            matBadge="{{ not_processing.areas.length }}"
            matBadgeColor="warn"
            style="color: white; cursor: pointer"
            >report</mat-icon
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="dropdown-item micursor"
            (click)="logout()"
            title="Cerrar sesión"
            ><i class="fa fa-power-off m-r-5 m-l-5" style="color: white"></i
          ></a>
        </li>
      </ul>
    </div>
  </nav>
</header>

<ng-template #spinnerUsers>
  <mat-spinner class="spinner-users"></mat-spinner>
</ng-template>
