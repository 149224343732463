<div class="main-table-container">
    <div class="top-container">
        <div class="top-container-data">
            <span *ngIf="dataFound != null">Datos encontrados: {{dataFound}}</span>
            <span *ngIf="errorsFound != null" style="margin-left: 2.5%;">Errores encontrados: {{errorsFound}}</span>
        </div>
        <div *ngIf="step==1" style="display: flex; flex-direction: row; justify-content: end; align-items: center;">
            <div class="button" (click)="downloadErrors()" style="margin-right: 1vh;">
                <span>Descargar errores</span>
                <mat-icon>download</mat-icon>
            </div>
            <div class="button" (click)="discardErrors()">
                <span>Descartar errores</span>
                <mat-icon>delete</mat-icon>
            </div>
        </div>
    </div>
    <div class="table-container">
        <table mat-table
           [dataSource]="tableData"
           multiTemplateDataRows
           class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let element"> {{(element[column]?.toString().length>30 ? (element[column]?.toString() | slice: -30)+'...' : element[column]?.toString())}} </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div style="width: 95%; margin-left: 5%;">
                            <div *ngFor="let detail of element.details" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                                <span>{{detail}}</span>
                                <div *ngIf="step==1" mat-icon-button (click)="discardParcela(element.id)" style="cursor: pointer;">
                                    <mat-icon>delete</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: columns;" [class.example-expanded-row]="expandedElement === element"
                (click)="expandElement(element)" [ngClass]="{
                  'example-element-row': step != 2 || element.parcelas,
                  'example-element-row-hidden': step == 2 && !element.parcelas,
                  'not-expandable': !canExpand(element)
                }">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [ngClass]="{'example-detail-row': step != 2 || row.parcelas, 'example-element-row-hidden': step == 2 && !row.parcelas}"></tr>
        </table>

        <mat-paginator *ngIf="data"
        [length]="data.length"
        [pageSize]="5"
        [(pageIndex)]="pageIndex"
        (page)="changePage($event)">
        </mat-paginator>
    </div>
</div>
