<div class="table-responsive heightTable" *ngIf="datos && datos.length > 0">
  <table class="table">
    <thead>
      <tr>
        <!-- CABECERAS -->
        <th *ngFor="let column of columns" (click)="(!loading) && changeOrder(column)" [ngClass]="{'ordered-disabled': loading }">
          {{ (column === 'clientes') ? (column | titlecase) : (column | columnName) }}
          <ng-container *ngIf="column !== 'clientes'">
            <i *ngIf="(orderedBy !== column && orderedBy !== '-' + column) || !orderedBy.includes('-')" [ngClass]="{'fa fa-angle-down': true, 'ordered-disabled': loading || (orderedBy !== column && orderedBy !== '-' + column) }"></i>
            <i *ngIf="(orderedBy === column || orderedBy === '-' + column) && orderedBy.includes('-')" [ngClass]="{'fa fa-angle-up': true, 'ordered-disabled': loading }"></i>
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="!loading">
      <!-- FILAS -->
      <tr *ngFor="let element of datos; let i = index; trackBy: trackById" style="border-bottom-color: rgba(0, 0, 0, 0.1);">
        <ng-container *ngFor="let column of columns">
          <td>
            {{ (element[column] && element[column].length > 45) ? (element[column] | slice:0:20) + '...' : '' }}
            {{ (element[column] && element[column].length <= 45) ? element[column] : '' }}
            {{ ((element[column] | typeof) === 'number') ? element[column] : ''}}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
<app-indeterminate-progress-bar *ngIf="loading"></app-indeterminate-progress-bar>
<div class="pie" *ngIf="datos && datos.length > 0">
  <app-pagination
    [totalRegistros]="objects"
    [registrosPorPagina]="limit"
    [totalPages]="totalPages"
    [paginaActual]="actualPage"
    [title]="title"
    (cambiarPagina)="changePage($event)"
    (cambiarLimit)="changeLimit($event)">
  </app-pagination>
</div>
<div class="error" *ngIf="(!datos || (datos && datos.length === 0)) && !loading">No hay resultados.</div>