<div class="global">
  <form *ngIf="client || isNew" [formGroup]="todoForm" (keydown.enter)="$event.preventDefault()"> 
    <div *ngFor="let group of groups" style="margin-bottom: 1vw;">
      <h4>{{group}}</h4>
      <div class="group">
        <div *ngFor="let field of fields" [ngClass]="{'col-lg-4': step==0, 'col-lg-3': step==2}" class="field" [ngStyle]="{display: group==field.group ? 'auto' : 'none'}">
          <div class="titleField">
              <mat-label style="margin-right: 0.5vw;">{{(field.name | infoEditor) | titlecase}}</mat-label>
              <mat-icon *ngIf="field.help" class="infoIcon" [placement]="field.help.placement" [ngbPopover]="field.help.value" triggers="mouseenter:mouseleave">help</mat-icon>
          </div>
          
          <!-- Dates -->
          <ng-container *ngIf="field.type=='date'; else templateBoolean">
            <div style="display: flex;">
              <input class="inputs form-control" [max]="field.name == 'fin_actualizacion' ? todoForm?.controls['fin_plataforma']?.value : null" [placeholder]="((field.name | infoEditor) | titlecase)+'*'" [formControlName]="field.name" style="cursor: pointer;" matInput [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker (closed)="step==0 ? controlPlatformEnd() : null"></mat-datepicker>
            </div>
          </ng-container>
          
          <!-- Checkboxs -->
          <ng-template #templateBoolean>
            <ng-container *ngIf="field.type=='boolean'; else templateSelector">
              <fieldset class="fieldSet">
                <div class="form-check">
                  <input type="radio" [name]="field.name" [value]="true" [formControlName]="field.name" class="form-check-input" [id]="'customControlValidation'+field.name" (change)="field.name=='alta_freq' ? changeValidatorHaContract() : null"/>
                  <label class="form-check-label mb-0" [for]="'customControlValidation'+field.name">Si</label>
                </div>
                <div style="margin-left: 1em;" class="form-check">
                  <input type="radio" [name]="field.name" [value]="false" [formControlName]="field.name" class="form-check-input" [id]="'customControlValidation'+field.name" (change)="field.name=='alta_freq' ? changeValidatorHaContract() : null"/>
                  <label class="form-check-label mb-0" [for]="'customControlValidation'+field.name">No</label>
                </div>
              </fieldset>
            </ng-container>
          </ng-template>
          
          <!-- Selects -->
          <ng-template #templateSelector>
            <ng-container *ngIf="field.type=='selector'; else templateOthers">
              <ng-select [formControlName]="field.name" class="inputs capitalize" style="width: 100%;" [placeholder]="((field.name | infoEditor) | titlecase)+'*'" appendTo="body">
                <ng-option *ngFor="let option of field.options" [value]="option">
                {{ step==2 ? ((option | dictionary) | titlecase) : (option | infoEditor) }}
                </ng-option>
              </ng-select>
            </ng-container>
          </ng-template>

          <!-- Text and Number -->
          <ng-template #templateOthers>
            <input *ngIf="field.type=='number';else text" [readonly]="field.name=='ha_contrat' && !todoForm.get('alta_freq')?.value" type="number" class="inputs form-control" [placeholder]="((field.name | infoEditor) | titlecase)+'*'" [formControlName]="field.name" onKeyPress="if(this.value.length==6) return false;" max="999999" min="0"/>
            <ng-template #text><input type="text" class="inputs form-control" [placeholder]="((field.name | infoEditor) | titlecase)+'*'" [formControlName]="field.name"/></ng-template>
          </ng-template>

          <mat-error *ngIf="todoForm.controls[field.name].invalid && (!isNew || todoForm.controls[field.name].touched)">
            <span class="matError">{{(field.name | infoEditor) | titlecase}} no válido</span>
          </mat-error>
        </div>
      </div>
    </div>
  </form>
</div>