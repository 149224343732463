<div id="content">
  <h2>{{ title }}</h2>
  <form [formGroup]="formNovedad" (ngSubmit)="prepararInfo()">
    <div>
      <div class="left">
        <label
          >TÍTULO
          <input
            [ngClass]="{
              'is-invalid':
                formNovedad.get('titulo').invalid &&
                formNovedad.get('titulo').touched,
              'inputs form-control': true
            }"
            formControlName="titulo"
            placeholder="Titulo..."
          />
          <div
            *ngIf="
              formNovedad.get('titulo').invalid &&
              formNovedad.get('titulo').touched
            "
            class="invalid-feedback"
          >
            Campo requerido
          </div>
        </label>
        <div class="twoInRow">
          <label
            >FECHA DE INICIO
            <div class="d-flex align-items-center">
              <input
                [ngClass]="{
                  'is-invalid':
                    formNovedad.get('fecha_inicio').invalid &&
                    formNovedad.get('fecha_inicio').touched,
                  'inputs form-control': true
                }"
                placeholder="dd/MM/yyyy"
                formControlName="fecha_inicio"
                style="cursor: pointer"
                readonly
                [max]="formNovedad.get('fecha_vencimiento').value"
                matInput
                [matDatepicker]="picker"
                (focus)="picker.open()"
                (click)="picker.open()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <div
              *ngIf="
                formNovedad.get('fecha_inicio').invalid &&
                formNovedad.get('fecha_inicio').touched
              "
              class="invalid-feedback"
            >
              Campo requerido
            </div>
          </label>
          <label
            >FECHA DE VENCIMIENTO
            <div class="d-flex align-items-center">
              <input
                [ngClass]="{
                  'is-invalid':
                    formNovedad.get('fecha_vencimiento').invalid &&
                    formNovedad.get('fecha_vencimiento').touched,
                  'inputs form-control': true
                }"
                placeholder="dd/MM/yyyy"
                formControlName="fecha_vencimiento"
                style="cursor: pointer"
                readonly
                [min]="formNovedad.get('fecha_inicio').value"
                matInput
                [matDatepicker]="picker2"
                (focus)="picker2.open()"
                (click)="picker2.open()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </div>
            <div
              *ngIf="
                formNovedad.get('fecha_vencimiento').invalid &&
                formNovedad.get('fecha_vencimiento').touched
              "
              class="invalid-feedback"
            >
              Campo requerido
            </div>
          </label>
        </div>
        <label
          >LINK
          <input
            [ngClass]="{
              'is-invalid': formNovedad.get('link').invalid,
              'inputs form-control': true
            }"
            type="url"
            formControlName="link"
            placeholder="Link..."
          />
        </label>
        <label
          >LINK VÍDEO
          <input
            [ngClass]="{
              'is-invalid':
                formNovedad.get('imagen').invalid &&
                formNovedad.get('imagen').touched,
              'inputs form-control': true
            }"
            type="url"
            formControlName="link_video"
            placeholder="Link vídeo..."
            (input)="changeValidatorImagen()"
          />
          <div
            *ngIf="
              formNovedad.get('imagen').invalid &&
              (formNovedad.get('imagen').touched ||
                formNovedad.get('link_video').touched)
            "
            class="invalid-feedback"
          >
            Vídeo o imagen requeridos
          </div>
        </label>
        <!-- si se pone el tipo, hay que quitar este label -->
        <label
          >ROL USUARIO
          <select
            formControlName="rol_user"
            [ngClass]="{
              'is-invalid': formNovedad.get('rol_user').invalid,
              'inputs form-control shadow-none': true
            }"
          >
            <option class="select2-selection" [value]="null" disabled>
              Selecciona un rol
            </option>
            <option class="select2-selection" [value]="1">ROL_ADMIN</option>
            <!-- otros roles -->
          </select>
        </label>
        <!-- -->
        <!--<div class="twoInRow">
          <label>TIPO
            <input [ngClass]="{'is-invalid': formNovedad.get('tipo').invalid && formNovedad.get('tipo').touched,
            'inputs form-control': true}" type="number" formControlName="tipo" min="0" placeholder="Tipo...">
            <div *ngIf="formNovedad.get('tipo').invalid && formNovedad.get('tipo').touched" class="invalid-feedback">
              Campo requerido
            </div>
          </label>-->
        <!--<label>ROL USUARIO
            <select formControlName="rol_user" [ngClass]="{'is-invalid': formNovedad.get('rol_user').invalid,
            'inputs form-control shadow-none': true}">
              <option class="select2-selection" [value]="null" disabled>Selecciona un rol</option>
              <option class="select2-selection" [value]="1">ROL_ADMIN</option>
            </select>
          </label>
        </div> -->
      </div>
      <div class="right">
        <label>
          DESCRIPCIÓN
          <textarea
            [ngClass]="{
              'is-invalid':
                formNovedad.get('descripcion').invalid &&
                formNovedad.get('descripcion').touched,
              'inputs form-control': true
            }"
            formControlName="descripcion"
            placeholder="Descripción..."
          ></textarea>
          <div
            *ngIf="
              formNovedad.get('descripcion').invalid &&
              formNovedad.get('descripcion').touched
            "
            class="invalid-feedback"
          >
            Campo requerido
          </div>
        </label>
        <div>
          <div class="d-flex align-items-center image">
            <label>
              IMAGEN
              <ng-container *ngIf="!loadingImage; else loadingImageText">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="typeFile.click()"
                >
                  <i class="fas fa-save mr-2"></i
                  >{{
                    imageToSave
                      ? "Seleccionar otro archivo"
                      : "Seleccionar archivo"
                  }}
                </button>
                <input
                  #typeFile
                  hidden
                  class="inputs"
                  (input)="readImageInfo($event); changeValidatorImagen()"
                  formControlName="imagen"
                  accept="image/*"
                  type="file"
                />
                <div
                  *ngIf="
                    formNovedad.get('imagen').invalid &&
                    (formNovedad.get('imagen').touched ||
                      formNovedad.get('link_video').touched)
                  "
                  class="invalid-feedback"
                >
                  Vídeo o imagen requeridos
                </div>
              </ng-container>
              <ng-template #loadingImageText>
                <div>Cargando imagen...</div>
              </ng-template>
            </label>
            <i
              class="fas fa-eye"
              *ngIf="imageToSave"
              (click)="openImage($event)"
            ></i>
          </div>
        </div>
        <div>
          <label>CLIENTES</label>
          <mat-form-field appearance="outline">
            <mat-label
              ><i class="fas fa-filter"></i> Seleccionar por cultivo</mat-label
            >
            <mat-select
              style="text-transform: capitalize"
              placeholder="Personalizado"
              multiple
              [(ngModel)]="cultivesSelected"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="changeFilterCultives($event.value)"
            >
              <mat-option
                style="text-transform: capitalize"
                *ngFor="let cultive of cultivesUniques"
                [value]="cultive"
              >
                {{ cultive | dictionary }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>CLIENTES</mat-label>
            <mat-select
              placeholder="CLIENTES"
              formControlName="clientes"
              [ngClass]="{
                'is-invalid': formNovedad.get('clientes').invalid,
                inputs: true
              }"
              multiple
              (closed)="searchText = ''"
            >
              <input
                *ngIf="clients && clients.length"
                [(ngModel)]="searchText"
                matInput
                class="search-select"
                type="text"
                placeholder="Buscar..."
                [ngModelOptions]="{ standalone: true }"
              />
              <mat-option
                *ngIf="clients && clients.length"
                [ngClass]="{
                  'color-check':
                    formNovedad.controls.clientes.value &&
                    clients &&
                    formNovedad.controls.clientes.value.length ===
                      clients.length,
                  'option-sticky': true
                }"
                (click)="selectAll()"
              >
                <i
                  [ngClass]="{
                    'fas fa-check-square':
                      formNovedad.controls.clientes.value &&
                      clients &&
                      formNovedad.controls.clientes.value.length ===
                        clients.length,
                    'far fa-square':
                      formNovedad.controls.clientes.value &&
                      clients &&
                      formNovedad.controls.clientes.value.length !==
                        clients.length,
                    'mr-2': true
                  }"
                ></i>
                {{
                  formNovedad.controls.clientes.value &&
                  clients &&
                  formNovedad.controls.clientes.value.length === clients.length
                    ? "Deseleccionar todos"
                    : "Seleccionar todos"
                }}
              </mat-option>
              <mat-option
                *ngFor="let client of clients"
                [value]="client.id"
                [hidden]="
                  !(
                    clients | searchclientes : searchText : 'workspace'
                  ).includes(client)
                "
                (click)="cultivesSelected = []; changeClientsForm(client.id)"
              >
                {{ client.workspace }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                formNovedad.get('clientes').invalid &&
                formNovedad.get('clientes').touched
              "
              class="invalid-feedback"
            >
              Campo requerido
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div id="buttons">
      <button type="button" (click)="closeTool()" class="btn btn-danger btn">
        Cancelar
      </button>
      <button
        type="submit"
        class="btn btn-success btn"
        [disabled]="(formNovedad.invalid && !firstTouch) || loadingImage"
      >
        Aceptar
      </button>
    </div>
  </form>
</div>
