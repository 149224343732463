import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'infoEditor'
})
export class InfoEditorPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'ha_empresa': return 'Hectáreas';
      case 'alta_freq': return 'Alta frecuencia';
      case 'ha_contrat': return 'Ha. contratadas alta frecuencia';
      case 'ha_contrat_sent': return 'Ha. contratadas sentinel';
      case 'fin_plataforma': return 'Fin de contrato';
      case 'fin_actualizacion': return 'Fin de actualización';
      case 'descargar_raster': return 'Descargar raster';
      case 'spanish': return 'Español';
      case 'english': return 'English';
      case 'portuguese': return 'Português';
      case 'titulo': return 'Título';
      default: return value;
    }
  }

}
