import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AuthModule } from './auth/auth.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
  MatSelectModule,
} from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  TRANSLOCO_CONFIG,
  TranslocoConfig,
  TranslocoService,
} from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthEffects } from './auth/state/auth.effects';
import { CommonsModule } from './commons/commons.module';
import { NavbarModule } from './commons/navbar/navbar.module';
import { AdminEffects } from './pages/admin/state/admin.effects';
import { PagesModule } from './pages/pages.module';
import { clearRequest } from './services/clearRequest.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { AppState, appReducer } from './store/app.state';
import { clearState } from './store/share/share.reducer';
import { TranslocoRootModule } from './transloco-root.module';

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>(
  'appReducer'
);
export const metaReducers: MetaReducer<any>[] = [clearState];
export const reducerProvider = {
  provide: REDUCERS_TOKEN,
  useValue: appReducer,
};

export function preloadLang(transloco: TranslocoService) {
  return function () {
    transloco.load('es').toPromise();
    transloco.load('en').toPromise();
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    NavbarModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    PagesModule,
    CommonsModule,
    EffectsModule.forRoot([AdminEffects, AuthEffects]),
    //StoreModule.forRoot(appReducer),
    StoreModule.forRoot(REDUCERS_TOKEN, { metaReducers: [clearState] }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode // Pauses recording actions and state changes when the extension window is not open
    }),
    BrowserAnimationsModule,
    DragDropModule,
    MatTableModule,
    FlexLayoutModule,
    MatSelectModule,
    HttpClientModule,
    TranslocoRootModule,
    NgbModule,
  ],
  providers: [
    clearRequest,
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    reducerProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ['en', 'es'],
        reRenderOnLangChange: true,
        fallbackLang: 'es',
        defaultLang: 'es',
      } as TranslocoConfig,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadLang,
      deps: [TranslocoService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
