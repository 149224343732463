import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import { loadData, setLoading } from '../../state/admin.actions';
import { Actions, ofType } from '@ngrx/effects';
import { categories } from 'src/app/commons/enums/categories';
import {
  loadProviders,
  saveProductConfiguration,
  saveProductConfigurationSuccess,
} from '../state/productsConfiguration.actions';
import { getProviders } from '../state/productsConfiguration.selector';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-form-products-configuration',
  templateUrl: './form-products_configuration.component.html',
  styleUrls: ['./form-products_configuration.component.scss'],
})
export class FormProductsConfigurationComponent implements OnInit, OnDestroy {
  title: string = '';
  min: number | null;
  max: number | null;
  firstTouch: boolean = true;
  formNovedad: FormGroup;
  formBuilder: FormBuilder = new FormBuilder();
  cultivos: string[] = [...AdminService.cultivesBBDD];
  providers: any[];
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<FormProductsConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<Date>,
    private actions: Actions,
    private store: Store<AppState>
  ) {
    this.dateAdapter.setLocale('es');
  }

  /** INIT */
  ngOnInit(): void {
    this.actions
      .pipe(
        ofType(saveProductConfigurationSuccess),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((value) => {
        const { result } = value;
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Producto editado correctamente!',
            timer: 3000,
          }).then((fin) => {
            this.store.dispatch(loadData({ category: categories.PRODUCTS }));
            this.dialogRef.close();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Producto no editado!',
            text: 'Hubieron errores al editar el producto',
            timer: 3000,
          });
          this.store.dispatch(setLoading({ loading: false }));
        }
      });

    this.title = 'Editar configuracion de ' + this.data.nombre;

    this.store.select(getProviders).subscribe((value) => {
      this.providers = value;
    });

    this.iniciarFormulario();
  }

  iniciarFormulario() {
    this.formNovedad = this.formBuilder.group({
      titulo: [this.data ? this.data['titulo'] : '', [Validators.required]],
      titulo_portuguese: [
        this.data ? this.data['titulo_portuguese'] : '',
        [Validators.required],
      ],
      titulo_english: [
        this.data ? this.data['titulo_english'] : '',
        [Validators.required],
      ],
      agrupacion: [
        this.data ? this.data['agrupacion'] : '',
        [Validators.required],
      ],
      fk_provider_id: [
        this.data ? this.data['fk_provider_id'] : '',
        [Validators.required],
      ],
      cultivos: [this.data ? this.data['cultivos'] : '', [Validators.required]],
      pixel_2: [
        this.data ? this.data['pixel_2'] : false,
        [Validators.required],
      ],
      min: [this.data ? this.data['min'] : 0, [Validators.required]],
      max: [this.data ? this.data['max'] : 1, [Validators.required]],
    });

    this.min = this.data ? this.data['min'] : 0;
    this.max = this.data ? this.data['max'] : 1;
  }

  /** FORMAT */
  /**
   * Función que escucha el click de el option seleccionar todos y los selecciona o deselecciona según el comportamiento
   */
  selectAll() {
    if (
      this.formNovedad.controls.cultivos.value.length - 1 ===
      this.cultivos.length
    ) {
      this.formNovedad.controls.cultivos.setValue([]);
    } else {
      this.formNovedad.controls.cultivos.setValue(
        this.cultivos.map((element) => element)
      );
    }
  }

  controlRangos(input: number) {
    this.min = this.formNovedad.get('min').value;
    this.max = this.formNovedad.get('max').value;

    if (this.min != null && this.max != null && this.min > this.max) {
      if (input == 1) {
        this.min = this.max;
      } else if (input == 2) {
        this.max = this.min;
      }
      this.formNovedad.get('min').setValue(this.min);
      this.formNovedad.get('max').setValue(this.max);
    }
  }

  /** SEND */

  /**
   * Función submit que envía la información según el tipo de envío
   */
  async prepararInfo() {
    if (this.formNovedad.invalid && this.firstTouch) {
      // no es válido el form
      this.formNovedad.markAllAsTouched();
      this.firstTouch = false;
      this.formNovedad.updateValueAndValidity();
      return false;
    }

    this.enviarInfo();
  }

  /**
   * Función que formatea los datos y los envía
   */
  enviarInfo() {
    const product_configuration = {
      titulo: this.formNovedad.get('titulo').value,
      titulo_portuguese: this.formNovedad.get('titulo_portuguese').value,
      titulo_english: this.formNovedad.get('titulo_english').value,
      agrupacion: this.formNovedad.get('agrupacion').value,
      fk_provider_id: Number(this.formNovedad.get('fk_provider_id').value),
      cultivos: this.formNovedad.get('cultivos').value,
      pixel_2: this.formNovedad.get('pixel_2').value,
      min: this.formNovedad.get('min').value,
      max: this.formNovedad.get('max').value,
    };

    // lanzamos nuevo o editar

    this.store.dispatch(setLoading({ loading: true }));

    this.store.dispatch(
      saveProductConfiguration({
        product_conf: product_configuration,
        id: this.data.id,
      })
    );
  }

  /** CLOSE */
  /**
   * Cierra diálogo
   */
  closeTool() {
    this.dialogRef.close();
    this.ngOnDestroy();
  }

  /**
   * Cierra componente
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
