import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { columnsTable } from 'src/app/commons/constants/columnsTable';
import {
  TypeofColumns,
  TypesInColumns,
} from 'src/app/commons/enums/typeofColumns.enum';
import { Tile } from 'src/app/interfaces/tiles.interface';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  deleteCola,
  loadProccesses,
  moveProcess,
  moveProcessDown,
  moveProcessUp,
  setLoading,
} from '../state/admin.actions';
import { getLoading, getProcesos } from '../state/admin.selector';
import { NewTileComponent } from './new-alta/new-tile.component';

/**
 * STATUS OF QUEUES:
 * 0. NO PROCESSING
 * 1. PROCESSING
 */
@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent implements OnInit, OnDestroy {
  typesInColumns = TypesInColumns;
  typeofColumns = TypeofColumns;
  editPriority: boolean = false;
  editRow: number = -1;
  inputPriority: number;
  @ViewChild('dataTable') table: MatTable<Tile>;
  viewProcessed: boolean = false;

  displayedColumns: string[] = columnsTable['tiles'].concat('acciones');

  dataSource: Tile[] = [];
  loading: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private store: Store<AppState>, public dialog: MatDialog) {}

  ngOnInit(): void {
    const container = document.getElementsByClassName('container-fluid')[0];
    container.classList.add('drop-scroll');
    this.loadProcesses();
    this.store
      .select(getLoading)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.loading = value;
      });

    this.store
      .select(getProcesos)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.dataSource = value || [];
      });
  }

  /**
   * Lamadas para cargar los procesos
   */
  loadProcesses() {
    this.store.dispatch(setLoading({ loading: true }));
    this.store.dispatch(loadProccesses({ processed: this.viewProcessed }));
  }

  /**
   * Evento que escucha el movimiento de la fila y posiciona el item
   * @param event evento dragDrop
   */
  dropTable(event: CdkDragDrop<Tile[]>) {
    const { previousIndex, currentIndex } = event;
    // no se mueve al mismo sitio
    if (previousIndex !== currentIndex) {
      this.store.dispatch(setLoading({ loading: true }));
      let movedElement = this.dataSource[previousIndex];
      // posibles acciones a ejecutar
      this.launchPriorityChange(previousIndex, currentIndex, movedElement);

      //this.moveItemInArray(previousIndex, currentIndex, movedElement);
    }
  }

  /**
   * Función que compara los cambios y lanza las acciones depediendo de los valores dados
   * @param previousIndex donde se encontraba el elemento
   * @param currentIndex hacia donde se quiere desplazar
   * @param movedElement datos del elemento
   */
  launchPriorityChange(
    previousIndex: number,
    currentIndex: number,
    movedElement: Tile
  ) {
    if (previousIndex - 1 === currentIndex)
      this.store.dispatch(moveProcessUp({ id: movedElement.id }));
    else if (previousIndex + 1 === currentIndex)
      this.store.dispatch(moveProcessDown({ id: movedElement.id }));
    else {
      if (currentIndex === 0) var idNext: number | null = null;
      else {
        const anterior =
          this.dataSource[
            currentIndex < previousIndex ? currentIndex - 1 : currentIndex
          ];
        var idNext = anterior.id;
      }
      this.store.dispatch(moveProcess({ id: movedElement.id, idNext: idNext }));
    }
  }

  /**
   * Función que ordena la lista según la nueva ordenación
   * @param previousIndex donde se situaba el elemento
   * @param currentIndex hacia donde quiere moverse
   * @param movedElement elemento que se va a desplazar
   */
  moveItemInArray(
    previousIndex: number,
    currentIndex: number,
    movedElement: Tile
  ) {
    var copyData = JSON.parse(JSON.stringify(this.dataSource));
    var tail = copyData.splice(
      currentIndex < previousIndex ? currentIndex : currentIndex + 1
    );
    if (currentIndex < previousIndex) {
      tail = tail.filter((element) => element.id !== movedElement.id);
    } else {
      copyData = copyData.filter((element) => element.id !== movedElement.id);
    }
    this.dataSource = copyData
      .concat(movedElement, tail)
      .map((element, index) => {
        return { ...element, priority: index + 1 };
      });
    //this.dataSource.sort((a, b) => a.priority - b.priority);
    this.table.renderRows();
    //this.store.dispatch(setLoading({ loading: false }));
  }

  /**
   * Método que optimiza el renderizado de las filas
   * @param index fila
   * @param element elemento de la fila
   * @returns id del elemento
   */
  trackById(index: number, element: Tile) {
    return element.id;
  }

  /**
   * Cambiar al modo edición de la prioridad de una fila determinada
   * @param event
   * @param i fila
   */
  changeEditMode(event, i: number) {
    const element = { ...this.dataSource[i] };
    this.inputPriority = element.priority;
    this.editPriority = true;
    this.editRow = i;
  }

  /**
   * Función que escucha el evento del input de una fila determinada
   * @param event evento click
   * @param i fila
   * @param cancel si es para cancelar la operación
   */
  changePriorityInput(event, i: number, cancel?: boolean) {
    if (
      i === this.editRow &&
      this.editPriority &&
      this.inputPriority &&
      !cancel
    ) {
      // aseguramos que es un valor óptimo
      if (this.inputPriority < 1) this.inputPriority = 1;
      else if (this.inputPriority > this.dataSource.length)
        this.inputPriority = this.dataSource.length;
      const element = { ...this.dataSource[i] };
      if (element.priority !== this.inputPriority) {
        this.launchPriorityChange(
          element.priority - 1,
          this.inputPriority - 1,
          element
        );
      }
    }
    this.inputPriority = null;
    this.editPriority = false;
  }

  /**
   * Función que abre el form de crear un alta
   */
  openFormNewAlta() {
    this.dialog
      .open(NewTileComponent, {
        data: {
          priority: this.dataSource.length,
        },
        disableClose: true,
      })
      .afterClosed()
      .pipe()
      .subscribe(() => {
        this.viewProcessed = false;
      });
  }

  /**
   * Mensaje de confirmación de eliminar alta y llamada a api
   * @param cola
   */
  deleteCola(cola: Tile) {
    Swal.fire({
      icon: 'warning',
      title: `Eliminar cola ${cola.area_nombre}`,
      text: '¿Está seguro de eliminar la cola? No habrá vuelta atrás.',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(setLoading({ loading: true }));
        this.store.dispatch(
          deleteCola({ ids: [cola.id], processed: this.viewProcessed })
        );
      }
    });
  }

  /**
   * close component
   */
  ngOnDestroy(): void {
    const container = document.getElementsByClassName('container-fluid')[0];
    container.classList.remove('drop-scroll');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
