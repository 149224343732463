import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class CommonService {
  static pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(
    private clipboard: Clipboard,
    private router: Router,
    private serializer: UrlSerializer,
    private translocoService: TranslocoService
  ) {}

  /**
   * Copy textToCopy to clipboard
   * @param textToCopy
   */
  copyTextToClipboard(textToCopy: string): void {
    this.clipboard.copy(textToCopy);
  }

  /**
   * Function to translate an objet to a determinate language
   * @param object objetc to translate
   * @param language translation language
   */
  translateObject(object: any, id: string, language: string): Object {
    this.translocoService.setActiveLang(language);
    const keyValues = Object.keys(object).map((key) => {
      const newKey =
        this.translocoService.translate('object.' + id + '.' + key) || key;
      return { [newKey]: object[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  /* Get url string with query params in correct formar
   * @param url url string to concatenate
   * @param obj query params
   * @returns new url formatted
   */
  serializeParamsQuery(url: string, obj: Object = {}): string {
    obj = JSON.parse(JSON.stringify(obj));
    let entries = Object.entries(obj);
    entries.forEach(([key, value]) => {
      if (value === null || value === undefined) delete obj[key];
    });
    const tree = this.router.createUrlTree([url], { queryParams: obj });
    return this.serializer.serialize(tree);
  }

  /**
   * Transform moment date to string yyyy-mm-dd
   * @param date
   */
  convertMomentDateToString(date: any): string {
    try {
      return date ? moment(date).toISOString(true).split('T')[0] : '';
    } catch (error) {
      return date;
    }
  }

  /**
   * Convert image file to a string base64
   * @param image
   * @returns observable with result
   */
  converImageFileToBase64(image: File): Observable<string | 'error'> {
    const type = image.type;
    CommonService;
    let result = new ReplaySubject<string>(1);
    let reader = new FileReader();
    reader.readAsBinaryString(image);
    reader.onloadend = (event) =>
      result.next(
        `data:${type};base64,` + btoa(event?.target?.result?.toString() || '')
      );
    reader.onerror = (error) => 'error';
    return result;
  }

  /**
   * Convert date type to ISO format without miliseconds and timezone (Z)
   * @param date
   * @returns
   */
  getISOFormatDate(date: Date): string {
    try {
      return date.toISOString().split('.')[0];
    } catch (effects) {
      return date.toDateString();
    }
  }

  /**
   * Get initial date from today
   * @returns
   */
  getTodayDate(): Date {
    const date = new Date(Date.now());
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Returns page size options according of actual size
   * @param objects length of actual data
   * @returns page size options
   */
  getPageSizeOptions(objects: number): number[] {
    if (CommonService.pageSizeOptions.every((e) => e <= objects))
      return CommonService.pageSizeOptions;
    let lastElement = CommonService.pageSizeOptions.find(
      (value, index, array) => index === array.length - 1
    );
    return lastElement < objects
      ? [...CommonService.pageSizeOptions, objects]
      : [...CommonService.pageSizeOptions.filter((e) => e < objects), objects];
  }

  getPaginationUrlParams(page?: number, limit?: number, orderby?: string) {
    return page !== undefined
      ? limit !== undefined
        ? orderby
          ? `/${page}/${limit}/${orderby}`
          : `/${page}/${limit}`
        : `/${page}`
      : '';
  }

  loadSwalSpinner(title: string, text?: string) {
    return Swal.fire({
      title: title || '',
      text: text || '',
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      onOpen: () => {
        Swal.showLoading();
      },
    });
  }
}
