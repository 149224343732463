import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  createServer,
  getAllServers,
  loadingTable,
  sendArea,
  updateServer,
} from '../state/servers.actions';
import { getAllServersSelector } from '../state/servers.selector';

@Component({
  selector: 'app-servers-dialog',
  templateUrl: './servers-dialog.component.html',
  styleUrls: ['./servers-dialog.component.scss'],
})
export class ServersDialog implements OnInit {
  serverForm: FormGroup;
  statusOptions: Array<number> = [-1, 1, 2];
  loading: boolean;

  displayedColumns: string[] = [
    'select',
    'id',
    'ip',
    'name',
    'port_rdp',
    'port_ssh',
    'port_ftp',
    'path_sentinel_2',
    'path_planet',
    'status',
  ];
  dataSource;
  selection = new SelectionModel<any>(true, []);
  someSelected: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ServersDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getAllServers({}));

    this.store
      .select(getAllServersSelector)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value != null) {
          this.dataSource = new MatTableDataSource<any>(value);
        }
      });

    this.inicializeForms();
  }

  /** Inicialize form with data */
  inicializeForms() {
    this.serverForm = this.formBuilder.group({
      id: [
        this.data.servidor
          ? this.data.servidor.id
            ? this.data.servidor.id
            : ''
          : '',
      ],
      ip: [
        this.data.servidor
          ? this.data.servidor.ip
            ? this.data.servidor.ip
            : ''
          : '',
        [Validators.required],
      ],
      name: [
        this.data.servidor
          ? this.data.servidor.name
            ? this.data.servidor.name
            : ''
          : '',
        [Validators.required],
      ],
      port_rdp: [
        this.data.servidor
          ? this.data.servidor.port_rdp
            ? this.data.servidor.port_rdp
            : ''
          : '',
        [Validators.required],
      ],
      port_ssh: [
        this.data.servidor
          ? this.data.servidor.port_ssh
            ? this.data.servidor.port_ssh
            : ''
          : '',
        [Validators.required],
      ],
      port_ftp: [
        this.data.servidor
          ? this.data.servidor.port_ftp
            ? this.data.servidor.port_ftp
            : ''
          : '',
        [Validators.required],
      ],
      path_sentinel_2: [
        this.data.servidor
          ? this.data.servidor.path_sentinel_2
            ? this.data.servidor.path_sentinel_2
            : ''
          : '',
        [Validators.required],
      ],
      path_planet: [
        this.data.servidor
          ? this.data.servidor.path_planet
            ? this.data.servidor.path_planet
            : ''
          : '',
        [Validators.required],
      ],
      status: [
        this.data.servidor
          ? this.data.servidor.status
            ? this.data.servidor.status
            : 2
          : 2,
        [Validators.required],
      ],
    });
  }

  /** Send data of server to create or update it */
  sendServer() {
    let server = {
      name: this.serverForm.get('name').value,
      ip: this.serverForm.get('ip').value,
      port_rdp: this.serverForm.get('port_rdp').value,
      port_ssh: this.serverForm.get('port_ssh').value,
      port_ftp: this.serverForm.get('port_ftp').value,
      path_sentinel_2: this.serverForm.get('path_sentinel_2').value,
      path_planet: this.serverForm.get('path_planet').value,
      status: this.serverForm.get('status').value,
    };

    this.store.dispatch(
      this.data.servidor
        ? updateServer({
            id: this.serverForm.get('id').value,
            body: server,
            parametersPagination: this.data.parametros,
          })
        : createServer({
            body: server,
            parametersPagination: this.data.parametros,
          })
    );

    // Mientras el error NG0100 siga activo
    this.store.dispatch(loadingTable({ active: true }));
    this.dialogRef.close();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /** Apply automatically the filter input */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Clear selection on table */
  clear() {
    this.selection.clear();
  }

  /** Actions to do when check a server in the table */
  check(event, row) {
    let removeSame = false;
    this.selection.selected.map((value) => {
      if (value.id == row.id) {
        removeSame = true;
        this.clear();
      }
    });
    this.selection.clear();
    if (!removeSame) this.selection.toggle(row);
  }

  /** Send area to other server with confirmation */
  sendArea() {
    Swal.fire({
      title: '¿Quieres enviar este área?',
      text: 'Se borrará del anterior servidor y se creará en el nuevo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Adelante',
    }).then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(loadingTable({ active: true }));
        this.store.dispatch(
          sendArea({
            idArea: this.data.servidor.id,
            idServer: this.selection.selected[0].id,
            parametersPagination: this.data.parametros,
          })
        );
        this.dialogRef.close();
      }
    });
  }

  /** DESTROY */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
