<div class="animate__animated animate__fadeInRight animate__faster content">
  <div>
    <app-filter-table
      [title]="title"
      (obtenerTipo)="obtenerTipo($event)"
      (filtrarTabla)="filtrar($event)"
      [tipoInput]="tipoInput"
      [actualFilter]="actualFilter"
      [disabled]="disabledFilter"
      [eliminarFiltro]="data && data.objects > 0"
    ></app-filter-table>
    <div
      [ngClass]="{
        'card col-md-12 py-2': true,
        'min-table': data && datos && datos.length > 0
      }"
    >
      <app-table
        [data]="data"
        [datos]="datos"
        [title]="title"
        [buttons]="buttonsTable"
        [page]="actualPage"
        (editarDato)="openEditProduct($event)"
        (changePage)="changePage($event)"
        (changeLimit)="changeLimit($event)"
        (changeOrderedBy)="changeOrder($event)"
      >
      </app-table>
    </div>
  </div>
</div>
