import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, map, withLatestFrom, mergeMap, switchMap, takeUntil, tap, timeout, timeoutWith } from "rxjs/operators";
import { AbstractPagination, Pagination } from "src/app/interfaces/Pagination.interface";
import { AppState } from "src/app/store/app.state";
import Swal from "sweetalert2";
import { loadAltasAreasData, loadAltasAreasObjects, loadDataAreasDataSuccess, setKeyValueAltasArea } from "./altas-area.actions";
import { AltasAreasService } from "src/app/services/AltasAreas.service";
import { AltaArea } from "src/app/interfaces/AltasArea.interface";
import { labelsStateAltasArea } from "./altas-area.state";
import { noData } from "src/app/auth/state/auth.actions";
import { getFilterAltasArea } from "./altas-area.selector";

@Injectable()
export class AltasAreaEffects{
  constructor(
    private store: Store<AppState>,
    private actions$:Actions,
    private altasAreasService: AltasAreasService
  ) { }

  loadDataAreasData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAltasAreasData),
      withLatestFrom(this.store.select(getFilterAltasArea)),
      tap(e => this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.LOADING_TABLE, value: true }))),
      switchMap(([{ page, limit, orderby, url, filter, workspace }, actualFilter]) => {
        let filt: { filtro: Object, extra_filt?: Object } = actualFilter//{ ...(actualFilter || {}), filtro: { fk_cliente__category__in: [2, 3], ...(actualFilter?.filtro || {}) } };
        if(workspace) {
          filt = {
            ...(filt || {}),
            filtro: {
              ...(filt?.filtro || {}),
              fk_cliente__workspace: workspace
            }
          }
        }
        let petition = (url) ? this.altasAreasService.getDataByUrl(url) : this.altasAreasService.getData(page, limit, orderby, filt);
        return petition.pipe(
          map((data: AbstractPagination<AltaArea>) => {
            return loadDataAreasDataSuccess({ pagination: data });
          }),
          catchError((error) => {
            return of(loadDataAreasDataSuccess({ pagination: null }));
          })
        );
      }),
      map(e => {
        this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.LOADING_TABLE, value: false }));
        return e;
      })
    );
  });

  loadAltasAreasObjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAltasAreasObjects),
      tap(e => this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.LOADING, value: true }))),
      switchMap((action) => {
        return this.altasAreasService.getData(1, 10, 'status', { extra_filt: { status__in: [0, 25, 50, 75] }}).pipe(
          map((data: AbstractPagination<AltaArea>) => {
            if(!data) return noData();
            return setKeyValueAltasArea({ key: labelsStateAltasArea.TOTAL_ALTAS, value: data?.objects });
          }),
          catchError((error) => {
            return of(noData());
          })
        );
      }),
      map(e => {
        this.store.dispatch(setKeyValueAltasArea({ key: labelsStateAltasArea.LOADING, value: false }));
        return e;
      })
    );
  });
}
