import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarAltasService {
  public changeViewSubject = new Subject<any>();
  public loadingCalendarSubject = new BehaviorSubject<boolean>(false);
  public limitsSubject = new Subject<{ last: Date; first: Date }>();
  public actualDateViewSubject = new Subject<Date>();

  constructor() {}

  changeView(): Observable<any> {
    return this.changeViewSubject.asObservable();
  }

  loadingCalendar(): Observable<boolean> {
    return this.loadingCalendarSubject.asObservable();
  }

  limits(): Observable<{ last: Date; first: Date }> {
    return this.limitsSubject.asObservable();
  }

  actualDateView(): Observable<Date> {
    return this.actualDateViewSubject.asObservable();
  }
}
