import { createAction, props } from '@ngrx/store';
import { grafica, indicador } from 'src/app/interfaces/dashboard.interface';
import { Pagination } from 'src/app/interfaces/Pagination.interface';

/** GRÁFICAS */
export const LOAD_DASHBOARD_SUPERFICIE =
  '[ dashboard page] load dashboard superficie';
export const LOAD_DASHBOARD_SUPERFICIE_SUCCESS =
  '[ dashboard page] load dashboard superficie success';

export const loadDashboardSuperficie = createAction(LOAD_DASHBOARD_SUPERFICIE);

export const loadDashboardSuperficieSuccess = createAction(
  LOAD_DASHBOARD_SUPERFICIE_SUCCESS,
  props<{ data: grafica[] }>()
);

/** INDICADORES */
export const LOAD_DASHBOARD_CLIENTES =
  '[ dashboard page] load dashboard clientes';
export const LOAD_DASHBOARD_CLIENTES_SUCCESS =
  '[ dashboard page] load dashboard clientes success';

export const loadDashboardClientes = createAction(LOAD_DASHBOARD_CLIENTES);

export const loadDashboardClientesSuccess = createAction(
  LOAD_DASHBOARD_CLIENTES_SUCCESS,
  props<{ data: indicador[] }>()
);

/** RESET */
export const RESET_DASHBOARD_STATE = '[ dashboard page] resetDashboardState';

export const resetDashboardState = createAction(RESET_DASHBOARD_STATE);

export const GET_DEMOS_AND_CLIENTS = '[ dashboard page] get demos and clients';
export const getDemosAndClients = createAction(
  GET_DEMOS_AND_CLIENTS,
  props<{ ids: Array<string> }>()
);

export const GET_DEMOS_AND_CLIENTS_SUCCESS =
  '[ dashboard page] get demos and clients success';
export const getDemosAndClientsSuccess = createAction(
  GET_DEMOS_AND_CLIENTS_SUCCESS,
  props<{ data: Array<Pagination>; ids: Array<string> }>()
);

export const GET_DATA_BY_URL = '[ dashboard page] get data by url';
export const getDataByUrl = createAction(
  GET_DATA_BY_URL,
  props<{ url: string; id: string }>()
);

export const SET_LOADING_TABLES = '[ dashboard page] set loading tables';
export const setLoadingTables = createAction(
  SET_LOADING_TABLES,
  props<{ ids: Array<string> }>()
);
