import { Pipe, PipeTransform } from '@angular/core';
import { filterColumnsFormatTilesHistoric } from '../interfaces/tiles/tiles_historic';

@Pipe({
  name: 'tilesFiltersPipe',
})
export class TilesFiltersPipe implements PipeTransform {
  transform(value: string): string {
    return filterColumnsFormatTilesHistoric[value]
      ? filterColumnsFormatTilesHistoric[value]
      : value;
  }
}
