import { botonesTabla } from '../enums/botonesTabla.enum';

const botonesTablaEnum = botonesTabla;

export const fk_cliente = [
  'workspace',
  'nombre',
  'correo',
  'empresa',
  'telefono',
  'ha_empresa',
  'verificado',
  'contactado',
];

export const fk_contacto = [
  'language',
  'pais',
  'cultivo',
  'apellidos',
  'phone',
  'email',
];

const userColumns = [
  'email',
  'nombre',
  'apellidos',
  'phone',
  'pais',
  'cultivo',
];

const defaultColumns = [
  'workspace',
  'email',
  'nombre',
  'apellidos',
  'empresa',
  'phone',
  'pais',
  'cultivo',
  'fecha_registro',
  'ha_empresa',
];

const commonNoLeads = [
  'workspace',
  'nombre',
  'empresa',
  'ha_empresa',
  'ha_consum',
  'ha_activas',
];

const demos = [
  'ha_activas_sent',
  'fin_plataforma',
  //"prioridad", // no está
  'fin_actualizacion',
];

const clientes = [
  'alta_freq',
  'pagado',
  'ha_contrat', // planet
  'ha_contrat_sent', // contratadas
  'metodo_pago', // no está
];

const leads = ['verificado', 'contactado'];

const bajas = ['correo', 'telefono'];

const areas = [
  'nombre',
  'titulo',
  'cultivo',
  'fin_actualizacion',
  'superficie',
  'terminado',
  'reinicio',
  'zafra_cont',
  'freinicio',
];

const novedades = [
  'titulo',
  'fecha_inicio',
  'fecha_vencimiento',
  'clientes',
  'rol_user',
  'descripcion',
  'tipo',
  'link',
  'link_video',
  //"imagen",
  //"clientes__cultivo",
  //"clientes__pais"
];

export const buttonsEachTabla = {
  leads: [
    botonesTablaEnum.usuario,
    botonesTablaEnum.convertir,
    botonesTabla.superuser,
  ],
  demos: [
    botonesTablaEnum.editar,
    botonesTablaEnum.usuario,
    botonesTablaEnum.crear,
    botonesTabla.superuser,
  ],
  clientes: [
    botonesTablaEnum.editar,
    botonesTablaEnum.usuario,
    botonesTablaEnum.crear,
    botonesTabla.superuser,
  ],
  bajas: [
    botonesTablaEnum.usuario,
    botonesTabla.superuser,
    botonesTablaEnum.convertir,
  ],
  usuarios: [botonesTabla.superuser],
  areas: [
    botonesTablaEnum.editar,
    botonesTablaEnum.terminado,
    botonesTablaEnum.procCurvas,
    botonesTablaEnum.procHistoricos,
  ],
  novedades: [
    botonesTablaEnum.editar,
    botonesTablaEnum.eliminar,
    botonesTablaEnum.crear,
  ],
  products: [botonesTablaEnum.editar],
  powerbi: [],
  curvas: [botonesTablaEnum.curvas],
};

const usuarios = [
  'user',
  'last_login',
  'contador_login',
  'contador_dashboard',
  'contador_powerbi',
  'fecha_registro',
];

const tiles = [
  'area_nombre',
  'priority',
  'date_start',
  'date_end',
  'processed_pctg',
  'downloaded_pctg',
  'processed_tiles_pctg',
  'process_init',
  'process_end',
  'historic',
  'generate_curves',
  'generate_points',
  'upload_image',
];

const products = [
  'nombre',
  'titulo',
  'titulo_portuguese',
  'titulo_english',
  'agrupacion',
  'cultivos',
  'pixel_2',
  'fk_provider',
];

const powerbi = ['workspace', 'nombre', 'actualizar', 'proceso', 'estado'];

const curvas = [
  'variedad',
  'mes',
  'riego',
  'zona_eco',
  'dds',
  'rend',
  'soca',
  'corregir',
];

const editar = [
  'category',
  'tools',
  'language',
  'descargar_raster',
  'ha_contrat_sent',
  'ha_activas',
  'ha_activas_sent',
];

export const columnsTable = {
  leads: defaultColumns.concat(leads),
  demos: commonNoLeads.concat(demos),
  clientes: commonNoLeads.concat(demos, clientes),
  bajas: commonNoLeads.concat(bajas, demos, clientes),
  usuarios: defaultColumns
    .filter((a) => !a.startsWith('empre') && !a.startsWith('workspace'))
    .slice(0, 6)
    .concat(
      demos.slice(0, 4),
      leads.filter((a) => a.includes('veri')),
      usuarios
    )
    .sort((a, b) => (a === 'user' ? -1 : 1)),
  editar: defaultColumns.concat(
    usuarios,
    demos.filter((element) => element.includes('fin')),
    leads,
    clientes.filter((a) => !a.includes('sent')),
    editar
  ),
  areas: areas,
  novedades: novedades,
  tiles: tiles,
  products: products,
  curvas: curvas,
  powerbi: powerbi,
};

export const changesColumns = {
  email: 'correo',
  phone: 'telefono',
};

export const columnsNoFilter = ['imagen', 'actualizar', 'estado', 'proceso'];

export const columnsNoChangeOrder = [
  'acciones',
  'clientes',
  'actualizar',
  'estado',
  'proceso',
];
