import { createAction, props } from '@ngrx/store';
import {
  PaginationFilterInput,
  ProductProcess,
} from 'src/app/interfaces/altas/Altas.interface';

/** STATISTICS */
export const LOAD_STATISTICS = '[ altas page] load statistics';
export const LOAD_ALTA_DATA = '[ altas page] load altas data';
export const CHANGE_KEY_VALUE_ALTAS = '[ altas page] change key values altas';
export const LOAD_STATISTICS_SUCCESS = '[ altas page] load stadistics success';
export const LOAD_ALTAS_FILTERS = '[ altas page] load altas filters';

export const loadStatistics = createAction(
  LOAD_STATISTICS,
  props<{ filters: any[]; date?: { $gte: string; $lt: string } }>()
);

export const loadStatisticsSuccess = createAction(
  LOAD_STATISTICS_SUCCESS,
  props<{ data: any }>()
);

export const loadAltasFilters = createAction(
  LOAD_ALTAS_FILTERS,
  props<{ filters: any[] }>()
);

/** PROCESSES */
export const loadDataAltas = createAction(
  LOAD_ALTA_DATA,
  props<{ attributes: PaginationFilterInput }>()
);

export const changeKeyValueAltas = createAction(
  CHANGE_KEY_VALUE_ALTAS,
  props<{ key: string; value: any }>()
);

/** MONGO */
export const GET_MONGO_CLIENT = '[ altas page] get mongo client';
export const GET_MONGO_AREA = '[ altas page] get mongo area';
export const GET_MONGO_SUCCESS = '[ altas page] get mongo client success';
export const getMongoClient = createAction(
  GET_MONGO_CLIENT,
  props<{ client: any }>()
);
export const getMongoArea = createAction(
  GET_MONGO_AREA,
  props<{ area: any }>()
);
export const getMongoSuccess = createAction(
  GET_MONGO_SUCCESS,
  props<{ attribute: string; value: any }>()
);

export const CREATE_MONGO_CLIENT = '[ altas page] create mongo client';
export const CREATE_MONGO_AREA = '[ altas page] create mongo area';
export const createMongoClient = createAction(
  CREATE_MONGO_CLIENT,
  props<{ data: any }>()
);
export const createMongoArea = createAction(
  CREATE_MONGO_AREA,
  props<{ data: any }>()
);

export const GET_NOT_PROCESSING_CLIENTS =
  '[ altas page] get not processing clients';
export const GET_NOT_PROCESSING_CLIENTS_SUCCESS =
  '[ altas page] get not processing clients success';
export const GET_NOT_PROCESSING_AREAS =
  '[ altas page] get not processing areas';
export const GET_NOT_PROCESSING_AREAS_SUCCESS =
  '[ altas page] get not processing areas success';
export const POST_NOT_PROCESSING_CLIENTS_AND_AREAS =
  '[ altas page ] post not processing clients and areas';
export const getNotProcessingClients = createAction(GET_NOT_PROCESSING_CLIENTS);
export const getNotProcessingClientsSuccess = createAction(
  GET_NOT_PROCESSING_CLIENTS_SUCCESS,
  props<{ data: any[] }>()
);
export const getNotProcessingAreas = createAction(GET_NOT_PROCESSING_AREAS);
export const getNotProcessingAreasSuccess = createAction(
  GET_NOT_PROCESSING_AREAS_SUCCESS,
  props<{ data: any[] }>()
);
export const postNotProcessingClientsAndAreas = createAction(
  POST_NOT_PROCESSING_CLIENTS_AND_AREAS,
  props<{ areas: any[]; clientsSelected: any[] }>()
);

export const UPDATE_ALTAS_CLIENTS = '[ altas page] update altas clients';
export const updateAltasClients = createAction(
  UPDATE_ALTAS_CLIENTS,
  props<{ id: number | string; body: Object }>()
);

export const UPDATE_ALTAS_CLIENTS_SUCCESS =
  '[ altas page] update altas clients success';
export const updateAltasClientsSuccess = createAction(
  UPDATE_ALTAS_CLIENTS_SUCCESS,
  props<{
    result: boolean;
    message?: string;
    description?: string;
    newAlta?: Object;
  }>()
);

export const GET_PRODUCT_PROCESS = '[ altas page] get product process';
export const getProductProcess = createAction(
  GET_PRODUCT_PROCESS,
  props<{ id: string }>()
);

export const GET_PRODUCT_PROCESS_SUCCESS =
  '[ altas page] get product process success';
export const getProductProcessSuccess = createAction(
  GET_PRODUCT_PROCESS_SUCCESS,
  props<{ result: boolean; message?: string; products: ProductProcess[] }>()
);

/** Sidebar */
export const OPENED_SIDEBAR = '[ all pages] opened sidebar';
export const openedSidebar = createAction(
  OPENED_SIDEBAR,
  props<{ opened: boolean }>()
);
