<div class="main-container">
    <!-- TITLE -->
    <span>Productos</span>
    <!-- PLAN AND INFO -->
    <div *ngIf="crop && !productLoader" class="select-container">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
            <span style="margin-right: 1em;">Selección de plan:</span>
            <ng-select class="inputs capitalize" style="height: 36px" placeholder="Selecciona un plan" (change)="changePlan($event)">
                <ng-option [value]="null" disabled>Selecciona un plan</ng-option>
                <ng-option value="basic">Basic</ng-option>
                <ng-option value="premium">Premium</ng-option>
            </ng-select>
        </div>
        <div style="margin-left: 0.5vw;">
            <span class="provider-span" style="background-color: rgb(230, 230, 230);">Sentinel</span>
            <span *ngIf="client?.alta_freq" class="provider-span" style="background-color: lightcyan;">Planet</span>
        </div>
    </div>
    <!-- PRODUCTS -->
    <div *ngIf="crop && !productLoader" class="products-container">
        <div *ngFor="let group of products_configuration | keyvalue" class="group-container">
            <label>
                {{group.key}}
                <input id="{{group.key}}_checkbox" type="checkbox" class="myCheckbox" (change)="changeGroupSelection(group.key, $event)" [checked]="checked[group.key]" [indeterminate]="indeterminates[group.key]">
            </label>
            <div class="product-container">
                <label *ngFor="let product of group.value;" class="provider-span" [ngStyle]="{'background-color': product.fk_provider_id == 1 ? 'lightcyan' : 'rgb(230, 230, 230)', 'display': !crop || product.cultivos.includes(crop) ? 'initial' : 'none'}">
                    <input id="{{product.nombre}}_checkbox" type="checkbox" [checked]="product.check" class="myCheckbox" (change)="changeSelection(group.key,product)">
                    {{product.nombre}}
                </label>
            </div>
        </div>
    </div>
    <!-- NO CROP -->
    <div *ngIf="!crop && !productLoader" class="noCrop">
        <span>Seleccione un cultivo para visualizar y añadir productos.</span>
    </div>
    <!-- LOADER -->
    <div *ngIf="crop && productLoader" class="loader-container">
        <mat-spinner></mat-spinner>
    </div>
</div>
