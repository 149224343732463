import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { getSupersuserToken } from 'src/app/auth/state/auth.selector';
import {
  AltaArea,
  OtherOptionsTableAltas,
} from 'src/app/interfaces/AltasArea.interface';
import { AbstractPagination } from 'src/app/interfaces/Pagination.interface';
import { Area } from 'src/app/interfaces/area';
import { cliente } from 'src/app/interfaces/cliente.interface';
import { Token } from 'src/app/models/auth/token.model';
import { setLoading } from 'src/app/pages/admin/state/admin.actions';
import {
  getClientData,
  getLoadingSuperusers,
  getMessageSuperuser,
} from 'src/app/pages/admin/state/admin.selector';
import { AltasAreasService } from 'src/app/services/AltasAreas.service';
import { CommonService } from 'src/app/services/Common.service';
import { PlanService } from 'src/app/services/configuration/plan.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  AltasAreasOptions,
  AltasAreasTableActions,
  columnsAltasAreasEditor,
  namesColumnsAltasAreas,
  typesColumnsAltasAreas,
} from '../constants/AltasAreas.constants';
import {
  loadAltasAreasData,
  resetAltasArea,
  setKeyValueAltasArea,
} from '../dialog/altas-areas-dialog/state/altas-area.actions';
import {
  getFilterAltasArea,
  getLoadingTableAltasArea,
  getPaginationAltasAreas,
} from '../dialog/altas-areas-dialog/state/altas-area.selector';
import { labelsStateAltasArea } from '../dialog/altas-areas-dialog/state/altas-area.state';
import { categories } from '../enums/categories';
import {
  changeEditorStep,
  clearEditorState,
  createArea,
  createAreaProductsSuccess,
  createClient,
  createOrEditClientSuccess,
  editArea,
  expireClient,
  newEditClient,
} from './state/editor.actions';
import { getEditorAreaSelected, getEditorStep } from './state/editor.selector';
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewChecked {
  // Unsuscribe
  private ngUnsubscribe: Subject<any> = new Subject();

  // Routing variables
  urlTree: any;
  type: any;
  isNew: boolean = false;

  // Stepper variables
  step: number;

  // Client variables
  client: cliente;
  client_data: cliente;
  changeCategory: boolean = false;

  // Area variables
  area: Area | null;
  area_data: Area;
  area_products_initial: any;
  area_products_data: any;
  area_products_assigned: boolean = false;
  productsLoader: boolean;

  // Superuser variables
  superuserToken: Token | null = null;
  loadingSuperuserToken: boolean = false;
  messageSuper: Observable<string | null>;

  // Form variables
  editForm: FormGroup;
  todoForm: FormGroup;
  validForm: boolean = true;
  changeStepSuperuser: boolean = false;

  // Areas table variables
  paginatorAltasAreas: AbstractPagination<AltaArea> | null = null;
  loadingTableAltas: boolean = false;
  actualFilterAltas: { filtro: Object; extra_filt: Object } | null = null;
  showFilterAltas: Object = {};
  orderbyTable: string = 'status';
  typesInColumns = typesColumnsAltasAreas;
  columnsAltas = columnsAltasAreasEditor;
  namesAltas = namesColumnsAltasAreas;
  actionsTable = [AltasAreasTableActions.OTHER_OPTIONS];
  moreOptionsTable: OtherOptionsTableAltas[] = [
    { icon: 'insights', title: AltasAreasOptions.GENERAR_CURVAS },
    { icon: 'playlist_add', title: AltasAreasOptions.ASIGNAR_CURVAS },
    { icon: 'download', title: AltasAreasOptions.DESCARGAR_HISTORICOS },
    { icon: 'event_repeat', title: AltasAreasOptions.REPROCESAR },
  ];
  loadingOptionsDialogs: boolean = false;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private actions: Actions,
    public dialog: MatDialog,
    public planService: PlanService,
    private altasAreasService: AltasAreasService,
    private commonService: CommonService
  ) {
    if (router) {
      this.urlTree = this.router.parseUrl(this.router.url);
      this.type = this.urlTree.queryParams['type'];
      if (
        ![
          categories.CLIENTES,
          categories.DEMOS,
          categories.BAJAS,
          categories.LEADS,
        ].includes(parseInt(this.type))
      )
        router.navigateByUrl('/admin');
      // se indica que es el formulario de creación con la variable nuevo = 'true'
      this.isNew = this.urlTree.queryParams['nuevo'] === 'true';
      // salimos de la página si se pasa que es nuevo y no son las categorías adecuadas
      if (
        this.isNew &&
        ![categories.CLIENTES, categories.DEMOS].includes(parseInt(this.type))
      )
        router.navigateByUrl('/admin');
    }
  }

  ngOnInit(): void {
    /** Listen to setp changes */
    this.store
      .select(getEditorStep)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((step: number) => {
        this.step = step;
      });

    /** Initializate data actions */
    this.dataInitialization();

    /** Initializate superuser actions */
    this.superuserInitialization();

    /** Initializate areas table listeners */
    this.initAreasTableListeners();
  }

  ngAfterViewChecked(): void {
    let danger = document.getElementById('buttonDanger');
    let success = document.getElementById('buttonSuccess');
    if (danger || success) {
      setTimeout(() => {
        if (danger) danger.hidden = true;
        if (success) success.hidden = true;
      }, 5000);
    }
  }

  private initAreasTableListeners() {
    this.store.dispatch(resetAltasArea());
    this.store
      .select(getPaginationAltasAreas)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.paginatorAltasAreas = JSON.parse(JSON.stringify(value));
      });

    this.store
      .select(getLoadingTableAltasArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.loadingTableAltas = value;
      });

    this.store
      .select(getFilterAltasArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.actualFilterAltas = JSON.parse(JSON.stringify(value));
        this.showFilterAltas = this.altasAreasService.getShowFilter(
          this.actualFilterAltas
        );
        this.store.dispatch(
          loadAltasAreasData({
            orderby: this.orderbyTable,
            workspace: this.client?.workspace,
          })
        );
      });
  }

  selectionChangeStep({
    selectedIndex,
    previouslySelectedIndex,
    selectedStep,
    previouslySelectedStep,
  }) {
    if (selectedIndex === 1) {
      this.changePage({ page: 1, limit: 5, orderby: this.orderbyTable });
    }

    if (previouslySelectedIndex === 1) {
      this.store.dispatch(
        setKeyValueAltasArea({
          key: labelsStateAltasArea.ACTUAL_FILTER,
          value: null,
        })
      );
    }
  }

  /**
   * Load new data because page, limit or orderby have changed
   * @param param0
   */
  changePage({
    page,
    limit,
    orderby = undefined,
    filter = undefined,
    dropAll = undefined,
  }) {
    this.orderbyTable = this.altasAreasService.changePage(
      {
        page: page,
        limit: limit,
        orderby: orderby,
        filter: filter,
        dropAll: dropAll,
      },
      this.actualFilterAltas,
      this.client?.workspace
    );
  }

  async selectedOption({ option, area }) {
    let subject: Subject<boolean> | null = null;
    let areaObject =
      this.altasAreasService.transformAltasAreaToAreaObject(area);
    this.loadingOptionsDialogs = true;
    switch (option?.title || option) {
      case AltasAreasOptions.GENERAR_CURVAS:
      case 'some_curve':
        subject = this.altasAreasService.openGenerarCurvas(
          areaObject,
          this.client?.id
        );
        break;
      case AltasAreasOptions.ASIGNAR_CURVAS:
        this.altasAreasService.openAsignarCurvas(areaObject);
        break;
      case AltasAreasOptions.DESCARGAR_HISTORICOS:
        subject = this.altasAreasService.openDescargarHistoricos(
          area.fk_cliente__workspace,
          areaObject
        );
        break;
      case AltasAreasOptions.REPROCESAR:
        this.altasAreasService.openReprocesarFs(areaObject);
        break;
      case 'some_field':
      case 'some_historical_data':
        this.area = areaObject;
        this.area_data = this.area;
        this.store.dispatch(changeEditorStep({ step: 3 }));
        break;
      case 'in_update_flow':
        let category = this.altasAreasService.getCategoryStringMongo(
          area?.fk_cliente__category
        );
        if (category) {
          let result = await this.altasAreasService.createMongoArea(
            area.fk_cliente__workspace,
            areaObject,
            'client',
            this.client?.id
          );
          result &&
            this.store.dispatch(
              loadAltasAreasData({
                orderby: this.orderbyTable,
                workspace: this.client?.workspace,
              })
            );
        }
        break;
      default:
        break;
    }

    if (subject) {
      subject.pipe(take(1)).subscribe((value) => {
        this.loadingOptionsDialogs = false;
      });
    } else {
      this.loadingOptionsDialogs = false;
    }
  }

  /**
   * Function to select an area on the table
   * @param area
   */
  selectArea(area: AltaArea) {
    this.area = this.altasAreasService.transformAltasAreaToAreaObject(area);
    this.area_data = this.area;
    this.store.dispatch(changeEditorStep({ step: 2 }));
  }

  controlPlatformEnd() {
    let fin_plat =
      typeof this.todoForm?.controls['fin_plataforma'].value === 'string'
        ? new Date(this.todoForm?.controls['fin_plataforma'].value)
        : this.todoForm?.controls['fin_plataforma'].value;
    let fin_actualizacion =
      typeof this.todoForm?.controls['fin_actualizacion'].value === 'string'
        ? new Date(this.todoForm?.controls['fin_actualizacion'].value)
        : this.todoForm?.controls['fin_actualizacion'].value;
    if (fin_plat < fin_actualizacion) {
      this.todoForm?.controls['fin_actualizacion'].setValue(fin_plat);
      this.todoForm.updateValueAndValidity();
      this.todoForm?.controls['fin_actualizacion'].markAsDirty();
      this.todoForm?.controls['fin_actualizacion'].markAsTouched();
    }
  }

  /**
   * Client and area data initialization
   */
  dataInitialization() {
    /** Load client selected */
    this.store
      .select(getClientData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.client = data;
        this.client_data = data;

        if (!data && !this.isNew) {
          switch (this.type) {
            case '2':
              this.router.navigate([`../admin/demos`]);
              break;
            case '3':
              if (this.changeCategory != true) {
                this.router.navigate([`../admin/clientes`]);
                this.changeCategory = false;
              }
              break;
          }
        }
      });

    /** Listen to area data edit */
    this.store
      .select(getEditorAreaSelected)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.area = data;
        this.area_data = data;
      });
  }

  /**
   * Superuser data initialization
   */
  superuserInitialization() {
    this.messageSuper = this.store.select(getMessageSuperuser);

    this.store
      .select(getLoadingSuperusers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.loadingSuperuserToken = value;
      });

    /**
     * Obtener el valor actual del token del superusuario
     */
    this.store
      .select(getSupersuserToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: Token) => {
        this.superuserToken = value?.token?.token;

        this.changeStepSuperuser &&
          this.store.dispatch(changeEditorStep({ step: 1 }));
        this.changeStepSuperuser = false;

        // si es null es que está en proceso de obtenerlo, si no, se ha terminado
        value === null || value?.token?.token === null
          ? this.store.dispatch(setLoading({ loading: true }))
          : this.store.dispatch(setLoading({ loading: false }));
      });
  }

  /**
   * Function to go back in the stepper or exit the editor
   * step 0: exit the editor (cancel create or cancel edit)
   * step 1: go back to client info
   * step 2: exit area info (cancel create or cancel edit)
   * step 3: go back to area info
   */
  Back() {
    switch (this.step) {
      case 0:
        if (this.validForm) {
          if (this.client) {
            if (this.client != this.client_data) {
              // Desea salir sin guardar los cambios?
              this.loadStepperAlert(
                'Salir de la edición de cliente',
                '¿Desea salir de la edición de cliente sin guardar los cambios?',
                'Guardar y salir',
                'Salir sin guardar',
                true
              );
            } else {
              this.router.navigateByUrl(
                this.router.url.split('/').slice(0, 3).join('/')
              );
            }
          } else {
            // Desea salir sin crear el cliente?
            if (this.client_data) {
              this.loadStepperAlert(
                'Salir de la creación de cliente',
                '¿Desea salir sin crear el cliente?',
                'Crear y salir',
                'Salir sin crear',
                false
              );
            } else {
              this.router.navigateByUrl(
                this.router.url.split('/').slice(0, 3).join('/')
              );
            }
          }
        } else {
          this.loadInvalidAlert(
            `Salir de la ${this.isNew ? 'creación' : 'edición'} de cliente`,
            `El formulario no está completado. ¿Desea cancelar la ${
              this.isNew ? 'creación' : 'edición'
            }?`,
            'Sí',
            'No',
            true
          );
        }
        break;
      case 1:
        this.store.dispatch(changeEditorStep({ step: 0 }));
        break;
      case 2:
        if (this.validForm) {
          if (
            this.area &&
            (JSON.stringify(this.area) != JSON.stringify(this.area_data) ||
              JSON.stringify(this.area_products_data) !=
                JSON.stringify(this.area_products_initial))
          ) {
            this.loadStepperAlert(
              'Salir de la edición de área',
              '¿Desea salir de la edición de área sin guardar los cambios?',
              'Guardar y salir',
              'Salir sin guardar',
              true
            );
            return;
          } else if (!this.area && this.area_data) {
            this.loadStepperAlert(
              'Salir de la creación de área',
              '¿Desea salir sin crear el área?',
              'Crear y salir',
              'Salir sin crear',
              false
            );
            return;
          } else {
            this.area_products_assigned = false;
            this.store.dispatch(changeEditorStep({ step: 1 }));
          }
        } else {
          this.loadInvalidAlert(
            `Salir de la ${this.isNew ? 'creación' : 'edición'} de area.`,
            `El formulario no está completado. ¿Desea cancelar la ${
              this.isNew ? 'creación' : 'edición'
            }?`,
            'Sí',
            'No',
            true
          );
        }
        break;
      case 3:
        this.store.dispatch(changeEditorStep({ step: 2 }));
        break;
    }
  }

  /**
   * Stepper forward function
   */
  Forward() {
    switch (this.step) {
      case 0:
        if (!this.editForm?.touched && this.client) {
          this.store.dispatch(changeEditorStep({ step: 1 }));
          break;
        }
        this.loadingOptionsDialogs = true;
        let a: any = this.commonService.loadSwalSpinner(
          this.client ? 'Editando cliente...' : 'Creando cliente...'
        );
        // Create or save client data
        if (this.client) {
          this.store.dispatch(
            newEditClient({ id: this.client.id, data: this.client_data })
          );
        } else {
          this.createClient();
        }

        let subjectCreate = new Subject();
        this.actions
          .pipe(ofType(createOrEditClientSuccess), takeUntil(subjectCreate))
          .subscribe((value) => {
            this.loadingOptionsDialogs = false;
            value.result && this.superuserToken
              ? this.store.dispatch(changeEditorStep({ step: 1 }))
              : this.store.dispatch(changeEditorStep({ step: 0 }));

            this.changeStepSuperuser =
              this.superuserToken === null ||
              typeof this.superuserToken === 'undefined';
            subjectCreate.next();
          });
        break;
      case 1:
        this.area = null;
        this.area_data = null;
        this.store.dispatch(changeEditorStep({ step: 2 }));
        break;
      case 2:
        // Create or save Area data
        let area_data_copy = {
          ...this.area_data,
          fin_actualizacion: this.formatDate(this.area_data.fin_actualizacion),
        };

        if (
          this.area &&
          (JSON.stringify(this.area) != JSON.stringify(this.area_data) ||
            JSON.stringify(this.area_products_data) !=
              JSON.stringify(this.area_products_initial))
        ) {
          let b: any = this.commonService.loadSwalSpinner('Editando area...');
          this.store.dispatch(
            editArea({
              area: this.area,
              data: area_data_copy,
              cliente: this.client,
              productsForm: this.area_products_data,
            })
          );
        } else if (!this.area && this.area_data) {
          let b: any = this.commonService.loadSwalSpinner('Creando area...');
          this.store.dispatch(
            createArea({
              client: this.client,
              area: area_data_copy,
              productsForm: this.area_products_data,
            })
          );
        } else {
          this.store.dispatch(changeEditorStep({ step: 3 }));
        }

        this.actions
          .pipe(ofType(createAreaProductsSuccess))
          .subscribe((value) => {
            this.loadingOptionsDialogs = false;
            if (value.result) {
              this.store.dispatch(changeEditorStep({ step: 3 }));
              this.area_products_initial = { ...this.area_products_data };
              this.area = { ...this.area_data };
            } else {
              this.store.dispatch(changeEditorStep({ step: 2 }));
            }
          });
        break;
    }
  }

  /**
   * Function to format a date string
   * @param dateString
   * @returns
   */
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Function to create a client
   */
  createClient() {
    this.client_data['category'] = Number(this.type);
    if (!this.client_data['alta_freq']) this.client_data['alta_freq'] = false;
    if (!this.client_data['ha_contrat']) this.client_data['ha_contrat'] = false;
    this.store.dispatch(createClient({ data: this.client_data }));
    this.router.navigateByUrl(this.router.url.split('/').slice(0, 3).join('/'));
  }

  /**
   * Function to show alerts on stepper back and forward
   */
  loadStepperAlert(
    title: string,
    text: string,
    confirmText: string,
    cancelText: string,
    edit: boolean
  ) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        if (title.includes('área')) {
          let a: any = this.commonService.loadSwalSpinner(
            edit ? 'Editando area...' : 'Creando area...'
          );

          let area_data_copy = {
            ...this.area_data,
            fin_actualizacion: this.formatDate(
              this.area_data.fin_actualizacion
            ),
          };
          this.area_data = area_data_copy;

          edit
            ? this.store.dispatch(
                editArea({
                  area: this.area,
                  data: this.area_data,
                  cliente: this.client,
                  productsForm: this.area_products_data,
                })
              )
            : this.store.dispatch(
                createArea({
                  client: this.client,
                  area: this.area_data,
                  productsForm: this.area_products_data,
                })
              );

          this.actions
            .pipe(ofType(createAreaProductsSuccess))
            .subscribe((value) => {
              this.loadingOptionsDialogs = false;
              value.result
                ? this.store.dispatch(changeEditorStep({ step: 1 }))
                : this.store.dispatch(changeEditorStep({ step: 2 }));
            });
        } else {
          edit
            ? this.store.dispatch(
                newEditClient({ id: this.client.id, data: this.client_data })
              )
            : this.createClient();
        }
      } else if (result.isDismissed) {
        this.step == 0
          ? this.router.navigateByUrl(
              this.router.url.split('/').slice(0, 3).join('/')
            )
          : this.store.dispatch(changeEditorStep({ step: this.step - 1 }));
        // Reset variables
        this.validForm = true;
        this.area_products_assigned = false;
        this.area_products_initial = { ...this.area_products_data };
        this.area = { ...this.area_data };
      }
    });
  }

  loadInvalidAlert(
    title: string,
    text: string,
    confirmText: string,
    cancelText: string,
    edit: boolean
  ) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        if (title.includes('area')) {
          this.store.dispatch(changeEditorStep({ step: 1 }));
        } else {
          this.router.navigateByUrl(
            this.router.url.split('/').slice(0, 3).join('/')
          );
        }
      } else if (result.isDismissed) {
      }
    });
  }

  /**
   * Update client_data object with changes in editor-form.component
   * @param event object with form values
   */
  getFormChange(event) {
    this.editForm = event.client;
    this.step == 0
      ? (this.client_data = { ...this.client, ...event.client.value })
      : (this.area_data = { ...this.area, ...event.client.value });
    this.validForm = this.editForm?.valid;
  }

  /**
   * Update products data object with changes in products component
   * @param event object with form values
   */
  productsFormChange(event) {
    this.area_products_data = event;
    if (!this.area_products_assigned) {
      this.area_products_initial = event;
      this.area_products_assigned = true;
    }
  }

  /**
   * Function to convert a demo to client category
   */
  demoToClient() {
    Swal.fire({
      title: '¿Está seguro que desea convertir esta demo a cliente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(
          newEditClient({ id: this.client.id, data: { category: 3 } })
        );
        this.router.navigateByUrl(
          this.router.url.split('/').slice(0, 3).join('/')
        );
      }
    });
  }

  /**
   * Function to convert a client to category 4 (expired)
   */
  expireClient() {
    Swal.fire({
      title: '¿Está seguro que desea dar de baja este cliente?',
      text: 'Al dar de baja el cliente, ya no podrá acceder a la plataforma ni se actualizarán sus imágenes y datos.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(expireClient({ client: this.client }));
        this.router.navigateByUrl(
          this.router.url.split('/').slice(0, 3).join('/')
        );
      }
    });
  }

  finishedArea(event: boolean) {
    this.clear();
  }

  /**
   * CLOSE TOOL
   */

  clear() {
    this.step = 1;

    this.area = null;
    this.area_data = null;
    this.area_products_initial = null;
    this.area_products_data = null;
    this.area_products_assigned = false;
    this.productsLoader = false;
  }

  ngOnDestroy(): void {
    this.store.dispatch(changeEditorStep({ step: 0 }));
    this.store.dispatch(clearEditorState());
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
