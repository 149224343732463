import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { geojsonToWKT } from '@terraformer/wkt';
import * as telData from 'country-telephone-data';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { columnsTable } from '../commons/constants/columnsTable';
import { PaginationUrlOptions } from '../commons/enums/GenerarCurvas.enum';
import { OptionsCheckUser } from '../interfaces/OptionsCheckUser.interface';
import { Area } from '../interfaces/area';
import { herramienta } from '../interfaces/herramienta.interface';
import { Parcela2 } from '../interfaces/parcela';
import { Producto } from '../interfaces/producto';

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  constructor(private http: HttpClient) {}

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  /** FORMAT */
  /**
   * Función para crear los objetos de tipo Producto
   * @param productos Todos los productos
   * @param productosCliente Productos que tiene el cliente
   * @returns Array de productos
   */
  formatearProductos(productos, productosCliente): Array<Producto> {
    var productosFormateados: Array<Producto> = [];
    for (let index = 0; index < productos.length; index++) {
      var newProducto: Producto = {
        nombre: productos[index].nombre,
        agrupacion: productos[index].agrupacion,
        id: productosCliente.find((el) => el.nombre == productos[index].nombre)
          ? productosCliente.find((el) => el.nombre == productos[index].nombre)
              .id
          : null,
        check: productosCliente.find(
          (el) => el.nombre == productos[index].nombre && el.activo
        )
          ? true
          : false,
        titulo: productosCliente.find(
          (el) => el.nombre == productos[index].nombre
        )
          ? productosCliente.find((el) => el.nombre == productos[index].nombre)
              .titulo
          : productos[index].titulo,
        titulo_english: productosCliente.find(
          (el) => el.nombre == productos[index].nombre
        )
          ? productosCliente.find((el) => el.nombre == productos[index].nombre)
              .titulo_english
          : productos[index].titulo_english,
        titulo_portuguese: productosCliente.find(
          (el) => el.nombre == productos[index].nombre
        )
          ? productosCliente.find((el) => el.nombre == productos[index].nombre)
              .titulo_portuguese
          : productos[index].titulo_portuguese,
        fk_provider: productos[index].fk_provider_id,
        cultivos: productosCliente.find(
          (el) => el.nombre == productos[index].nombre
        )
          ? productosCliente.find((el) => el.nombre == productos[index].nombre)
              .cultivos
          : productos[index].cultivos,
        pixel_2: productosCliente.find(
          (el) => el.nombre == productos[index].nombre
        )
          ? productosCliente.find((el) => el.nombre == productos[index].nombre)
              .pixel_2
          : productos[index].pixel_2,
      };

      productosFormateados.push(newProducto);
    }
    return productosFormateados;
  }

  /**
   * Función para crear las herramientas con su interfaz
   * @param herramientas array de strings con las herramientas
   * @returns array de herramientas
   */
  formatearHerramientas(herramientas, herramientasCliente): Array<herramienta> {
    var herramientasFormateados: Array<herramienta> = [];

    if (!herramientasCliente) {
      herramientasCliente = [];
    }
    for (let index = 0; index < herramientas.length; index++) {
      var newHerramienta: herramienta = {
        nombre: herramientas[index].name,
        icono: herramientas[index].icon,
        check: herramientasCliente.find((el) => el == herramientas[index].name)
          ? true
          : false,
      };

      herramientasFormateados.push(newHerramienta);
    }

    return herramientasFormateados;
  }

  /**
   * Función para crear el body de un producto que crea
   * @param producto Producto sobre el que se crea el body
   * @returns Devuelve el body para la petición
   */
  bodyProductoPOST(producto, language): string {
    var titulo = producto.titulo;
    if (language == 'english') {
      titulo = producto.titulo_english;
    } else if (language == 'portuguese') {
      titulo = producto.titulo_portuguese;
    }

    var body: any = {
      nombre: producto.nombre,
      titulo: titulo,
      agrupacion: producto.agrupacion ? producto.agrupacion : 'otros',
      activo: producto.check,
      fk_provider: producto.fk_provider,
      cultivos: producto.cultivos,
      pixel_2: producto.pixel_2,
    };

    return JSON.stringify(body);
  }

  /**
   * Función para crear el body de un producto que modifica
   * @param activo Campo que indica si el producto está activo o no
   * @returns Devuelve el body para la petición
   */
  bodyProductoPUT(activo) {
    var body = '{"activo":';

    if (activo == true) {
      body = body.concat('true');
    } else {
      body = body.concat('false');
    }

    body = body.concat('}');

    return body;
  }

  /**
   * Función que llama a las funciones que depende del producto lo crean o lo modifican para un área
   * @param productos Productos para las peticiones
   * @returns Las respuestas de las peticiones
   */
  peticionesProductos(configuraciones, productos, language, area) {
    let peticiones = [];
    let productsModified = [];
    let productsNew = [];

    if (configuraciones) {
      for (const key in configuraciones) {
        configuraciones[key].forEach((conf) => {
          if (productos?.some((producto) => conf.nombre === producto.nombre)) {
            productsModified.push({
              ...productos.find((producto) => conf.nombre === producto.nombre),
              check: conf.check,
            });
          } else if (conf.check == true) {
            productsNew.push(conf);
          }
        });
      }

      productsModified.forEach((producto) => {
        let body = this.bodyProductoPUT(producto.check);
        peticiones.push(this.editAreaProduct(producto.id, body));
      });

      productsNew.forEach((producto) => {
        let body = this.bodyProductoPOST(producto, language);
        peticiones.push(this.postAreaProduct(area.id, body));
      });
    }
    return peticiones;
  }

  /**
   * Función que transforma los datos en un objeto seleccionado
   * @param datos datos que llegan de la llamada
   * @param key datos a los que hace referencia
   * @returns objeto con los datos transformados
   */
  transformDatos(datos: Object[], key: string): Object[] {
    return datos.map((element) => {
      let dato = columnsTable[key].reduce((actualData, column) => {
        if (Object.keys(element).includes(column)) {
          actualData[column] = element[column];
        } else {
          let objects = Object.keys(element)
            .filter(
              (a) => typeof element[a] === 'object' && element[a] !== null
            )
            .sort((a, b) => {
              return a.includes('contacto') ? -1 : 1;
            });
          objects.forEach((label) => {
            if (
              Object.keys(element[label]).includes(column) &&
              actualData[column] === undefined
            ) {
              actualData[column] = element[label][column];
            }
          });
        }
        return actualData;
      }, {});
      if (element['fk_cliente']['id']) dato['id'] = element['fk_cliente']['id'];
      return dato;
    });
  }

  /**
   * Función para formatear los errores según si necesitan csv o no y como estructurarlos
   * @param error La respuesta de la llamada que ha dado error
   * @param tipo Tipo de error que se está pasando según el formato de la variable
   * @returns Devuelve un array de errores formateados para mostrar o descargar
   */
  formatearErrores(error, tipo): any {
    var formatedError: any = '';

    if (tipo == 1) {
      if (Array.isArray(error)) {
        var arrayErrores = [];
        error.map((value) => {
          if (typeof value === 'object') {
            for (const property in value) {
              value[property].map((er) => {
                arrayErrores.push({ ATRIBUTO: property, ERROR: er });
              });
            }
          } else if (typeof value === 'string') {
            arrayErrores.push({ ATRIBUTO: 'ERROR', ERROR: value });
          } else {
            arrayErrores.push({
              ATRIBUTO: 'Fallo al leer el error',
              ERROR:
                'No se ha podido leer el error, contacte con el equipo de desarrllo, puede ver el error en la consola.',
            });
          }
        });

        formatedError = this.downloadErrorsText(arrayErrores);
      }

      if (error?.type == 'FeatureCollection') {
        var arrayErrores = [];

        for (let index = 0; index < error.features.length; index++) {
          if (
            error.features[index].properties.errors.length > 0 &&
            typeof error.features[index].properties.errors != 'string'
          ) {
            for (
              let j = 0;
              j < error.features[index].properties.errors.length;
              j++
            ) {
              if (error.features[index].properties.id_) {
                var obj = {
                  id: error.features[index].properties.id_,
                  error: error.features[index].properties.errors[j],
                };
              } else {
                var obj = {
                  id: error.features[index].properties.id,
                  error: error.features[index].properties.errors[j],
                };
              }
              arrayErrores.push(obj);
            }
          } else {
            var obj = {
              id: error.features[index].properties.id,
              error: error.features[index].properties.errors,
            };

            arrayErrores.push(obj);
          }
        }
        formatedError = this.downloadErrorsText(arrayErrores);
      }
    } else if (tipo == 3) {
      var arrayErrores = [];

      for (let i = 0; i < error.length; i++) {
        if (error[i]) {
          var keys = Object.keys(error[i]);
          keys.forEach((key, index) => {
            var obj = {
              id: i,
              error: key,
              contexto: error[i][key],
            };
            arrayErrores.push(obj);
          });
        }
      }

      formatedError = this.downloadErrorsText(arrayErrores);
    } else {
      var arrayErrores = [];

      error.error.map((value) =>
        value.errors.map((error) =>
          arrayErrores.push({ id: value.id, error: error })
        )
      );

      formatedError = this.downloadErrorsText(arrayErrores);
    }

    return formatedError;
  }

  /**
   * Función que construye la estructura del objeto parcela
   * @param data Respuesta de la petición
   * @returns Array de parcelas
   */
  buildParcelas(data) {
    var parcelas: Array<Parcela2> = [];

    if (data.features) {
      for (let i = 0; i < data.features.length; i++) {
        var parcela: Parcela2 = new Parcela2();
        if (data.features[i].geometry)
          parcela['geometry'] = geojsonToWKT(data.features[i].geometry);
        for (const property in data.features[i].properties) {
          if (parcela[property] != 'zafra') {
            parcela[property] = data.features[i].properties[property];
          } else {
            if (parcela['zafra'] == null) {
              parcela[property] = 0;
            }
          }
        }
        parcelas.push(parcela);
      }
    }

    return parcelas;
  }

  /**
   * Construir el body para getAreasAll (en este caso filtra solo por cultivo)
   * @param filtros Filtros para las áreas (cultivo)
   * @returns Body de la petición
   */
  formatAreasFilters(filtros) {
    let formateado = `{}`;

    if (filtros.length > 0) formateado = `{"cultivo":"${filtros[0]}"}`;

    return formateado;
  }

  /**
   * Construir el body para postCurvasOptimas
   * @param curvas Curvas que quieres asignar a otro área
   * @returns Body de la petición
   */
  formatBodyAsignarCurvas(curvas) {
    let formateado = [];
    let devolver;

    curvas.map((value) => {
      let aux = [];
      aux.push(value.id);
      formateado = formateado.concat(aux);
    });

    devolver = `{"curvas":[${formateado}]}`;

    return devolver;
  }

  /**
   * Construir el body para deleteCurvasOptimas
   * @param curvas Curvas para borrar de un área
   * @returns Body de la petición
   */
  formatBodyBorrarCurvas(curvas) {
    let formateado = [];
    let devolver;
    curvas.map((value) => {
      formateado.push(value.id);
    });
    devolver = `{"id":[${formateado}]}`;
    return devolver;
  }

  /**
   * Función que determina si un área tiene polígonos o no
   * @param areaId área a comprobar
   * @returns si tiene polígonos o no
   */
  async tienePoligonos(areaId: number): Promise<boolean> {
    const datos = { atributo: ['id'], activo: true, limit: 3, filtro: '' };
    return await this.uniqueslimited(areaId, datos)
      .toPromise()
      .then((result: any[]) => result && result.length > 0)
      .catch((error) => false);
  }

  /**
   * Función que formatea el teléfono y lo devuelve perfecto
   * @param phone telefono con formato
   * @param country_code código del país al que pertenece
   * @returns teléfono perfecto
   */
  formatPhone(phone: string, country_code: string) {
    if (!phone || !country_code) return '';
    if (phone) {
      let country = telData.allCountries.find(
        (element) =>
          country_code &&
          element.iso2.toLowerCase() === country_code.toLowerCase()
      );

      var codigo = country ? country.dialCode.split(',')[0] || '' : undefined;
    }

    if (codigo.length > 0) {
      // encontrar el separador
      let separador = phone.split('').find((e) => !/[0-9]/.test(e)) || '';
      // eliminar el separador
      phone = phone.split(separador).join('');
      // juntar con el código
      phone = '+' + codigo + phone;
    }

    return phone;
  }

  formatAreaBody(area: Area) {
    let body: any = JSON.parse(JSON.stringify(area));
    body['titulo'] = area.nombre;
    body['id_label'] = 'id';
    body['unidad_01'] = 'unidad_01';
    body['unidad_02'] = 'unidad_02';
    body['unidad_03'] = 'unidad_03';
    body['unidad_04'] = 'unidad_04';
    body['unidad_05'] = 'unidad_05';
    body['agrupacion'] = 'idnax';
    return body;
  }

  /** DOWNLOADS */
  /**
   * Función que convierte los errores actuales a formato csv
   */
  downloadErrorsText(arrayErrores): Blob {
    let message: string = ``;

    for (var i = 0; i < arrayErrores.length; i++) {
      //construimos cabecera del csv
      if (i == 0) message += Object.keys(arrayErrores[i]).join(';') + '\n';
      //resto del contenido
      message +=
        Object.keys(arrayErrores[i])
          .map(function (key) {
            // Hay que quitarle los acentos a esta cadena pero es imposible
            return arrayErrores[i][key];
          })
          .join(';') + '\n';
    }

    // crear csv
    var blob = new Blob([message], { type: 'text/csv' });

    return blob;
  }

  /** ERRORES */
  /**
   * Show dialog about not load superuser data
   */
  mensajeErrorSuperuser(): void {
    Swal.fire({
      icon: 'error',
      title: '¡Superuser no cargado!',
    });
  }

  /** PETICIONES */
  /**
   * Función que crea un cliente en BD
   * @param user datos del cliente
   * @returns resultado de la acción
   */
  createClient(data: any): Observable<any> {
    return this.http.post(
      `${environment.databaseURL}/rest/clientes/requestgestor`,
      data,
      this.httpOptions
    );
  }

  /**
   * Function to create an area in a client
   * @param fk_cliente client id
   * @param area area data
   * @returns
   */
  crearArea(fk_cliente, area) {
    return this.http.post<Area>(
      `${environment.databaseURL}/rest/clientes/${fk_cliente}/areas`,
      area,
      this.httpOptions
    );
  }

  /**
   * Función para obtener todos los productos
   * @returns array de strings
   */
  getProductos() {
    return this.http.get<any[]>(
      `${environment.databaseURL}/rest/productosAll`,
      this.httpOptions
    );
  }

  /**
   * Function to get all areas from a client
   * @param cliente
   * @returns
   */
  getAreasCliente(cliente) {
    return this.http.get<JSON>(
      `${environment.databaseURL}/rest/clientes/${cliente}/areas`,
      this.httpOptions
    );
  }

  /**
   * Function to edit a client
   * @param client_id id
   * @param datos client data
   * @returns
   */
  editClient(client_id: number, datos: string) {
    return this.http.put(
      `${environment.databaseURL}/rest/clientes/${client_id}`,
      datos,
      this.httpOptions
    );
  }

  /**
   * Function to get products from an area
   * @param area
   * @returns
   */
  getAreaProducts(area: number): Observable<any[]> {
    return this.http.get<Producto[]>(
      `${environment.databaseURL}/rest/areas/${area}/productos/empty_date`
    );
  }

  /**
   * Function to edit a product from an area
   * @param producto_id id
   * @param datos product data
   * @returns
   */
  editAreaProduct(producto_id, datos) {
    return this.http.put(
      `${environment.databaseURL}/rest/productos/${producto_id}`,
      datos,
      this.httpOptions
    );
  }

  /**
   * Function to edit an area
   * @param area_id id
   * @param datos area data
   * @returns
   */
  editArea(area_id: number, datos: string) {
    return this.http.put(
      `${environment.databaseURL}/rest/areas/${area_id}`,
      datos,
      this.httpOptions
    );
  }

  /**
   * Function to add a product to an area
   * @param idArea id
   * @param datos product data
   * @returns
   */
  postAreaProduct(idArea, datos) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${idArea}/productos`,
      datos,
      this.httpOptions
    );
  }

  /**
   * Pedir todas las areas de la plataforma con posibilidad de filtrar
   * @param filtros Filtros que se aplican
   * @returns Áreas filtradas
   */
  getAreasAll(filtros) {
    return this.http.post(
      `${environment.databaseURL}/rest/areasAll`,
      filtros,
      this.httpOptions
    );
  }

  /**
   * Función que realiza la petición de la url que se pasa por parámetro
   * @param url url para realizar una petición
   * @param body posible filtro
   * @returns datos
   */
  getCurvasOptimasPage(options: PaginationUrlOptions, body?: any) {
    let { url, page, limit, orderBy } = options;
    let params = page
      ? limit
        ? orderBy
          ? `/${page}/${limit}/${orderBy}`
          : `/${page}/${limit}`
        : `/${page}`
      : '';
    return this.http.post<any>(
      `${environment.databaseURL}${
        url ? url : `/rest/curvasoptimaspage${params}`
      }`,
      body,
      this.httpOptions
    );
  }

  /**
   * Asignar Curvas a otro área
   * @param idArea Id del área al que se asignan las curvas
   * @param body Body de la petición
   * @returns Número de curvas asignadas correctamente
   */
  postCurvasOptimas(idArea, body) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${idArea}/curvasOptimas`,
      body,
      this.httpOptions
    );
  }

  /**
   * Borrar curvas de un área concreta
   * @param idArea Id del área sobre la que se borran las curvas
   * @param body Body de la petición
   * @returns Nada
   */
  deleteCurvasOptimas(idArea, body) {
    let jsonBorradas = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body,
    };

    return this.http.delete(
      `${environment.databaseURL}/rest/areas/${idArea}/curvasOptimas`,
      jsonBorradas
    );
  }

  /**
   * Función que pregunta a BD si existe el email o el user
   * @param options opciones de comprobación: user y email
   * @returns si ya existe el dato o no
   */
  async checkUserExists(options: OptionsCheckUser): Promise<boolean> {
    const { user, email } = options;
    let body = user ? { user: user } : email ? { email: email } : undefined;
    // no se ha enviado nada para comprobar
    if (!body) return false;
    return await this.http
      .post(
        `${environment.databaseURL}/rest/usuarios/check`,
        body,
        this.httpOptions
      )
      .toPromise()
      .then((result) => {
        // comprobamos resultado
        return typeof result === 'string' ? !result.includes('no') : false;
      })
      .catch((error) => {
        return false;
      });
  }

  /**
   * Función que registra un cliente en BD
   * @param user datos de usuario a registrar
   * @returns resultado de la acción
   */
  registerUser(user: Object): Observable<any> {
    return this.http.post(
      `${environment.databaseURL}/rest/clientes/request`,
      user
    );
  }

  uploadPolygons(areaId: number, body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/areas/${areaId}/parcelas/bulk_upload`,
      body,
      this.httpOptions
    );
  }

  editPolygons(body: any): Observable<any> {
    return this.http.put<any>(
      `${environment.databaseURL}/rest/parcelas/bulk_update`,
      body,
      this.httpOptions
    );
  }

  /**
   * Function to obtain a limited amount of uniques of an attribute from an ara
   * @param areaId id
   * @param datos body
   * @returns
   */
  uniqueslimited(areaId, datos) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${areaId}/uniqueslimited`,
      datos,
      this.httpOptions
    );
  }

  /**
   * Function to obtain uniques of an attribute from an area
   * @param areaId id
   * @param datos body
   * @returns
   */
  uniques(areaId, datos) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${areaId}/uniques`,
      datos,
      this.httpOptions
    );
  }

  /**
   * Function to finish an area
   * @param area_id id
   * @returns
   */
  finishArea(area_id) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${area_id}/finished`,
      null
    );
  }

  /**
   * Function to expire a client
   * @param id client id
   * @returns
   */
  expireClient(id: number) {
    return this.http.post(
      `${environment.databaseURL}/rest/clientes/${id}/expire`,
      null
    );
  }

  /**
   * Function to expire an area
   * @param id area id
   * @returns
   */
  expireArea(id: number) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${id}/expire`,
      null
    );
  }

  /**
   * Función que registra en la pasarela Stripe a un cliente
   * @param id del cliente a registrar en Stripe
   * @returns resultado de la acción
   */
  addToStripePayment(id: number) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/clientes/${id}/add_to_payment_processor/`,
      {}
    );
  }

  /**
   * Function to process files with compare shape
   * @param body files
   * @param areaId id
   * @returns
   */
  compareShape(areaId: number, body: any) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${areaId}/compareshape`,
      body
    );
  }

  /**
   * Function to process files with file to geojson
   * @param body files
   * @param areaId id
   * @returns
   */
  fileToGeojson(body: any) {
    return this.http.post(
      `${environment.databaseURL}/rest/fileToGeojson`,
      body
    );
  }

  /**
   * Function to process files with historic shape
   * @param body files
   * @param areaId id
   * @returns
   */
  historicShape(areaId: number, body: any) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${areaId}/historicshape`,
      body
    );
  }

  /**
   * Function to obtain parcelas data pagination
   * @param areaId id
   * @param filter body
   * @returns
   */
  getParcelasPage(areaId, filter) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/areas/${areaId}/parcelaspage`,
      filter
    );
  }
}
