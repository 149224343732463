<div class="p-3" id="container-new-alta">
  <h2>Nueva alta</h2>
  <div class="form-altas">
    <form [formGroup]="formAlta" (ngSubmit)="crearAlta()">
      <label style="width: 100%" class="py-3">
        Prioridad
        <ngx-slider
          [ngClass]="{ 'w-75': !showRange, 'w-100': showRange }"
          formControlName="priority"
          [options]="optionsSlider"
        ></ngx-slider>
      </label>
      <div class="py-3 w-100 d-flex">
        <label class="mr-1 w-50">
          Cliente
          <ng-select
            placeholder="Selecciona un cliente"
            [clearable]="false"
            (change)="loadAreasClient($event)"
          >
            <ng-option *ngFor="let client of clients" [value]="client.id">{{
              client.workspace
            }}</ng-option>
          </ng-select>
        </label>
        <label class="ml-1 w-50">
          Área
          <ng-select
            placeholder="Selecciona un área"
            [clearable]="false"
            formControlName="fk_area_id"
          >
            <ng-option
              *ngIf="
                !clientSelected || !areas[clientSelected];
                else areasSelect
              "
              [value]="null"
              >Selecciona primero un cliente</ng-option
            >
            <ng-template #areasSelect>
              <ng-option
                *ngFor="let area of areas[clientSelected]"
                [value]="area.id"
                >{{ area.nombre | titlecase }}</ng-option
              >
            </ng-template>
          </ng-select>
        </label>
      </div>

      <div class="py-3 d-flex w-100">
        <label class="mr-1 w-50">
          Fecha inicio
          <div id="date-container">
            <input
              [ngClass]="{
                'inputs form-control': true,
                'is-invalid':
                  formAlta.controls['date_start'].value >
                  formAlta.controls['date_end'].value
              }"
              placeholder="dd/MM/yyyy"
              input="date"
              style="cursor: pointer"
              (dateInput)="changeDateValue($event.value, 'date_start')"
              readonly
              [matDatepicker]="picker"
              (focus)="picker.open()"
              (click)="picker.open()"
              formControlName="date_start"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </label>

        <label class="ml-1 w-50">
          Fecha final
          <div id="date-container">
            <input
              [ngClass]="{
                'inputs form-control': true,
                'is-invalid':
                  formAlta.controls['date_start'].value >
                  formAlta.controls['date_end'].value
              }"
              placeholder="dd/MM/yyyy"
              input="date"
              style="cursor: pointer"
              (dateInput)="changeDateValue($event.value, 'date_end')"
              readonly
              [matDatepicker]="picker1"
              (focus)="picker1.open()"
              (click)="picker1.open()"
              formControlName="date_end"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>
          <div
            class="color-error"
            *ngIf="
              formAlta.controls['date_start'].value >
              formAlta.controls['date_end'].value
            "
          >
            Fecha final debe ser mayor que la de inicio
          </div>
        </label>
      </div>

      <div class="radios py-3">
        <label>
          Histórico
          <fieldset>
            <label>
              <input
                type="radio"
                name="historic"
                formControlName="historic"
                [value]="true"
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="historic"
                formControlName="historic"
                [value]="false"
              />
              No
            </label>
          </fieldset>
        </label>
        <label>
          Curvas generadas
          <fieldset>
            <label>
              <input
                type="radio"
                name="generate_curves"
                formControlName="generate_curves"
                [value]="true"
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="generate_curves"
                formControlName="generate_curves"
                [value]="false"
              />
              No
            </label>
          </fieldset>
        </label>
        <label>
          Puntos generados
          <fieldset>
            <label>
              <input
                type="radio"
                name="generate_points"
                formControlName="generate_points"
                [value]="true"
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="generate_points"
                formControlName="generate_points"
                [value]="false"
              />
              No
            </label>
          </fieldset>
        </label>
        <label>
          Subida de imágenes
          <fieldset>
            <label>
              <input
                type="radio"
                name="upload_image"
                formControlName="upload_image"
                [value]="true"
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="upload_image"
                formControlName="upload_image"
                [value]="false"
              />
              No
            </label>
          </fieldset>
        </label>
      </div>
      <div class="d-flex justify-content-center w-100" id="container-buttons">
        <button
          type="button"
          class="btn btn-sm btn-danger mx-1"
          (click)="closeDialog()"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-success mx-1"
          [disabled]="
            formAlta.invalid ||
            formAlta.controls['date_start'].value >
              formAlta.controls['date_end'].value
          "
        >
          Aceptar
        </button>
      </div>
    </form>
  </div>
</div>
