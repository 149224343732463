import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TilesService {
  constructor(private http: HttpClient) {}

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  /**
   * Función que pide los procesos sin terminar en la cola
   * @returns procesos en la cola
   */
  getProccessQueue(processed?: boolean) {
    return this.http.get<any>(
      `${environment.databaseURL}/rest/processing_areas_queues/?processed=${
        processed ? 'true' : 'false'
      }`
    );
  }

  /**
   * Aumenta la prioridad del proceso definido por el id en 1
   * @param id del proceso
   * @returns lista de procesos con las prioridades actualizadas
   */
  putPriorityOneUp(id: number) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/processing_areas_queues/${id}/priority_up/`,
      {}
    );
  }

  /**
   * Disminuye la prioridad del proceso definido por el id en 1
   * @param id del proceso
   * @returns lista de procesos con las prioridades actualizadas
   */
  putPriorityOneDown(id: number) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/processing_areas_queues/${id}/priority_down/`,
      {}
    );
  }

  /**
   * Sitúa el elemento definido por el id debajo del elemento definido por idNext
   * @param id del proceso a cambiar
   * @param idNext que se situaría encima | null si el proceso va a pasar a ser el 1º
   * @returns lista de procesos con las prioridades actualizadas
   */
  putPriorityNextTo(id: number, idNext: number) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/processing_areas_queues/${id}/put_next/`,
      { id: idNext }
    );
  }

  /**
   * Función que llama a la API para crear una nueva cola
   * @param data datos del valor de la cola
   * @returns estado de la operacion
   */
  createAlta(data: any) {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/processing_areas_queues/`,
      data
    );
  }

  /**
   * Borrar colas indicadas en ids
   * @param ids de las colas a eliminar
   * @returns lista de procesos tras los borrados
   */
  deleteCola(ids: number[]) {
    return this.http.delete<any>(
      `${environment.databaseURL}/rest/processing_areas_queues/`,
      { body: { id__in: ids } }
    );
  }
}
