import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-table',
  templateUrl: './upload-table.component.html',
  styleUrls: ['./upload-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class UploadTableComponent implements OnInit {
  @Input() data: any;
  @Input() step: number;
  @Input() expandible: boolean;
  @Input() columns: boolean;
  @Input() errorsFound: number;
  @Input() dataFound: number;

  @Output('discardParcela') discardParcelaEvent: EventEmitter<any> =
    new EventEmitter();
  @Output('discardErrors') discardErrorsEvent: EventEmitter<any> =
    new EventEmitter();

  expandedElement: any = null;
  tableData: any;
  pageIndex: number = 0;

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild(MatTable) table: MatTable<any>;

  constructor() {}

  ngOnInit(): void {
    this.cleanData();
    this.tableData = this.getData(this.pageIndex, 5);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.data = changes.data.currentValue;
      this.cleanData();
      this.tableData = [...this.getData(this.pageIndex, 5)];
      this.table?.renderRows();
    }
  }

  /**
   * Function for expanding an element
   * @param element
   */
  expandElement(element: any) {
    if (this.expandible && this.expandedElement != element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

  /**
   * Function for changing the page on the table
   * @param event
   */
  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.tableData = this.getData(this.pageIndex, 5);
  }

  /**
   * Function to emit the output for discard a parcela with error
   * @param id
   */
  discardParcela(id: any) {
    this.discardParcelaEvent.emit(id);
  }

  /**
   * Function to emit the output for discard all parcelas with error
   * @param id
   */
  discardErrors() {
    this.discardErrorsEvent.emit();
  }

  /**
   * Function for downloading a csv with the errors
   */
  downloadErrors() {
    let message: string = `id;error\n`;

    // fill csv
    this.data.forEach((element: any) => {
      element.details.forEach((detail: string) => {
        message += element.id + ';' + detail + '\n';
      });
    });

    // crear csv
    var blob = new Blob([message], { type: 'text/csv' });
    saveAs(blob, `detallesErrores.csv`);
  }

  /**
   * Function to paginate the data of the table
   * @param page
   * @param limit
   * @returns
   */
  private getData(page, limit): any {
    let ind1: number = page * limit;
    let ind2: number = ind1 + limit;
    return this.data.slice(ind1, ind2);
  }

  canExpand(row: any): boolean {
    return row.details && row.details.length > 0;
  }

  cleanData() {
    this.data = this.data.filter((item) => item.details.length > 0);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
