<div class="grid-tiles">
  <div class="container-button">
    <button
      [ngClass]="{ button: true, 'button-selected': viewProcessed }"
      (click)="viewProcessed = !viewProcessed; loadProcesses()"
    >
      Procesadas
    </button>
    <button class="button" (click)="openFormNewAlta()">
      <i class="fas fa-plus"></i> Nueva alta
    </button>
  </div>
  <div
    fxFlex
    fxLayout="row wrap"
    class="table-responsive responsive-tile"
    style="position: relative"
  >
    <app-indeterminate-progress-bar
      *ngIf="loading"
    ></app-indeterminate-progress-bar>
    <div cdkScrollable fxFlex="100" class="mat-elevation-z8 table-scroll card">
      <mat-table
        *ngIf="dataSource && dataSource.length > 0; else noResults"
        [dataSource]="dataSource"
        #dataTable
        [cdkDropList]="!viewProcessed && !editPriority"
        (cdkDropListDropped)="
          !viewProcessed && !editPriority && dropTable($event)
        "
        [trackBy]="trackById"
        fxFlex
      >
        <!-- NOMBRE AREA -->
        <ng-container *ngFor="let key of displayedColumns" [matColumnDef]="key">
          <mat-header-cell
            *matHeaderCellDef
            [ngClass]="{ 'sticky-row header-row': key === 'acciones' }"
          >
            {{ key | columnName }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; index as i"
            [ngClass]="{
              'mat-cell-priority': ['priority'].includes(key),
              processing: element['status'] === 1,
              'sticky-row': key === 'acciones'
            }"
          >
            <div
              *ngIf="
                key === 'priority' &&
                  editPriority &&
                  i === editRow &&
                  !viewProcessed;
                else actionsTable
              "
            >
              <input
                type="number"
                class="input"
                [(ngModel)]="inputPriority"
                min="1"
                [max]="dataSource.length"
                (keyup.enter)="changePriorityInput($event, i)"
              />
              <i
                *ngIf="inputPriority && inputPriority !== element[key]"
                class="fas fa-paper-plane"
                style="margin-left: 0.5em; cursor: pointer; display: inline"
                (click)="changePriorityInput($event, i)"
                title="Cambiar"
              ></i>
              <i
                class="fas fa-times"
                style="margin-left: 0.5em; cursor: pointer"
                title="Cancelar"
                (click)="changePriorityInput($event, i, true)"
              ></i>
            </div>
            <ng-template #actionsTable>
              <div *ngIf="key === 'acciones'; else noEdit">
                <i
                  class="fas fa-trash delete-icon"
                  title="Eliminar cola"
                  (click)="deleteCola(element)"
                ></i>
              </div>
            </ng-template>
            <ng-template #noEdit>
              <div
                [ngSwitch]="typeofColumns[key]"
                class="w-100"
                style="text-align: center; display: inline"
              >
                <ng-template [ngSwitchCase]="undefined">
                  <!-- undefined values -->
                  {{ element[key] || "-" }}
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumns.BOOLEAN">
                  <!-- boolean values -->
                  <i
                    class="fas fa-check"
                    [ngClass]="{
                      fa: true,
                      'fa-check': element[key],
                      'fa-times': !element[key]
                    }"
                  ></i>
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumns.DATE">
                  <!-- date values -->
                  {{ element[key] | date : "dd/MM/yyyy" }}
                </ng-template>
                <ng-template [ngSwitchCase]="typesInColumns.OTHER">
                  <!-- PROGRESS values -->
                  <div class="progress" style="width: 100%">
                    <div
                      [ngClass]="{
                        'progress-bar progress-bar-striped progress-bar-animated': true,
                        'bg-cyan': element[key] > 40 && element[key] <= 80,
                        'bg-warning': element[key] <= 40,
                        'bg-success': element[key] > 80
                      }"
                      [ngStyle]="{ width: (element[key] || 0) + '%' }"
                    ></div>
                  </div>
                </ng-template>
                <ng-template [ngSwitchDefault]>
                  <!-- default values -->
                  {{ element[key] || "-" }}
                </ng-template>
              </div>
              <i
                *ngIf="key === 'priority'"
                class="fas fa-edit"
                style="margin-left: 0.25em; cursor: pointer"
                title="Cambiar prioridad"
                (click)="changeEditMode($event, i)"
              ></i>
              <span
                *ngIf="
                  key === 'processed_pctg' ||
                  key === 'processed_tiles_pctg' ||
                  key === 'downloaded_pctg'
                "
                style="margin-left: 0.25em"
                >{{ element[key] || 0 | number : "1.0-2" }} %</span
              >
            </ng-template>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky"
        ></mat-header-row>
        <mat-row
          [ngClass]="{ processing: row['status'] === 1 }"
          cdkDrag
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
        <div *cdkDragPlaceholder></div>
      </mat-table>
      <ng-template #noResults>
        <div *ngIf="!loading" class="messageNoResults">
          {{
            !viewProcessed
              ? "No hay elementos en la cola"
              : "No hay elementos procesados"
          }}
        </div>
      </ng-template>
    </div>
  </div>
  <div id="legend-altas" *ngIf="!viewProcessed">
    <div class="casilla-processing"></div>
    Procesando
  </div>
</div>
