import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AltasAreaState } from "./altas-area.state";

export const ALTAS_AREA_STATE_NAME = 'altas_area';

const getAltasAreaState = createFeatureSelector<AltasAreaState>(ALTAS_AREA_STATE_NAME);

export const getPaginationAltasAreas = createSelector(getAltasAreaState, (state) => {
  return state?.pagination;
});

export const getTotalObjectsAltasArea = createSelector(getAltasAreaState, (state) => {
  return state?.total_altas;
});

export const getLoadingAltasArea = createSelector(getAltasAreaState, (state) => {
  return state?.loading || false;
});

export const getLoadingTableAltasArea = createSelector(getAltasAreaState, (state) => {
  return state?.loadingTable || false;
});

export const getFilterAltasArea = createSelector(getAltasAreaState, (state) => {
  return state?.actualFilter || null;
});
