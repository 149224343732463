import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indeterminate-progress-bar',
  templateUrl: './indeterminate-progress-bar.component.html',
  styleUrls: ['./indeterminate-progress-bar.component.scss'],
})
export class IndeterminateProgressBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
