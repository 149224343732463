import { createReducer, on } from '@ngrx/store';
import {
  changeKeyValueAltas,
  getNotProcessingAreasSuccess,
  getNotProcessingClientsSuccess,
  loadAltasFilters,
  loadStatisticsSuccess,
  openedSidebar,
} from './altas.actions';
import { initialStateAltas, statistic_element } from './altas.state';

const _AltasReducer = createReducer(
  initialStateAltas,
  on(loadStatisticsSuccess, (state, action) => {
    //let aux_data = state.data ? Object.assign(state.data) : []
    let aux_data = state.data ? [...state.data] : [];
    action.data.forEach((element) => {
      let find = aux_data.findIndex(
        (el: statistic_element) => el.id == element.id
      );
      if (find >= 0) {
        aux_data[find] = element;
      } else {
        aux_data = [...aux_data, element];
      }
    });

    return {
      ...state,
      data: aux_data,
    };
  }),
  on(loadAltasFilters, (state, action) => {
    return {
      ...state,
      activeFilters: action.filters,
    };
  }),
  on(changeKeyValueAltas, (state, { key, value }) => {
    return {
      ...state,
      [key]: value,
    };
  }),
  on(getNotProcessingClientsSuccess, (state, action) => {
    return {
      ...state,
      not_processing_clients: action.data,
    };
  }),
  on(getNotProcessingAreasSuccess, (state, action) => {
    return {
      ...state,
      not_processing_areas: action.data,
    };
  }),
  on(openedSidebar, (state, action) => {
    return {
      ...state,
      status_Sidebar: action.opened,
    };
  })
);

export function AltasReducer(state, action) {
  return _AltasReducer(state, action);
}
