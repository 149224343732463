import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as JSZip from 'jszip';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/Common.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  editPolygons,
  editPolygonsSuccess,
  finishArea,
  finishAreaSuccess,
  parcelasPage,
  parcelasPageSuccess,
  proccessFilesSuccess,
  processCompareShape,
  processFileToGeojson,
  processHistoricShape,
  uploadPolygons,
  uploadPolygonsSuccess,
} from '../state/editor.actions';
import { getEditorLoader } from '../state/editor.selector';

interface elementResumen {
  title: string;
  value: number;
}

@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss'],
})
export class UploadDataComponent implements OnInit {
  @Input('area') area;
  @Input('client') client;

  @Output('finishedArea') finishedArea: EventEmitter<any> = new EventEmitter();

  resumen: Array<elementResumen>;
  proccessResult: any;
  step: number = 0;
  editorLoader: Observable<{ id: string; message: string; value: boolean }>;

  dragover = false;
  dragoverH = false;
  files: File[] = [];

  dataTable: any[] = [];
  dataColumns: string[] = [];
  dataFound: number | null;
  errorsFound: number | null;

  addCompare: Array<string> = [
    'añadir',
    'sustituir area completa',
    'sustituir shape',
  ];
  addCompareOption: string = 'anyadir';
  historic: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(ofType(proccessFilesSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value.result && !value.historic) {
          this.proccessResult = value.result;
          // Calculate number of objects and errors
          this.calculateAnalytics();

          this.step = 1;

          // Format errors
          this.formatErrorData(this.proccessResult.features, true);
        } else {
          this.proccessResult = null;
          this.step = 0;
        }
      });

    this.actions
      .pipe(ofType(editPolygonsSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) {
          Swal.fire({
            icon: 'success',
            title: 'Polígonos editados correctamente',
          });
          this.clear();
          this.finishedArea.emit(true);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Fallo al subir los polígonos',
          });
          this.step = 2;
        }
      });

    this.actions
      .pipe(ofType(uploadPolygonsSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value && this.area.terminado == false) {
          this.store.dispatch(
            finishArea({
              areaId: this.area.id,
              clienteId: this.area.fk_cliente,
            })
          );
          this.actions
            .pipe(ofType(finishAreaSuccess), takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
              Swal.fire({
                icon: 'success',
                title: 'Polígonos añadidos correctamente',
              });
              this.clear();
              this.finishedArea.emit(true);
            });
        }

        if (value && this.area.terminado == null) {
          Swal.fire({
            icon: 'success',
            title: 'Polígonos subidos correctamente',
          });
          this.clear();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Fallo al subir los polígonos',
          });
          this.step = 2;
        }
      });

    this.editorLoader = this.store.select(getEditorLoader);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('area' in changes && this.area) {
      this.store.dispatch(parcelasPage({ area: this.area }));
      this.actions
        .pipe(ofType(parcelasPageSuccess), takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          if (value) {
            this.resumen = [
              { title: 'Parcelas activas', value: value.parcelas.par_activas },
              {
                title: 'Ha. activas Sentinel',
                value: this.area.ha_activas_tot ?? 0,
              },
              {
                title: 'Ha. disponibles Sentinel',
                value:
                  this.client.ha_contrat_sent - this.client.ha_activas_sent,
              },
              {
                title: 'Parcelas activas Planet',
                value: value.parcelas.par_activas_planet,
              },
              {
                title: 'Ha. activas Planet',
                value: this.area.ha_activas_planet ?? 0,
              },
              {
                title: 'Ha. disponibles Planet',
                value: this.client.ha_contrat - this.client.ha_activas,
              },
            ];
          }
        });
    }
  }

  /** Navigation */
  /**
   * Function to navigate backwards
   */
  goBack() {
    switch (this.step) {
      case 1:
        this.step = 0;
        this.clear();
        break;
      case 2:
        this.step = 1;
        this.formatErrorData(this.proccessResult.features, false);
        break;
      case 3:
        this.step = 2;
        break;
    }
  }

  /**
   * Function to navigate forward
   */
  async forward(stepper: MatStepper) {
    switch (this.step) {
      case 1:
        this.step = 2;
        this.formatAttributesData(this.proccessResult.features);
        break;
      case 2:
        const inputOptionsCreated = {};

        const statusValues = [null, 0, 1, 2, 3];
        for (const status of statusValues) {
          const hasStatus = this.proccessResult.features.some(
            (feature) => feature.properties.status === status
          );
          if (hasStatus) {
            if (status === null || status === 1 || status === 2) {
              inputOptionsCreated['anyadir'] = 'Añadir';
            }
            if (status === 0 || status === 1 || status === 2 || status === 3) {
              inputOptionsCreated['sus_comp'] = 'Sustituir area completa';
            }
            if (status === 0 || status === 1 || status === 2) {
              inputOptionsCreated['sus_shp'] = 'Sustituir shape';
            }
          }
        }

        if (this.area.terminado == null) {
          await Swal.fire({
            title: 'Selecciona el tipo de subida',
            input: 'select',
            inputOptions: inputOptionsCreated,
            inputPlaceholder: 'Tipo de subida',
            showCancelButton: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  this.addCompareOption = value.toString();
                  stepper.next();
                  this.step = 3;
                  this.sendPolygons(this.proccessResult);
                  Swal.close();
                } else {
                  resolve('Tienes que elegir un tipo de subida');
                }
              });
            },
          }).then((result) => {
            if (result.isDismissed) {
              this.step = 2;
            }
          });
        } else {
          stepper.next();
          this.step = 3;
          this.sendPolygons(this.proccessResult);
        }
        break;
    }
  }

  /** Inputs */
  /**
   * Dragover file event function
   * @param event
   * @param type
   */
  onDragOver(event: DragEvent, type: string) {
    event.preventDefault();
    type == 'actual' ? (this.dragover = true) : (this.dragoverH = true);
  }

  /**
   * Dragleave file event function
   * @param event
   * @param type
   */
  onDragLeave(event: DragEvent, type: string) {
    event.preventDefault();
    type == 'actual' ? (this.dragover = false) : (this.dragoverH = false);
  }

  /**
   * Drop file event function
   * @param event
   * @param type
   */
  onDrop(event: DragEvent, type: string) {
    event.preventDefault();
    type == 'actual' ? (this.dragover = false) : (this.dragoverH = false);
    this.files = [];

    const dataTransfer = event.dataTransfer;
    if (dataTransfer && dataTransfer.files) {
      for (let i = 0; i < dataTransfer.files.length; i++) {
        this.files.push(dataTransfer.files[i]);
      }
    }

    // Create form
    this.createForm(this.files, type);
  }

  /**
   * Select file event function
   * @param event
   * @param type
   */
  onFileSelected(event: Event, type: string) {
    Array.from(event.target['files']).forEach((file: File) => {
      this.files.push(file);
    });
    // Create form
    this.createForm(this.files, type);
  }

  /** Process */
  /**
   * Function for creating the formData sent to the server
   * @param files
   * @param type
   */
  async createForm(files: File[], type: string) {
    let form = new FormData();
    const promises = files.map((file) => {
      switch (file.type.split('/')[1]) {
        case 'csv':
          form.append('csv', file);
          break;
        case 'zip':
          // Cargar el archivo zip
          return JSZip.loadAsync(file).then((zip) => {
            // Iterar sobre los archivos dentro del zip
            Object.keys(zip.files).forEach(async (filename) => {
              // Obtener el tipo de archivo utilizando la extensión del nombre de archivo
              const fileType = filename.split('.').pop();
              // Añadir el archivo
              fileType == 'shp' ? form.append('shapefile', file) : null;
              fileType == 'kml' ? form.append('kml', file) : null;
            });
          });
      }
    });

    await Promise.all(promises);

    // Process files
    this.processFiles(form, type);
  }

  /**
   * Function to process files depending on the type (compare shape, file to geojson or historic shape)
   * @param form
   * @param type
   */
  processFiles(form: FormData, type: string) {
    if (type == 'actual') {
      if (this.area.some_field) {
        // Compare shape
        this.store.dispatch(
          processCompareShape({ areaId: this.area.id, data: form })
        );
      } else {
        // FileToGeojson
        this.store.dispatch(processFileToGeojson({ data: form }));
      }
    } else if (type == 'historico') {
      // Historic endpoint processHistoricShape
      this.store.dispatch(
        processHistoricShape({ areaId: this.area.id, data: form })
      );
    }
    let a: any = this.commonService.loadSwalSpinner('Procesando archivos');
    this.files = [];
  }

  /**
   * Function to discard a parcela that has an error
   * @param event
   */
  discardParcela(event: any) {
    let newProcess: any = JSON.parse(JSON.stringify(this.proccessResult));
    newProcess.features = newProcess.features.filter(
      (el: any) => el.properties.id != event
    );
    this.proccessResult = newProcess;
    this.formatErrorData(this.proccessResult.features, false);
  }

  /**
   * Function to discard all parcelas with errors
   */
  discardErrors() {
    let newProcess: any = JSON.parse(JSON.stringify(this.proccessResult));
    newProcess.features = newProcess.features.filter(
      (el: any) => !el.properties.errors
    );
    this.proccessResult = newProcess;
    if (this.proccessResult.features?.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'No hay datos suficientes.',
        text: 'Se han descartado todas las parcelas.',
      });
      this.step = 0;
    } else {
      this.formatErrorData(this.proccessResult.features, false);
    }
  }

  sendPolygons(data: any) {
    const { features } = data;
    const upload = { ...data, features: [] };
    const update = { ...data, features: [] };

    for (let i = 0, len = features.length; i < len; i++) {
      const { status, errors } = features[i].properties;
      if (!errors) {
        switch (this.addCompareOption) {
          case 'anyadir':
            if (status === 1) {
              update.features.push(features[i]);
            } else if (!status || status === 2) {
              upload.features.push(features[i]);
            }
            break;
          case 'sus_comp':
            if (status === 0 || status === 1 || status === 3) {
              update.features.push(features[i]);
            } else if (status === 2) {
              upload.features.push(features[i]);
            }
            break;
          case 'sus_shp':
            if (status === 0 || status === 1) {
              update.features.push(features[i]);
            } else if (status === 2) {
              upload.features.push(features[i]);
            }
            break;
        }
      }
    }

    update.features?.length > 0
      ? this.store.dispatch(
          editPolygons({ areaId: this.area.id, update: update, upload: upload })
        )
      : this.store.dispatch(
          uploadPolygons({ areaId: this.area.id, upload: upload })
        );
  }

  /** Format */
  /**
   * Function to extract the errors info
   * @param data features
   */
  formatErrorData(data: any, forward: boolean) {
    this.dataTable = [];
    this.dataColumns = ['id', 'errores'];
    data?.forEach((element) => {
      element.properties.errors &&
        this.dataTable.push({
          id: element.properties.id,
          errores: element.properties.errors.length,
          details: element.properties.errors,
        });
    });
    if (this.dataTable.length == 0) {
      if (forward) {
        this.step = 2;
        this.formatAttributesData(this.proccessResult.features);
      } else {
        this.step = 0;
        this.clear();
      }
    }
  }

  /**
   * Function to extract the attributes info
   * @param data features
   */
  formatAttributesData(data: any) {
    this.dataTable = [];
    this.dataColumns = ['attribute', 'parcelas', 'details'];
    if (data) {
      let attributes = Object.keys(data[0].properties).filter(
        (el: string) => el != 'errors'
      );
      if (data.some((el: any) => !el.properties.errors)) {
        attributes.forEach((element) => {
          this.dataTable.push({
            attribute: element,
            parcelas: data.filter(
              (el: any) => el.properties[element] && !el.properties.errors
            ).length,
            details: [
              ...new Set(
                data
                  .filter((el: any) => !el.properties.errors)
                  .map((el: any) => el.properties[element])
                  .filter((el: any) => el)
              ),
            ],
          });
        });
      }
    }

    if (this.dataTable.length == 0 && !this.historic) {
      Swal.fire({
        icon: 'error',
        title: 'No hay datos suficientes.',
        text: 'No existen parcelas sin errores.',
      });
      // this.step = 0;
      this.clear();
    }
  }

  /**
   * Function to calculate the total objects and the total errors
   */
  calculateAnalytics() {
    // Total of objects
    this.dataFound = this.proccessResult.features?.length;

    // Errors
    this.errorsFound = this.proccessResult.features?.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.properties.errors
          ? currentValue.properties.errors.length
          : 0),
      0
    );
  }

  /** Destroy */
  clear() {
    this.step = 0;

    this.proccessResult = null;
    this.files = [];

    this.dataTable = [];
    this.dataColumns = [];
    this.dataFound = null;
    this.errorsFound = null;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
