import { createAction, props } from "@ngrx/store";
import { AltaArea } from "src/app/interfaces/AltasArea.interface";
import { AbstractPagination } from "src/app/interfaces/Pagination.interface";
import { labelsStateAltasArea } from "./altas-area.state";

export const LOAD_ALTAS_AREAS_DATA = '[altas area page] load altas area data';
export const loadAltasAreasData = createAction(
  LOAD_ALTAS_AREAS_DATA,
  props<{ page?: number, limit?: number, orderby?: string, url?: string, filter?: { filtro: Object, extra_filt?: Object }, workspace?: string }>()
)

export const LOAD_ALTAS_AREA_OBJECTS = '[altas area page] load altas area objects';
export const loadAltasAreasObjects = createAction(
  LOAD_ALTAS_AREA_OBJECTS
)

export const LOAD_ALTAS_AREAS_DATA_SUCCESS = '[altas area page] load altas area data success';
export const loadDataAreasDataSuccess = createAction(
  LOAD_ALTAS_AREAS_DATA_SUCCESS,
  props<{ pagination: AbstractPagination<AltaArea> | null }>()
)

export const SET_KEY_VALUE_ALTAS_AREAS = '[altas area page] set key value altas area';
export const setKeyValueAltasArea = createAction(
  SET_KEY_VALUE_ALTAS_AREAS,
  props<{ key: labelsStateAltasArea, value?: any }>()
)

export const RESET_ALTAS_AREAS = '[altas area page] reset altas area';
export const resetAltasArea = createAction(
  RESET_ALTAS_AREAS
)
