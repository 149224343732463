<div [ngClass]="{ 'height-container': !loading }">
  <div class="title-container">
    <div>
      <button
        class="btn btn-sm btn-primary"
        [disabled]="loading"
        (click)="reloadProducts()"
      >
        <span>{{ !loading ? "Actualizar" : "Cargando..." }}</span>
        <mat-icon [inline]>refresh</mat-icon>
      </button>
    </div>
    <h2 mat-dialog-title ID="title-products-dialog">{{ data.title }}</h2>
    <div></div>
  </div>
  <div
    mat-dialog-content
    class="dialog-container"
    *ngIf="!loading; else loadingProducts"
  >
    <mat-radio-group
      aria-label="Select an option"
      [(ngModel)]="radioButtonInput"
      (change)="changeViewList($event.value)"
    >
      <mat-radio-button [value]="true">Terminado</mat-radio-button>
      <mat-radio-button [value]="false">No terminado</mat-radio-button>
    </mat-radio-group>
    <section
      class="main-products"
      *ngIf="productsFinished?.length; else noElements"
    >
      <div class="grid-content title">
        <div class="products-column-title" (click)="selectedOrderBy('name')">
          <span>Nombre</span>
          <ng-container *ngIf="orderBy?.attribute === 'name'">
            <mat-icon>{{
              orderBy.mode === OrderByMode.ASC
                ? "arrow_drop_down"
                : "arrow_drop_up"
            }}</mat-icon>
          </ng-container>
        </div>
        <div
          class="products-column-title"
          (click)="selectedOrderBy('clouds_ptge')"
        >
          <mat-icon class="mr-2">cloud</mat-icon>
          <span>% nubes</span>
          <ng-container *ngIf="orderBy?.attribute === 'clouds_ptge'">
            <mat-icon>{{
              orderBy.mode === OrderByMode.ASC
                ? "arrow_drop_down"
                : "arrow_drop_up"
            }}</mat-icon>
          </ng-container>
        </div>
      </div>
      <div class="products-list-content" #productsList>
        <ng-container
          *ngFor="let product of productsInView; trackBy: trackByName"
        >
          <div
            [ngClass]="{
              'grid-content': true,
              finished: radioButtonInput,
              'not-finished': !radioButtonInput,
              expandible: product['error_log']
            }"
            #containerProduct
            (click)="changeErrorVisibility(containerProduct)"
          >
            <div class="products-column-title">
              <span>{{ product["name"] }}</span>
            </div>
            <div class="products-column-title">
              <span>{{ (product["clouds_ptge"] | percent) || "-" }}</span>
            </div>
            <div class="products-column-title error-icon">
              <mat-icon *ngIf="product['error_log']">error</mat-icon>
            </div>
            <ng-container *ngIf="product['error_log']">
              <div
                class="products-error"
                #errorMessage
                [id]="idErrorMessages"
                style="display: none"
              >
                <span
                  id="all-error-message"
                  class="error-message"
                  style="display: none"
                  >{{ product["error_log"] }}</span
                >
                <span class="error-message">{{
                  product["error_log"] | slice : 0 : 100
                }}</span>
                <ng-container *ngIf="product['error_log'].length > 100">
                  <span class="error-message">...</span>
                  <a
                    class="ml-2 link-danger"
                    (click)="
                      seeAllError(errorMessage); $event.stopPropagation()
                    "
                    >Ver más</a
                  >
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</div>

<ng-template #loadingProducts>
  <div class="loading-products-container">
    <mat-spinner></mat-spinner>
    <span>{{ text }}</span>
  </div>
</ng-template>

<ng-template #noElements>
  <div class="message-no-elements">
    <span>No hay elementos</span>
  </div>
</ng-template>
