import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AltasState } from './altas.state';

export const ALTAS_STATE_NAME = 'altas';

const getAltasState = createFeatureSelector<AltasState>(ALTAS_STATE_NAME);

export const getStatisticsData = createSelector(getAltasState, (state) => {
  return state ? state.data : null;
});

export const getAltasActiveFilters = createSelector(getAltasState, (state) => {
  return state ? state.activeFilters : null;
});

export const getPaginationAltas = createSelector(getAltasState, (state) => {
  return state ? state.pagination : null;
});

export const getLoadingData = createSelector(getAltasState, (state) => {
  return state?.loadingData;
});

export const getNotProcessingData = (not_processing_type: string) =>
  createSelector(getAltasState, (state) => {
    return state ? state['not_processing_' + not_processing_type] : null;
  });

export const getStatusSidebar = createSelector(getAltasState, (state) => {
  return state?.status_Sidebar;
});
