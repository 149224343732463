<h3 mat-dialog-title>Descargar {{ data.title  }}</h3>
<div mat-dialog-content>
  <p>Elija las columnas a descargar</p>
  <div class="container-form">
    <mat-radio-group [(ngModel)]="valueRadio" (ngModelChange)="changeSelectedColumns()">
      <mat-radio-button [value]="false">Por defecto</mat-radio-button>
    <mat-radio-button [value]="true" [disabled]="!columns?.length">Personalizada</mat-radio-button>
    </mat-radio-group>
    <div class="container-checklist">
      <ng-container *ngFor="let column of columns; index as i; last as isLast">
        <div [ngClass]="{'container-row-checklist': true, 'disabled-container-row-checklist': !valueRadio}"
        (click)="valueRadio && addSelectedColumn(column)">
          <mat-checkbox [disabled]="!valueRadio" [checked]="selectedColumns.includes(column)" (change)="addSelectedColumn(column)" (click)="$event.stopPropagation()">
            <span (click)="$event.stopPropagation()">{{ namesColumns[i] | titlecase }}</span>
          </mat-checkbox>
        </div>
        <mat-divider *ngIf="!isLast"></mat-divider>
      </ng-container>
    </div>
    <div class="container-button">
      <button class="btn btn-success" (click)="sendColumns()">Descargar</button>
      <button class="btn btn-danger" (click)="closeDialog()">Cancelar</button>
    </div>
  </div>
</div>
