import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthLayoutComponent } from '../layouts/auth-layout/auth-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ALTAS_STATE_NAME } from '../pages/admin/altas/state/altas.selector';
import { AltasReducer } from '../pages/admin/altas/state/altas.reducer';

@NgModule({
  declarations: [AuthLayoutComponent],
  exports: [AuthLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EffectsModule.forFeature([]),
    StoreModule.forFeature(ALTAS_STATE_NAME, AltasReducer),
  ],
})
export class AuthModule {}
