<div id="content">
  <h2>{{ title }}</h2>
  <h6>
    Una vez editado, todos los productos creados de {{ data.nombre }} tendrán
    estas configuraciones (títulos, cultivos, agrupación, etc). Los productos de
    {{ data.nombre }} que ya existen mantendrán las configuraciones que ya
    tenían.
  </h6>
  <p></p>
  <form [formGroup]="formNovedad" (ngSubmit)="prepararInfo()">
    <div>
      <div class="left">
        <label
          >TÍTULO EN CASTELLANO
          <input
            [ngClass]="{
              'is-invalid':
                formNovedad.get('titulo').invalid &&
                formNovedad.get('titulo').touched,
              'inputs form-control': true
            }"
            formControlName="titulo"
            placeholder="Titulo en castellano..."
          />
          <div
            *ngIf="
              formNovedad.get('titulo').invalid &&
              formNovedad.get('titulo').touched
            "
            class="invalid-feedback"
          >
            Campo requerido
          </div>
        </label>
        <label
          >TÍTULO EN PORTUGUÉS
          <input
            [ngClass]="{
              'is-invalid':
                formNovedad.get('titulo_portuguese').invalid &&
                formNovedad.get('titulo_portuguese').touched,
              'inputs form-control': true
            }"
            formControlName="titulo_portuguese"
            placeholder="Titulo en portugués..."
          />
          <div
            *ngIf="
              formNovedad.get('titulo_portuguese').invalid &&
              formNovedad.get('titulo_portuguese').touched
            "
            class="invalid-feedback"
          >
            Campo requerido
          </div>
        </label>
        <label
          >TÍTULO EN INGLÉS
          <input
            [ngClass]="{
              'is-invalid':
                formNovedad.get('titulo_english').invalid &&
                formNovedad.get('titulo_english').touched,
              'inputs form-control': true
            }"
            formControlName="titulo_english"
            placeholder="Titulo en inglés..."
          />
          <div
            *ngIf="
              formNovedad.get('titulo_english').invalid &&
              formNovedad.get('titulo_english').touched
            "
            class="invalid-feedback"
          >
            Campo requerido
          </div>
        </label>
        <label
          >AGRUPACIÓN
          <select
            formControlName="agrupacion"
            [ngClass]="{
              'is-invalid': formNovedad.get('agrupacion').invalid,
              'inputs form-control shadow-none': true
            }"
          >
            <option class="select2-selection" value="null" disabled>
              Selecciona una agrupacion
            </option>
            <option class="select2-selection" value="hidrico">Hídrico</option>
            <option class="select2-selection" value="desarrollo">
              Desarrollo
            </option>
            <option class="select2-selection" value="salud">Salud</option>
            <option class="select2-selection" value="produccion">
              Produccion
            </option>
            <option class="select2-selection" value="otros">Otros</option>
          </select>
        </label>
      </div>
      <div class="right">
        <label
          >PROVIDER
          <select
            formControlName="fk_provider_id"
            [ngClass]="{
              'is-invalid': formNovedad.get('fk_provider_id').invalid,
              'inputs form-control shadow-none': true
            }"
          >
            <option class="select2-selection" [value]="null" disabled>
              Selecciona un provider
            </option>
            <option
              class="select2-selection"
              *ngFor="let provider of providers"
              [value]="provider.id"
            >
              {{ provider.provider }}
            </option>
          </select>
        </label>
        <label
          >CULTIVOS
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="cultivos"
              formControlName="cultivos"
              [ngClass]="{
                'is-invalid': formNovedad.get('cultivos').invalid,
                inputs: true
              }"
              multiple
            >
              <mat-option
                [ngClass]="{
                  'color-check':
                    formNovedad.controls.cultivos.value &&
                    cultivos &&
                    formNovedad.controls.cultivos.value.length ===
                      cultivos.length,
                  'option-sticky': true
                }"
                (click)="selectAll()"
              >
                <i
                  [ngClass]="{
                    'fas fa-check-square':
                      formNovedad.controls.cultivos.value &&
                      cultivos &&
                      formNovedad.controls.cultivos.value.length ===
                        cultivos.length,
                    'far fa-square':
                      formNovedad.controls.cultivos.value &&
                      cultivos &&
                      formNovedad.controls.cultivos.value.length !==
                        cultivos.length,
                    'mr-2': true
                  }"
                ></i>
                {{
                  formNovedad.controls.cultivos.value &&
                  cultivos &&
                  formNovedad.controls.cultivos.value.length === cultivos.length
                    ? "Deseleccionar todos"
                    : "Seleccionar todos"
                }}
              </mat-option>
              <mat-option *ngFor="let cultivo of cultivos" [value]="cultivo">
                {{ cultivo }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                formNovedad.get('cultivos').invalid &&
                formNovedad.get('cultivos').touched
              "
              class="invalid-feedback"
            >
              Campo requerido
            </mat-error>
          </mat-form-field>
        </label>
        <label
          >RANGOS
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
              align-items: center;
            "
          >
            <input
              [ngClass]="{
                'is-invalid':
                  formNovedad.get('min').invalid &&
                  formNovedad.get('min').touched,
                'inputs form-control': true
              }"
              type="number"
              [max]="max"
              step="0.01"
              (change)="controlRangos(1)"
              formControlName="min"
              placeholder="Valor mínimo"
            />
            <input
              [ngClass]="{
                'is-invalid':
                  formNovedad.get('max').invalid &&
                  formNovedad.get('max').touched,
                'inputs form-control': true
              }"
              type="number"
              [min]="min"
              step="0.01"
              (change)="controlRangos(2)"
              formControlName="max"
              placeholder="Valor máximo"
            />
          </div>
        </label>
      </div>
    </div>
    <div id="buttons">
      <button type="button" (click)="closeTool()" class="btn btn-danger btn">
        Cancelar
      </button>
      <button
        type="submit"
        class="btn btn-success btn"
        [disabled]="formNovedad.invalid && !firstTouch"
      >
        Aceptar
      </button>
    </div>
  </form>
</div>
