import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Pagination } from 'src/app/interfaces/Pagination.interface';
import { IsButtonTable } from 'src/app/pipes/isButtonTable.pipe';
import { IsStatusColumnTable } from 'src/app/pipes/isStatusColumnTable.pipe';
import { AdminService } from 'src/app/services/admin.service';
import { PowerbiService } from 'src/app/services/powerbi.service';
import { AppState } from 'src/app/store/app.state';
import { getDataByUrl } from '../state/dashboard.actions';
import { selectTablas } from '../state/dashboard.selector';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss']
})
export class DashboardTableComponent implements OnInit {

  @Input() id:string;
  @Input() columns:string[];
  
  loading:boolean = true;
  private ngUnsubscribe: Subject<any> = new Subject();
  actualPage: number = 1;
  data: Pagination;
  datos: Object[];
  title: string = 'estadisticas';
  limit: number;
  objects: number;
  totalPages: number;
  orderedBy: string;
  isButtonPipe: IsButtonTable = new IsButtonTable();
  IsStatusColumnTable: IsStatusColumnTable = new IsStatusColumnTable();

  constructor(private adminService: AdminService, private store: Store<AppState>, private powerbiService: PowerbiService) { }

  ngOnInit(): void {
    this.id = this.id.replace(/\s/g, '_');
    this.columns && this.columns.length>0 ? this.orderedBy = this.columns[0] : '';

    this.store.select(selectTablas)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (value) => {
        if(value && value.some(t=>t.id==this.id)){
          let thisValue;
          value.map(t=>{if(t.id==this.id){thisValue = t.data; this.loading = t.loading;}})
          if(thisValue?.datos) {
            // actualizar número de página
            let corte = 6;
            if(thisValue.current.split('/').length != 9) corte = 3;
            let change = thisValue.current.split('/').slice(corte);
            // son los datos de la categoría actual
            this.actualPage = parseInt(change[0]);
  
            this.datos = this.adminService.transformDatos(thisValue.datos, 'clientes', false, 3);
            this.data = { ... thisValue }
  
            if(this.data.objects < 10) this.data.limit = this.data.objects;
  
            this.initData();
          } else {
            this.data = thisValue;
            this.datos = undefined;
          }
        }
      }
    );
  }

  /** Initialize pagination variables */
  initData() {
    if(this.data) {
      this.limit = this.data.limit;
      this.objects = this.data.objects;
      this.totalPages = this.data.total_pages;
    } else {
      this.datos = (!this.datos) ? [] : this.datos;
      this.limit = 0;
      this.objects = (!this.datos) ? 0 : this.datos.length;
      this.totalPages = (!this.datos) ? 0 : 1;
    }
  }

  /**
   * Change page
   * @param page page to change
   */
  changePage(page: number) {
    if(page > 0 && page <= this.data?.total_pages) {
      switch(page) {
        // previous page
        case this.actualPage - 1:
          this.store.dispatch(getDataByUrl({
            url: this.data.previous,
            id:this.id
          }));
          break;
        // next page
        case this.actualPage + 1:
          this.store.dispatch(getDataByUrl({
            url: this.data.next,
            id:this.id
          }));
          break;
        // other page
        default:
          this.store.dispatch(getDataByUrl({
            url: this.powerbiService.changePageUrl(this.data.current, page),
            id:this.id
          }));
          break;
      }
    }
  }

  /**
   * Change pagination limit
   * @param limit limit to change
   */
  changeLimit(limit: number) {
    this.store.dispatch(getDataByUrl({
      url: this.powerbiService.changeLimitUrl(this.data.current, limit, this.data.limit, this.actualPage),
      id:this.id
    }));
  }

  /**
   * Change order
   * @param column
   */
  changeOrder(column: string) {
    let newColumn;
    if(!this.isButtonPipe.transform(this.datos[0][column.replace('-', '')]) && !this.IsStatusColumnTable.transform(this.datos[0][column.replace('-', '')])) {
      if(this.orderedBy.includes(column) && !this.orderedBy.includes('-')) column = '-' + column;
      this.orderedBy = column;
      newColumn = column;
    }
    this.store.dispatch(getDataByUrl({
      url: this.powerbiService.changeOrderUrl(this.data.current, newColumn),
      id:this.id
    }));
  }
}
