<div class="animate__animated animate__fadeInRight animate__faster content">
  <div style="overflow-y: hidden">
    <!-- GLOBAL FILTER -->
    <div id="content">
      <div>
        <input
          (keyup.enter)="inputSearch != actualFilter ? search() : ''"
          [(ngModel)]="inputSearch"
          placeholder="Escribe aquí"
        />
      </div>
      <div *ngIf="actualFilter" class="divCleanSearch" (click)="clearSearch()">
        <mat-icon title="Limpiar búsqueda">close</mat-icon>
      </div>
      <div>
        <button
          style="margin-left: 1vw"
          [disabled]="inputSearch == actualFilter"
          (click)="search()"
        >
          BUSCAR
        </button>
      </div>
    </div>
    <div
      *ngIf="datos && datos.length > 0"
      [ngClass]="{ 'col-md-12': true, 'min-table': datos && datos.length > 0 }"
    >
      <!-- CREATE SERVER -->
      <div class="buttonCreate">
        <button (click)="createServer()">
          <i class="fas fa-plus" style="margin-right: 0.5vw"></i>Crear servidor
        </button>
      </div>
      <!-- TABLE SERVERS-->
      <div style="overflow-y: scroll; height: 32vw">
        <table
          *ngIf="loading == false"
          mat-table
          [dataSource]="dataSource"
          multiTemplateDataRows
          class="mat-elevation-z8"
        >
          <!-- COLUMNS-1 -->
          <ng-container
            matColumnDef="{{ column }}"
            *ngFor="
              let column of columnsToDisplay
                | slice : 0 : columnsToDisplay.length - 1
            "
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              style="cursor: pointer"
              (click)="changeOrder(column)"
            >
              <div
                [ngClass]="{
                  order_by: column == order_by || '-' + column == order_by
                }"
                class="divColumns"
              >
                <span>{{ column | titleColumns | titlecase }}</span>
                <mat-icon *ngIf="order_by != '-' + column"
                  >expand_more</mat-icon
                >
                <mat-icon *ngIf="order_by == '-' + column"
                  >expand_less</mat-icon
                >
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="centered">
                <span>{{
                  element[column] == null
                    ? "null"
                    : column == "status"
                    ? (element[column] | statusServers)
                    : element[column]
                }}</span>
              </div>
            </td>
          </ng-container>
          <!-- ACTIONS -->
          <ng-container matColumnDef="Acciones" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>
              <div class="centered">
                <span>{{ "Acciones" | titleColumns | titlecase }}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div style="pointer-events: all !important" class="centered">
                <mat-icon
                  title="Editar servidor"
                  style="cursor: pointer"
                  (click)="editServer($event, element)"
                  >edit</mat-icon
                >
              </div>
              <!-- BORRAR - Hasta que Pascu haga el endpoint bien esto no se pone -->
              <!-- <mat-icon style="cursor: pointer; margin-left: 1vw;" (click)="deleteServer($event)">delete</mat-icon> -->
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="columnsToDisplay.length"
            >
              <div
                class="example-element-detail"
                [@detailExpand]="
                  element == expandedElement ? 'expanded' : 'collapsed'
                "
              >
                <div class="example-element-description">
                  <!-- TABLE AREAS -->
                  <div>
                    <table
                      mat-table
                      [dataSource]="element.areas"
                      class="mat-elevation-z8"
                    >
                      <!-- COLUMNS-4 -->
                      <ng-container
                        matColumnDef="{{ column }}"
                        *ngFor="
                          let column of columnsToDisplayAreas
                            | slice : 0 : columnsToDisplayAreas.length - 4
                        "
                      >
                        <th mat-header-cell *matHeaderCellDef>
                          <div class="centered">
                            <span>{{ column | titleColumns | titlecase }}</span>
                          </div>
                        </th>
                        <td mat-cell *matCellDef="let area">
                          <div class="centered">
                            <span>{{
                              area[column] == null ? "null" : area[column]
                            }}</span>
                          </div>
                        </td>
                      </ng-container>
                      <!-- AREA CLIENT ID -->
                      <ng-container matColumnDef="clientId">
                        <th mat-header-cell *matHeaderCellDef>
                          <div class="centered">
                            <span>{{
                              "Id Cliente" | titleColumns | titlecase
                            }}</span>
                          </div>
                        </th>
                        <td mat-cell *matCellDef="let area">
                          <div class="centered">
                            <span>{{ area.client.id }}</span>
                          </div>
                        </td>
                      </ng-container>
                      <!-- AREA CLIENT TYPE -->
                      <ng-container matColumnDef="clientType_client">
                        <th mat-header-cell *matHeaderCellDef>
                          <div class="centered">
                            <span>{{
                              "Tipo Cliente" | titleColumns | titlecase
                            }}</span>
                          </div>
                        </th>
                        <td mat-cell *matCellDef="let area">
                          <div class="centered">
                            <span>{{ area.client.type_client }}</span>
                          </div>
                        </td>
                      </ng-container>
                      <!-- AREA CLIENT WORKSPACE -->
                      <ng-container matColumnDef="clientWorkspace">
                        <th mat-header-cell *matHeaderCellDef>
                          <div class="centered">
                            <span>{{
                              "Workspace Cliente" | titleColumns | titlecase
                            }}</span>
                          </div>
                        </th>
                        <td mat-cell *matCellDef="let area">
                          <div class="centered">
                            <span>{{ area.client.workspace }}</span>
                          </div>
                        </td>
                      </ng-container>
                      <!-- ACTIONS -->
                      <ng-container matColumnDef="Acciones" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef>
                          <div class="centered">
                            <span>{{
                              "Enviar a otro servidor"
                                | titleColumns
                                | titlecase
                            }}</span>
                          </div>
                        </th>
                        <td mat-cell *matCellDef="let area">
                          <div class="centered">
                            <mat-icon
                              title="Enviar a otro servidor"
                              style="cursor: pointer"
                              (click)="sendArea(area)"
                              >send</mat-icon
                            >
                          </div>
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="columnsToDisplayAreas"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: columnsToDisplayAreas"
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="columnsToDisplay; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: columnsToDisplay"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            [ngClass]="{ noAreas: element.areas.length == 0 }"
            [ngStyle]="{
              cursor: element.areas.length > 0 ? 'pointer' : 'not-allowed'
            }"
            (click)="
              expandedElement = expandedElement === element ? null : element
            "
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="example-detail-row"
          ></tr>
        </table>
      </div>
    </div>
    <!-- LOADING -->
    <app-indeterminate-progress-bar
      *ngIf="loading"
    ></app-indeterminate-progress-bar>
    <!-- PAGINATION -->
    <div class="pie" *ngIf="datos && datos.length > 0 && !disabledPagination">
      <app-pagination
        [totalRegistros]="objects"
        [registrosPorPagina]="limit"
        [totalPages]="totalPages"
        [paginaActual]="page"
        [title]="title"
        (cambiarPagina)="cambiarPagina($event)"
        (cambiarLimit)="cambiarLimit($event)"
      ></app-pagination>
    </div>
    <!-- NO DATA -->
    <div
      class="error"
      *ngIf="(!datos || (datos && datos.length === 0)) && !loading"
    >
      No hay resultados.
    </div>
  </div>
</div>
