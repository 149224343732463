import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { categories } from '../commons/enums/categories';
import {
  ProcessPowerbi,
  StatusPowerbi,
} from '../commons/enums/StatusColorsPowerbi.enum';
import { Pagination } from '../interfaces/Pagination.interface';
import { PendingTasksPowerbi } from '../interfaces/powerbi/PendingTasksPowerbi.interface';
import { ResponseInfoPowerbi } from '../interfaces/powerbi/ResponseInfoPowerbi.interface';
import {
  deletePendingTaskPowerbi,
  sendOperationPowerbi,
} from '../pages/admin/powerbi/store/powerbi.actions';
import { AppState } from '../store/app.state';

const LABEL_TASKS_STORAGE = 'pendingTasksPowerbi';

export interface SwalOptions {
  title: string;
  icon?: SweetAlertIcon;
  timer?: number;
  text?: string;
  confirmButton?: boolean;
}

export interface DefaultResponse {
  result: boolean;
  value: any;
  message?: string;
}

@Injectable({ providedIn: 'root' })
export class PowerbiService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  /**
   * Adds to filter element to search clients and demos
   * @param filter applicated filter
   * @returns filter with others params
   */
  private getFilterPowerbi(filter: Object): Object {
    if (filter && filter['filtro']) {
      return {
        filtro: {
          ...filter['filtro'],
          category__in: [categories.DEMOS, categories.CLIENTES],
        },
      };
    }
    return {
      filtro: { category__in: [categories.DEMOS, categories.CLIENTES] },
    };
  }

  /**
   * Load data pagination of clients and demos from the platform (users who can use powerbi)
   * @param filter applicated filter
   * @param orderby selected order
   * @returns paginated data
   */
  getPaginationPowerbi(
    filter?: Object | null,
    orderby?: string
  ): Observable<Pagination> {
    filter = this.getFilterPowerbi(filter);
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/clientespage${
        orderby ? '/' + orderby : ''
      }`,
      filter
    );
  }

  /**
   * Get paginate data from url
   * @param url url to search (contains limit, page and order)
   * @param filter applicated filter
   * @returns paginated data
   */
  getDataByUrl(url: string, filter?: Object): Observable<Pagination> {
    filter = this.getFilterPowerbi(filter);
    return this.http.post<Pagination>(
      `${environment.databaseURL}${url}`,
      filter
    );
  }

  /**
   * Return actual page spitting an url
   * @param url
   * @returns actual page
   */
  getPageFromUrl(url: string = ''): number {
    let partition: string[] = url.split('/');
    if (partition.length >= 3) {
      let page: string = partition.slice(-3)[0];
      let result = parseInt(page);
      return !isNaN(result) ? result : 0;
    }
    return 0;
  }

  /**
   * Returns new url with new page
   * @param url url to change
   * @param page page to change
   * @returns new url
   */
  changePageUrl(url: string, page: number): string {
    let partition: string[] = url.split('/');
    if (partition.length >= 3) {
      let pages: string[] = partition.splice(-3);
      pages[0] = page + '';
      return partition.concat(pages).join('/');
    }
    return url;
  }

  /**
   * Function that change limit and page from new limit
   * @param url actual url
   * @param limit limit to change
   * @param previousLimit previous limit in url
   * @param page actual selected page
   * @returns new url
   */
  changeLimitUrl(
    url: string,
    limit: number,
    previousLimit: number,
    page: number
  ): string {
    let partition: string[] = url.split('/');
    if (partition.length >= 3) {
      let pages: string[] = partition.splice(-3);
      pages[0] = this.calculatePageNewLimit(limit, previousLimit, page) + '';
      pages[1] = limit + '';

      return partition.concat(pages).join('/');
    }
    return url;
  }

  /**
   * Calculates position of first element in previous limit in new limit
   * @param limit new limit
   * @param prevLimit previous limit
   * @param actPage actual page
   * @returns new page
   */
  calculatePageNewLimit(
    limit: number,
    prevLimit: number,
    actPage: number
  ): number {
    let firstElement = (actPage - 1) * prevLimit + 1;
    return Math.ceil(firstElement / limit);
  }

  /**
   * Change column order in url
   * @param url actual url
   * @param order column to order data
   * @returns new url
   */
  changeOrderUrl(url: string, order: string): string {
    let partition: string[] = url.split('/');
    if (partition.length >= 3) {
      let pages: string[] = partition.splice(-1);
      pages[0] = order;
      return partition.concat(pages).join('/');
    }
    return url;
  }

  /**
   * Returns string body to sendOperationPowerbi post
   * @param workspace client's workspace
   * @param processType
   * @returns string body
   */
  private getBodyFromSendOperationPowerbi(
    workspace: string,
    processType: ProcessPowerbi
  ): string {
    return JSON.stringify({
      args: [{ cliente: workspace, tipo_proceso: processType, table: `all` }],
    });
  }

  /**
   * Send proccesType operation to client powerbi
   * @param workspace client's workspace
   * @param processType
   * @returns process id => "c6c02eb5-3728-4ab5-90d6-d977e6c17eb9"
   */
  sendOperationPowerbi(
    workspace: string,
    processType: ProcessPowerbi
  ): Observable<DefaultResponse> {
    let body: string = this.getBodyFromSendOperationPowerbi(
      workspace,
      processType
    );
    return this.http
      .post<Object>(
        `${environment.celeryURL}/api/task/send-task/app.tasks.update_powerbi`,
        body
      )
      .pipe(
        map((result: Object) => {
          return {
            result: true,
            value: result['task-id'] || null,
          };
        }),
        catchError(({ status, error }) => {
          return of({
            result: false,
            value: null,
            message:
              error.constructor.name === 'String'
                ? error
                : 'No se pudo crear la operación. Inténtelo más tarde.',
          });
        })
      );
  }

  /**
   * Get info from id task
   * @param taskId id's process powerbi client launch
   * @returns info
   */
  statusInfoPowerbiClient(
    task: PendingTasksPowerbi
  ): Observable<ResponseInfoPowerbi> {
    let { workspace, taskId, processType } = task;
    return this.http
      .get<ResponseInfoPowerbi>(
        `${environment.celeryURL}/api/task/info/${taskId}`
      )
      .pipe(
        map((result: ResponseInfoPowerbi) => {
          let newResult: ResponseInfoPowerbi = {
            state: result.state,
            args: result.args,
            exception: result.exception,
          };
          return newResult;
        }),
        catchError(({ status, error }) => {
          /**
           * STATUS 404: TASK NOT FOUND
           */
          let newResult: ResponseInfoPowerbi = {
            state:
              status === 404 ? StatusPowerbi.FAILURE : StatusPowerbi.PENDING,
            args: JSON.stringify(task),
            exception:
              status === 404
                ? 'No existe esa tarea'
                : 'Error al obtener la info',
          };
          return of(newResult);
        })
      );
  }

  /**
   * Get pendingTasks in localStorage
   * @returns pendingTasks in localStorage
   */
  getStatusFromStorage(): PendingTasksPowerbi[] {
    return JSON.parse(localStorage.getItem(LABEL_TASKS_STORAGE));
  }

  /**
   * Save pending tasks in localStorage
   * @param pendingTasksPowerbi actual pending tasks in powerbi
   */
  loadStatusToStorage(pendingTasksPowerbi: PendingTasksPowerbi[]): void {
    localStorage.setItem(
      LABEL_TASKS_STORAGE,
      JSON.stringify(pendingTasksPowerbi)
    );
  }

  /**
   * Clear pending tasks item of localStorage
   */
  clearStatusPowerbiStorage(): void {
    localStorage.removeItem(LABEL_TASKS_STORAGE);
  }

  /**
   * Show info of distincts status of tasks
   * @param title
   * @param icon icon to show
   * @param text possible failure
   */
  swalStatus(values: SwalOptions): void {
    let { title, icon, timer, text, confirmButton = false } = values;
    Swal.fire({
      position: 'top-end',
      icon: icon || 'success',
      title: title,
      text: text,
      showConfirmButton: confirmButton,
      timer: timer,
    });
  }

  /**
   * Check success status for proccessType
   * @param workspace of the client's task
   * @param processType actual
   */
  private checkSuccessStatusOfTask(
    workspace: string,
    processType: ProcessPowerbi
  ): void {
    switch (processType) {
      case ProcessPowerbi.CREATE:
        this.store.dispatch(
          sendOperationPowerbi({
            workspace: workspace,
            processType: ProcessPowerbi.UPDATE,
          })
        );
        this.swalStatus({ title: `¡${workspace} creado!`, timer: 1500 });
        break;
      case ProcessPowerbi.UPDATE:
        this.store.dispatch(deletePendingTaskPowerbi({ workspace: workspace }));
        this.swalStatus({
          title: `¡${workspace} actualizado!`,
          confirmButton: true,
        });
        break;
      default:
        break;
    }
  }

  /**
   * Check failure status
   * @param workspace of the client's task
   * @param message possible error
   */
  private checkFailureStatusOfTask(workspace: string, message?: string): void {
    this.swalStatus({
      title: `¡Error en ${workspace}!`,
      icon: 'error',
      text: message,
      confirmButton: true,
    });
    this.store.dispatch(deletePendingTaskPowerbi({ workspace: workspace }));
  }

  /**
   * Check task for status
   * @param state actual status
   * @param workspace of the client's task
   * @param processType of the client's task ('update' | 'create')
   * @param message possible error
   */
  checkNewStatusOfTask(
    state: StatusPowerbi,
    workspace: string,
    processType: ProcessPowerbi,
    message?: string
  ): void {
    switch (state) {
      case StatusPowerbi.SUCCESS:
        this.checkSuccessStatusOfTask(workspace, processType);
        break;
      case StatusPowerbi.FAILURE:
        this.checkFailureStatusOfTask(workspace, message);
        break;
      case StatusPowerbi.PENDING:
        this.swalStatus({
          title: `${workspace}`,
          icon: 'warning',
          text: message,
        });
        break;
    }
  }

  /**
   * Revoke task to queue
   * @param tokenTask task id
   * @returns action result
   */
  revokeTask(tokenTask: string): Observable<boolean> {
    return this.http
      .post<any>(`${environment.celeryURL}/api/task/revoke/${tokenTask}`, {})
      .pipe(
        map((e) => true),
        catchError((err) => of(false))
      );
  }
}
