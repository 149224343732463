import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import { AppState } from 'src/app/store/app.state';
import { categories } from '../../enums/categories';
import { getEditorStep } from '../state/editor.selector';

interface field {
  name: string,
  type: string,
  validators: Array<any>,
  value: any,
  group: string,
  help?: {placement:string, value:string},
  options?: Array<string>
}

@Component({
  selector: 'app-editor-form',
  templateUrl: './editor-form.component.html',
  styleUrls: ['./editor-form.component.scss']
})
export class EditorFormComponent implements OnInit {

  @Input() client;
  @Input() area;
  @Input() isNew:boolean;
  @Output() formChange:EventEmitter<{ client: FormGroup }> = new EventEmitter();

  todoForm: FormGroup;
  phoneFormat: string;
  fields: Array<field> = [];
  today = new Date(Date.now());
  groups: Array<string> = ['Información general'];
  urlTree: UrlTree;
  type: string;
  private ngUnsubscribe: Subject<any> = new Subject();
  step: number;
  cultivos = [ ...AdminService.cultivesBBDD ];

  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    /** Esto igual debería hacerse en el constructor de editor.component.ts y pasarlo como Input al resto de componentes. */
    if(router){
      this.urlTree = this.router.parseUrl(this.router.url);
      this.type = this.urlTree.queryParams['type'];
      if(![categories.CLIENTES, categories.DEMOS, categories.BAJAS, categories.LEADS].includes(parseInt(this.type))) router.navigateByUrl('/admin');
      // Se indica que es el formulario de creación con la variable nuevo = 'true'
      this.isNew = this.urlTree.queryParams['nuevo'] === 'true';
      // Salimos de la página si pasa que es nuevo y no son las categorías adecuadas
      if(this.isNew && ![categories.CLIENTES, categories.DEMOS].includes(parseInt(this.type))) router.navigateByUrl('/admin');
    }
  }

  ngOnInit(): void {
    this.client ? this.isNew = false : this.isNew = true;

    /** Listen to setp changes */
    this.store.select(getEditorStep)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((step: number) => {
      this.step = step;
      if (step==0 || step ==2){
        this.inicializarForms();
        this.todoForm.valueChanges.subscribe(() => {
          this.formChange.emit({client: this.todoForm});
        });
      }
    })
  }

  inicializarForms(): void {
    const { client, step, area } = this;

    this.fields = null;

    if(step == 0){
      this.groups = this.groups.concat(['Información contractual', 'Herramientas']);

      const formCliente:Array<field> = parseInt(this.type) === 3 ? [
        // Información contractual
        {name: 'alta_freq', type: 'boolean', validators: [Validators.required], value: client?.alta_freq ?? false, group: 'Información contractual', help:{placement: "right", value:"Si el cliente tiene contratados los servicios de Planet (imágenes de alta frecuencia). Solo marcar si se está seguro de que está contratado, ya que puede provocar la descarga de datos de Planet y su respectivo cobro."}},
        {name: 'ha_contrat', type: 'number', validators: client?.alta_freq ? [Validators.required] : [], value: client?.ha_contrat ?? null, group: 'Información contractual', help:{placement: "right", value:"El número de hectáreas de servicios de Planet (imágenes de alta frecuencia) que tiene contratadas el cliente. Solo marcar si se está seguro de que están contratadas, ya que puede provocar la descarga de datos de Planet y su respectivo cobro."}}
      ] : undefined;

      this.fields = [
        // Información general
        {name: 'empresa', type: 'text', validators: [Validators.required], value: client?.empresa ?? '', group: 'Información general'},
        {name: 'ha_empresa', type: 'number', validators: [Validators.required], value: client?.ha_empresa ?? null, group: 'Información general', help:{placement: "right", value:"El total de hectáreas potenciales que tiene la empresa (no tienen porqué ser todas las que están contratadas)."}},
        {name: 'language', type: 'selector', validators: [Validators.required], value: client?.language ?? "spanish", group: 'Información general', options: ["spanish", "english", "portuguese"]},

        // Información contractual
        {name: 'fin_plataforma', type: 'date', validators: [Validators.required], value: client?.fin_plataforma ?? '', group: 'Información contractual', help:{placement: "right", value:"Fecha a partir de la cual los usuarios no podrán entrar a la plataforma y se dejarán de procesar sus áreas. Normalmente coincide con la fecha de final de contrato del cliente."}},
        {name: 'fin_actualizacion', type: 'date', validators: [Validators.required], value: client?.fin_actualizacion ?? '', group: 'Información contractual', help:{placement: "right", value:"Fecha a partir de la cual se dejan de actualizar las áreas del cliente. Esta fecha no puede ser superior a la fecha de fin de plataforma."}},
        {name: 'ha_contrat_sent', type: 'number', validators: [Validators.required], value: client?.ha_contrat_sent ?? null, group: 'Información contractual', help:{placement: "top", value:"Hectáreas de Sentinel contratadas por el cliente."}},

        // Herramientas
        {name: 'descargar_raster', type: 'boolean', validators: [], value: (this.client) ? (this.client.descargar_raster) ? true : false : false, group: 'Herramientas', help:{placement: "right", value:"Opción que permite a los usuarios del cliente descargar los modelos en formato tiff (con los valores del producto)."}}
      ];

      if(parseInt(this.type) === 3) {
        this.fields = this.fields.concat(formCliente);
      }
    }else if(step == 2){
      this.fields = [
        // Información general
        {name: area ? 'titulo' : 'nombre', type: 'text', validators: [Validators.required], value: area ? area?.titulo ?? '' : '', group: 'Información general'},
        {name: 'fin_actualizacion', type: 'date', validators: [Validators.required], value: area?.fin_actualizacion ?? '', group: 'Información general', help:{placement: "right", value:"Fecha a partir de la cual se dejan de actualizar las áreas del cliente. Esta fecha no puede ser superior a la fecha de fin de plataforma."}},
        {name: 'cultivo', type: 'selector', validators: [Validators.required], value: area?.cultivo ?? '', group: 'Información general', options: this.cultivos},
        {name: 'activo', type: 'boolean', validators: [Validators.required], value: area?.activo ?? true, group: 'Información general'}
      ];
    }

    const formGroup = {};
    for (let field of this.fields) {
      formGroup[field.name] = new FormControl(field.value, field.validators);
    }
    this.todoForm = new FormGroup(formGroup);
  }

  controlPlatformEnd() {
    let fin_plat = typeof this.todoForm?.controls['fin_plataforma'].value === 'string' ? new Date(this.todoForm?.controls['fin_plataforma'].value) : this.todoForm?.controls['fin_plataforma'].value;
    let fin_actualizacion = typeof this.todoForm?.controls['fin_actualizacion'].value === 'string' ? new Date(this.todoForm?.controls['fin_actualizacion'].value) : this.todoForm?.controls['fin_actualizacion'].value;
    if(fin_plat < fin_actualizacion) {
      this.todoForm?.controls['fin_actualizacion'].setValue(fin_plat);
      this.todoForm.updateValueAndValidity();
      this.todoForm?.controls['fin_actualizacion'].markAsDirty();
      this.todoForm?.controls['fin_actualizacion'].markAsTouched();
    }
  }

  /**
   * Cambiar el validador de ha_contrat según el valor de alta_freq
   */
  changeValidatorHaContract() {
    // añadir validador o no según el valor de alata_freq
    if(this.todoForm.get('alta_freq').value) {
      this.todoForm.get('ha_contrat').setValidators(Validators.required);
      if (this.client) this.todoForm.get('ha_contrat').setValue(this.client.ha_contrat);
    }
    else {
      this.todoForm.get('ha_contrat').clearValidators();
      // reseteamos el valor de ha_contrat
      if (this.client) this.todoForm.get('ha_contrat').setValue((this.client.ha_contrat) ? 0 : null);
    }
    // actualizar control form
    this.todoForm.get('ha_contrat').updateValueAndValidity();
  }
}
