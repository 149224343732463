<div class="globalDiv">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab *ngFor="let tab of tabs" [label]="tab.title | titlecase">
      <div style="margin-top: 1vh">
        <mat-grid-list cols="2" rowHeight="19vw" gutterSize="15px">
          <mat-grid-tile
            *ngFor="let stat of tab.stats"
            [colspan]="stat.cols"
            [rowspan]="stat.rows"
            style="border: 1px solid rgba(0, 0, 0, 0.12)"
          >
            <div class="statTile">
              <div class="title">
                <span style="margin-left: 1vw; font-size: 1vw">{{
                  stat.title | titlecase
                }}</span>
              </div>
              <div
                [ngStyle]="{
                  height: stat.type == 'table' ? '35.5vw' : '16.5vw'
                }"
              >
                <!-- TYPE TEXT -->
                <app-indicadores
                  *ngIf="stat.type == 'text'"
                  [indicadores]="stat.data"
                  [loading]="loadingIndicadores"
                ></app-indicadores>
                <!-- TYPE GRAPH -->
                <app-graficas
                  *ngIf="stat.type == 'graph'"
                  [graficas]="stat.data"
                  [loading]="loadingGraficas"
                ></app-graficas>
                <!-- TYPE TABLE -->
                <app-dashboard-table
                  *ngIf="stat.type == 'table'"
                  [id]="stat.title"
                  [columns]="stat.columns"
                ></app-dashboard-table>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
