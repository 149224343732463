export const nameColumns = {
  phone: 'teléfono',
  telefono: 'teléfono',
  pais: 'país',
  correo: 'email',
  fecha_registro: 'f. registro',
  ha_empresa: 'Ha. empresa',
  last_login: 'último login',
  contador_login: 'cont. login',
  contador_dashboard: 'cont. dashboard',
  contador_powerbi: 'cont. powerbi',
  ha_activas_sent: 'Ha. activas Sent.',
  fecha_final: 'F. final',
  fin_plataforma: 'Fin plataforma',
  fin_actualizacion: 'fin actualización',
  alta_freq: 'alta frec.',
  ha_contrat: 'Ha. contrat. Planet',
  ha_contrat_sent: 'Ha. contrat. Sent.',
  metodo_pago: 'método pago',
  titulo: 'título',
  superficie: 'superfície',
  subarea: 'subárea',
  id_label: 'id label',
  unidad_01: 'unidad 01',
  unidad_02: 'unidad 02',
  unidad_03: 'unidad 03',
  unidad_04: 'unidad 04',
  unidad_05: 'unidad 05',
  agrupacion: 'agrupación',
  zafra_cont: 'zafra continua',
  freinicio: 'fecha reinicio',
  date_start: 'fecha inicio',
  date_end: 'fecha final',
  historic: 'histórico',
  processed_pctg: 'prog. procesado',
  generate_curves: 'curvas generadas',
  generate_points: 'puntos generados',
  priority: 'prioridad',
  area_nombre: 'nombre área',
  downloaded_pctg: 'prog. descargado',
  processed_tiles_pctg: 'prog. tiles procesados',
  process_init: 'Inicio del procesado',
  process_end: 'Final del procesado',
  fecha_vencimiento: 'fecha vencimiento',
  rol_user: 'rol usuario',
  descripcion: 'descripción',
  link_video: 'link video',
  fecha_inicio: 'fecha inicio',
  clientes: 'id cliente',
  clientes__cultivo: 'cultivo cliente',
  clientes__pais: 'país cliente',
  titulo_portuguese: 'Título Portugués',
  titulo_english: 'Título Inglés',
  cultivos: 'Cultivos',
  fk_provider: 'Provider',
  ha_consum: 'Ha. consumidas Planet',
  ha_activas: 'Ha. activas Planet',
  upload_image: 'Subida de imágenes',
};
