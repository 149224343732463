import { createAction, props } from '@ngrx/store';
import { ProcessPowerbi } from 'src/app/commons/enums/StatusColorsPowerbi.enum';
import { PendingTasksPowerbi } from 'src/app/interfaces/powerbi/PendingTasksPowerbi.interface';

export const LOAD_POWERBI_DATA = '[powerbi] load data';
export const SAVE_POWERBI_DATA = '[powerbi] save powerbi data';
export const GET_POWERBI_DATA_BY_URL = '[powerbi] load data by url';
export const SET_VALUE_STATE_DIRECTY = '[powerbi] set value state directly';
export const ADD_PENDING_TASK_POWERBI = '[powerbi] add pending task powerbi';
export const DELETE_PENDING_TASK_POWERBI =
  '[powerbi] delete pending task powerbi';
export const GET_INFO_TASK_POWERBI = '[powerbi] get info task powerbi';
export const SEND_OPERATION_POWERBI = '[powerbi] send operation powerbi';
export const REVOKE_TASK = '[powerbi] revoke task';

export const loadPowerbiData = createAction(
  LOAD_POWERBI_DATA,
  props<{ filter?: Object; orderby?: string }>()
);

export const getPowerbiDataByUrl = createAction(
  GET_POWERBI_DATA_BY_URL,
  props<{ url: string; filter?: Object }>()
);

export const setValueStatePowerbiDirectly = createAction(
  SET_VALUE_STATE_DIRECTY,
  props<{ value: any; key: string }>()
);

export const addPendingTaskPowerbi = createAction(
  ADD_PENDING_TASK_POWERBI,
  props<{ task: PendingTasksPowerbi }>()
);

export const deletePendingTaskPowerbi = createAction(
  DELETE_PENDING_TASK_POWERBI,
  props<{ workspace: string; clear?: boolean }>()
);

export const getInfoPowerbiTask = createAction(
  GET_INFO_TASK_POWERBI,
  props<{ tasks: PendingTasksPowerbi[] }>()
);

export const sendOperationPowerbi = createAction(
  SEND_OPERATION_POWERBI,
  props<{ workspace: string; processType: ProcessPowerbi }>()
);

export const revokeTask = createAction(
  REVOKE_TASK,
  props<{ task: PendingTasksPowerbi }>()
);
