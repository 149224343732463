// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  databaseURL: 'https://api.naxsolutions.com',
  api_process_client_url: 'https://api-process.naxsolutions.com/v1',
  ip: 'https://geoserver.naxsolutions.com',
  portip: '443',
  powerbi: ['desarrollo', 'template_desarrollo'],
  bigMapsKey:
    'AvIF6wukfXk6v4m9sGBwJZiKcy_XulknVCibu1NCJCwPlbIseiBjsdI6bMzu61Ff',
  celeryURL: 'https://flower.naxsolutions.com',
  ip_app: 'https://pruebaweb.naxsolutions.com',
};

/* production: false,
  api_process_client_url: 'http://localhost:9999/v1',
  databaseURL:'http://127.0.0.1:8000/',
  ip: 'https://ns383435.ip-94-23-252.eu:8443',
  portip: '8443',
  powerbi: ['desarrollo', 'template_desarrollo'],
  bigMapsKey: 'AvIF6wukfXk6v4m9sGBwJZiKcy_XulknVCibu1NCJCwPlbIseiBjsdI6bMzu61Ff',
 */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
