export class Token {
  constructor(public token: any, public drive: string) {}

  getToken() {
    return this.token;
  }

  getTokenDrive() {
    return this.drive;
  }
}
