<div class="animate__animated animate__fadeInRight animate__faster content">
  <div>
    <app-filter-table
      [title]="title"
      (obtenerTipo)="obtenerTipo($event)"
      (filtrarTabla)="filtrar($event)"
      [tipoInput]="tipoInput"
      [actualFilter]="actualFilter"
      [disabled]="disabledFilter || (data && data.objects < 2)"
      [eliminarFiltro]="data && data.objects > 0"
      (eliminarConFiltro)="deleteNovedades($event)"
    ></app-filter-table>
    <div
      [ngClass]="{
        'card col-md-12 py-2': true,
        'min-table': data && datos && datos.length > 0
      }"
    >
      <app-table
        [data]="data"
        [datos]="datos"
        [title]="title"
        [buttons]="buttonsTable"
        [page]="actualPage"
        [danger]="[dangerTitle, dangerFunction]"
        (editarDato)="openCreateNovedades($event)"
        (openNew)="openCreateNovedades($event)"
        (changePage)="changePage($event)"
        (changeLimit)="changeLimit($event)"
        (changeOrderedBy)="changeOrder($event)"
        (sendDrop)="deleteNovedad($event)"
      >
      </app-table>
    </div>
  </div>
</div>
