import { createReducer, on } from "@ngrx/store";
import { initialAltasAreaState } from "./altas-area.state";
import { loadDataAreasDataSuccess, resetAltasArea, setKeyValueAltasArea } from "./altas-area.actions";

const _altasAreaReducer = createReducer(
  initialAltasAreaState,
  on(loadDataAreasDataSuccess, (state, { pagination }) => {
    return {
      ...state,
      pagination: pagination
    }
  }),
  on(setKeyValueAltasArea, (state, { key, value }) => {
    if(!(key in state)) return { ...state };
    return {
      ...state,
      [key]: value
    }
  }),
  on(resetAltasArea, (state) => {
    return {
      ...initialAltasAreaState,
      total_altas: state?.total_altas || -1
    }
  }),
);

export const AltasAreaReducer = (state, action) => {
  return _altasAreaReducer(state, action);
}
