import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { categories } from '../commons/enums/categories';
import { grafica, indicador } from '../interfaces/dashboard.interface';
import { AppState } from '../store/app.state';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  /**
   * Función para darle formato a una gráfica
   * @param data
   * @returns
   */
  formateandoGraficas(data): grafica[] {
    var array: grafica[] = [];
    var devolver: grafica;
    var cultivos: Array<string> = [];
    var superficies: Array<number> = [];

    for (let index = 0; index < data.length; index++) {
      if (data[index].cultivo != null) {
        cultivos.push(data[index].cultivo);
      } else {
        cultivos.push('null');
      }

      superficies.push(data[index].superficie);
    }

    devolver = {
      titulo: 'Hectáreas por cultivo',
      tipo: 'pie',
      sets: [{ label: 'Datos', data: superficies }],
      labels: cultivos,
    };

    array.push(devolver);

    return array;
  }

  /**
   * Función para crear los indicadores principales
   * @param data
   * @returns indicators formated
   */
  filtrarClientes(data: any[]): indicador[] {
    let numCaducados = 0;
    let leads = 0;
    let demos = 0;
    let clientes = 0;
    let bajas = 0;
    let actual = new Date();
    let cultivos = {};
    let indicadores = [];

    data.forEach((x) => {
      if (x.fin_plataforma != null) {
        let d = new Date();
        let c = new Date(d.setMonth(d.getMonth() + 1));
        if (
          new Date(x.fin_plataforma) > actual &&
          new Date(x.fin_plataforma) < c
        ) {
          numCaducados++;
        }
      }

      if (x.category === 1) {
        leads++;
      } else if (x.category === 2) {
        demos++;
      } else if (x.category === 3) {
        clientes++;
      } else if (x.category === 4) {
        bajas++;
      }

      if (x.cultivos) {
        x.cultivos.forEach((cultivo) => {
          if (cultivos[cultivo]) {
            cultivos[cultivo]++;
          } else {
            cultivos[cultivo] = 1;
          }
        });
      }
    });

    indicadores = [
      {
        titulo: 'Clientes caducos',
        numero: numCaducados,
        type: 'clientes_por_tipo',
      },
      { titulo: 'Leads totales', numero: leads, type: 'clientes_por_tipo' },
      { titulo: 'Demos totales', numero: demos, type: 'clientes_por_tipo' },
      {
        titulo: 'Clientes totales',
        numero: clientes,
        type: 'clientes_por_tipo',
      },
      { titulo: 'Bajas totales', numero: bajas, type: 'clientes_por_tipo' },
    ];

    for (const property in cultivos) {
      if (property != 'null')
        indicadores.push({
          titulo: property,
          numero: cultivos[property],
          type: 'clientes_por_cultivo',
        });
    }

    return indicadores;
  }

  getHectareasPorCultivo() {
    return this.http.get<JSON>(
      `${environment.databaseURL}/rest/superficie`,
      this.httpOptions
    );
  }

  /**
   * Función que comunica con la api y obtiene todos los clientes
   * @returns
   */
  getClientes(): Observable<Object[]> {
    return this.http.get<any>(
      `${environment.databaseURL}/rest/clientes`,
      this.httpOptions
    );
  }

  /**
   * Función que devuelve un array con clientes y demos
   * @param clientes array con todos los clientes
   * @returns
   */
  getClientsAndDemos(clientes: Object[]): Object[] {
    return clientes.filter(
      (element) =>
        element['category'] === categories.CLIENTES ||
        element['category'] === categories.DEMOS
    );
  }
}
