import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { loadSuperuserToken, noData } from 'src/app/auth/state/auth.actions';
import { Pagination } from 'src/app/interfaces/Pagination.interface';
import { Tile } from 'src/app/interfaces/tiles.interface';
import { AdminService } from 'src/app/services/admin.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { EditorService } from 'src/app/services/editor.service';
import { TilesService } from 'src/app/services/tiles.sevice';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  borrarNovedades,
  crearNovedad,
  crearNovedadSuccess,
  createAlta,
  createAltaSuccess,
  deleteCola,
  downloadClients,
  editCategory,
  editCategorySuccess,
  editarNovedad,
  editarNovedadSuccess,
  getClientsUniquesLimited,
  getClientsUniquesLimitedSuccess,
  getDataByUrl,
  getDetailNovedad,
  getDetailNovedadSuccess,
  loadClientsDemosAndClients,
  loadData,
  loadDataNovedades,
  loadDataProductosConf,
  loadProccesses,
  loadUsersClient,
  moveProcess,
  moveProcessDown,
  moveProcessUp,
  saveClientData,
  setClientsDemosAndClients,
  setData,
  setFilter,
  setLoading,
  setProccesses,
} from './admin.actions';
import { getFilter } from './admin.selector';

@Injectable()
export class AdminEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private adminService: AdminService,
    private editorService: EditorService,
    private dashboardService: DashboardService,
    private tilesService: TilesService,
    private router: Router
  ) {}

  loadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadData),
      withLatestFrom(this.store.select(getFilter)),
      mergeMap((action) => {
        const { category, body, orderby } = action[0];
        const actualFilter = action[1];
        return this.adminService.getData(category, body, orderby).pipe(
          map((data: Pagination) => {
            if (body || actualFilter)
              this.store.dispatch(
                setFilter({
                  filter:
                    body && body.filtro && !Object.keys(body.filtro).length
                      ? undefined
                      : body,
                })
              );
            this.store.dispatch(setLoading({ loading: false }));
            return setData({ data: data });
          }),
          catchError((error) => {
            this.store.dispatch(setLoading({ loading: false }));
            this.store.dispatch(setFilter({ filter: undefined }));
            return of(setData({ data: undefined }));
          })
        );
      })
    );
  });

  loadDataNovedades$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDataNovedades),
      withLatestFrom(this.store.select(getFilter)),
      mergeMap((action) => {
        const { body } = action[0];
        const actualFilter = action[1];
        return this.adminService.getNovedadesData(body).pipe(
          map((data: Pagination) => {
            if (body || actualFilter)
              this.store.dispatch(
                setFilter({
                  filter:
                    body && body.filtro && !Object.keys(body.filtro).length
                      ? undefined
                      : body,
                })
              );
            this.store.dispatch(setLoading({ loading: false }));
            return setData({ data: data });
          }),
          catchError((error) => {
            this.store.dispatch(setLoading({ loading: false }));
            this.store.dispatch(setFilter({ filter: undefined }));
            return of(setData({ data: undefined }));
          })
        );
      })
    );
  });

  loadDataProductosConf$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDataProductosConf),
      withLatestFrom(this.store.select(getFilter)),
      mergeMap((action) => {
        const { body } = action[0];
        const actualFilter = action[1];
        return this.adminService.getProductosConfData(body).pipe(
          map((data: Pagination) => {
            if (body || actualFilter)
              this.store.dispatch(
                setFilter({
                  filter:
                    body && body.filtro && !Object.keys(body.filtro).length
                      ? undefined
                      : body,
                })
              );
            this.store.dispatch(setLoading({ loading: false }));
            return setData({ data: data });
          }),
          catchError((error) => {
            this.store.dispatch(setLoading({ loading: false }));
            this.store.dispatch(setFilter({ filter: undefined }));
            return of(setData({ data: undefined }));
          })
        );
      })
    );
  });

  getDataByUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getDataByUrl),
      mergeMap((action) => {
        const { url, body, category } = action;
        const peticion = !category
          ? this.adminService.getDataByUrlPost(url, body)
          : this.adminService.getDataByUrl(url, body, category);
        return peticion.pipe(
          map((data: Pagination) => {
            this.store.dispatch(setLoading({ loading: false }));
            return setData({ data: data });
          }),
          catchError((error) => {
            this.store.dispatch(setLoading({ loading: false }));
            this.store.dispatch(setFilter({ filter: undefined }));
            return of(setData({ data: undefined }));
          })
        );
      })
    );
  });

  loadUsersClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUsersClient),
      withLatestFrom(this.store.select(getFilter)),
      exhaustMap((action) => {
        const { idCliente, body } = action[0];
        const actualFilter = action[1];
        return this.adminService.getUsers(idCliente, body).pipe(
          map((data: Pagination) => {
            if (body || actualFilter)
              this.store.dispatch(setFilter({ filter: body }));
            this.store.dispatch(setLoading({ loading: false }));
            return setData({ data: data });
          }),
          catchError((error) => {
            this.store.dispatch(setLoading({ loading: false }));
            this.store.dispatch(setFilter({ filter: undefined }));
            return of(setData({ data: undefined }));
          })
        );
      })
    );
  });

  loadDemosAndClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadClientsDemosAndClients),
      exhaustMap((action) => {
        return this.dashboardService.getClientes().pipe(
          map((result) => {
            const clients = this.dashboardService.getClientsAndDemos(
              JSON.parse(JSON.stringify(result))
            );
            return setClientsDemosAndClients({ clientes: clients });
          }),
          catchError((error) => {
            return of(setClientsDemosAndClients({ clientes: null }));
          })
        );
      })
    );
  });

  changeCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editCategory),
      exhaustMap((action) => {
        // id del cliente
        let clientId = action.client.fk_cliente.fk_cliente.id;

        // Data to send
        let clientDataSend = { category: action.category, verificado: true };

        // client
        let client = action.client;

        return this.editorService
          .editClient(clientId, JSON.stringify(clientDataSend))
          .pipe(
            map((data) => {
              Swal.fire({
                icon: 'success',
                title: 'Convertido correctamente',
                showConfirmButton: false,
                timer: 2000,
              });

              // Aquí hacemos la redirección al editor de Demo
              const id = client['id'];

              // cargar token del superusuario a editar
              this.store.dispatch(loadSuperuserToken({ id: id }));

              // seguridad de implementacion
              let cliente = this.editorService.transformDatos(
                [client],
                'editar'
              )[0];

              const objCopy: any = { ...cliente };
              objCopy['category'] = action.category;

              // Aquí va el dispatch para guardar en el state el cliente
              this.store.dispatch(saveClientData({ cliente: objCopy }));

              if (action.category == 2) {
                this.router.navigateByUrl('/admin/demos/demo?type=2');
              } else if (action.category == 3) {
                this.router.navigateByUrl('/admin/clientes/cliente?type=3');
              }

              return editCategorySuccess();
            }),
            catchError((error) => {
              Swal.fire({
                icon: 'error',
                title: 'Error al enviar los datos',
                text: 'Algunos datos no se han actualizado correctamente',
                showConfirmButton: true,
              });
              return of(editCategorySuccess());
            })
          );
      })
    );
  });

  borrarNovedades$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(borrarNovedades),
      withLatestFrom(this.store.select(getFilter)),
      exhaustMap((action) => {
        const { novedades, filtro } = action[0];
        if (novedades) {
          var body = this.adminService.bodyBorrarNovedades(novedades);
        } else if (filtro) {
          var body = filtro;
        }
        return this.adminService.borrarNovedades(body).pipe(
          map((result) => {
            Swal.fire({
              icon: 'success',
              title:
                novedades && novedades.length > 1
                  ? 'Novedades eliminadas'
                  : 'Novedad eliminada',
              timer: 2000,
            });
            return loadDataNovedades({});
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text:
                novedades && novedades.length > 1
                  ? 'Error al eliminar las novedades'
                  : 'Error al eliminar la novedad',
              timer: 2000,
            });
            return of(loadDataNovedades({}));
          })
        );
      })
    );
  });

  crearNovedad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(crearNovedad),
      exhaustMap((action) => {
        const { novedad } = action;
        return this.adminService.crearNovedad(novedad).pipe(
          map((result) => {
            return crearNovedadSuccess({ result: true });
          }),
          catchError((error) => {
            return of(crearNovedadSuccess({ result: false }));
          })
        );
      })
    );
  });

  editarNovedad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editarNovedad),
      exhaustMap((action) => {
        const { body, id } = action;
        return this.adminService.editarNovedad(body, id).pipe(
          map((result) => {
            return editarNovedadSuccess({ result: true });
          }),
          catchError((error) => {
            return of(editarNovedadSuccess({ result: false }));
          })
        );
      })
    );
  });
  loadProccesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProccesses),
      switchMap((action) => {
        const { processed } = action;
        return this.tilesService.getProccessQueue(processed).pipe(
          map((result: Tile[]) => {
            this.store.dispatch(setLoading({ loading: false }));
            return setProccesses({ data: result });
          }),
          catchError((error) => {
            this.store.dispatch(setProccesses({ data: [] }));
            return of(setLoading({ loading: false }));
          })
        );
      })
    );
  });

  movesProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(moveProcess, moveProcessUp, moveProcessDown),
      exhaustMap((action) => {
        const { type, id } = action;
        // coger el tipo de petición
        switch (type) {
          case moveProcessUp.type:
            var peticion = this.tilesService.putPriorityOneUp(id);
            break;
          case moveProcessDown.type:
            var peticion = this.tilesService.putPriorityOneDown(id);
            break;
          case moveProcess.type:
            var peticion = this.tilesService.putPriorityNextTo(
              id,
              action['idNext']
            );
            break;
        }
        return peticion.pipe(
          map((result: Tile[]) => {
            this.store.dispatch(setLoading({ loading: false }));
            return setProccesses({ data: result });
          }),
          catchError((error) => {
            return of(setLoading({ loading: false }));
          })
        );
      })
    );
  });

  createAlta$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createAlta),
      mergeMap((action) => {
        const { newAlta } = action;
        return this.tilesService.createAlta(newAlta).pipe(
          map((result) => {
            this.store.dispatch(setLoading({ loading: false }));
            return createAltaSuccess({ result: true });
          }),
          catchError((error) => {
            this.store.dispatch(
              createAltaSuccess({ result: false, message: error.error || '' })
            );
            return of(setLoading({ loading: false }));
          })
        );
      })
    );
  });

  deleteCola$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCola),
      mergeMap((action) => {
        const { ids, processed } = action;
        return this.tilesService.deleteCola(ids).pipe(
          map((result) => {
            // cargar procesos
            !processed && this.store.dispatch(setLoading({ loading: false }));
            return !processed
              ? setProccesses({ data: result })
              : loadProccesses({ processed: processed });
          }),
          catchError((error) => {
            return of(setLoading({ loading: false }));
          })
        );
      })
    );
  });

  getDetailNovedad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getDetailNovedad),
      switchMap(({ id }) => {
        return this.adminService.getDetailNovedad(id).pipe(
          map((data: Object) => data),
          catchError((error) => of(null))
        );
      }),
      map((result: Object | null) => {
        return getDetailNovedadSuccess({ value: result });
      })
    );
  });

  getClientsUniquesLimited$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getClientsUniquesLimited),
      switchMap(({ search, limit }) => {
        return this.adminService.getClientsUniquesLimited(search, limit).pipe(
          takeUntil(this.adminService.subjectClients),
          map((result) => {
            return getClientsUniquesLimitedSuccess({ clients: result });
          }),
          catchError((error) =>
            of(getClientsUniquesLimitedSuccess({ clients: [], error: 'error' }))
          )
        );
      })
    );
  });

  downloadClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadClients),
      mergeMap(({ filt, categories: categoriesList, columns }) => {
        return this.adminService
          .downloadClients(
            filt ? filt['filtro'] || {} : {},
            categoriesList,
            columns
          )
          .pipe(
            map((e) => {
              saveAs(e, 'clients.csv');
              return noData();
            }),
            catchError((error) => of(noData()))
          );
      })
    );
  });
}
