<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside
  [ngClass]="{ closed: isClosed }"
  class="left-sidebar"
  data-sidebarbg="skin5"
>
  <div class="scroll-sidebar">
    <nav class="sidebar-nav">
      <ul style="padding: 0" id="sidebarnav" class="p-t-30">
        <li
          class="sidebar-item"
          *ngFor="let item of menu"
          style="margin-bottom: 1vw"
        >
          <div
            (click)="item.isCollapsed = !item.isCollapsed"
            [attr.aria-expanded]="!item.isCollapsed"
            aria-controls="collapseExample"
            class="groupContainer"
          >
            <div class="groupDiv centerIcon">
              <div style="width: 90%; display: flex">
                <div
                  [ngStyle]="{ width: isClosed ? '100%' : '20%' }"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="{{ item.icono }} centerIcon"></i>
                </div>
                <div
                  [ngStyle]="{ display: isClosed ? 'none' : 'flex' }"
                  style="
                    width: 80%;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="menu-collapsed hide-menu" style="color: white">{{
                    item.titulo
                  }}</span>
                </div>
              </div>
              <div
                class="hide-menu"
                style="width: 10%; display: flex; justify-content: end"
              >
                <mat-icon *ngIf="item.isCollapsed">expand_more</mat-icon>
                <mat-icon *ngIf="!item.isCollapsed">expand_less</mat-icon>
              </div>
            </div>
          </div>
          <div>
            <div [(ngbCollapse)]="item.isCollapsed" class="subitemContainer">
              <div
                *ngFor="let subItem of item.subMenu"
                (click)="navigate(subItem.url)"
                class="list-group-item list-group-item-action bg-dark text-white"
                style="margin-bottom: 0.5vw"
              >
                <div
                  class="centerIcon"
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                  "
                >
                  <div
                    [ngStyle]="{ opacity: subItem.active ? '1' : '0.6' }"
                    style="display: flex; width: 90%"
                  >
                    <div
                      [ngStyle]="{ width: isClosed ? '100%' : '20%' }"
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <i class="{{ subItem.icono }} centerIcon"></i>
                    </div>
                    <div
                      [ngStyle]="{ display: isClosed ? 'none' : 'flex' }"
                      style="
                        width: 80%;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span class="menu-collapsed hide-menu">{{
                        subItem.titulo
                      }}</span>
                    </div>
                  </div>
                  <div
                    style="width: 10%"
                    class="hide-menu"
                    style="display: flex; align-items: center"
                  >
                    <ng-container *ngIf="subItem.titulo == 'Altas'">
                      <i
                        *ngIf="statusAltas == 0"
                        style="color: green"
                        class="fas fa-check"
                      ></i>
                      <i
                        *ngIf="statusAltas == 1"
                        style="color: blue"
                        class="fas fa-history"
                      ></i>
                      <i
                        *ngIf="statusAltas == 2"
                        style="color: yellow"
                        class="fas fa-exclamation-triangle"
                      ></i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
