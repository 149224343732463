<!-- RESUMEN -->
<div *ngIf="step==0" class="resumenContainer">
  <div *ngFor="let element of resumen" class="resumenElement">
    <div id="resumenElementTitle"><span>{{element.title}}</span></div>
    <div><span>{{element.value | number}}</span></div>
  </div>
</div>

<!-- DRAG AND DROP -->
<div *ngIf="step==0" class="parcelasContainer">
  <!-- ZAFRA ACTUAL -->
  <div style="width: 50%; margin-right: 0.5vw;">
    <h5>ZAFRA ACTUAL</h5>
    <div
      class="dropzone"
      [class.dragover]="dragover"
      (dragover)="onDragOver($event,'actual')"
      (dragleave)="onDragLeave($event,'actual')"
      (drop)="onDrop($event,'actual'); historic=false;"
      (click)="fileInputActual.click('actual'); historic=false;"
    >
      Arrastra tus archivos aquí <br>
      O <br>
      Haz click para seleccionarlos de un directorio <br>
      Supported: zip, shp, kml, csv, json
    </div>
    <input #fileInputActual type="file" (change)="onFileSelected($event,'actual')" multiple="true" style="display: none;" />
  </div>
  <!-- HISTÓRICO -->
  <div style="width: 50%; margin-left: 0.5vw;">
    <h5>HISTÓRICOS</h5>
    <div
      class="dropzone"
      [class.dragover]="dragoverH"
      (dragover)="onDragOver($event,'historico')"
      (dragleave)="onDragLeave($event,'historico')"
      (drop)="onDrop($event,'historico'); historic=true;"
      (click)="fileInputHistorico.click('historico'); historic=true;"
      [ngClass]="{'disableHistoricShape': area?.terminado!=null}"
    >
      Arrastra tus archivos aquí <br>
      O <br>
      Haz click para seleccionarlos de un directorio <br>
      Supported: zip, shp, kml, csv, json
    </div>
    <input #fileInputHistorico type="file" (change)="onFileSelected($event,'historico')" multiple="true" style="display: none;" />
  </div>
</div>

<mat-horizontal-stepper #stepperData id="stepperUpload" style="height: 100%;" *ngIf="step>0" [selectedIndex]="step">
  <!-- stepper icons -->
  <ng-template matStepperIcon="upload">
    <mat-icon>upload</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="warning">
    <mat-icon>warning</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="fact_check">
    <mat-icon>fact_check</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="save">
    <mat-icon>save</mat-icon>
  </ng-template>

  <!-- step 1: procesado correctamente -->
  <mat-step [completed]="step>0" state="upload">
    <ng-template matStepLabel>Históricos procesados</ng-template>
  </mat-step>
  <!-- step 2: errores -->
  <mat-step [completed]="step>1" state="warning">
    <ng-template matStepLabel>Errores</ng-template>
    <div class="containerTab" *ngIf="step==1">
      <app-upload-table class="table-component" [dataFound]="dataFound" [errorsFound]="errorsFound" [step]="step" [data]="dataTable" [expandible]="true" [columns]="dataColumns" (discardParcela)="discardParcela($event)" (discardErrors)="discardErrors()"></app-upload-table>
    </div>
    <div *ngIf="step==1">
      <button mat-button matStepperPrevious (click)="goBack()">Atrás</button>
      <button mat-button matStepperNext (click)="forward()">Siguiente</button>
    </div>
  </mat-step>
  <!-- step 3: resumen y confirmación -->
  <mat-step [completed]="step>2" state="fact_check" #confirmacion>
    <ng-template matStepLabel>Confirmación</ng-template>
    <div class="containerTab" *ngIf="step==2">
      <app-upload-table class="table-component" [dataFound]="dataFound" [errorsFound]="errorsFound" [step]="step" [data]="dataTable" [expandible]="false" [columns]="dataColumns"></app-upload-table>
    </div>
    <div *ngIf="step==2">
      <button mat-button (click)="goBack()">Atrás</button>
      <button mat-button (click)="forward(stepperData)">Siguiente</button>
    </div>
  </mat-step>
  <!-- step 4: subida de datos -->
  <mat-step [completed]="step>3" state="save">
    <ng-template matStepLabel>Datos subidos</ng-template>
    <div *ngIf="editorLoader | async as loader" class="containerTab" style="text-align: center; flex-direction: column; justify-content: center; align-items: center;">
      <span style="width: 30%;" *ngIf="loader.message && loader.message.length>0">{{loader.message}}</span>
      <mat-progress-bar *ngIf="loader.value" style="width: 30%;" mode="query"></mat-progress-bar>
    </div>
    <div>
      <button mat-button matStepperPrevious (click)="goBack()">Atrás</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>