<div *ngIf="!loading" class="row">
  <!-- NO DATA -->
  <div *ngIf="!graficas || graficas.length == 0; else data" class="divNoData">
    <span>No hay datos para esta gráfica</span>
  </div>
  <!-- CHARTS PIE OR LINE -->
  <ng-template #data>
    <div *ngFor="let grafica of graficas" class="col-md-12">
      <app-line-chart
        *ngIf="grafica.tipo == 'line'"
        [donutData]="grafica.sets"
        [donutLabels]="grafica.labels"
      ></app-line-chart>

      <app-donut-bar
        *ngIf="grafica.tipo == 'pie'"
        [donutData]="grafica.sets"
        [donutLabels]="grafica.labels"
      ></app-donut-bar>
    </div>
  </ng-template>
</div>

<div
  *ngIf="loading"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  "
>
  <mat-spinner></mat-spinner>
</div>
