import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatCalendarCellCssClasses,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Pagination } from 'src/app/interfaces/Pagination.interface';
import {
  FilterAlta,
  columnsNameAltas,
  displayedColumnsAltas,
  filterColumnsAltas,
} from 'src/app/interfaces/altas/Altas.interface';
import { TypesStatus } from 'src/app/pipes/altas/StatusAltasPipe.pipe';
import { CalendarAltasService } from 'src/app/services/CalendarAltas.service';
import { CommonService } from 'src/app/services/Common.service';
import { AltasService } from 'src/app/services/altas.service';
import { clearRequest } from 'src/app/services/clearRequest.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import { CustomAltasCalendarHeaderComponent } from './custom-altas-calendar-header/custom-altas-calendar-header.component';
import { ProductsDialogComponent } from './products-dialog/products-dialog.component';
import {
  changeKeyValueAltas,
  loadDataAltas,
  loadStatistics,
  updateAltasClients,
  updateAltasClientsSuccess,
} from './state/altas.actions';
import {
  getLoadingData,
  getPaginationAltas,
  getStatisticsData,
} from './state/altas.selector';
import { statistic_element } from './state/altas.state';

const DEFAULT_LIMIT: number = 5;

@Component({
  selector: 'app-altas',
  templateUrl: './altas.component.html',
  styleUrls: ['./altas.component.scss'],
})
export class AltasComponent implements OnInit, OnDestroy {
  @ViewChild('datePicker') datePicker!: MatDateRangePicker<Date>;
  headerCalendar = CustomAltasCalendarHeaderComponent;
  columns: number = 2; // number of columns in the display
  orderby: string = 'status';
  grid_tiles: statistic_element[]; // statistics data to show
  inputFilter: string = '';
  filters: FilterAlta[] = []; // statistics filters applyed
  pageSizeOptions: number[] = AltasService.pageSizeOptions;
  limit: number = DEFAULT_LIMIT;
  today: Date = new Date(Date.now());
  columnsName: Object = columnsNameAltas;
  displayedColumns: string[] = [...displayedColumnsAltas];
  filterColumns: string[] = filterColumnsAltas;
  selectedFilter: string | null = filterColumnsAltas
    ? filterColumnsAltas.find((e, index) => index === 0)
    : null;
  showAll: boolean = false;
  totalObjects: number = 0;
  pagination: Pagination = null;
  loadingData: Observable<boolean> = of(false);
  loadYearsList: number[] = [];
  statusDatesLists: Object = {
    error: [],
    executing: [],
  };
  typeStatus = TypesStatus;
  loadingNewDates: boolean = false;
  loadingReprocess: boolean = false;
  startDate: Date;
  endDate: Date;
  lastStartDate: Date;
  lastEndDate: Date;
  pageIndex: number = 0;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private altasService: AltasService,
    private clearRequestService: clearRequest,
    private commonService: CommonService,
    private actions: Actions,
    private calendarAltasService: CalendarAltasService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.today = this.commonService.getTodayDate();
    this.startDate = new Date(this.today);
    this.endDate = this.startDate;
    this.lastStartDate = this.startDate;
    this.lastEndDate = this.startDate;
    this.displayedColumns.pop(); //To not show client type on table

    // load dates from current year
    this.loadDates();

    this.initializeListeners();
    this.listenersCalendar();

    this.grid_tiles = this.altasService.loadingGridTiles(
      this.filters,
      this.filters,
      {
        $gte: `${this.startDate}`,
        $lt: this.convertDateString(`${this.addOneDay(this.endDate)}`),
      },
      this.grid_tiles
    );
    this.store.dispatch(
      loadStatistics({
        filters: this.filters,
        date: {
          $gte: `${this.convertDateString(`${this.startDate}`)}`,
          $lt: `${this.convertDateString(`${this.addOneDay(this.endDate)}`)}`,
        },
      })
    );

    this.columns = window.innerWidth <= 400 ? 1 : 2;

    this.store.dispatch(
      loadDataAltas({
        attributes: {
          limit: this.limit,
          filter: this.filters,
          date: {
            $gte: `${this.convertDateString(`${this.startDate}`)}`,
            $lt: `${this.convertDateString(`${this.addOneDay(this.endDate)}`)}`,
          },
        },
      })
    );
  }

  /**
   * Function to listen the window resize to change the number of columns
   * @param event
   */
  onResize(event) {
    this.columns = event.target.innerWidth <= 1150 ? 1 : 2;
  }

  dropTable({
    previousIndex,
    currentIndex,
    item,
  }: CdkDragDrop<string[]>): void {
    let itemDropped = this.pagination.datos[previousIndex];
  }

  listenersCalendar() {
    this.calendarAltasService.loadingCalendarSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((element) => {
        this.loadingNewDates = element;
      });
  }

  initializeListeners() {
    this.loadingData = this.store.select(getLoadingData);
    /** Statistics data listeners */
    this.store
      .select(getStatisticsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.grid_tiles = value;
        let grid_tiles_copy = cloneDeep(this.grid_tiles);
        if (!this.startDate) this.startDate = new Date();
        if (!this.endDate) {
          [this.endDate, this.lastEndDate] = [this.startDate, this.startDate];
        }
        grid_tiles_copy[3].data = {
          ...grid_tiles_copy[3].data,
          date: { startDate: this.startDate, endDate: this.endDate },
        };

        this.grid_tiles = grid_tiles_copy;
        console.log('grid tiles: ', this.grid_tiles);
      });

    this.store
      .select(getPaginationAltas)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.pagination = value;
        if (value) {
          this.pageSizeOptions = this.altasService.getPageSizeOptions(
            this.pagination.objects
          );
          if (this.pageSizeOptions.length === 1) {
            this.limit = this.pageSizeOptions[0];
          }
        }
      });
  }

  /**
   * Function that opens calendar or adds filter
   * @param group statistic attribute
   * @param attribute statistic value
   */
  onStatisticClick(group: string, attribute: string) {
    this.loadingData.pipe(take(1)).subscribe((value) => {
      if (!value) {
        if (attribute && attribute == 'date') {
          this.openDatepicker();
        } else if (group && attribute && group != 'time') {
          this.filters = this.altasService.deleteByHierarchy(
            this.filters,
            group
          );
          this.filtrar([group, attribute]);
        }
      }
    });
  }

  /**
   * Function to get the date picked in the calendar
   * @param event calendar date change event
   */
  async onDateChange(load: boolean = false) {
    if (
      this.startDate?.toISOString() != this.lastStartDate?.toISOString() ||
      this.endDate?.toISOString() != this.lastEndDate?.toISOString() ||
      load
    ) {
      let range = {
        $gte: `${this.convertDateString(`${this.startDate}`)}`,
        $lt: `${
          this.endDate
            ? this.convertDateString(`${this.addOneDay(this.endDate)}`)
            : this.convertDateString(`${this.addOneDay(this.startDate)}`)
        }`,
      };

      await this.loadDates();
      this.limit = AltasService.pageSizeOptions.includes(this.limit)
        ? this.limit
        : DEFAULT_LIMIT;
      this.clearRequestService.cancelPendingRequestsAltasStatistics();
      this.store.dispatch(
        loadDataAltas({
          attributes: {
            page: 1,
            limit: this.limit,
            filter: this.filters,
            date: range,
          },
        })
      );
      this.grid_tiles = this.altasService.loadingGridTiles(
        this.filters,
        this.filters,
        range,
        this.grid_tiles
      );
      this.store.dispatch(
        loadStatistics({ filters: this.filters, date: range })
      );

      this.lastStartDate = this.startDate;
      this.lastEndDate = this.endDate;
    }
  }

  /**
   * Function to add the active filters and buy the POST body
   * @param event
   */
  filtrar([key, value]: any[]) {
    /** Add filter to active filters */
    this.filters = this.altasService.addFilter(this.filters, key, value);

    this.limit = AltasService.pageSizeOptions.includes(this.limit)
      ? this.limit
      : DEFAULT_LIMIT;

    /** get statistics */
    let range = {
      $gte: `${this.convertDateString(`${this.startDate}`)}`,
      $lt: `${
        this.endDate
          ? this.convertDateString(`${this.addOneDay(this.endDate)}`)
          : this.convertDateString(`${this.addOneDay(this.startDate)}`)
      }`,
    };
    this.grid_tiles = this.altasService.loadingGridTiles(
      this.filters,
      this.filters,
      range,
      this.grid_tiles
    );
    this.store.dispatch(loadStatistics({ filters: this.filters, date: range }));
    this.store.dispatch(
      loadDataAltas({
        attributes: {
          limit: this.limit,
          orderby: this.orderby,
          filter: this.filters,
          date: {
            $gte: `${this.commonService.convertMomentDateToString(
              this.startDate
            )}`,
            $lt: `${
              this.endDate
                ? this.commonService.convertMomentDateToString(
                    this.addOneDay(this.endDate)
                  )
                : this.convertDateString(`${this.addOneDay(this.startDate)}`)
            }`,
          },
        },
      })
    );
    this.pageIndex = 0;
  }

  deleteAllFilters() {
    this.filters = [];

    this.limit = AltasService.pageSizeOptions.includes(this.limit)
      ? this.limit
      : DEFAULT_LIMIT;

    /** get statistics */
    let range = {
      $gte: `${this.convertDateString(`${this.startDate}`)}`,
      $lt: `${
        this.endDate
          ? this.convertDateString(`${this.addOneDay(this.endDate)}`)
          : this.convertDateString(`${this.addOneDay(this.startDate)}`)
      }`,
    };
    this.grid_tiles = this.altasService.loadingGridTiles(
      this.filters,
      this.filters,
      range,
      this.grid_tiles
    );
    this.store.dispatch(loadStatistics({ filters: this.filters, date: range }));
    this.store.dispatch(
      loadDataAltas({
        attributes: {
          limit: this.limit,
          orderby: this.orderby,
          filter: this.filters,
          date: {
            $gte: `${this.commonService.convertMomentDateToString(
              this.startDate
            )}`,
            $lt: `${
              this.endDate
                ? this.commonService.convertMomentDateToString(
                    this.addOneDay(this.endDate)
                  )
                : this.convertDateString(`${this.addOneDay(this.startDate)}`)
            }`,
          },
        },
      })
    );
    this.pageIndex = 0;
  }

  changePage({ length, pageIndex, pageSize }: PageEvent): void {
    if (pageSize !== this.limit) {
      this.limit = pageSize;
      this.store.dispatch(
        loadDataAltas({
          attributes: {
            page: 1,
            limit: pageSize,
            orderby: this.orderby,
            filter: this.filters,
            date: {
              $gte: `${this.commonService.convertMomentDateToString(
                this.startDate
              )}`,
              $lt: `${
                this.endDate
                  ? this.commonService.convertMomentDateToString(
                      this.addOneDay(this.endDate)
                    )
                  : this.convertDateString(`${this.addOneDay(this.startDate)}`)
              }`,
            },
          },
        })
      );
      return;
    }
    this.store.dispatch(
      loadDataAltas({
        attributes: {
          page: pageIndex + 1,
          limit: pageSize,
          orderby: this.orderby,
          filter: this.filters,
          date: {
            $gte: `${this.commonService.convertMomentDateToString(
              this.startDate
            )}`,
            $lt: `${
              this.endDate
                ? this.commonService.convertMomentDateToString(
                    this.addOneDay(this.endDate)
                  )
                : this.convertDateString(`${this.addOneDay(this.startDate)}`)
            }`,
          },
        },
      })
    );
    this.pageIndex = pageIndex;
  }

  /**
   * Change order of table data, actionate when user click in header-cell
   * @param column name of the column
   * @returns
   */
  changeOrder(column: string): void {
    if (this.orderby.endsWith(column)) {
      this.orderby = this.orderby.startsWith('-') ? column : `-${column}`;
    } else {
      this.orderby = column;
    }

    this.store.dispatch(
      loadDataAltas({
        attributes: {
          page: this.altasService.getActualPage(this.pagination),
          limit: this.limit,
          orderby: this.orderby,
          filter: this.filters,
          date: {
            $gte: `${this.commonService.convertMomentDateToString(
              this.startDate
            )}`,
            $lt: `${
              this.endDate
                ? this.commonService.convertMomentDateToString(
                    this.addOneDay(this.endDate)
                  )
                : this.convertDateString(`${this.addOneDay(this.startDate)}`)
            }`,
          },
        },
      })
    );
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const listContainDate = Object.entries(this.statusDatesLists || {})
        .filter(([key, value]) =>
          value?.some((element) => {
            let elementDate = new Date(element);
            return (
              elementDate.getFullYear() === date.getFullYear() &&
              elementDate.getDate() === date.getDate() &&
              elementDate.getMonth() === date.getMonth()
            );
          })
        )
        .map(([key, value]) => key);
      return listContainDate.includes('error')
        ? 'red-circle-altas'
        : listContainDate.includes('executing')
        ? 'blue-circle-altas'
        : '';
    };
  }

  /**
   * Load dates with error or other status when no clicked
   * @param firstDate
   */
  async loadDates(event?: { $gte: Date; $lt: Date }) {
    if (event && !this.isDateRange(event)) {
      let diffEvent: any = event;
      let year = diffEvent.getFullYear();
      let month = diffEvent.getMonth();
      event = { $gte: diffEvent, $lt: new Date(year, month + 1, 0) };
    }

    this.calendarAltasService.loadingCalendarSubject.next(true);
    if (!this.filters.some((filter) => filter.attribute === 'date')) {
      let newFilter = [
        {
          attribute: 'date',
          value: {
            $gte: `${this.convertDateString(
              `${event ? event.$gte : this.startDate}`
            )}`,
            $lt: `${
              event
                ? this.convertDateString(`${event.$lt}`)
                : this.endDate
                ? this.convertDateString(`${this.addOneDay(this.endDate)}`)
                : this.convertDateString(`${this.addOneDay(this.startDate)}`)
            }`,
          },
        },
      ];
      this.filters = this.filters.concat(newFilter);
    } else if (event) {
      const index = this.filters.findIndex((obj) => obj.attribute === 'date');
      if (index !== -1) {
        const newObj = {
          ...this.filters[index],
          value: {
            $gte: this.convertDateString(`${event.$gte}`),
            $lt: this.convertDateString(`${this.addOneDay(event.$lt)}`),
          },
        };
        const newArr = [
          ...this.filters.slice(0, index),
          newObj,
          ...this.filters.slice(index + 1),
        ];
        this.filters = newArr;
      }
    }

    await this.altasService
      .loadDatesFromYear(
        null,
        [-1, 3],
        this.altasService.formatFilters(this.filters)
      )
      .then((dates) => {
        let objDates = Object.entries(dates);
        if (objDates.length) {
          this.statusDatesLists = objDates.reduce((acc, [key, value]) => {
            return {
              ...acc,
              [key]: value,
            };
          }, {});
        }
        this.statusDatesLists = { ...this.statusDatesLists };
        this.loadYearsList.push(
          event ? event.$gte.getFullYear() : this.startDate.getFullYear()
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.calendarAltasService.loadingCalendarSubject.next(false);
    this.calendarAltasService.actualDateViewSubject.next(
      event ? event.$gte : this.startDate
    );
  }

  async openDatepicker() {
    this.calendarAltasService.limitsSubject.next({
      last: this.today,
      first: null,
    });
    this.datePicker.open();
    if (this.startDate) {
      const startDate = new Date(this.startDate);
      startDate.setDate(1);
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(endDate.getDate() - 1);
      this.loadDates({ $gte: startDate, $lt: endDate });
    }
  }

  /**
   * Format the default date to the format required for altasService.loadDatesFromYear()
   * @param dateString
   * @returns String with date and hour
   */
  convertDateString(dateString: string): string {
    if (dateString !== 'undefined') {
      const date = new Date(dateString);
      // difference between timezone and actual hour and minute
      let hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
      let minutesDiff = (date.getHours() - date.getTimezoneOffset()) % 60;
      date.setHours(0 + hoursDiff, 0 + minutesDiff, 0);

      return this.commonService.getISOFormatDate(date);
    }
  }

  /**
   * Add one day to Date pass in the parameter
   * @param date date to change
   * @returns date with one day add
   */
  addOneDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    return newDate;
  }

  /**
   * Check if an object has a range format
   * @param obj
   * @returns
   */
  isDateRange(obj: unknown): boolean {
    let existsObj = typeof obj !== 'object' || obj === null;
    if (existsObj) return false;

    const { $gte, $lt } = obj as Record<string, unknown>;
    return !(!$gte || !$lt);
  }

  reprocessRow(element: any) {
    let id = element?.id;
    if (!id || element.status !== TypesStatus.ERROR) return;

    // TODO: change if the values are not the correct ones
    let body = { status: TypesStatus.QUEUE };
    let subject = new Subject();
    this.actions
      .pipe(ofType(updateAltasClientsSuccess), takeUntil(subject))
      .subscribe(({ result, message, newAlta, description }) => {
        this.loadingReprocess = false;
        Swal.fire({
          icon: result ? 'success' : 'error',
          title: message,
          text: description || '',
        }).then((value) => {
          result && this.onDateChange(true);
          subject.next();
        });
      });
    this.loadingReprocess = true;
    this.store.dispatch(updateAltasClients({ id: id, body: body }));
  }

  openProducts(row) {
    this.dialog.open(ProductsDialogComponent, {
      data: {
        title: `Productos proceso ${row.area.client.workspace}/${row.area.name}`,
        id_process: row.id,
      },
      maxWidth: '60%',
      maxHeight: '90%',
      minWidth: '60%',
    });
  }

  /**
   * Component destroy function
   */
  ngOnDestroy(): void {
    this.store.dispatch(
      changeKeyValueAltas({ key: 'pagination', value: null })
    );
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
