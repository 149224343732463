import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusServers',
})
export class StatusServersPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case -1:
        return 'ERROR';
      case 1:
        return 'ACTIVE';
      case 2:
        return 'INACTIVE';
      default:
        return 'INACTIVE';
    }
  }
}
