import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compareShape',
})
export class CompareShape implements PipeTransform {
  transform(polygons: any[], status: number): number {
    // new=2, edit=1, delete=0, fileToGeojson: null

    if (polygons && status != null) {
      if (status == 5) {
        var result =
          polygons.filter((obj) => {
            return obj.status == 5;
          }).length +
          polygons.filter((obj) => {
            return obj.status == 4;
          }).length;
      } else {
        var result = polygons.filter((obj) => {
          return obj.status == status;
        }).length;
      }
    } else {
      var result = polygons.length;
    }

    return result;
  }
}
