import { Component, Directive, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProcessPowerbi } from 'src/app/commons/enums/StatusColorsPowerbi.enum';
import { ButtonColumnTable } from 'src/app/interfaces/buttonColumnTable';
import { Pagination } from 'src/app/interfaces/Pagination.interface';
import { PendingTasksPowerbi } from 'src/app/interfaces/powerbi/PendingTasksPowerbi.interface';
import { PowerbiService } from 'src/app/services/powerbi.service';
import { AppState } from 'src/app/store/app.state';
import Swal from 'sweetalert2';
import {
  addPendingTaskPowerbi,
  getInfoPowerbiTask,
  getPowerbiDataByUrl,
  loadPowerbiData,
  sendOperationPowerbi,
  revokeTask,
} from './store/powerbi.actions';
import {
  getPendingTasksPowerbi,
  getPowerbiData,
  getPowerbiFilter,
  getPowerbiLoading,
  getPowerbiLoadingStatus,
} from './store/powerbi.selector';

const POWERBI_TITLE: string = 'Lanzar PowerBI';
const INTERVAL_ASK_PENDING_TASK: number = 6000;

@Directive({ selector: 'button[show-info]' })
export class ShowInfo {
  constructor() {}

  @HostListener('click', ['$event.target']) onClick(
    event: HTMLButtonElement
  ): void {
    event.classList.remove('btn-danger-flicker');
    Swal.fire({
      icon: 'info',
      customClass: {
        container: 'rulesSwal',
      },
      title: 'IMPORTANTE',
      html: `<div style="text-align=left;">
      <p>
        <strong>1. </strong>Para lanzar un PBI es necesario estar conectado a una red que NO SEA MiFibra
      </p>
      <p>
        <strong>2. </strong>Siempre que no cargue el PBI de algún cliente, primero se revisa
        que los campos "Fin actualización" (clientes), "Fin plataforma" y "Fin actualización" (cada una de las áreas del cliente) se encuentren rellenados y actualizados respecto a la fecha actual.
      </p>
      <p>
        <strong>3. </strong>En caso de que las fechas mencionadas no estén rellenadas o no estén actualizadas, el proceso se realizará de forma normal pero no se mostrarán los datos en el reporte.
      </p>
      <p>
        <strong>4. </strong>Al lanzar PowerBI, se ejecutarán dos procesos de forma seguida: create y update. En la tabla se podrá ver el proceso
        en el que se encuentra y el estado de dicho proceso. Una vez finalice el proceso update con el estado <b style="color: green;">SUCCESS</b>, PowerBI se habrá actualizado correctamente. Estas columnas se van actualizando cada poco tiempo, espere en la
        página hasta que el proceso haya finalizado.
      </p>
      <p>
        <strong>5. </strong>Puedes ejecutar más de un proceso al mismo tiempo y se puede volver a relanzar un proceso ya comenzado pulsando de nuevo en el botón de "Lanzar PowerBI". Puedes cancelar un proceso ya comenzado desde el botón situado a la derecha de "Tareas pendientes", pulsando en la papelera. 
      </p>
      <p>
        <strong>6. </strong>El proceso completo puede demorar de 5 a 15 minutos aproxidamante dependiendo del tamaño del cliente. Si pasan más de 20 minutos y no se ha completado, conviene cancelar el proceso y contactar con el equipo técnico.   
      </p>
    </div>`,
      confirmButtonText: `¡Entendido!`,
    });
  }
}
@Component({
  selector: 'app-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.scss'],
})
export class PowerbiComponent implements OnInit {
  title: string = 'PowerBI';
  loading: boolean = false;
  loadingStatus: Observable<boolean> = of(false);
  data: Pagination = null;
  actualFilter: Object = null;
  actualPage: number = null;
  tipoInput: string = 'text';
  orderby: string = 'workspace';
  pendingTasksPowerbi: PendingTasksPowerbi[] = [];
  intervalID: any = null;
  loadPage: boolean = true;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private powerbiService: PowerbiService
  ) {}

  ngOnInit() {
    this.store
      .select(getPowerbiLoading)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => (this.loading = value));

    this.loadingStatus = this.store.select(getPowerbiLoadingStatus);

    this.store
      .select(getPendingTasksPowerbi)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.pendingTasksPowerbi = value;
        !this.loadPage && this.powerbiService.loadStatusToStorage(value);
        this.loadPage = false;
      });

    this.store
      .select(getPowerbiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) {
          // copy value to add 'actualizar'
          let copyValue = JSON.parse(JSON.stringify(value));
          this.actualPage = this.powerbiService.getPageFromUrl(
            copyValue.current
          );
          copyValue.datos = copyValue.datos.map((e) => {
            return {
              ...e,
              actualizar: new ButtonColumnTable(POWERBI_TITLE),
            };
          });
          this.data = copyValue;
          this.intervalStatusInfoData();
        } else {
          this.data = value;
          clearInterval(this.intervalID);
        }
      });

    this.store
      .select(getPowerbiFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => (this.actualFilter = value));

    this.store.dispatch(
      loadPowerbiData({ filter: this.actualFilter, orderby: this.orderby })
    );

    if (!this.pendingTasksPowerbi.length) {
      let pendingTasks: PendingTasksPowerbi[] =
        this.powerbiService.getStatusFromStorage() || [];
      if (pendingTasks) {
        pendingTasks.forEach((e) => {
          this.store.dispatch(addPendingTaskPowerbi({ task: e }));
        });
      }
    }
  }

  /**
   * Dispatch revoke task action when delete is clicked
   * @param task task to revoke
   */
  revokePendingTask(task: PendingTasksPowerbi): void {
    this.store.dispatch(revokeTask({ task: task }));
  }

  /**
   * Filter table
   * @param filtro
   */
  filtrar(filtro: any[]) {
    if (filtro) {
      let [key, value, newFilter] = filtro;
      var filter: Object = this.actualFilter
        ? {
            filtro: {
              ...this.actualFilter['filtro'],
              [key + '__icontains']: value,
            },
          }
        : { filtro: { [key + '__icontains']: value } };

      if (newFilter) {
        filter =
          Object.keys(newFilter).includes('filtro') &&
          Object.keys(newFilter['filtro']).length
            ? newFilter
            : null;
      }
    } else {
      filter = this.actualFilter || null;
    }
    this.store.dispatch(
      loadPowerbiData({ filter: filter, orderby: this.orderby })
    );
  }

  /**
   * Change page
   * @param page page to change
   */
  changePage(page: number) {
    if (page > 0 && page <= this.data?.total_pages) {
      switch (page) {
        // previous page
        case this.actualPage - 1:
          this.store.dispatch(
            getPowerbiDataByUrl({
              url: this.data.previous,
              filter: this.actualFilter,
            })
          );
          break;
        // next page
        case this.actualPage + 1:
          this.store.dispatch(
            getPowerbiDataByUrl({
              url: this.data.next,
              filter: this.actualFilter,
            })
          );
          break;
        // other page
        default:
          this.store.dispatch(
            getPowerbiDataByUrl({
              url: this.powerbiService.changePageUrl(this.data.current, page),
              filter: this.actualFilter,
            })
          );
          break;
      }
    }
  }

  /**
   * Change pagination limit
   * @param limit limit to change
   */
  changeLimit(limit: number) {
    this.store.dispatch(
      getPowerbiDataByUrl({
        url: this.powerbiService.changeLimitUrl(
          this.data.current,
          limit,
          this.data.limit,
          this.actualPage
        ),
        filter: this.actualFilter,
      })
    );
  }

  /**
   * Change order
   * @param column
   */
  changeOrder(column: string) {
    this.store.dispatch(
      getPowerbiDataByUrl({
        url: this.powerbiService.changeOrderUrl(this.data.current, column),
        filter: this.actualFilter,
      })
    );
  }

  /**
   * Launch powerbi of selected client
   */
  launchPowerbi(element: any) {
    this.store.dispatch(
      sendOperationPowerbi({
        workspace: element['workspace'],
        processType: ProcessPowerbi.CREATE,
      })
    );
  }

  /**
   * Make calls to powerbi load status from clients in table
   */
  intervalStatusInfoData() {
    if (this.intervalID) clearInterval(this.intervalID);
    this.intervalID = setInterval(() => {
      this.pendingTasksPowerbi?.length &&
        this.store.dispatch(
          getInfoPowerbiTask({ tasks: this.pendingTasksPowerbi })
        );
    }, INTERVAL_ASK_PENDING_TASK);
  }

  /**
   * Function after destroy component
   */
  ngOnDestroy() {
    clearInterval(this.intervalID);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
